import { Box, Card, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import { PropTypes } from 'prop-types';

const OfflineTrainingCard = ({ training }) => (
  <Grid
    item
    md={4}
    xs={12}
    key={training?.trainingId}
  >
    <Card
      sx={{
        '&:hover': {
          transform: 'scale(1.007)',
          transition: 'all ease-in-out 0.3s',
          cursor: 'pointer',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        }
      }}
    >
      <Box
        p={2}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 5,
            textAlign: 'center',
          }}
        >
          {training?.title}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box>
            <Typography>
              Résztvevők száma:
            </Typography>
            <Typography
              sx={{
                mt: 1,
              }}
            >
              Dátum:
            </Typography>
            <Typography
              sx={{
                mt: 1,
              }}
            >
              Helyszín:
            </Typography>
          </Box>
          <Box>
            <Typography>
              {`${training?.numberOfParticipants} fő`}
            </Typography>
            <Typography
              sx={{
                mt: 1,
              }}
            >
              {format(training?.start, 'yyyy.MM.dd HH:mm')}
            </Typography>
            <Typography
              sx={{
                mt: 1,
              }}
            >
              {training?.location}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 1,
          }}
        >
          {training?.alldDay ? 'Egész napos rendezvény' : ''}
        </Typography>
        <Box>
          {/* <CircularProgressBar value={0.5} /> */}
        </Box>
      </Box>
    </Card>
  </Grid>
);

OfflineTrainingCard.propTypes = {
  training: PropTypes.object.isRequired,
};

export default OfflineTrainingCard;
