import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, ButtonBase, Card, CardContent, Grid, Typography } from '@material-ui/core';

const CallResultPickedUp = (props) => {
  const { onNext, setSuccessCall, onBack, handleOpenisConfrimModal } = props;
  // TODO Formik

  return (
    <Formik
      initialValues={
        {
          resultName: '',
          meeting: false,
          appointment: false
        }
      }
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          resultName: values.resultName,
          meeting: values.meeting,
          appointment: values.appointment
        };
        try {
          // Call API to store step data in server session
          // It is important to have it on server to be able to reuse it if user
          // decides to continue later.
          setStatus({ success: true });
          setSubmitting(false);

          if (onNext) {
            onNext('callResult', data);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid
                container
                spacing={3}
                display="flex"
                px={4}
                pt={4}
              >
                <Grid
                  item
                  pt={2}
                  md={5}
                  sm={4}
                  xs={12}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(true);
                      setFieldValue('resultName', 1); // NOTE: SUCCESS
                      setFieldValue('meeting', true);
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '120px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Találkozó megszervezése
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={3}
                  sm={4}
                  xs={6}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(false);
                      setFieldValue('resultName', 9); // NOTE: STILL_THINKING
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Még gondolkodik
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={3}
                  sm={4}
                  xs={6}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(false);
                      setFieldValue('resultName', 10); // NOTE: OTHER
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Egyéb
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                display="flex"
                p={4}
              >
                <Grid
                  item
                  pt={2}
                  md={5}
                  sm={4}
                  xs={12}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(true);
                      setFieldValue('resultName', 2); // NOTE: CALLBACK
                      setFieldValue('appointment', true);
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '120px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Ügyfél visszahívást kér
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={3}
                  sm={4}
                  xs={6}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(false);
                      setFieldValue('resultName', 5); // NOTE: WRONG_NUMBER
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Hibás telefonszám
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={3}
                  sm={4}
                  xs={6}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setSuccessCall(false);
                      setFieldValue('resultName', 6); // AGENT
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Ingatlanos (konkurencia)
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pl: 3,
              mb: 3,
              mt: 3
            }}
          >
            {onBack && (
              <Button
                color="primary"
                onClick={onBack}
                size="large"
                variant="text"
              >
                Vissza
              </Button>
            )}
            <Button
              onClick={handleOpenisConfrimModal}
              color="inherit"
              sx={{ mr: 1 }}
            >
              Megszakítás
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CallResultPickedUp.propTypes = {
  onNext: PropTypes.func,
  setSuccessCall: PropTypes.func,
  onBack: PropTypes.func,
  handleOpenisConfrimModal: PropTypes.func
};

export default CallResultPickedUp;
