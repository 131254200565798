import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as modalsReducer } from '../slices/modals';
import { reducer as applicantReducer } from '../slices/applicant';
import { reducer as employeeReducer } from '../slices/employee';
import { reducer as officeReducer } from '../slices/office';
import { reducer as folderReducer } from '../slices/folder';
import { reducer as ipReducer } from '../slices/ip';
import { reducer as propertyReducer } from '../slices/property';
import { reducer as attributeReducer } from '../slices/attribute';
import { reducer as hintReducer } from '../slices/hint';
import { reducer as callReducer } from '../slices/call';
import { reducer as clientReducer } from '../slices/client';
import { reducer as imageReducer } from '../slices/image';
import { reducer as generationReducer } from '../slices/generation';
import { reducer as searchReducer } from '../slices/search';
import { reducer as trainingReducer } from '../slices/training';
import { reducer as projectsReducer } from '../slices/projects';
import { reducer as taskReducer } from '../slices/task';
import { reducer as rateReducer } from '../slices/rate';
import { reducer as blogReducer } from '../slices/blog';
import loadingReducer from '../slices/loading';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  modals: modalsReducer,
  applicant: applicantReducer,
  employee: employeeReducer,
  office: officeReducer,
  folder: folderReducer,
  ip: ipReducer,
  generation: generationReducer,
  property: propertyReducer,
  attribute: attributeReducer,
  hint: hintReducer,
  call: callReducer,
  client: clientReducer,
  image: imageReducer,
  search: searchReducer,
  loading: loadingReducer,
  training: trainingReducer,
  projects: projectsReducer,
  task: taskReducer,
  rate: rateReducer,
  blog: blogReducer,
});

export default rootReducer;
