import { subHours, subDays, setHours, setMinutes } from 'date-fns';
// import createResourceId from '../utils/createResourceId';
import deepCopy from '../utils/deepCopy';

const now = new Date();

let ips = [
  {
    id: '5ecb868d0f437390ef3ac62c6',
    place: 'lp_siofok',
    client: {
      email: 'test@client1.com',
      name: 'Test Tibor'
    },
    properties: [
      {
        id: '155026253'
      },
      {
        id: '181704220'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    issueDate: subHours(now, 1).getTime(),
    hpDate: subHours(now, 1).getTime(),
    status: 'ready',
    employee: {
      id: '10220',
      email: 'contact@annaheniczky.io',
      name: 'Heniczky Anna'
    },
  },
  {
    id: '5ecb868d0f437390ef3ac62c',
    place: 'lp_siofok',
    client: {
      email: 'test@client1.com',
      name: 'Test Tibor'
    },
    properties: [
      {
        id: '155006253'
      },
      {
        id: '181702220'
      },
      {
        id: '182600315'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    issueDate: subHours(now, 1).getTime(),
    status: 'waitClientResponse',
    employee: {
      id: '10220',
      email: 'contact@annaheniczky.io',
      name: 'Heniczky Anna'
    },
  },
  {
    id: '5ecb868ada8deedee0638502',
    issueDate: subDays(subHours(now, 5), 3).getTime(),
    status: 'waitHP',
    place: 'lp_balatonkenese',
    client: {
      email: 'test@client2.com',
      name: 'Test Sándor'
    },
    properties: [
      {
        id: '181702220'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    employee: {
      id: '10221',
      email: 'sales@janos-jason.com',
      name: 'János Mátyás'
    },
  },
  {
    id: '5ecb868700aba84d0f1c0e48',
    issueDate: subDays(subHours(now, 4), 6).getTime(),
    status: 'ready',
    place: 'lp_siofok',
    client: {
      email: 'test@client3.com',
      name: 'Test Emese'
    },
    properties: [
      {
        id: '181702220'
      },
      {
        id: '182600315'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    employee: {
      id: '10223',
      email: 'support@tomitamas.io',
      name: 'Tomi Tamás'
    },
  },
  {
    id: '5ecb8682038e1ddf4e868764',
    issueDate: subDays(subHours(now, 2), 15).getTime(),
    status: 'deleted',
    place: 'lp_kaposvar',
    client: {
      email: 'test@client4.com',
      name: 'Test Ákos'
    },
    properties: [
      {
        id: '155006253'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    employee: {
      id: '10224',
      email: 'contact@triv-vasaros.hu',
      name: 'Vásáros Timoti'
    },
  },
  {
    id: '5ecb8682038e1ddf4e868765',
    issueDate: subDays(subHours(now, 2), 15).getTime(),
    status: 'deleted',
    place: 'lp_kaposvar',
    client: {
      email: 'test@client5.com',
      name: 'Test Richárd'
    },
    properties: [
      {
        id: '155006253'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    employee: {
      id: '10220',
      email: 'contact@annaheniczky.io',
      name: 'Heniczky Anna'
    },
  }
];

let draftIps = [
  {
    id: '5ecb868d0f437390ef3ac62c6testDraft',
    step: 3,
    place: 'lp_siofok',
    client: {
      email: 'test@client1.com',
      name: 'Test Tibor'
    },
    properties: [
      {
        id: '20003'
      },
      {
        id: '20004'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    issueDate: subHours(now, 1).getTime(),
    hpDate: subHours(now, 1).getTime(),
    status: 'ready',
    employee: {
      id: '10220',
      email: 'contact@annaheniczky.io',
      name: 'Heniczky Anna'
    },
  },
  {
    id: '5ecb868d0f437390ef3ac62c6testDraft2',
    step: 4,
    place: 'lp_siofok',
    client: {
      email: 'k.akos@email.com',
      name: 'Kis Ákos'
    },
    properties: [
      {
        id: '20001'
      },
      {
        id: '20002'
      },
    ],
    ipSendDate: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    issueDate: subHours(now, 1).getTime(),
    hpDate: subHours(now, 1).getTime(),
    status: 'ready',
    employee: {
      id: '10220',
      email: 'contact@annaheniczky.io',
      name: 'Heniczky Anna'
    },
  },
];

class IPApi {
  getIPs() {
    return Promise.resolve(ips);
  }

  getIP() {
    const ip = {
      id: '5ecb86785312dcc69b5799ad',
      currency: '$',
      customer: {
        address: '271 Richmond Rd, Grey Lynn, Auckland 1022, New Zealand',
        company: 'Countdown Grey Lynn',
        email: 'contact@anahenisky.io',
        name: 'Ana Henisky',
        taxId: '6934656584231'
      },
      dueDate: now.getTime(),
      issueDate: subHours(now, 1).getTime(),
      items: [
        {
          id: '5ecb8694db1760a701dfbf74',
          currency: '$',
          description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
          unitAmount: 55.50
        }
      ],
      number: 'DEV-9483',
      status: 'paid',
      subtotalAmount: 50.00,
      taxAmount: 5.50,
      totalAmount: 55.50
    };

    return Promise.resolve(ip);
  }

  createIp(data) {
    return new Promise((resolve, reject) => {
      try {
        const clonedIps = deepCopy(ips);

        const ip = data;

        clonedIps.push(ip);
        // Save changes
        // TODO: DISABLE SAVING TO IPS UNTIL UPDATING GETTERS
        // ips = clonedIps;

        resolve(deepCopy(ip));
      } catch (err) {
        console.error('[IpApi]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateIp({ ipId, update }) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedIps = deepCopy(ips);

        // Find the ip that will be updated
        const ip = ips.find((_ip) => _ip.id === ipId);

        if (!ip) {
          // TODO: Make ip id dynamic on button press
          // reject(new Error('ip not found'));
          return;
        }

        // Update the ip
        Object.assign(ip, update);

        // Save changes
        ips = clonedIps;

        resolve(deepCopy(ip));
      } catch (err) {
        console.error('[Ip Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  getNewIpAddConfig() {
    const config = {
      id: '',
      presentationPlace: 'LidoHome Siófok',
      presentationDate: now.getTime(),
      dataRequest: {
        name: '',
        email: '',
        phoneNumber: ''
      },
      propertySelection: {
        selectedProperties: []
      },
      propertyPresentation: {
        presentation: false,
        presentationSend: false,
        presentationSendDate: null,
        brokerageAgreementGeneration: false,
        hpDate: null
      },
    };
    return Promise.resolve(config);
  }

  // <------IPDraft -> only used by ipList where draft ip-s are listed ------->
  getDraftIps(filters) {
    if (filters) {
      const { types } = filters;
      const typeFilter = Object.keys(types).filter((key) => types[key]);
      // const filteredEvents = draftIps.filter((draftIp) => typeFilter.includes(draftIp.type));

      // return Promise.resolve(deepCopy(filteredEvents));
      return Promise.resolve(deepCopy(typeFilter));
    }
    return Promise.resolve(deepCopy(draftIps));
  }

  createDraftIp(data) {
    return new Promise((resolve, reject) => {
      try {
        const { id, step, properties, client, employee, place, startDate, sendDate, hpDate } = data;
        // Make a deep copy
        const clonedDraftIps = deepCopy(draftIps);

        // Create the new event
        const draftIp = {
          // id: createResourceId(),
          id,
          step,
          client,
          properties,
          employee,
          place,
          startDate,
          sendDate,
          hpDate
        };

        // Add the new event to events
        clonedDraftIps.push(draftIp);

        // Save changes
        draftIps = clonedDraftIps;

        resolve(deepCopy(draftIp));
      } catch (err) {
        console.error('[Draft Ip Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateDraftIp({ draftIpId, update }) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedDraftIps = deepCopy(draftIps);

        // Find the draftIp that will be updated
        const draftIp = draftIps.find((_draftIp) => _draftIp.id === draftIpId);

        if (!draftIp) {
          reject(new Error('draftIp not found'));
          return;
        }

        // Update the draftIp
        Object.assign(draftIp, update);

        // Save changes
        draftIps = clonedDraftIps;

        resolve(deepCopy(draftIp));
      } catch (err) {
        console.error('[Draft Ip Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  deleteDraftIp(draftIpId) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedDraftIps = deepCopy(draftIpId);

        // Find the draftIp that will be removed
        const draftIp = draftIpId.find((_draftIp) => _draftIp.id === draftIpId);

        if (!draftIp) {
          reject(new Error('draftIp not found'));
          return;
        }

        draftIpId = draftIpId.filter((_draftIp) => _draftIp.id !== draftIpId);

        // Save changes
        draftIpId = clonedDraftIps;

        resolve(true);
      } catch (err) {
        console.error('[Draft Ip Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
  // <------IPDraft -> only used by ipList where draft ip-s are listed ------->
}

export const ipApi = new IPApi();
