import { createSlice } from '@reduxjs/toolkit';
import { trainingApi } from '../__fakeApi__/trainingApi';

const initialState = {
  events: [],
  selectedEventId: null,
  selectedRange: null,
  openModals: [],
  openErrors: [],
  isTrainingEditModalOpen: false,
  isConfirmModalOpen: false,
  isHintRejectModalOpen: false,
  isHintModalOpen: false,
  isCallProcessModalOpen: false,
  isTestModalOpen: false,
  isNewCustomerModalOpen: false,
  scrollToTop: 0,
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    getEvents(state, action) {
      state.events = action.payload;
    },
    createEvent(state, action) {
      state.events.push(action.payload);
    },
    selectEvent(state, action) {
      const { eventId } = action.payload;
      state.isTrainingEditModalOpen = true;
      state.eventsisHintModalOpen = true;
      state.isHintRejectModalOpen = true;
      state.selectedEventId = eventId;
    },
    scrollToTop(state) {
      state.scrollToTop += 1;
    },
    openModals(state, action) {
      state.openModals.push(action.payload);
    },
    openErrors(state, action) {
      state.openErrors.push(action.payload);
    },
    closeErrors(state, action) {
      state.openErrors = state.openErrors.filter((error) => error !== action.payload);
    },
    clearErrors(state) {
      state.openErrors = [];
    },
    closeModals(state, action) {
      state.openModals = state.openModals.filter((modal) => modal !== action.payload);
    },
    clearModals(state) {
      state.openModals = [];
    },
    openModal(state) {
      state.isConfirmModalOpen = true;
    },

    updateEvent(state, action) {
      const event = action.payload;

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      state.events = state.events.filter((event) => event.id !== action.payload);
    },

    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isTrainingEditModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },

    openCallProcessModal(state) {
      state.isCallProcessModalOpen = true;
    },

    openTrainingEditModal(state) {
      state.isTrainingEditModalOpen = true;
    },
    openNewCostumerModal(state) {
      state.isNewCustomerModalOpen = true;
    },
    openHintRejectModal(state) {
      state.isHintRejectModalOpen = true;
    },
    openHintEditModal(state) {
      state.isHintModalOpen = true;
    },
    openTestModal(state) {
      state.isTestModalOpen = true;
    },
    closeModal(state) {
      state.isConfirmModalOpen = false;
      state.isTrainingEditModalOpen = false;
      state.isHintModalOpen = false;
      state.isHintRejectModalOpen = false;
      state.isCallProcessModalOpen = false;
      state.isTestModalOpen = false;
      state.isNewCustomerModalOpen = false;
      state.selectedEventId = null;
    }
  }
});

export const { reducer } = slice;

export const switchError = (errorName, open) => (dispatch) => {
  if (errorName === 'clear') {
    dispatch(slice.actions.clearErrors());
  } else if (open) {
    dispatch(slice.actions.openErrors(errorName));
  } else {
    dispatch(slice.actions.closeErrors(errorName));
  }
};

export const switchModal = (modalName, open) => (dispatch) => {
  if (modalName === 'clear') {
    dispatch(slice.actions.clearModals());
  } else if (open) {
    dispatch(slice.actions.openModals(modalName));
  } else {
    dispatch(slice.actions.closeModals(modalName));
  }
};

export const getEvents = (filters) => async (dispatch) => {
  const data = await trainingApi.getEvents(filters);

  dispatch(slice.actions.getEvents(data, filters));
};

export const scrollToTop = () => (dispatch) => {
  dispatch(slice.actions.scrollToTop());
};

export const createEvent = (createData) => async (dispatch) => {
  const data = await trainingApi.createEvent(createData);

  dispatch(slice.actions.createEvent(data));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  const data = await trainingApi.updateEvent({
    eventId,
    update
  });

  dispatch(slice.actions.updateEvent(data));
};

export const deleteEvent = (eventId) => async (dispatch) => {
  await trainingApi.deleteEvent(eventId);

  dispatch(slice.actions.deleteEvent(eventId));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openCallProcessModal = () => (dispatch) => {
  dispatch(slice.actions.openCallProcessModal());
};

export const openTrainingEditModal = () => (dispatch) => {
  dispatch(slice.actions.openTrainingEditModal());
};
export const openTestModal = () => (dispatch) => {
  dispatch(slice.actions.openTestModal());
};
export const openNewCustomerModal = () => (dispatch) => {
  dispatch(slice.actions.openNewCostumerModal());
};
export const openHintRejectEditModal = () => (dispatch) => {
  dispatch(slice.actions.openHintRejectModal());
};

export const openHintEditModal = () => (dispatch) => {
  dispatch(slice.actions.openHintEditModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
