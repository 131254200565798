import { Box, Card, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import { getStatusColorTraining, getStatusLabelTraining } from '../../utils/helperFunctions';
import { Link } from 'react-router-dom';
import CircularProgressBar from '../../components/CircularProgressBar';
import EastIcon from '@mui/icons-material/East';
import { PropTypes } from 'prop-types';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const OnlineTrainingCard = ({ training }) => (
  <Grid
    item
    md={4}
    xs={12}
    p={0}
    key={training?.trainingId}
    component={Link}
    to={`/trainings/otrainings/user-trainings/${training?.userTrainingId}/steps`}
    sx={{
      textDecoration: 'none',
      color: 'inherit',
    }}
  >
    <Card
      sx={{
        '&:hover': {
          transform: 'scale(1.007)',
          transition: 'all ease-in-out 0.3s',
          cursor: 'pointer',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
        }
      }}
    >
      <Box
        p={2}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 5,
            textAlign: 'center',
          }}
        >
          {training?.course?.name}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
        // justifyContent="center"
        // alignItems="center"
        >
          <Box>
            <Box
              display="flex"
              mb={1}
            >
              <OndemandVideoIcon
                opacity={0.7}
                sx={{
                  // color: 'warning.main',
                  color: getStatusColorTraining(training?.status),
                  mr: 1,
                }}
              />
              <Typography>
                {`Leckék: ${training?.numberOfSections}`}
              </Typography>
            </Box>
            <Box
              display="flex"
            >
              <AccessAlarmIcon
                opacity={0.7}
                sx={{
                  color: getStatusColorTraining(training?.status),
                  // color: 'warning.main',
                  mr: 1,
                }}
              />
              <Typography>
                {`Határidő: ${format(training?.dueDate, 'yyyy/MM/dd HH:mm')} `}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Typography>
          {training?.course?.description && `leírás: ${training?.course?.description}`}
        </Typography> */}
        <Box
          my={5}
        >
          <Typography
            sx={{
              mb: 5
            }}
          >
            Tréning előrehaladás
          </Typography>
          <CircularProgressBar
            value={Math.round((training.numberOfDoneSections / training.numberOfSections) * 100)}
            // value={100}
            withText
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
        // mt={1}
        >
          <Box
            display="flex"
          >
            <Typography>
              {getStatusLabelTraining(training?.status)}
            </Typography>
          </Box>
          <EastIcon
            sx={{
              opacity: 0.5,
              mt: 0.4,
            }}
          />
        </Box>
      </Box>
    </Card>
  </Grid>
);

OnlineTrainingCard.propTypes = {
  training: PropTypes.object.isRequired,
};

export default OnlineTrainingCard;
