import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "../store";
import DocumentTextIcon from "../icons/DocumentText";
import HomeIcon from "../icons/Home";
import MenuIcon from "../icons/Menu";
import TemplateIcon from "../icons/Template";
import ClipboardListIcon from "../icons/ClipboardList";
import UserIcon from "../icons/User";
import ClockIcon from "../icons/Clock";
// import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  Button,
  Dialog,
  // Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  // Typography
} from "@material-ui/core";
import BrokerageAgreementEventForm from "./dashboard/modal/BrokerageAgreementEventForm";
import CalendarEventForm from "./dashboard/calendar/CalendarEventForm";
import CalendarDutyEventForm from "./dashboard/calendar/CalendarDutyEventForm";
import CalendarTaskEventForm from "./dashboard/calendar/CalendarTaskEventForm";
import CustomerEditForm from "./dashboard/customer/CustomerEditForm";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { closeModal, openModal } from "../slices/calendar";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import CalendarMeetingEventModal from "./modals/CalendarMeetingEventModal";
import CalendarAppointmentEventModal from "./modals/CalendarAppointmentEventModal";
import ExternalLinkIcon from "../icons/ExternalLink";
import {
  openNewCustomerModal,
  closeModal as closeModal2,
} from "../slices/modals";

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }

  return null;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text,
  },
}));

const Hotkeys = ({ buttonVariant, buttonText }) => {
  const { isModalOpen, isDutyModalOpen, isTaskModalOpen, selectedRange } =
    useSelector((state) => state.calendar);
  const { isNewCustomerModalOpen } = useSelector((state) => state.modals);
  const selectedEvent = useSelector(selectedEventSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  const isMac = navigator.userAgent.indexOf("Mac OS X") !== -1;

  const [brokerageAgreementModalOpen, setBrokerageAgreementModalOpen] =
    useState(false);
  const handleBrokerageAgreementModalOpen = () =>
    setBrokerageAgreementModalOpen(true);
  const handleBrokerageAgreementModalClose = () =>
    setBrokerageAgreementModalOpen(false);

  // const [newCustomerModalOpen, setNewCustomerModalOpen] = useState(false);
  const handleNewCustomerModalOpen = () => {
    dispatch(openNewCustomerModal());
  };
  const handleNewCustomerModalClose = () => {
    dispatch(closeModal2());
  };
  // setNewCustomerModalOpen(false);

  const handleModalMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleModalMenuClose = () => setAnchorEl(null);

  const handleAddNewEvent = (type) => dispatch(openModal(type));

  const handleModalClose = () => dispatch(closeModal());

  useEffect(() => {
    // NOTE: maybe a slice in property slice would be better for perfomance
    if (
      location.pathname.split("/").includes("properties") &&
      location.pathname.split("/").includes("new")
    ) {
      setIsDisabled(true);
      return;
    }
    if (
      location.pathname.split("/").includes("properties") &&
      location.pathname.split("/").includes("edit")
    ) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [location.pathname]);

  return (
    <>
      {buttonText ? (
        <Button
          startIcon={<MenuIcon fontSize="small" />}
          variant={buttonVariant}
          id="demo-positioned-button"
          aria-haspopup="true"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleModalMenuClick}
        >
          {buttonText}
        </Button>
      ) : (
        <Tooltip title="Gyorsgombok">
          <IconButton className={classes.icon} onClick={handleModalMenuClick}>
            {isMac /** Easter egg for windows and mac users. Using an apple command icon on mac and windows start icon like on windows for hotkeys */ ? (
              <KeyboardCommandKeyIcon fontSize="small" />
            ) : (
              <GridViewSharpIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        sx={{ marginTop: 1 }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleModalMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            handleAddNewEvent();
            handleModalMenuClose();
          }}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <DocumentTextIcon fontSize="small" sx={{ marginX: 2 }} />
          Naptárbejegyzés felvétele
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={handleModalMenuClose}
          component={RouterLink}
          to="/properties/new"
          disabled={isDisabled}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <HomeIcon fontSize="small" sx={{ marginX: 2 }} />
          Ingatlan felvétele
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleNewCustomerModalOpen();
            handleModalMenuClose();
          }}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <UserIcon fontSize="small" sx={{ marginX: 2 }} />
          Ügyfél felvétele
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleBrokerageAgreementModalOpen();
            handleModalMenuClose();
          }}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <TemplateIcon fontSize="small" sx={{ marginX: 2 }} />
          Közvetítési nyilatkozat generálása
        </MenuItem>
        <MenuItem
          disableRipple
          // disabled
          onClick={() => {
            handleAddNewEvent("task");
            handleModalMenuClose();
          }}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <ClipboardListIcon fontSize="small" sx={{ marginX: 2 }} />
          Feladat felvétele
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleAddNewEvent("duty");
            handleModalMenuClose();
          }}
          sx={{
            padding: 1,
            color: "navigation.text",
            "&:hover": { color: "navigation.active" },
          }}
        >
          <ClockIcon fontSize="small" sx={{ marginX: 2 }} />
          Ügyelet felvétele
        </MenuItem>
        {/* <MenuItem
          disableRipple
          sx={{
            padding: 1,
            color: 'navigation.text',
            '&:hover': { color: 'navigation.active' }
          }}
        >
          <AssignmentIcon
            fontSize="small"
            sx={{ marginX: 2, }}
          />
          <Grid
            container
            display="flex"
          >
            <Typography>
              Elvégzendő feladatok:
            </Typography>
            <Typography ml={1}>
              5
            </Typography>
          </Grid>
        </MenuItem> */}
        <a
          href="https://lidohome.hu/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <MenuItem
            disableRipple
            // onClick={handleModalMenuClose}
            // component={RouterLink}
            // to="/properties/new"
            sx={{
              padding: 1,
              color: "navigation.text",
              "&:hover": { color: "navigation.active" },
            }}
          >
            <ExternalLinkIcon fontSize="small" sx={{ marginX: 2 }} />
            Külső weblap
          </MenuItem>
        </a>
      </Menu>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleBrokerageAgreementModalClose}
        open={brokerageAgreementModalOpen}
      >
        {brokerageAgreementModalOpen && (
          <BrokerageAgreementEventForm
            onAddComplete={handleBrokerageAgreementModalClose}
            onCancel={handleBrokerageAgreementModalClose}
            onDeleteComplete={handleBrokerageAgreementModalClose}
            onEditComplete={handleBrokerageAgreementModalClose}
          />
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleNewCustomerModalClose}
        open={isNewCustomerModalOpen}
      >
        {isNewCustomerModalOpen && (
          <CustomerEditForm
            onAddComplete={handleNewCustomerModalClose}
            onCancel={handleNewCustomerModalClose}
            onEditComplete={handleNewCustomerModalClose}
            // onDeleteComplete={handleNewCustomerModalClose}
          />
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isModalOpen}
      >
        {/* Dialog renders its body even if not open */}
        {isModalOpen && (
          <CalendarEventForm
            event={selectedEvent}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
            range={selectedRange}
          />
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isDutyModalOpen}
      >
        {isDutyModalOpen && (
          <CalendarDutyEventForm
            event={selectedEvent}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
            range={selectedRange}
          />
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isTaskModalOpen}
      >
        {isTaskModalOpen && (
          <CalendarTaskEventForm
            event={selectedEvent}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
            range={selectedRange}
          />
        )}
      </Dialog>
      {selectedEvent && (
        <>
          <CalendarMeetingEventModal />
          <CalendarAppointmentEventModal />
        </>
      )}
    </>
  );
};

Hotkeys.propTypes = {
  buttonVariant: PropTypes.string,
  buttonText: PropTypes.string,
};

export default Hotkeys;
