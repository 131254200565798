/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
// import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
// import ArrowRightIcon from '../../../icons/ArrowRight';
// import ImageIcon from '../../../icons/Image';
import { useDispatch, useSelector } from '../../../store';
import { useEffect, useState } from 'react';
import { getClientPresentations } from '../../../slices/client';
import { dateFormatter } from '../../../utils/helperFunctions';
import { selectLoading } from '../../../slices/loading';
import PropTypes from 'prop-types';
import ImageIcon from '../../../icons/Image';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import NumberFormatCustom from '../../NumberFormatCustom';
import { alpha } from '@material-ui/core/styles';

// const Presentations = [{
//   type: 'típus',
//   status: 1,
//   presentationDate: null,
//   sent: null,
//   officeID: 1010123132,
//   userId: 1241354135,
//   clientId: 12341434,
//   propertyIds: [213213123, 352535235, 12313123123]
// }]

// const getTypeLabel = (type) => {
//   const map = {
//     email: {
//       text: 'E-mail',
//       color: 'success'
//     },
//     phone: {
//       text: 'Telefon',
//       color: 'warning'
//     },
//     ip: {
//       text: 'IP',
//       color: 'error'
//     },
//     hp: {
//       text: 'HP',
//       color: 'error'
//     }
//   };

//   const { text, color } = map[type];

//   return (
//     <Label color={color}>
//       {text}
//     </Label>
//   );
// };

const ClientPresentation = (props) => {
  const { id, isClientCard } = props;
  const { clientPresentations } = useSelector((state) => state.client);
  const isLoadingArray = useSelector(selectLoading);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(isClientCard ? 3 : 50);
  const dispatch = useDispatch();
  const { REACT_APP_FILE_URL } = process.env;
  const { user } = useAuth();

  const isVisibleForPartner = (property) => {
    if (user.isPartner) {
      if (property?.user?.office?.id !== user.office.id) {
        return false;
      }

      return true;
    }

    return true;
  };

  const presentations = clientPresentations?.[id];

  useEffect(() => {
    dispatch(getClientPresentations(id, pageNumber, pageSize));
  }, [pageNumber, pageSize]);

  return (
    <Box>
      <Card>
        {isLoadingArray.includes(`GET_CLIENT_PRESENTATIONS${id}`) ? (
          <Grid
            container
            width="100%"
            justifyContent="center"
            alignContent="center"
            minHeight={200}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {presentations?.data?.length > 0 ? (
              <>
                <TablePagination
                  component="div"
                  count={presentations?.totalElements || 0}
                  onPageChange={(_, nextPage) => setPageNumber(nextPage)}
                  onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
                  page={presentations?.pageNumber}
                  rowsPerPageOptions={[3, 25, 50]}
                  rowsPerPage={presentations?.pageSize}
                  labelDisplayedRows={
                    ({ from, to, count }) => `Elem ${from}-${to} - Összesen: ${count}`
                  }
                  labelRowsPerPage=""
                />
                {presentations?.data && presentations?.data?.length > 0 && presentations?.data?.map((pres, index) => (
                  <>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      p={2}
                      sx={{ backgroundColor: (theme) => pres?.emailError ? alpha(theme.palette.warning.main, 0.2) : 'background.default' }}
                    >
                      <Stack direction="row" gap={2}>
                        <Typography>
                          {pres?.type}
                        </Typography>
                        <Typography>
                          {pres?.user?.name}
                        </Typography>
                      </Stack>
                      <Typography>
                        {pres?.emailError ? pres.emailError : pres.presentationDate ? dateFormatter(pres.presentationDate) : 'Email küldés alatt...'}
                      </Typography>
                    </Stack>
                    {pres?.properties?.map((property) => (
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          p={2}
                          gap={2}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'background.default',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => {
                            window.open(`/properties/${property?.id}`, '_blank');
                          }}
                        >
                          <>
                            {property?.images[0]?.source?.thumb
                              ? (
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    height: 100,
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    borderRadius: '8px',
                                    width: 130,
                                    '& img': {
                                      height: 'auto',
                                      width: '100%'
                                    }
                                  }}
                                >
                                  <img
                                    style={{
                                      borderRadius: '8px',
                                    }}
                                    alt="propertyPicture"
                                    src={`${REACT_APP_FILE_URL}${property?.images[0]?.source?.thumb}`}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    backgroundColor: 'background.default',
                                    display: 'flex',
                                    height: 100,
                                    justifyContent: 'center',
                                    borderRadius: '8px',
                                    width: 130,
                                  }}
                                >
                                  <ImageIcon fontSize="small" />
                                </Box>
                              )}
                          </>
                          <Typography
                            variant="subtitle2"
                            color="textPrimary"
                          >
                            {property?.publicId}
                          </Typography>
                          {' - '}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {isVisibleForPartner(property) ? (
                              property.address && property.address.city
                                ? `${property?.address?.city} ${property?.address?.suburb
                                  ? ` (${property?.address?.suburb})`
                                  : ""
                                }${property?.address?.street
                                  ? `, ${property.address.street}`
                                  : ""
                                }`
                                : property.formattedAddress

                            ) : (
                              property.address && property.address.city
                                ? `${property?.address?.city} ${property?.address?.suburb
                                  ? ` (${property?.address?.suburb})`
                                  : ""
                                }`
                                : property.formattedAddress
                            )}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            {property.salePrice ? (
                              <NumberFormatCustom
                                value={property.salePrice}
                                displayType="text"
                                suffix={
                                  property?.currency
                                    ? ` ${property?.currency}`
                                    : " Ft"
                                }
                              />
                            ) : (
                              property.rentPrice && (
                                <NumberFormatCustom
                                  value={property.rentPrice}
                                  displayType="text"
                                  suffix={
                                    property?.currency
                                      ? ` ${property?.currency}`
                                      : " Ft"
                                  }
                                />
                              )
                            )}
                            {!property.salePrice && !property.rentPrice && (
                              <Typography variant="subtitle2">
                                Nincs megadva
                              </Typography>
                            )}
                          </Typography>
                        </Stack>
                      </>
                    ))
                    }
                    {index !== presentations?.data?.length - 1 && (
                      <Divider />
                    )}
                  </>
                ))}
              </>
            ) : (
              <Grid
                container
                justifyContent="center"
                mt={5}
                mb={5}
              >
                <Typography
                  fontSize={20}
                >
                  Nem található kiajánlás
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

export default ClientPresentation;

ClientPresentation.propTypes = {
  id: PropTypes.any,
  isClientCard: PropTypes.bool,
};
