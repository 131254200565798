/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { subMinutes } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import FileDropzone from "../../FileDropzone";
import TrashIcon from "../../../icons/Trash";
import {
  createTaskEvent,
  updateTaskEvent,
  deleteTaskEvent,
  deleteEvent,
  updateEvent,
} from "../../../slices/calendar";
import { useDispatch, useSelector } from "../../../store";
import { DesktopDateTimePicker } from "@material-ui/lab";
import AOCAutocomplete from "../../AOCAutocomplete";
import { getEmployees } from "../../../slices/employee";
import { selectLoading } from "../../../slices/loading";
import QuillEditor from "../../QuillEditor";
import useAuth from "../../../hooks/useAuth";
import { isOfficeManagerOrAbove } from "../../../utils/helperFunctions";

const selectStatusOptions = [
  {
    label: "Folyamatban",
    value: "IN_PROGRESS",
  },
  {
    label: "Elvégezve",
    value: "DONE",
  },
  {
    label: "Ütemezve",
    value: "SCHEDULED",
  },
];

const CalendarTaskEventForm = (props) => {
  const {
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
  } = props;
  const isLoadingArray = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.employee);
  const [employeeSearchName, setEmployeeSearchName] = useState();
  const [cover, setCover] = useState(null);
  const { user } = useAuth();

  const getInitialValues = (event, range) => {
    if (event) {
      return {
        color: event.color || "",
        description: event.description || "",
        status: event.status || "",
        start: event.start ? new Date(event.start) : new Date(),
        end: event.end ? new Date(event.end) : new Date(),
        user: event.user || null,
        title: event.title || "",
        type: "task",
        submit: null,
      };
    }

    if (range) {
      return {
        color: "",
        description: "",
        status: "",
        end: new Date(range.end),
        start: new Date(range.start),
        user: null,
        title: "",
        type: "task",
        submit: null,
      };
    }

    return {
      color: "",
      description: "",
      status: "",
      start: new Date(),
      end: new Date(),
      user: isOfficeManagerOrAbove(user.roles) ? null : user,
      title: "",
      type: "task",
      submit: null,
    };
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleDropCover = async ([file]) => {
    const data = await toBase64(file);
    setCover(data);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getEmployees(0, null, employeeSearchName));
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [employeeSearchName]);

  const handleDelete = () => {
    try {
      dispatch(deleteEvent(event.id));

      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveCover = () => {
    setCover(null);
  };

  console.log(user);

  return (
    <Formik
      initialValues={getInitialValues(event, range)}
      validationSchema={Yup.object().shape({
        description: Yup.string().max(5000),
        start: Yup.date()
          .typeError("Nincs helyesen megadva a kezdő dátum (éééé/hh/nn óó/pp)")
          .required("Kezdő dátum megadása kötelező"),
        title: Yup.string().max(255).required("A cím megadása a kötelező!"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            allDay: false,
            title: values.title,
            start: subMinutes(values.end, 30).getTime(),
            end: values.end.getTime(),
            // dueDate: getUnixTime(values.start.getTime()), // TODO: dueDate should be the start or the end date?
            userId: values?.user?.id,
            status: values.status || null,
            description: values.description,
            type: "task",
          };
          if (event) {
            await dispatch(updateEvent(event.id, data));
          } else {
            await dispatch(createTaskEvent(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Naptár frissítve!");

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error("Naptár frissítése sikertelen!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          {console.log(values)}
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event ? "Feladat Szerkesztése" : "Feladat hozzáadása"}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              autoFocus
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              label="Feladat Neve"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <AOCAutocomplete
                options={employees?.data && employees?.data}
                onChange={(_, newValue) => setFieldValue("user", newValue)}
                textFieldOnChange={(e) => setEmployeeSearchName(e.target.value)}
                label="Munkatárs"
                disabled={!isOfficeManagerOrAbove(user.roles)}
                placeholder="Kezdje el beírni a munkatárs nevét."
                loadingArray={isLoadingArray.includes("GET_EMPLOYEES")}
                value={values.user}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Határidő"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue("end", date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.end}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                fullWidth
                label="Státusz"
                name="status"
                select
                value={values.status}
                variant="outlined"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Válassz...</em>
                </MenuItem>
                {selectStatusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ m: 2, mb: 1 }}>
                Leírás
              </Typography>
              <Box sx={{ border: (theme) => theme.palette.mode === "dark" ? '1px solid #555C65' : '1px solid #C4C4C4', borderRadius: '18px' }}>
                <QuillEditor
                  onChange={(value) => setFieldValue('description', value)}
                  sx={{
                    height: 300,
                    maxWidth: 945
                  }}
                  value={values.description}
                  lineLimit={27}
                  lineLength={110}
                />
              </Box>
            </Box>
            {/* {cover
              ? (
                <div>
                  <Box
                    sx={{
                      backgroundImage: `url(${cover})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      height: 420
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: 2
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={handleRemoveCover}
                      variant="text"
                    >
                      Change Cover
                    </Button>
                  </Box>
                </div>
              )
              : (
                <Box sx={{ mt: 2 }}>
                  <FileDropzone
                    accept="image/*"
                    maxFiles={1}
                    onDrop={handleDropCover}
                  />
                </Box>
              )} */}
            {/* {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>
                  {errors.end}
                </FormHelperText>
              </Box>
            )} */}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button color="primary" onClick={onCancel} variant="text">
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CalendarTaskEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
};

export default CalendarTaskEventForm;
