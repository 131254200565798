import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { toggleLoading } from './loading';

const initialState = {
  attributes: [],
  counties: [],
};
const slice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    getAttributeOptions(state, action) {
      state.attributes = action.payload;
    },
    getCountyOptions(state, action) {
      state.counties = action.payload;
    }
  }
});

export const { reducer } = slice;

// GET ATTRIBUTES

export const getAttributeOptions = (filter) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_ATTRIBUTES' }));
  dispatch(slice.actions.getAttributeOptions([], null));
  await axiosConfig.get(
    '/attributes'
  ).then((response) => {
    dispatch(slice.actions.getAttributeOptions(response.data, filter));
  }).catch((error) => {
    // handle error
    toast.error('A lista betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_ATTRIBUTES' }));
  });
};

const getLevel = (value) => {
  switch (value) {
    case 'country':
      return 2;
    case 'countryPart':
      return 4;
    case 'region':
      return 5;
    case 'county':
      return 6;
    case ' subregion':
      return 7;
    case 'city':
      return 8;
    default:
      return 6;
  }
};

export const getLocationOptions = (filter) => async (dispatch) => {
  await axiosConfig.get(
    `/locations/name/e?level=${getLevel(filter)}`
  ).then((response) => {
    dispatch(slice.actions.getCountyOptions(response.data.data));
  }).catch((error) => {
    // handle error
    toast.error('A megyék betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};
export default slice;
