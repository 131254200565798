import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  const { leftSideItems, rightSideItems, onChange, leftText } = props;
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(leftSideItems);
  const [right, setRight] = useState(rightSideItems);

  useEffect(() => {
    setLeft(leftSideItems);
    if (left?.length > 0 && right?.length > 0) { // NOTE: onEdit we should check that items on the right side are not peresented on the left side
      const filteredLeft = left.filter((leftObject) => !right.some((rightObject) => rightObject.id === leftObject.id));
      setLeft(filteredLeft);
    }
  }, [leftSideItems]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  React.useEffect(() => {
    if (onChange) {
      onChange({ right });
    }
  }, [right]);

  const customList = (title, items) => (
    <Card sx={{ maxWidth: '250px' }}>
      <CardHeader
        sx={{ px: 5, py: 2 }}
        avatar={(
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} kiválasztott`}
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          height: 434,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${typeof value === 'object' ? value.name : value}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center" // TODO: if potentialParticipants (in this case) are loading then show a loading icon or skeleton or sg
    >
      <Grid item>
        {customList(leftText || 'Munkatársak', left)}
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList('Hozzáadott', right)}
      </Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  rightSideItems: PropTypes.array,
  leftSideItems: PropTypes.array,
  onChange: PropTypes.func,
  leftText: PropTypes.string
};
