import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  makeStyles,
  Grid,
  Typography,
  Tabs,
  Tab,
  Divider,
  Skeleton,
  Pagination,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import { useDispatch, useSelector } from '../../store';
import ClientCard from './client/ClientCard';
import { openCallProcessModal, searchClients } from '../../slices/client';
import { InView } from 'react-intersection-observer';
import { selectLoading } from '../../slices/loading';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { getAllCalls, setPhone } from '../../slices/call';
import CallCard from './calls/CallCard';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text
  }
}));
function hasNumber(myString) {
  return /\d/.test(myString);
}

const tabs = [
  { label: 'Találatok', value: 'clients', disabled: false },
  { label: 'Híváslista', value: 'calls', disabled: false },
];

const ContentSearch = () => {
  const topRef = useRef(null);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const { calls } = useSelector((state) => state.call);
  const [page, setPage] = useState(1);
  const lazyRef = useRef();
  const [showResults, setShowResults] = useState(false);
  const classes = useStyles();
  const isLoadingArray = useSelector(selectLoading);
  const { phoneSearches, isSearchLoading, isCallProcessModalOpen } = useSelector((state) => state.client);
  const [pageNumber, setPageNumber] = useState(2);
  const [ps, setPs] = useState([]);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('clients');
  const [lastCallSearchValue, setLastCallSearchValue] = useState('');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleTabsChange = (tabValue) => {
    setCurrentTab(tabValue);
  };

  useEffect(() => {
    // if (phoneSearches?.data?.length > 0 && !isLoadingArray.includes('GET_LAZY_CLIENTS_PAGE')) { TODO
    if (phoneSearches?.data?.length > 0) {
      setPs(phoneSearches.data);
    }
  }, [phoneSearches]);

  // open phone number search bar
  const handleKeyPress = useCallback((event) => {
    if (event.ctrlKey === true && event.keyCode === 75) { // NOTE: ctrl+k
      event.preventDefault();
      handleOpen();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isCallProcessModalOpen) {
      handleClose();
    }
  }, [isCallProcessModalOpen]);
  // const search = async () => {
  //   setShowResults(false);
  //   setIsLoading(true);
  //   if (value.length !== 0) {
  //     // TODO: delete it
  //     await wait(10000);
  //     setShowResults(true);
  //     // TODO: filter by value
  //     dispatch(getClients(value));
  //   }
  //   setIsLoading(false);
  // }; FIXME

  const search = () => {
    let dataResults = {};
    setShowResults(false);
    if (hasNumber(value)) {
      const phoneNumber = value.replace(/\s+/g, '');
      dataResults = {
        phone: phoneNumber,
        state: [1, 2, 3, 4]
      };
    } else {
      dataResults = {
        name: value,
        state: [1, 2, 3, 4]
      };
    }
    if (!isSearchLoading && value.length !== 0) { // TODO: bug suspected, enable research
      dispatch(searchClients(dataResults, 'contentSearch'));
      setShowResults(true);
    }
  };

  const lazySearch = () => {
    let dataResults = {};
    if (hasNumber(value)) {
      const phoneNumber = value.replace(/\s+/g, '');
      dataResults = {
        phone: phoneNumber,
        state: [1, 2, 3, 4]
      };
    } else {
      dataResults = {
        name: value,
        state: [1, 2, 3, 4]
      }; // TODO: if u want add email param as well
    }
    dispatch(searchClients(dataResults, 'lazyContentSearch', pageNumber));
    setPageNumber(pageNumber + 1);
  };
  // lazySearch();
  const handleClick = () => {
    setPageNumber(1);
    setPage(1);
    search();
    dispatch(getAllCalls(0, { phone: value }));
    setLastCallSearchValue(value);
  };

  const handleKeyUp = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      search();
    }
  };

  useEffect(() => {
    if (open) {
      document.getElementById('root').style.filter = 'blur(2px)';
      document.getElementById('root').style.transition = '0.3s';
    } else {
      document.getElementById('root').style.filter = 'blur(0px)';
      document.getElementById('root').style.transition = '0.3s';
    }
  }, [open]);

  const handlePageChange = (nextPage) => {
    if (page !== nextPage) {
      setPage(nextPage);
      if (nextPage) {
        dispatch(getAllCalls(nextPage - 1, { phone: lastCallSearchValue }));
      }
    }
  };

  const forceScroll = () => {
    if (topRef !== null) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // useEffect(() => { // NOTE: i removed it because it was causing a bug, it could search twice therefore sometimes it does't show the results.
  //   const delayDebounceFn = setTimeout(() => {
  //     if (value.length > 0) {
  //       handleClick(); /* NOTE: call search func when value change */
  //     } else {
  //       setShowResults(false);
  //     }
  //   }, 400);
  //   return () => {
  //     clearTimeout(delayDebounceFn);
  //   };
  // }, [value]);

  return (
    <>
      <Tooltip title="Ügyfél kereső">
        <IconButton
          className={classes.icon}
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%', backgroundColor: 'background.default' }
        }}
        variant="temporary"
      >
        <Box
          ref={topRef}
          sx={{ p: 3 }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{ p: 3 }}
        >
          <Container maxWidth="lg">
            <Container maxWidth="md">
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <TextField
                  autoFocus
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={(event) => {
                    setShowResults(false);
                    setValue(event.target.value);
                  }}
                  onKeyUp={handleKeyUp}
                  placeholder="Ügyfél neve vagy telefonszáma"
                  value={value}
                />
                <Button /* NOTE: actually this button is unnecessary bc it is call the search func on typing */
                  color="primary"
                  onClick={handleClick}
                  size="large"
                  disabled={!value || isLoadingArray.includes('GET_CALLS')}
                  sx={{ ml: 2 }}
                  variant="contained"
                >
                  Keresés
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(setPhone(value));
                    dispatch(openCallProcessModal());
                  }}
                  size="large"
                  // disabled={!value}
                  disabled={!isValidPhoneNumber(value) && !isValidPhoneNumber(`+36${value}`)}
                  sx={{ ml: 2 }}
                  variant="contained"
                >
                  Hívás
                </Button>
              </Box>
            </Container>
          </Container>
          <Box sx={{ my: 3 }}>
            <Container maxWidth="md">
              <Tabs
                indicatorColor="primary"
                onChange={(_, tabValue) => handleTabsChange(tabValue)}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs?.map((tab) => (
                  <Tab
                    sx={{ backgroundColor: 'background.default' }}
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    disabled={tab.disabled}
                  />
                ))}
              </Tabs>
            </Container>
            <Divider />
          </Box>
          <Container maxWidth="lg">
            {currentTab === 'clients' && (
              <Box>
                {isSearchLoading && value.length !== 0
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )
                  : (
                    <>
                      {showResults && (
                        <Grid
                          container
                          spacing={3}
                        >
                          {phoneSearches?.data?.length === 0
                            ? (
                              <Grid
                                item
                                xs={12}
                              >
                                <Typography
                                  textAlign="center"
                                  color="textSecondary"
                                  variant="h5"
                                >
                                  {`Nincs találat a következőre "${value}"`}
                                </Typography>
                              </Grid>
                            )
                            : (
                              <Grid
                                container
                                spacing={3}
                                mt={2}
                              >
                                {ps.map((client, i) => (
                                  <Grid
                                    item
                                    /* eslint-disable-next-line */
                                    key={`ps${i}`}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    <ClientCard
                                      filter="all"
                                      client={client}
                                      closeSearchBar={handleClose}
                                      searchComponent
                                    />
                                  </Grid>
                                ))}
                                {pageNumber < phoneSearches.totalPages && (
                                  <Grid
                                    item
                                    minHeight={200}
                                    mt={10}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    ref={lazyRef}
                                  >
                                    <InView
                                      delay={0}
                                      onChange={(inView) => {
                                        if (inView && !isLoadingArray.includes('GET_LAZY_CLIENTS_PAGE')) {
                                          lazySearch();
                                        }
                                      }}
                                    >
                                      <Box
                                        ref={lazyRef}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Typography fontSize={30}>
                                          További találatok betöltése...
                                        </Typography>
                                        {isLoadingArray.includes('GET_LAZY_CLIENTS_PAGE') && (
                                          <CircularProgress sx={{
                                            mt: 2
                                          }}
                                          />
                                        )}
                                      </Box>
                                    </InView>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                        </Grid>
                      )}
                    </>
                  )}
              </Box>
            )}
            {currentTab === 'calls' && (
              <>
                {
                  isLoadingArray.includes('GET_CALLS') ? (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      mb={5}
                    >
                      {[...Array(24)].map((e, i) => (
                        <Skeleton
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          animation="wave"
                          height={100}
                          sx={{ my: -4 }}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {calls?.data?.map((call) => (
                          <Box
                            mb={1}
                            key={call.id}
                          >
                            <CallCard call={call} />
                          </Box>
                        ))}
                      </Grid>
                    </>
                  )
                }
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6
                  }}
                >
                  <Pagination
                    count={calls?.totalPages || 1}
                    page={page}
                    showFirstButton
                    showLastButton
                    onChange={(_, nextPage) => {
                      handlePageChange(nextPage);
                      forceScroll();
                    }}
                  />
                </Box>
              </>
            )}
          </Container>
        </Box>
      </Drawer>
      {/* {
        open && (
          <CallProcessModal handleClose={handleClose} />
        )
      } */}
    </>
  );
};

export default ContentSearch;
