import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import { useDispatch } from '../../store';
import FileDropzone from '../FileDropzone';
import { updateStatement } from '../../slices/generation';

const getInitialValues = () => ({
  title: '',
  status: '',
  office: '',
  description: '',
  files: [],
  submit: null
});

const UploadeStatementForm = (props) => {
  const { onCancel, id } = props;
  const [currentFiles, setCurrentFiles] = useState(null);
  const dispatch = useDispatch();

  const handleDropCover = async ([file]) => {
    setCurrentFiles(file);
  };

  const handleRemoveCover = () => {
    setCurrentFiles(null);
  };

  return (
    <Formik
      initialValues={getInitialValues}
      validationSchema={Yup
        .object()
        .shape({
          title: Yup.string().max(38),
          status: Yup.string(),
          office: Yup.string(),
          description: Yup.string().max(5000),
        })}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          const sendFile = {
            id: currentFiles.id,
            title: currentFiles.name,
            file: currentFiles,
            type: currentFiles.status || 1
          };
          dispatch(updateStatement(id, sendFile));
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          onCancel();
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              Dokumentum feltöltése
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            {currentFiles
              ? (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 20,
                    p: 1
                  }}
                >
                  <Box
                    ml={1}
                  >
                    <Typography>
                      {`Fájl neve: ${currentFiles.name}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color="primary"
                      onClick={handleRemoveCover}
                      variant="text"
                    >
                      Eltávolítás
                    </Button>
                  </Box>
                </Grid>
              )
              : (
                <Box sx={{ mt: 2 }}>
                  <FileDropzone
                    // accept="image/*"
                    maxFiles={1}
                    onDrop={handleDropCover}
                  />
                </Box>
              )}
            {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>
                  {errors.end}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              display: 'flex',
              p: 2
            }}
          >
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

UploadeStatementForm.propTypes = {
  onCancel: PropTypes.func,
  id: PropTypes.any,
};

export default UploadeStatementForm;
