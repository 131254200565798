import { createSlice } from '@reduxjs/toolkit';
import { callsApi } from '../__fakeApi__/callsApi';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
// import { openCallProcessModal } from './client';

// TODO: CallApi does not use this!
const initialState = {
  calls: [],
  callProcesses: [],
  privateAdds: {},
  propertyTypeList: [],
  clientCalls: {},
  selectedCallId: null,
  selectedAddId: null,
  phone: null,
  isCallProcessModalOpen: false,
  iframeLink: null,
  realmonitorLink: null,
};

const slice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    getCalls(state, action) {
      state.calls = action.payload;
    },
    getClientCalls(state, action) {
      const { clientId, reponseData } = action.payload;
      state.clientCalls[clientId] = reponseData;
    },
    createCall(state, action) {
      state.calls.unshift(action.payload);
    },
    getPropertyTypeList(state, action) {
      state.propertyTypeList = action.payload;
    },
    setSelectedAddId(state, action) {
      state.selectedAddId = action.payload;
    },
    setPhone(state, action) {
      state.phone = action.payload;
    },
    setIframeLink(state, action) {
      state.iframeLink = action.payload;
    },
    getPrivateAdds(state, action) {
      state.privateAdds = action.payload;
    },
    setPrivateAddPhone(state, action) {
      state.privateAdds.data.forEach((add) => {
        if (add.realId === action.payload.id) {
          add.phone = action.payload.phone;
        }
      });
      // state.privateAdds.phone = action.payload;
    },
    selectCall(state, action) {
      const { callId } = action.payload;
      state.selectedCallId = callId;
    },

    createCallProcess(state, action) {
      state.callProcesses.push(action.payload);
    },

    updateCallProcess(state, action) {
      const callProcess = action.payload;

      state.callProcesses = state.callProcesses.map((_callProcess) => {
        if (_callProcess.id === callProcess.id) {
          return callProcess;
        }

        return _callProcess;
      });
    },
    setRealmonitorLink(state, action) {
      state.realmonitorLink = action.payload;
    },
    // deleteCall(state, action) {
    //   state.calls = state.calls.filter((call) => call.id !== action.payload);
    // },

    closeModal(state) {
      state.isCallProcessModalOpen = false;
      state.selectedCallId = null;
    }
  }
});

export const { reducer } = slice;

export const getEvents = (filters) => async (dispatch) => {
  const data = await callsApi.getEvents(filters);

  dispatch(slice.actions.getEvents(data, filters));
};

// export const createCall = (createData) => async (dispatch) => {
//   const data = await callsApi.createCall(createData);

//   dispatch(slice.actions.createCall(data));
// };

export const setIframeLink = (link) => async (dispatch) => {
  dispatch(slice.actions.setIframeLink(link));
};

export const setSelectedAddId = (id) => async (dispatch) => {
  dispatch(slice.actions.setSelectedAddId(id));
  // dispatch(openCallProcessModal());
};

export const setPhone = (phone) => async (dispatch) => {
  dispatch(slice.actions.setPhone(phone));
};

export const getAllCalls = (pageNumber, data, pageSize, type) => async (dispatch) => {
  if (type === 'clientCallList') {
    dispatch(toggleLoading({ type: `GET_CALLS_${data.clientId}` }));
  } else {
    dispatch(toggleLoading({ type: 'GET_CALLS' }));
  }
  await axiosConfig.post(
    `callhistory?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24}`,
    data
  ).then((response) => {
    if (type === 'clientCallList') {
      const { clientId } = data;
      dispatch(slice.actions.getClientCalls({ clientId, reponseData: response.data }));
    } else {
      dispatch(slice.actions.getCalls(response.data));
    }
  }).catch((error) => {
    console.error(error);
  }).then(() => {
    if (type === 'clientCallList') {
      dispatch(toggleLoading({ type: `GET_CALLS_${data.clientId}` }));
    } else {
      dispatch(toggleLoading({ type: 'GET_CALLS' }));
    }
  });
};

export const saveCall = (data, eventId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_CALLS' }));
  if (eventId) {
    data.eventId = eventId;
  }
  await axiosConfig.post(
    'callhistory/calls',
    data
  ).then((response) => {
    if (data?.realmonitorId) {
      const id = data?.realmonitorId;
      dispatch(slice.actions.setPrivateAddPhone({ id, phone: response.data.phoneNumber }));
    } else {
      dispatch(getAllCalls());
    }
    dispatch(slice.actions.getCalls(response.data));
  }).catch((error) => {
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_CALLS' }));
  });
};

/* eslint-disable */
export const saveCallAdd = (id, data) => async (dispatch) => {
  await axiosConfig.post(
    `privateads/${id}/call`,
    data
  ).then((response) => {
    // console.log(response);
    // const data = {
    //   client: response.data,
    //   date: response.data.date,
    //   description: response.data.description,
    //   id: response.data.id,
    //   phoneNumber: response.data.defaultPhone,
    //   // result: response.data.result,
    // };
    dispatch(slice.actions.setPrivateAddPhone({ id, phone: response.data.phoneNumber }));
    // dispatch(getAllCalls());
  }).catch((error) => {
    console.error(error);
  }).then(() => {

  });
};

export const saveCallState = (id, data,) => async (dispatch) => {
  await axiosConfig.post(
    `clients/${id}/call`,
    data
  ).then(() => {
    // console.log(response);
    // const data = {
    //   client: response.data,
    //   date: response.data.date,
    //   description: response.data.description,
    //   id: response.data.id,
    //   phoneNumber: response.data.defaultPhone,
    //   // result: response.data.result,
    // };
    dispatch(getAllCalls());
  }).catch((error) => {
    console.error(error);
  }).then(() => {

  });
};

export const selectCall = (callId) => async (dispatch) => {
  dispatch(slice.actions.selectCall({ callId }));
};

// export const openModal = () => async (dispatch) => {
//   dispatch(slice.actions.openModal());
// };

export const createCallProcess = (createData) => async (dispatch) => {
  const data = await callsApi.createCallProcess(createData);

  dispatch(slice.actions.createCallProcess(data));
};

export const updateCallProcess = (callProcessId, update) => async (dispatch) => {
  const data = await callsApi.updateCallProcess({
    callProcessId,
    update
  });

  dispatch(slice.actions.updateCallProcess(data));
};

// export const deleteCall = (callId) => async (dispatch) => {
//   await callsApi.deleteCall(callId);

//   dispatch(slice.actions.deleteCall(callId));
// };
/* eslint-disable */

export const getPrivateAdds = (filters) => async (dispatch) => {
  if (filters?.code === 'clear') {
    dispatch(slice.actions.getPrivateAdds({}));
    return;
  }
  dispatch(toggleLoading({ type: 'GET_PRIVATE_ADS' }));
  // console.log('getPrivateAdds');
  await axiosConfig.get(
    // `privateads?pageNumber=${filters?.pageNumber || 0}&pageSize=${filters?.pageSize || 0}&adTypes=${filters?.adTypes || 0}&propertyTypes=${filters?.propertyTypes || 0}&settlements=${filters?.settlements || 0}&floorSpaceFrom=${filters?.floorSpaceFrom || 0}&floorSpaceTo=${filters?.floorSpaceTo || 0}&priceFrom=${filters?.priceFrom || 0}&priceTo=${filters?.priceTo || 0}&unitPriceFrom=${filters?.unitPriceFrom || 0}&unitPriceTo=${filters?.unitPriceTo || 0}`
    `privateads?pageNumber=${filters?.pageNumber || 0}&pageSize=${filters?.pageSize || 0}${filters?.adTypes ? `&adTypes=${filters?.adTypes || 0}` : ''}${filters?.propertyTypes ? `&propertyTypes=${filters?.propertyTypes || 0}` : ''}${filters?.realtyType ? `&propertyTypes=${filters?.realtyType || 0}` : ''}${filters.settlements ? `&settlements=${filters?.settlements || 0}` : ''}${filters.floorSpaceFrom ? `&floorSpaceFrom=${filters?.floorSpaceFrom || 0}` : ''}${filters.floorSpaceTo ? `&floorSpaceTo=${filters?.floorSpaceTo || 0}` : ''}${filters?.priceFrom ? `&priceFrom=${filters?.priceFrom || 0}` : ''}${filters?.priceTo ? `&priceTo=${filters?.priceTo || 0}` : ''}${filters?.unitPriceFrom ? `&unitPriceFrom=${filters?.unitPriceFrom || 0}` : ''}${filters?.unitPriceTo ? `&unitPriceTo=${filters?.unitPriceTo || 0}` : ''}`
  ).then((response) => {
    dispatch(slice.actions.getPrivateAdds(response.data));
  }).catch((error) => {
    // dispatch(slice.actions.getPrivateAdds({}));
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_PRIVATE_ADS' }));
  });
};

export const getPropertyTypeList = () => async (dispatch) => {
  await axiosConfig.get(
    'privateads/propertyTypes'
  ).then((response) => {
    // console.log(response.data, 'response.data');
    dispatch(slice.actions.getPropertyTypeList(response.data));
  }).catch((error) => {
    console.error(error);
  }).then(() => {

  });
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const getRealmonitorLink = () => async (dispatch) => {
  await axiosConfig.get(
    'privateads/realmonitor-link'
  ).then((response) => {
    dispatch(slice.actions.setRealmonitorLink(response.data.link));
  }).catch((error) => {
    console.error(error);
  }).then(() => {
  });
};

export const clearRealmonitorLink = () => async (dispatch) => {
  dispatch(slice.actions.setRealmonitorLink(null));
};

export default slice;
