import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { addMinutes } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import DesktopDateTimePicker from "@material-ui/lab/DesktopDateTimePicker";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
// eslint-disable-next-line no-unused-vars
import {
  clearCalendarTypes,
  clearCalendars,
  createEvent,
  deleteEvent,
  getCalendarTypes,
  getCalendars,
  searchCalendars,
  updateEvent,
} from "../../../slices/calendar";
import { useDispatch, useSelector } from "../../../store";
import AOCAutocomplete from "../../AOCAutocomplete";
import { isOfficeManagerOrAbove } from "../../../utils/helperFunctions";
import useAuth from "../../../hooks/useAuth";
import { selectLoading } from "../../../slices/loading";

// const selectReminderOptions = [
//   {
//     label: 'Az esemény előtt',
//     value: 0
//   },
//   {
//     label: '5 percel előtte',
//     value: 5
//   },
//   {
//     label: '10 percel előtte',
//     value: 10
//   },
//   {
//     label: '15 percel előtte',
//     value: 15
//   },
//   {
//     label: '30 percel előtte',
//     value: 30
//   },
//   {
//     label: '1 órával előtte',
//     value: 60
//   },
//   {
//     label: '2 órával előtte',
//     value: 120
//   },
//   {
//     label: '1 nappal előtte',
//     value: 1440
//   },
// ];

const selectPriorityOptions = [
  {
    label: "Fontos",
    value: 2,
  },
  {
    label: "Normál",
    value: 1,
  },
];

const getCalendarTypeLabel = (calendarType) => {
  switch (calendarType) {
    case "USER":
      return "Saját";
    case "OFFICE":
      return "Iroda";
    case "DUTY":
      return "Ügyelet";
    case "TRAINING":
      return "Képzés";
    case "MEETING":
      return "Meeting";
    default:
      return "";
  }
};

const getCalendarTypeOptions = (calendarTypes) => {
  const options = [];
  calendarTypes.forEach((calendarType) => {
    options.push({
      label: getCalendarTypeLabel(calendarType),
      id: calendarType,
      disabled: calendarType === "TRAINING",
    });
  });
  return options;
};

const getInitialValues = (event, range, calendars) => {
  if (event) {
    return {
      title: event.title || "",
      allDay: event.allDay || false,
      isPrivate: event.isPrivate || false,
      start: event.start ? new Date(event.start) : new Date(),
      end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      place: event.place || "",
      // reminder: event.reminder || '',
      priority: event.priority || 1,
      description: event.description || "",
      // calendarType: null, // TODO: find out how can u get this from calendar object
      calendar: event
        ? calendars.find((calendar) => calendar.id === event?.calendar?.id)
        : null,
      submit: null,
    };
  }

  if (range) {
    return {
      title: "",
      allDay: false,
      isPrivate: false,
      start: new Date(range.start),
      end: new Date(range.end),
      place: "",
      // reminder: '',
      priority: 1,
      description: "",
      // calendarType: null,
      calendar: null,
      submit: null,
    };
  }

  return {
    title: "",
    allDay: false,
    isPrivate: false,
    start: new Date(),
    end: addMinutes(new Date(), 30),
    place: "",
    // reminder: '',
    priority: 1,
    description: "",
    // calendarType: null,
    calendar: null,
    submit: null,
  };
};

const CalendarEventForm = (props) => {
  const {
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
  } = props;
  const dispatch = useDispatch();
  const isLoadingArray = useSelector(selectLoading);
  const { user } = useAuth();
  const { calendars, calendarTypes } = useSelector((state) => state.calendar);
  const calendarTypeOptions = getCalendarTypeOptions(calendarTypes);
  const formikRef = useRef();
  // const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({
    calendarTypes: event ? [event.calendar?.type] : [], // TODO: find out how can u get this from calendar object
  });
  const isCalendarAutocomplete =
    filters.calendarTypes[0] &&
    filters.calendarTypes[0] !== "USER" &&
    filters.calendarTypes[0] !== "TRAINING";
  const isSaveDisabled = event && event.calendar?.type === "TRAINING";

  useEffect(() => {
    // dispatch(getCalendars());
    dispatch(getCalendarTypes());
    return () => {
      dispatch(clearCalendarTypes());
    };
  }, []);

  useEffect(() => {
    // if (!isFirstLoad) {
    dispatch(searchCalendars(filters));
    // } else {
    // setIsFirstLoad(false);
    // }
    return () => {
      dispatch(clearCalendars());
    };
  }, [filters]);

  useEffect(() => {
    if (calendars) {
      // NOTE: calendar value from getInitialValues is null because we featch the calendars but getInitialValues is faster. So we need to set the calendar value after we get the calendars.
      formikRef.current.setFieldValue(
        "calendar",
        calendars.find((calendar) => calendar.id === event?.calendar?.id) ||
        null
      );
    }
  }, [calendars]);

  const handleDelete = async () => {
    try {
      dispatch(deleteEvent(event.id));
      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilterChange = (type, value) => {
    if (type === "calendarTypes") {
      setFilters({ ...filters, calendarTypes: [value?.id] }); // NOTE: backend can filter for more calendar types but now we only need one
      return;
    }
    setFilters({ ...filters, [type]: value });
  };

  return (
    <Formik
      initialValues={getInitialValues(event, range, calendars)}
      // enableReinitialize
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        allDay: Yup.bool(),
        description: Yup.string().max(5000),
        end: Yup.date()
          .typeError("Nincs helyesen megadva a záró dátum (éééé/hh/nn óó/pp)")
          .min(
            Yup.ref("start"),
            "Az esemény vége, nem lehet korábbi a kezdeténél."
          )
          .required("Záró dátum megadása kötelező"),
        start: Yup.date()
          .typeError("Nincs helyesen megadva a kezdő dátum (éééé/hh/nn óó/pp)")
          .required("Kezdő dátum megadása kötelező"),
        title: Yup.string().max(255).required("Esemény megnevezése kötelező"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            title: values.title,
            allDay: values.allDay,
            isPrivate: values.isPrivate,
            start: values.start.getTime(),
            end: values.end.getTime(),
            place: values.place,
            // reminder: values.reminder, // TODO: backend doesn't accept reminder
            priority: values.priority,
            description: values.description,
            calendarId: values?.calendar?.id,
          };

          if (event) {
            dispatch(updateEvent(event.id, data));
          } else {
            dispatch(createEvent(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error("Naptár frissítése sikertelen!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event
                ? "Naptárbejegyzés szerkesztése"
                : "Új naptárbejegyzés felvétele"}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              error={Boolean(touched.title && errors.title)}
              autoFocus
              fullWidth
              helperText={touched.title && errors.title}
              label="Esemény neve"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.allDay}
                    color="primary"
                    name="allDay"
                    onChange={handleChange}
                  />
                }
                label="Egész nap"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isPrivate}
                    color="primary"
                    name="isPrivate"
                    onChange={handleChange}
                  />
                }
                label="Privát esemény"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Kezdete"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue("start", date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.start}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Vége"
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                ampm={false}
                onChange={(date) => setFieldValue("end", date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.end}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.place && errors.place)}
                fullWidth
                helperText={touched.place && errors.place}
                label="Helyszín"
                name="place"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.place}
                variant="outlined"
              />
            </Box>
            {/* <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.reminder && errors.reminder)}
                fullWidth
                helperText={touched.reminder && errors.reminder}
                label="Emlékeztetés"
                name="reminder"
                select
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reminder}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>Válassz...</em>
                </MenuItem>
                {selectReminderOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box> */}
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.priority && errors.priority)}
                fullWidth
                helperText={touched.priority && errors.priority}
                label="Prioritás"
                name="priority"
                select
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.priority}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>Válassz...</em>
                </MenuItem>
                {selectPriorityOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {isOfficeManagerOrAbove(user?.roles) && (
              <>
                <Box sx={{ mt: 2 }}>
                  <AOCAutocomplete
                    // getOptionLabel={(option) => `${option.type === 'USER' ? 'SAJÁT' : option.type} - ${option.office.name}`}
                    // options={calendarTypes}
                    getOptionDisabled={(option) => !!option.disabled}
                    options={calendarTypeOptions}
                    // value={filters.calendarTypes[0] || null}
                    value={
                      calendarTypeOptions.find(
                        (option) => option.id === filters.calendarTypes[0]
                      ) || null
                    }
                    // onChange={(_, newValue) => setFieldValue('calendarType', newValue)}
                    onChange={(_, newValue) =>
                      handleFilterChange("calendarTypes", newValue)
                    }
                    label="Naptár típusa"
                  />
                </Box>
                {console.log(calendars, "calendars")}
                {isCalendarAutocomplete && (
                  <Box sx={{ mt: 2 }}>
                    <AOCAutocomplete
                      getOptionLabel={(option) => option?.office?.name || ""}
                      // getOptionLabel={(option) => `${option.type === 'USER' ? 'SAJÁT' : option.type} - ${option.office.name}`}
                      options={calendars}
                      loadingArray={isLoadingArray.includes("GET_CALENDARS")}
                      value={values.calendar || null}
                      onChange={(_, newValue) =>
                        setFieldValue("calendar", newValue)
                      }
                      label="Naptár"
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option?.office?.name || ""}
                        </li>
                      )}
                    />
                  </Box>
                )}
              </>
            )}
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Megjegyzés"
                name="description"
                multiline
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>
            {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.end}</FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            {event && (
              <IconButton
                onClick={() => handleDelete()}
                disabled={isSaveDisabled}
              >
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            {isSaveDisabled && (
              <Typography>
                A képzések csak a képzés felületen módosíthatóak!
              </Typography>
            )}
            <Button
              color="primary"
              sx={{ ml: 1 }}
              onClick={onCancel}
              variant="text"
            >
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting || isSaveDisabled}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CalendarEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
};

export default CalendarEventForm;
