import { setHours, subDays, setMinutes } from 'date-fns';
import deepCopy from '../utils/deepCopy';

const now = new Date();

// TODO get function for callProcesses
let callProcesses = [
  {
    id: 100000001,
    callEvent: {
      eventName: 'Nem vette fel'
    },
    callResult: {
      resultName: ''
    },
    callTask: {
      description: '',
      appointment: '',
    }
  }
];

class CallsApi {
  getCalls() {
    const calls = [
      {
        id: 112200,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Kovács Dominik', value: '1023' },
        place: 'lh_siofok',
        phone: '+36209920000',
        result: 'Visszahívást kér',
        comment: 'Még gondolkodik'
      },
      {
        id: 112201,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Kiss János', value: '1024' },
        place: 'lh_kaposvar',
        phone: '+36209921111',
        result: 'Visszahívást kér - Rögzítve 2021.01.06.  11:00',
        comment: 'Pénteken vagy szombaton lehet a házat felvenni a pontosításhoz kell egyeztetni még az ügyféllel.'
      },
      {
        id: 112202,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Nagy Elemér', value: '1025' },
        place: 'lh_siofok',
        phone: '+36209922222',
        result: 'Találkozó - 2021.01.10.  14:30',
        comment: ''
      },
      {
        id: 112203,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Kaszás Dominik', value: '1026' },
        place: 'lh_siofok',
        phone: '+36209920000',
        result: 'Visszahívást kér',
        comment: 'Még gondolkodik'
      },
      {
        id: 112204,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Nagy János', value: '1027' },
        place: 'lh_balatonkenese',
        phone: '+36209921111',
        result: 'Visszahívást kér - Rögzítve 2021.01.06.  11:00',
        comment: 'Pénteken vagy szombaton lehet a házat felvenni a pontosításhoz kell egyeztetni még az ügyféllel.'
      },
      {
        id: 112205,
        date: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
        employee: { name: 'Kis Elemér', value: '1028' },
        place: 'lh_kaposvar',
        phone: '+36209922222',
        result: 'Találkozó - 2021.01.10.  14:30',
        comment: ''
      },
    ];

    return Promise.resolve(calls);
  }

  getCall() {
    const call = {
      id: '10001',
    };

    return Promise.resolve(call);
  }

  getCallProcesses() {
    return Promise.resolve(callProcesses);
  }

  getCallProcessConfig() {
    const config = {
      id: '',
      clientId: '',
      callEvent: {
        eventName: ''
      },
      callResult: {
        resultName: '',
        meeting: false,
        appointment: false
      },
      callTask: {
        description: '',
        appointment: '',
      }
    };

    return Promise.resolve(config);
  }

  createCallProcess(data) {
    return new Promise((resolve, reject) => {
      try {
        const clonedCallProcesses = deepCopy(callProcesses);
        const callProcess = data;
        clonedCallProcesses.push(callProcess);
        callProcesses = clonedCallProcesses;
        resolve(deepCopy(callProcess));
      } catch (err) {
        console.error('[CallApi]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateCallProcess({ callProcessId, update }) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedCallProcesses = deepCopy(callProcesses);

        // Find the property that will be updated
        const callProcess = callProcesses.find((_callProcess) => _callProcess.id === callProcessId);

        if (!callProcess) {
          // TODO: Make callProcess id dynamic on button press
          // reject(new Error('callProcess not found'));
          return;
        }

        // Update the callProcess
        Object.assign(callProcess, update);
        // Save changes
        callProcesses = clonedCallProcesses;
        resolve(deepCopy(callProcess));
      } catch (err) {
        console.error('[Call Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const callsApi = new CallsApi();
