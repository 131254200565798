/* eslint-disable */
import { endOfDay, setHours, setMinutes, startOfDay, subDays } from 'date-fns';
import createResourceId from '../utils/createResourceId';
import deepCopy from '../utils/deepCopy';

const now = new Date();

const trainingTest = {
  name: 'Teszt',
  type: 'online-vizsga',
  startDate: 0,
  endDate: 0,
  minPoints: 5,
  deadline: 0,
  questions: [
    {
      title: 'mi az aoc logója?',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: 'egy a betü jelenleg stílusosan, de majd lesz jobb logó emberkével',
          status: true
        },
        {
          text: 'egy p betü ami az indokolatlan mennyiségű pénzt jelképezi amit meg fogunk keresni',
          status: false
        },
        {
          text: 'egy öltönyös galamb teniszütővel',
          status: false
        }
      ]
    },
    {
      title: 'ki a kedvenc frontentesed',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: 'én',
          status: true
        },
        {
          text: 'Zoli',
          status: false
        },
        {
          text: 'Balázs',
          status: false
        }
      ]
    },
    {
      title: 'Mikor alakult a Lido Home',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: '2004',
          status: false
        },
        {
          text: '2003',
          status: false
        },
        {
          text: 'néhány éve',
          status: true
        }
      ]
    },
    {
      title: 'Hány irodával dolgozik a Lido Home ingatlan',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: '10',
          status: true
        },
        {
          text: 'sok',
          status: false
        },
        {
          text: 'minta',
          status: false
        }
      ]
    },
    {
      title: 'Milyen üzletágai vannak a Lido Home Ingatlannak az ingatlanértékesítésen túl?',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: 'még több ingatlanértékesítés',
          status: true
        },
        {
          text: 'csocsó',
          status: false
        },
        {
          text: 'esport',
          status: false
        }
      ]
    },
    {
      title: 'Hogyan fogalmaznád meg az ingatlan értékesítő szerepét?',
      type: 'radioButton',
      pointValue: 2,
      options: [
        {
          text: 'ügyesen',
          status: true
        },
        {
          text: 'minta',
          status: false
        },
        {
          text: 'cápa',
          status: false
        }
      ]
    },
    {
      title: 'Melyek a sikeres értékesítő "eszközei?',
      type: 'radioButton',
      pointValue: 6,
      options: [
        {
          text: 'Végtelen kávé',
          status: true
        },
        {
          text: 'teniszütő',
          status: false
        },
        {
          text: 'minta',
          status: false
        }
      ]
    },
  ],
  status: 'started',
}

let events = [
  {
    id: '5ece2c077e39da27658aa8a1',
    name: 'Szakmai nap',
    title: 'Szakmai nap',
    end: startOfDay(subDays(now, 20)).getTime(),
    start: endOfDay(subDays(now, 21)).getTime(),
    participants: ['Csepregi Dominik', 'Iván', 'János', 'Elemér'],
    realParticipantsNum: 4,
    type: 'training',
    place: 'LidoHome Siófok',
    status: 'success',
    description: 'Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris.'
  },
  {
    id: '5ece2c077e39da27658aa8a2',
    name: 'Alapképzés 1. nap',
    title: 'Alapképzés 1. nap',
    end: startOfDay(subDays(now, 14)).getTime(),
    start: endOfDay(subDays(now, 15)).getTime(),
    participants: ['H Anna', 'B Elemér'],
    realParticipantsNum: 2,
    type: 'training',
    place: 'LidoHome Siófok',
    status: 'fail',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
  },
  {
    id: '5ece2c077e39da27658aa8a3',
    name: 'Alapképzés 2. nap',
    title: 'Alapképzés 2. nap',
    end: startOfDay(subDays(now, -18)).getTime(),
    start: endOfDay(subDays(now, -17)).getTime(),
    participants: ['Anna', 'Ákos', 'Bence', 'Árpád', 'Jószef'],
    realParticipantsNum: '',
    type: 'training',
    place: 'LidoHome Siófok',
    status: 'unknown',
    description: 'Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission: to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no man has gone before. Many say exploration is part of our destiny, but it’s actually our duty to future generations and their quest to ensure the survival of the human species.'
  },
  {
    id: '5ece2c077e39da27658aa8a4',
    name: 'Alapképzés 3. nap',
    title: 'Alapképzés 3. nap',
    end: startOfDay(subDays(now, -20)).getTime(),
    start: endOfDay(subDays(now, -19)).getTime(),
    participants: ['Anna', 'Ákos', 'Bence', 'Árpád', 'Jószef'],
    realParticipantsNum: '',
    type: 'training',
    place: 'LidoHome Siófok',
    status: 'unknown',
    description: 'Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission: to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no man has gone before. Many say exploration is part of our destiny, but it’s actually our duty to future generations and their quest to ensure the survival of the human species.'
  },
  {
    id: '5ece2c077e39da27658aa8a5',
    name: 'Alapképzés 4. nap',
    title: 'Alapképzés 4. nap',
    end: startOfDay(subDays(now, -22)).getTime(),
    start: endOfDay(subDays(now, -21)).getTime(),
    participants: ['András', 'Bence', 'Tibor', 'Zoltán'],
    realParticipantsNum: '',
    type: 'training',
    place: 'LidoHome Siófok',
    status: 'unknown',
    description: 'Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission: to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no man has gone before. Many say exploration is part of our destiny, but it’s actually our duty to future generations and their quest to ensure the survival of the human species.'
  },
  {
    id: '5ece2c077e39da27658aa8a6',
    name: 'Alapképzés 5. nap',
    title: 'Alapképzés 5. nap',
    end: startOfDay(subDays(now, -51)).getTime(),
    start: endOfDay(subDays(now, -50)).getTime(),
    participants: ['Zsolt', 'Nóri', 'Tünde'],
    realParticipantsNum: '',
    type: 'online',
    place: 'LidoHome Siófok',
    status: 'unknown',
    description: 'Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission: to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no man has gone before. Many say exploration is part of our destiny, but it’s actually our duty to future generations and their quest to ensure the survival of the human species.'
  },
  {
    id: '5ece2c077e39da27658aa8a7',
    name: 'Alapképzés 6. nap',
    title: 'Alapképzés 6. nap',
    end: setHours(setMinutes(subDays(now, -90), 0), 19).getTime(),
    start: setHours(setMinutes(subDays(now, -80), 30), 17).getTime(),
    participants: ['Nikoletta', 'Miklós', 'Fruzsi', 'Zoltán'],
    realParticipantsNum: '',
    type: 'online',
    place: 'LidoHome Siófok',
    status: 'unknown',
    description: 'Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission: to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no man has gone before. Many say exploration is part of our destiny, but it’s actually our duty to future generations and their quest to ensure the survival of the human species.'
  },
];
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


class TrainingApi {
  getEvents(filters) {
    if (filters) {
      const { types } = filters;
      const typeFilter = Object.keys(types).filter((key) => types[key]);
      const filteredEvents = events.filter((event) => typeFilter.includes(event.type));

      return Promise.resolve(deepCopy(filteredEvents));
    }
    return Promise.resolve(deepCopy(events));
  }

  getQuestions(count) {
    // const allQuestions = []
    // trainingTest.questions.forEach((elem, i) => {
    //   allQuestions.push(i)
    // });
    // const chosenQuestions = []

    // for (let i = 0; i < count; i++) {
    //   const int = getRandomInt(allQuestions.length);
    //   chosenQuestions.push(trainingTest.questions[allQuestions[int]]);
    //   allQuestions.splice(int, 1);
    //   console.log(allQuestions, chosenQuestions, 'int');
    //   // chosenQuestions.push(getRandomInt(allQuestions.length));
    // }
    return Promise.resolve(deepCopy(trainingTest));
  }

  createEvent(data) {
    return new Promise((resolve, reject) => {
      try {
        const { allDay, participants, name, title, description, status, end, start, type, place } = data;

        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Create the new event
        const event = {
          id: createResourceId(),
          allDay,
          name,
          title,
          end,
          start,
          participants,
          description,
          status,
          type,
          place,
        };

        // Add the new event to events
        clonedEvents.push(event);

        // Save changes
        events = clonedEvents;

        resolve(deepCopy(event));
      } catch (err) {
        console.error('[Training Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateEvent({ eventId, update }) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Find the event that will be updated
        const event = events.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error('Event not found'));
          return;
        }

        // Update the event
        Object.assign(event, update);

        // Save changes
        events = clonedEvents;

        resolve(deepCopy(event));
      } catch (err) {
        console.error('[Training Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  deleteEvent(eventId) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Find the event that will be removed
        const event = events.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error('Event not found'));
          return;
        }

        events = events.filter((_event) => _event.id !== eventId);

        // Save changes
        events = clonedEvents;

        resolve(true);
      } catch (err) {
        console.error('[Calendar Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const trainingApi = new TrainingApi();
