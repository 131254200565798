import { createSlice } from '@reduxjs/toolkit';
import { generationApi } from '../__fakeApi__/generationApi';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { toggleLoading } from './loading';

const initialState = {
  generations: [],
  statements: {},
  isModalOpen: false,
  selectedProperties: [],
  selectedGeneration: null,
  selectedGenerationId: null,
  selectedHp: null,
  defaultConfig: {
    userId: null,
    officeId: null,
    clientId: null,
    generationDate: null,
    selectedPropertyIds: [],
    activity: '',
    properties: []
  },
  config: {
    userId: null,
    officeId: null,
    clientId: null,
    generationDate: null,
    selectedPropertyIds: [],
    activity: '',
    properties: []
  }
};

const slice = createSlice({
  name: 'generation',
  initialState,
  reducers: {
    getGenerations(state, action) {
      state.generations = action.payload;
    },
    getStatements(state, action) {
      state.statements = action.payload;
    },
    createGeneration(state, action) {
      state.generations.push(action.payload);
    },
    selectGeneration(state, action) {
      const { generationId } = action.payload;

      state.selectedGenerationId = generationId;
      state.isModalOpen = true;
    },
    selectHp(state, action) {
      state.selectedHp = action.payload;
    },
    updateGeneration(state, action) {
      const generation = action.payload;

      state.generations = state.generations.map((_generation) => {
        if (_generation.id === generation.id) {
          return generation;
        }

        return _generation;
      });
    },
    updateSelectedProperties(state, action) {
      state.selectedProperties = action.payload;
    },
    updateConfigProperties(state, action) {
      state.config.properties = action.payload;
    },
    updateStatement(state, action) {
      const statement = action.payload;

      state.statements.data = state.statements.data.map((_statements) => {
        if (_statements.id === statement.id) {
          return statement;
        }

        return _statements;
      });
    },
    getStatementProperties(state, action) {
      const { id, data } = action.payload;

      state.statements.data = state.statements.data.map((statement) => {
        if (statement.id === id) {
          if (statement.properties) {
            return statement;
          }
          return { ...statement, properties: data };
        }

        return statement;
      });
    },
    deleteGeneration(state, action) {
      state.generations = state.generations.filter((generation) => generation.id !== action.payload);
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedGenerationId = null;
    }
  },
});

export const { reducer } = slice;

export const getGenerations = (filters) => async (dispatch) => {
  const data = await generationApi.getGenerations(filters);

  dispatch(slice.actions.getGenerations(data, filters));
};
export const getStatements = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'VITRIN_LOADING' }));
  await axiosConfig.get(
    `statements?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 0}`,
  ).then((response) => {
    dispatch(slice.actions.getStatements(response.data));
  }).catch((error) => {
    console.log(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'VITRIN_LOADING' }));
  });
};

export const clearGenerations = () => (dispatch) => {
  dispatch(slice.actions.getStatements({}));
};

export const getStatementsByFilter = (pageNumber, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_STATEMENTS' }));
  const { client, office, start, end, id, type, employee, signedUploaded } = filters;
  const data = {
    clientId: client?.id || null,
    officeId: office?.id || null,
    docNumber: id || null,
    userId: employee?.id || null,
    dateFrom: start?.getTime() || null,
    dateTo: end?.getTime() || null,
    type: type?.label || null,
    signedUploaded: signedUploaded?.id ? signedUploaded?.status : null
  };
  await axiosConfig.post(
    `statements/search?pageNumber=${pageNumber || 0}`,
    data
  ).then((response) => {
    dispatch(slice.actions.getStatements(response.data));
    // dispatch(slice.actions.getCalls(response.data));
  }).catch((error) => {
    console.log(error);
    toast.error('Hiba a közetítési nyilatkozatok betöltésekor');
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_STATEMENTS' }));
  });
};

export const updateStatement = (id, data) => async (dispatch) => {
  const formData = new FormData();
  formData.append('signedFile', data?.file || '');
  formData.append('title', data?.title || '');
  formData.append('category', data?.type || '');
  // }
  await axiosConfig.put(
    `/statements/${id}`,
    formData
  ).then((response) => {
    dispatch(slice.actions.updateStatement(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getStatementProperties = (id) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_STATEMENT_PROPERTY${id}` }));
  await axiosConfig.get(
    `/statements/${id}/properties`,
  ).then((response) => {
    const { data } = response;
    dispatch(slice.actions.getStatementProperties({ data, id }));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az ügyfelek betöltése sikertelen');
    }
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: `GET_STATEMENT_PROPERTY${id}` }));
  });
};

export const createGeneration = (createData) => async (dispatch) => {
  const data = await generationApi.createGeneration(createData);

  dispatch(slice.actions.createGeneration(data));
};

export const selectGeneration = (generationId, type) => async (dispatch) => {
  dispatch(slice.actions.selectGeneration({ generationId, type }));
};

export const selectHp = (hp) => (dispatch) => {
  dispatch(slice.actions.selectHp(hp));
};
export const updateGeneration = (generationId, update) => async (dispatch) => {
  const data = await generationApi.updateGeneration({
    generationId,
    update
  });

  dispatch(slice.actions.updateGeneration(data));
};

export const deleteGeneration = (generationId) => async (dispatch) => {
  await generationApi.deleteGeneration(generationId);

  dispatch(slice.actions.deleteGeneration(generationId));
};

export const archiveProperties = (propertyIds, data) => async (dispatch) => { // TODO: what should i send?
  await axiosConfig.put(
    '/properties/archive',
    { propertyIds, ...data }
  ).then((response) => {
    dispatch(slice.actions.updateSelectedProperties(response.data));
    toast.success('Ingatlanok sikeresen arhiválva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const assignUser = (properties) => async (dispatch) => {
  await axiosConfig.put(
    '/properties/assignUser',
    properties
  ).then((response) => {
    dispatch(slice.actions.updateSelectedProperties(response.data));
    toast.success('Ingatlanok sikeresen arhiválva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const clearSelectedProperties = () => (dispatch) => {
  dispatch(slice.actions.updateSelectedProperties([]));
}

export default slice;
