/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ExpandeMoreIcon from '@material-ui/icons/ExpandMore';
import parsePhoneNumber from 'libphonenumber-js';
import PlusIcon from '../../icons/Plus';
import DownloadIcon from '@material-ui/icons/Download';
import UploadIcon from '@material-ui/icons/Upload';
import AOCAutocomplete from '../AOCAutocomplete';
import { useDispatch, useSelector } from '../../store';
import { selectLoading } from '../../slices/loading';
import { searchClients } from '../../slices/client';
import TrashIcon from '../../icons/Trash';
import OverflowTip from '../../utils/OverflowTip';
import UploadeStatementForm from './UploadeStatementForm';
import { updateStatement, getStatementProperties } from '../../slices/generation';
import ImageIcon from '../../icons/Image';
import { Link as RouterLink, } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import { copyToClipboard, isOfficeManagerOrAbove } from '../../utils/helperFunctions';
import { LinearProgress } from '@mui/material';

const { REACT_APP_FILE_URL } = process.env;

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const StatementCard = ({ statement }) => {
  const { id, user: statementUser, presentation, generationDate: date, docNumber, client } = statement;
  const { presentationDate, type } = presentation || {};
  const { name, title, email, phone, office } = statementUser && statementUser;
  const { name: clientName, defaultEmail, defaultPhone } = client || {};
  const { clients } = useSelector((state) => state.client);
  const isLoadingArray = useSelector(selectLoading);
  const { clientSearchDefaultParameters } = useSelector((state) => state.search);
  const [clientSearchName, setClientSearchName] = useState();
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    const abortController = new AbortController()
    const delayDebounceFn = setTimeout(() => {
      if (clientSearchName) {
        const defaultSearch = { ...clientSearchDefaultParameters };
        defaultSearch.name = clientSearchName;
        dispatch(searchClients(defaultSearch, '', 0, 50, '', abortController));
      }
    }, 400);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [clientSearchName]);

  const handleClickOpen = () => { // NOTE: It is used for add Client for statement if there is none but probably it will be unnecessary
    setOpen(true);
  };

  const handleClose = () => { // NOTE: It is used for add Client for statement if there is none but probably it will be unnecessary
    setOpen(false);
  };

  const handleOpenUploadForm = () => {
    setUploadFileModalOpen(true);
  };

  const handleCloseUploadForm = () => {
    setUploadFileModalOpen(false);
  };

  const handleExpanded = (isExpanded) => {
    if (isExpanded) {
      dispatch(getStatementProperties(id));
    }
  };

  return (
    <>
      <Accordion
        onChange={(_, isExpanded) => handleExpanded(isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandeMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            py={client ? 0.4 : 0}
          >
            <Grid
              item
              lg={1}
              md={1}
              sm={1}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {type}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {date && format(date, 'yyyy. MM. dd')}
              </Typography>
              <Tooltip title="Vágólapra másolás">
                <Typography
                  color="textSecondary"
                  variant="body2"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(docNumber);
                    toast('Vágólapra másolva');
                  }}
                  sx={{ userSelect: 'auto' }}
                >
                  {docNumber}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={12}
            >
              {name}
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={7}
            >
              {client ? (
                <Grid
                  container
                  display="flex"
                >
                  <Grid
                    item
                    sm={5}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <OverflowTip>
                        {clientName}
                      </OverflowTip>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={7}
                  >
                    {defaultPhone && (
                      <>
                        {defaultPhone.includes('*') ? (
                          defaultPhone
                        ) : (
                          defaultPhone && parsePhoneNumber(defaultPhone) && parsePhoneNumber(defaultPhone)?.formatInternational() ? (
                            parsePhoneNumber(defaultPhone)?.formatInternational()
                          ) : (
                            'Nincs megadva'
                          )
                        )}
                      </>
                    )}
                    {defaultEmail && (
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {client?.defaultEmail}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <>
                  {/* <Button // NOTE: It is used for add Client for a Statement if there is none but probably it will be unnecessary
                    // fullWidth
                    size="small"
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    variant="outlined"
                    onClick={(event) => {
                      handleClickOpen();
                      event.stopPropagation();
                    }}
                  >
                    Ügyfél megadása
                  </Button> */}
                </>
              )}
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              xs={12}
              textAlign="right"
            >
              <Tooltip title="Eredeti közvetítési nyilatkozat letöltése">
                <IconButton
                  disabled={!statement?.originalFileSource}
                  onClick={(event) => {
                    openInNewTab(`${REACT_APP_FILE_URL}${statement?.originalFileSource}`);
                    event.stopPropagation();
                  }}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {statement.signedFileSource ? (
                <>
                  <Tooltip title="Aláírt közvetítési nyilatkozat letöltése">
                    <IconButton
                      sx={{
                        color: 'green',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        openInNewTab(`${REACT_APP_FILE_URL}${statement?.signedFileSource}`);
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {isOfficeManagerOrAbove(user?.roles) && (
                    <Tooltip title="Aláírt közvetítési nyilatkozat törlése">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(updateStatement(id, null));
                        }}
                      >
                        <TrashIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {isOfficeManagerOrAbove(user?.roles) && (
                    <Tooltip title="Aláírt közvetítési nyilatkozat feltöltése">
                      <IconButton
                        sx={{
                          backgroundColor: 'red',
                          color: 'white',
                          '&:hover, &.Mui-focusVisible': {
                            backgroundColor: '#962F32'
                          }
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenUploadForm();
                        }}
                      >
                        <UploadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <>
            {isLoadingArray.includes(`GET_STATEMENT_PROPERTY${id}`) && !statement?.properties && (
              <LinearProgress />
            )}
            {statement?.properties && (
              <Table>
                <TableBody>
                  {statement?.properties?.map((property) => (
                    <TableRow
                      hover
                      key={property?.id}
                      component={RouterLink}
                      to={`/properties/${property?.id}`}
                      target="_blank"
                      sx={{
                        textDecoration: 'none',
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>
                        {property?.coverImage
                          ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                overflow: 'hidden',
                                borderRadius: '8px',
                                width: 130,
                                '& img': {
                                  height: 'auto',
                                  width: '100%'
                                }
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: '8px',
                                }}
                                alt="propertyPicture"
                                src={`${REACT_APP_FILE_URL}${property?.coverImage?.source?.thumb}`}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                borderRadius: '8px',
                                width: 130
                              }}
                            >
                              <ImageIcon fontSize="small" />
                            </Box>
                          )}
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Typography
                            color="textSecondary"
                            variant="body2"
                            mr={1}
                          >
                            Azonosító
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {property.publicId || ''}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <Typography
                            color="textSecondary"
                            variant="body2"
                            mr={1}
                          >
                            Cím
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {property.formattedAddress || ''}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {statement?.properties?.length === 0 && !isLoadingArray.includes(`GET_STATEMENT_PROPERTY${id}`) && (
              <Grid
                container
                justifyContent="center"
                mt={1}
                mb={1}
              >
                <Typography
                  fontSize={20}
                >
                  Nincs ingatlan hozzárendelve
                </Typography>
              </Grid>
            )}
          </>
        </AccordionDetails>
      </Accordion>
      <Dialog // NOTE: It is used for add Client for a Statement if there is none but probably it will be unnecessary
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        {open && (
          <DialogTitle>
            Ügyfél kiválasztása
          </DialogTitle>
        )}
        <DialogContent
          dividers
          sx={{ minWidth: 100 }}
        >
          <AOCAutocomplete
            fullWidth
            options={clients && clients.data}
            getOptionLabel={(option) => option.name}
            textFieldOnChange={(event) => setClientSearchName(event.target.value)}
            onChange={(_, value) => {
              setSelectedClient(value);
            }}
            value={selectedClient}
            label="Ügyfél"
            placeholder="Kezdje el beírni a ügyfél nevét."
            loadingArray={isLoadingArray.includes('GET_CLIENTS_PAGE')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Mégsem</Button>
          <Button
            onClick={handleClose}
            variant="contained"
          >
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        onClose={handleCloseUploadForm}
        open={uploadFileModalOpen}
      >
        {uploadFileModalOpen && (
          <UploadeStatementForm
            onCancel={handleCloseUploadForm}
            id={id}
          />
        )}
      </Dialog>
    </>
  );
};

StatementCard.propTypes = {
  statement: PropTypes.object.isRequired,
};

export default StatementCard;
