import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

const { REACT_APP_BASE_URL } = process.env;

const baseURL = REACT_APP_BASE_URL;
// const token = Cookies.get('token');
const instance = axios.create({ baseURL });
const timeout = null;

// instance.defaults.headers.common.Authorization = `Bearer ${token}`;
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
instance.defaults.timeout = timeout;

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (newToken) => {
  refreshSubscribers.map((cb) => cb(newToken));
};

const refreshAccessToken = () => {
  console.log('refreshAccessToken...');
};

instance.interceptors.request.use((config) => {
  const token = Cookies.get('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use((response) => {
  Cookies.set('token', response.headers.token);
  return response;
}, (error) => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  if (status === 401) {
    toast.error('401: Unauthorized', { duration: 5000 });
    Cookies.set('token', '');
    Cookies.set('user', JSON.stringify({
      id: '',
      name: '',
      email: '',
      avatar: '',
      plan: ''
    }));
  }

  // if (status === 500) {
  //   toast.error('500: Internal Server Error', { duration: 5000 });
  // }

  if (status === 403) {
    toast.error('403: Forbidden', { duration: 5000 });
    if (!isRefreshing) {
      isRefreshing = true;
      refreshAccessToken()
        .then((newToken) => {
          isRefreshing = false;
          onRefreshed(newToken);
        });
    }

    const retryOrigReq = new Promise((resolve) => {
      subscribeTokenRefresh((newToken) => {
        // replace the expired token and retry
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }
  return Promise.reject(error);
});

export default instance;
