import { useRef, useState, useEffect, Fragment } from 'react';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import BungalowIcon from '@mui/icons-material/Bungalow';
import LanguageIcon from '@mui/icons-material/Language';
import WindowIcon from '@mui/icons-material/Window';
import LightbulbCircleRoundedIcon from '@mui/icons-material/LightbulbCircleRounded';
import huLocale from 'date-fns/locale/hu';
import Scrollbar from '../Scrollbar';
import { checkNewActiveTasks, getActiveTasks, setTaskDone } from '../../slices/task';
import {
  useDispatch,
  useSelector
} from '../../store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';
import { selectLoading } from '../../slices/loading';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text
  }
}));

const iconsMap = {
  CLIENT_CONNECT: <PersonIcon color="text" />,
  CLIENT_HP_REQUEST: <PersonIcon color="text" />,
  CLIENT_PHONE_CALL: <ContactPhoneIcon color="text" />,
  CLIENT_UNSUBSCRIBED: <PersonIcon color="text" />,
  CLIENT_ASSIGN_TO_EMPLOYEE: <PersonIcon color="text" />,

  EMPLOYEE_PASSWORD_CHANGE_BO: <BadgeIcon color="text" />,
  EMPLOYEE_PASSWORD_CHANGE_EMAIL: <BadgeIcon color="text" />,
  EMPLOYEE_REASSIGN_CLIENT: <BadgeIcon color="text" />,
  EMPLOYEE_REASSIGN_PROPERTY: <BadgeIcon color="text" />,
  EMPLOYEE_REDIRECT_EMAIL: <BadgeIcon color="text" />,
  EMPLOYEE_REDIRECT_PHONE: <CallRoundedIcon color="text" />,

  PROPERTY_ADD: <BungalowIcon color="text" />,
  PROPERTY_APPROVE: <HomeRoundedIcon color="text" />,
  PROPERTY_ARCHIVE: <HomeRoundedIcon color="text" />,
  PROPERTY_CORRECT: <HomeRoundedIcon color="text" />,
  PROPERTY_LAND_REGISTRY: <HomeRoundedIcon color="text" />,
  PROPERTY_REQUEST_OFFER: <HomeRoundedIcon color="text" />,
  PROPERTY_ASSIGN_TO_EMPLOYEE: <HomeRoundedIcon color="text" />,

  SUGGESTION_TO_ASSIGN: <LightbulbCircleRoundedIcon color="text" />,
  SUGGESTION_ASSIGNED: <LightbulbCircleRoundedIcon color="text" />,

  PROPERTY_WARN_INGATLAN_COM: <LanguageIcon color="text" />,
  PROPERTY_WARN_KEY: <KeyRoundedIcon color="text" />,
  PROPERTY_WARN_SHOWCASE: <WindowIcon color="text" />,
  PROPERTY_WARN_BOARD: <PriorityHighRoundedIcon color="text" />,
  PROPERTY_WARN_VITRIN: <PriorityHighRoundedIcon color="text" />,

  ONLINE_TRAINING_ASSIGNED: <AssignmentRoundedIcon color="text" />,
  ONLINE_TRAINING_EVALUATE: <AssignmentRoundedIcon color="text" />,
};

const taskNameMap = {
  CLIENT_CONNECT: 'Kereső ügyfél kapcsolattartás',
  CLIENT_HP_REQUEST: 'HP igénylés',
  CLIENT_PHONE_CALL: 'Telefonhívás',
  CLIENT_UNSUBSCRIBED: 'Ügyfeled leiratkozott',
  CLIENT_ASSIGN_TO_EMPLOYEE: 'Új ügyfelet rendeltek hozzád',

  EMPLOYEE_PASSWORD_CHANGE_BO: 'Bo jelszavát megváltoztattad?',
  EMPLOYEE_PASSWORD_CHANGE_EMAIL: 'E-mail jelszavát megváltoztattad?',
  EMPLOYEE_REASSIGN_CLIENT: 'Kereső ügyfeleket szétosztottad?',
  EMPLOYEE_REASSIGN_PROPERTY: 'Értékesítő ingatlanjait szétosztottad?',
  EMPLOYEE_REDIRECT_EMAIL: 'Értékesítő e-mail címét átirányítottad?',
  EMPLOYEE_REDIRECT_PHONE: 'Értékesítő telefon átirányítás',

  PROPERTY_ADD: 'Ingatlan feltöltés',
  PROPERTY_APPROVE: 'Jóváhagyandó ingatlanok',
  PROPERTY_ARCHIVE: 'Archiválandó ingatlanok',
  PROPERTY_CORRECT: 'Ingatlan javítás',
  PROPERTY_LAND_REGISTRY: 'Földhivatali ellenőrzés',
  PROPERTY_REQUEST_OFFER: 'Ingatlan kiajánlás kérése',
  PROPERTY_ASSIGN_TO_EMPLOYEE: 'Új ingatlant rendeltek hozzád',

  SUGGESTION_TO_ASSIGN: 'Tipp szétosztás',
  SUGGESTION_ASSIGNED: 'Tipp szétosztva',

  PROPERTY_WARN_INGATLAN_COM: 'Ingatlan.com-ról levenni',
  PROPERTY_WARN_KEY: 'Kulcsos figyelmeztetés',
  PROPERTY_WARN_SHOWCASE: 'Kirakatba kitéve figyelmeztetés',
  PROPERTY_WARN_BOARD: 'Tábla kitéve figyelmeztetés',
  PROPERTY_WARN_VITRIN: 'Vitrin figyelmeztetés',

  ONLINE_TRAINING_ASSIGNED: 'Online képzés',
  ONLINE_TRAINING_EVALUATE: 'Online tréning értékelés',
};

const TaskListPopover = () => {
  const dispatch = useDispatch();
  const { activeTasks } = useSelector((state) => state.task);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const isLoadingArray = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getActiveTasks());
  }, []);

  const MINUTE_MS = 60000 * 5; /* NOTE: 5 minutes */
  // const MINUTE_MS = 10000; /* NOTE: 10 sec for test */

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkNewActiveTasks());
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const handleOpen = () => {
    if (!isLoadingArray.includes('GET_ACTIVE_TASKS') && Object.keys(activeTasks).length === 0) {
      dispatch(getActiveTasks());
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.getElementById('root').style.filter = 'blur(2px)';
      document.getElementById('root').style.transition = '0.3s';
    } else {
      document.getElementById('root').style.filter = 'blur(0px)';
      document.getElementById('root').style.transition = '0.3s';
    }
    return () => {
      document.getElementById('root').style.filter = 'blur(0px)';
      document.getElementById('root').style.transition = '0.3s';
    };
  }, [open]);

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Tooltip title="Feladat lista">
        <IconButton
          className={classes.icon}
          ref={anchorRef}
          onClick={handleOpen}
        // disabled
        >
          <Badge
            color="error"
            badgeContent={Object.keys(activeTasks).map((key) => activeTasks[key].length).reduce((a, b) => a + b, 0)}
          >
            <AssignmentRoundedIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 450 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Feladatok
          </Typography>
        </Box>
        <Divider />
        {isLoadingArray.includes('GET_ACTIVE_TASKS') && Object.keys(activeTasks).length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            my={2}
          >
            <CircularProgress />
          </Box>
        ) : null}
        {Object.keys(activeTasks).length === 0 && !isLoadingArray.includes('GET_ACTIVE_TASKS') ? (
          <>
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Nincs új feladat
              </Typography>
            </Box>
          </>
        ) : (
          <Scrollbar
            options={{ suppressScrollX: true }}
          >
            <Box
              sx={{ maxHeight: '75vh', width: 450, overflowY: 'auto', overflowX: 'hidden' }}
            >
              <>
                {Object.keys(activeTasks).map((task) => (
                  <Fragment key={taskNameMap[task]}>
                    <Accordion
                      // disableGutters
                      elevation={0}
                      sx={{
                        border: '0px solid red',
                        '&:before': {
                          display: 'none',
                        }
                      }}
                      expanded={expanded === task}
                      onChange={handleExpandChange(task)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ borderRadius: 0 }}
                      >
                        <Badge
                          color="error"
                          badgeContent={activeTasks[task].length}
                        >
                          {iconsMap[task]}
                        </Badge>
                        <Typography
                          sx={{ flexShrink: 0, ml: 3 }}
                          variant="subtitle2"
                        >
                          {taskNameMap[task]}
                        </Typography>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails
                        sx={{ p: 0, borderRadius: 0 }}
                      >
                        {activeTasks[task]?.map((taskItem, index) => (
                          <Fragment key={taskItem.id}>
                            {index < 15 && (
                              <>
                                <ListItem
                                  divider
                                  key={taskItem.id}
                                  sx={{
                                    borderRadius: 0,
                                    background: (theme) => theme.palette.background.default
                                    // background: (theme) => (taskItem.isNew ? theme.palette.background.default : theme.palette.background.paper),
                                  }}
                                >
                                  <ListItemText
                                    primary={(
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                      >
                                        <Tooltip
                                          disableHoverListener={taskItem.taskType === 'ONLINE_TRAINING_ASSIGNED'}
                                          title="Elvégzettnek jelölés"
                                        >
                                          {taskItem.taskType === 'ONLINE_TRAINING_ASSIGNED' ? (
                                            <Typography
                                              color="textPrimary"
                                              underline="none"
                                              variant="subtitle2"
                                            >
                                              {taskItem.title}
                                            </Typography>
                                          ) : (
                                            <Link
                                              color="textPrimary"
                                              onClick={() => dispatch(setTaskDone(taskItem.id))}
                                              sx={{ cursor: 'pointer' }}
                                              underline="none"
                                              variant="subtitle2"
                                            >
                                              {taskItem.title}
                                            </Link>
                                          )}
                                        </Tooltip>
                                        <Typography
                                          textAlign="right"
                                          variant="caption"
                                        >
                                          {format(taskItem?.created, 'yyyy. MMM dd. HH:mm', { locale: huLocale })}
                                        </Typography>
                                      </Box>
                                    )}
                                    secondary={(
                                      taskItem?.description && parse(taskItem.description)
                                    )}
                                  />
                                </ListItem>
                              </>
                            )}
                          </Fragment>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                    <Divider />
                  </Fragment>
                ))}
              </>
            </Box>
          </Scrollbar>
        )}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1
          }}
        >
          <Button
            component={RouterLink}
            to="/tasks"
            onClick={handleClose}
            color="primary"
            size="small"
            variant="text"
          >
            Összes feladat megtekintése
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default TaskListPopover;
