/* eslint-disable */
// NOTE: Keep all imports only until the development period

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import SessionTimeout from './components/SessionTimeout';
import WebPresentationBrowse from './components/webIpPresentation/WebPresentationBrowse';
import Statements from './pages/dashboard/Statements';
import OwnTrainings from './pages/dashboard/OwnTrainings';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const TaskList = Loadable(lazy(() => import('./pages/dashboard/TaskList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const HomePage = Loadable(lazy(() => import('./pages/dashboard/HomePage')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// IP Pages

// const IPDetails = Loadable(lazy(() => import('./pages/dashboard/IPDetails')));
const IPList = Loadable(lazy(() => import('./pages/dashboard/IPList')));
const IPCreate = Loadable(lazy(() => import('./pages/dashboard/IPCreate')));
const GenerationList = Loadable(lazy(() => import('./pages/dashboard/GenerationList')));
const GenerationModal = Loadable(lazy(() => import('./components/modals/GenerationModal')));
const GenerationCreate = Loadable(lazy(() => import('./pages/dashboard/GenerationCreate')));
// Calls

const Calls = Loadable(lazy(() => import('./pages/dashboard/Calls')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const Projects = Loadable(lazy(() => import('./pages/dashboard/Projects')));
const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectEdit = Loadable(lazy(() => import('./pages/dashboard/ProjectEdit')));
const ProjectDetailsPage = Loadable(lazy(() => import('./pages/dashboard/ProjectDetailsPage')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
// const ProjectPropertiesDetails = Loadable(lazy(() => import('./components/dashboard/project/ProjectPropertiesDetails')));

// Training pages

const TrainingLayout = Loadable(lazy(() => import('./components/training/TrainingLayout')));
const TrainingBrowse = Loadable(lazy(() => import('./pages/dashboard/TrainingBrowse')));
const OTrainingBrowse = Loadable(lazy(() => import('./components/training/OTrainingBrowse')));
const OTrainingParticipantsBrowse = Loadable(lazy(() => import('./components/training/OTrainingParticipantsBrowse')));
const OTrainingUserBrowse = Loadable(lazy(() => import('./components/training/OTrainingUserBrowse')));
const OTrainingSteps = Loadable(lazy(() => import('./components/training/OTrainingSteps')));

// Hint pages

const HintBrowse = Loadable(lazy(() => import('./pages/dashboard/HintBrowse')));
const HintCreate = Loadable(lazy(() => import('./pages/dashboard/HintCreate')));

// Client pages

const ClientBrowse = Loadable(lazy(() => import('./pages/dashboard/ClientBrowse')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));
const CardList = Loadable(lazy(() => import('./components/CardList')));

// Property pages

const PropertyBrowse = Loadable(lazy(() => import('./pages/dashboard/PropertyBrowse')));
const PropertyCreate = Loadable(lazy(() => import('./pages/dashboard/PropertyCreate')));
const PropertyDetails = Loadable(lazy(() => import('./pages/dashboard/PropertyDetails')));
// const Properties = Loadable(lazy(() => import('./pages/dashboard/Properties')));

// Document pages

const Documents = Loadable(lazy(() => import('./pages/dashboard/Documents')));
// const DocumentBrowse = Loadable(lazy(() => import('./pages/dashboard/DocumentBrowse')));

// Applicant pages

const Applicants = Loadable(lazy(() => import('./pages/dashboard/Applicants')));

// Rates

const Rate = Loadable(lazy(() => import('./pages/Rate')));

// Statistics
const StatisticsBrowse = Loadable(lazy(() => import('./pages/StatisticsBrowse')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      // {
      //   path: 'password-recovery',
      //   element: <PasswordRecovery />
      // },
      // {
      //   path: 'password-reset',
      //   element: <PasswordReset />
      // },
      // {
      //   path: 'register',
      //   element: (
      //     <GuestGuard>
      //       <Register />
      //     </GuestGuard>
      //   )
      // },
      // {
      //   path: 'register-unguarded',
      //   element: <Register />
      // },
      // {
      //   path: 'verify-code',
      //   element: <VerifyCode />
      // }
    ]
  },
  // {
  //   path: 'blog',
  //   element: <BlogLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <BlogPostList />
  //     },
  //     {
  //       path: 'new',
  //       element: <BlogPostCreate />
  //     },
  //     {
  //       path: ':postId',
  //       element: <BlogPostDetails />
  //     }
  //   ]
  // },
  // {
  //   path: 'contact',
  //   element: <Contact />
  // },
  {
    path: '/',
    element: (
      <AuthGuard>
        <SessionTimeout />
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: 'properties',
        children: [
          {
            path: '/',
            element: <PropertyBrowse />
          },
          {
            path: ':propertyId',
            element: <PropertyDetails />
          },
          {
            path: '/new',
            element: <PropertyCreate />
          },
          {
            path: '/edit/:id',
            element: <PropertyCreate />
          },
          {
            path: '/inprogress',
            element: <CardList />
          },
          {
            path: '/approve',
            element: <CardList />
          },
          {
            path: '/archive',
            element: <CardList />
          },
          {
            path: '/users/:userId',
            element: <CardList />
          },
        ]
      },
      {
        path: 'properties-unapproved',
        children: [
          {
            path: '/',
            element: <PropertyBrowse filter="unapproved" />
          }
        ]
      },
      // {
      //   path: '/',
      //   element: <Overview />
      // },
      {
        path: 'settings',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/settings/offices"
                replace
              />
            )
          },
          {
            path: 'offices',
            element: <Account settingsType="offices" />
          },
          {
            path: 'employees',
            element: <Account settingsType="employees" />
          },
          // {
          //   path: 'contacts',
          //   element: <Account settingsType="contacts" />
          // },
          // {
          //   path: 'notifications',
          //   element: <Account settingsType="notifications" />
          // },
        ]
      },
      // {
      //   path: 'profile',
      //   element: <SocialProfile />
      // },
      // {
      //   path: 'analytics',
      //   element: <Analytics />
      // },
      {
        path: 'calendar',
        element: <Calendar />
      },
      // {
      //   path: 'chat',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Chat />
      //     },
      //     {
      //       path: 'new',
      //       element: <Chat />
      //     },
      //     {
      //       path: ':threadKey',
      //       element: <Chat />
      //     }
      //   ]
      // },
      // {
      //   path: 'customers',
      //   children: [
      //     {
      //       path: '/',
      //       element: <CustomerList />
      //     },
      //     {
      //       path: ':customerId',
      //       element: <CustomerDetails />
      //     },
      //     {
      //       path: ':customerId/edit',
      //       element: <CustomerEdit />
      //     }
      //   ]
      // },
      {
        path: 'invoices',
        children: [
          {
            path: '/',
            element: <InvoiceList />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetails />
          }
        ]
      },
      {
        path: 'tasks',
        children: [
          {
            path: '/',
            element: <TaskList />
          },
        ]
      },
      {
        path: 'calls',
        children: [
          {
            path: '/',
            element: <Calls />
          },
        ]
      },
      {
        path: 'statements',
        children: [
          {
            path: '/',
            element: <Statements />
          },
        ]
      },
      {
        path: 'ip',
        children: [
          {
            path: '/',
            element: <IPList />
          },
          {
            path: '/new',
            element: <IPCreate />
          },
          {
            path: '/edit/:id',
            element: <IPCreate />
          },
        ]
      },
      // {
      //   path: 'kanban',
      //   element: <Kanban />
      // },
      // {
      //   path: 'mail',
      //   children: [
      //     {
      //       path: '/',
      //       element: (
      //         <Navigate
      //           to="/dashboard/mail/all"
      //           replace
      //         />
      //       )
      //     },
      //     {
      //       path: 'label/:customLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: 'label/:customLabel/:emailId',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel/:emailId',
      //       element: <Mail />
      //     }
      //   ]
      // },
      // {
      //   path: 'orders',
      //   children: [
      //     {
      //       path: '/',
      //       element: <OrderList />
      //     },
      //     {
      //       path: ':orderId',
      //       element: <OrderDetails />
      //     }
      //   ]
      // },
      // {
      //   path: 'finance',
      //   element: <Finance />
      // },
      // {
      //   path: 'products',
      //   children: [
      //     {
      //       path: '/',
      //       element: <ProductList />
      //     },
      //     {
      //       path: 'new',
      //       element: <ProductCreate />
      //     }
      //   ]
      // },
      {
        path: 'generation',
        children: [
          // {
          //   path: '/',
          //   element: <GenerationList />
          // },
          {
            path: '/new',
            element: <GenerationCreate />
          }
        ]
      },
      {
        path: 'projects',
        children: [
          // {
          //   paht: '/',
          //   element: <ProjectBrowse />
          // },
          {
            path: 'browse',
            element: <Projects />
          },
          {
            path: 'new',
            element: <ProjectCreate />
          },
          {
            path: ':projectId',
            element: <ProjectDetailsPage />
          },
          {
            path: ':projectId/edit',
            element: <ProjectEdit standAlone />
          }
        ]
      },
      {
        path: 'clients',
        children: [
          {
            path: 'sell',
            element: <ClientBrowse filter="sell" />
          },
          {
            path: 'buy',
            element: <ClientBrowse filter="buy" />
          },
          {
            path: 'browse',
            element: <ClientBrowse filter="all" />
          },
          {
            path: ':clientId',
            element: <CustomerDetails />
          },
          {
            path: ':clientId/edit',
            element: <CustomerEdit standAlone />
          },
          {
            path: '/users/:userId',
            element: <CardList />
          },
          {
            path: '/public/users/:userId',
            element: <CardList />
          },
        ]
      },
      {
        path: 'documents',
        children: [
          {
            path: '/',
            element: <Documents />
          },
          {
            path: ':folder/*',
            element: <Documents />
          }
        ]
      },
      {
        path: 'hr',
        children: [
          {
            path: '/',
            element: <Applicants />
          },
        ]
      },
      // {
      //   path: 'social',
      //   children: [
      //     {
      //       path: 'feed',
      //       element: <SocialFeed />
      //     },
      //     {
      //       path: 'profile',
      //       element: <SocialProfile />
      //     }
      //   ]
      // },
      {
        path: 'owntrainings',
        element: <OwnTrainings />
      },
      {
        path: 'trainings',
        element: <TrainingLayout />,
        children: [
          {
            path: '/',
            element: <TrainingBrowse />
          },
          {
            path: '/otrainings',
            element: <OTrainingBrowse />
          },
          {
            path: '/otrainings/:oTrainingId',
            element: <OTrainingParticipantsBrowse />
          },
          {
            path: '/otrainings/user-trainings/:userTrainingId',
            element: <OTrainingUserBrowse />
          },
        ]
      },
      {
        path: 'trainings/otrainings/user-trainings/:userTrainingId/steps',
        element: <OTrainingSteps />
      },
      {
        path: 'hint',
        children: [
          {
            path: '/',
            element: <HintBrowse />
          },
        ]
      },
      {
        path: 'add-hint',
        children: [
          {
            path: '/',
            element: <HintBrowse add />
          },
          {
            path: '/new',
            element: <HintCreate />
          },
          {
            path: '/link/new',
            element: <HintCreate outsideLink />
          },
        ]
      },

      {
        path: 'ratings',
        // element: <BlogLayout />,
        children: [
          {
            path: '/',
            element: <Rate />
          },
        ]
      },
      {
        path: 'blog',
        // element: <BlogLayout />,
        children: [
          {
            path: '/',
            element: <BlogPostList />
          },
          // {
          //   path: 'new', NOTE: now we create a blogPost immediately with empty fields, therefore after that we always just update
          //   element: <BlogPostCreate />
          // },
          {
            path: 'edit/:postId',
            element: <BlogPostCreate />
          },
          {
            path: ':postId',
            element: <BlogPostDetails />
          }
        ]
      },
      {
        path: 'statistics',
        // element: <BlogLayout />,
        children: [
          {
            path: '/',
            element: <StatisticsBrowse />
          },
        ]
      },
    ]
  },
  {
    path: 'webPresentation', // TODO: replace this element later, outside of the BO
    children: [
      {
        path: '/',
        element: <WebPresentationBrowse />
      },
      {
        path: ':id',
        element: <WebPresentationBrowse />
      }
    ]
  },
  // {
  //   path: 'docs',
  //   element: <DocsLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: (
  //         <Navigate
  //           to="/docs/overview/welcome"
  //           replace
  //         />
  //       )
  //     },
  //     {
  //       path: '*',
  //       element: <Docs />
  //     }
  //   ]
  // },
  {
    path: '*',
    // element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      // {
      //   path: '/',
      //   element: <Home />
      // },
      // {
      //   path: 'browse',
      //   element: <BrowseLayout />,
      //   children: [
      //     {
      //       path: '/',
      //       element: <Browse />
      //     },
      //     {
      //       path: '/buttons',
      //       element: <BrowseButtons />
      //     },
      //     {
      //       path: '/inputs',
      //       element: <BrowseInputs />
      //     },
      //     {
      //       path: '/charts',
      //       element: <BrowseCharts />
      //     },
      //     {
      //       path: '/colors',
      //       element: <BrowseColors />
      //     },
      //     {
      //       path: '/data-display/detail-lists',
      //       element: <BrowseDetailLists />
      //     },
      //     {
      //       path: '/data-display/quick-stats',
      //       element: <BrowseQuickStats />
      //     },
      //     {
      //       path: '/data-display/tables',
      //       element: <BrowseTables />
      //     },
      //     {
      //       path: '/forms',
      //       element: <BrowseForms />
      //     },
      //     {
      //       path: '/modals',
      //       element: <BrowseModals />
      //     },
      //     {
      //       path: '/lists/grouped-lists',
      //       element: <BrowseGroupedLists />
      //     },
      //     {
      //       path: '/lists/grid-lists',
      //       element: <BrowseGridLists />
      //     },
      //     {
      //       path: '/typography',
      //       element: <BrowseTypography />
      //     }
      //   ]
      // },
      // {
      //   path: 'checkout',
      //   element: <Checkout />
      // },
      // {
      //   path: 'pricing',
      //   element: <Pricing />
      // },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
