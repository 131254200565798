import {
  Box,
  Card,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import ArrowRightIcon from '../../../icons/ArrowRight';
import ImageIcon from '../../../icons/Image';

// const searchHistory = [
//   {
//     date: '2021.11.05. 09:30:00',
//     image: '/static/mock-images/properties/001.png',
//     isAvailable: true,
//     name: 'Charlie Tulip Dress',
//     id: '001',
//     location: 'Siófok',
//     type: 'email'
//   },
//   {
//     date: '2021.11.05. 09:30:00',
//     image: '/static/mock-images/properties/002.png',
//     isAvailable: true,
//     name: 'Charlie Tulip Dress',
//     id: '002',
//     location: 'Nagyberény',
//     type: 'phone'
//   },
//   {
//     date: '2021.11.05. 09:30:00',
//     image: '/static/mock-images/properties/003.png',
//     isAvailable: true,
//     name: 'Charlie Tulip Dress',
//     id: '003',
//     location: 'Siófok',
//     type: 'ip'
//   },
//   {
//     date: '2021.11.05. 09:30:00',
//     image: '/static/mock-images/properties/004.png',
//     isAvailable: true,
//     name: 'Charlie Tulip Dress',
//     id: '004',
//     location: 'Zamárdi',
//     type: 'phone'
//   },
//   {
//     date: '2021.11.05. 09:30:00',
//     image: '/static/mock-images/properties/005.png',
//     isAvailable: true,
//     name: 'Charlie Tulip Dress',
//     id: '005',
//     location: 'Siófok',
//     type: 'hp'
//   },
// ];

const searchHistory = undefined;

const getTypeLabel = (type) => {
  const map = {
    email: {
      text: 'E-mail',
      color: 'success'
    },
    phone: {
      text: 'Telefon',
      color: 'warning'
    },
    ip: {
      text: 'IP',
      color: 'error'
    },
    hp: {
      text: 'HP',
      color: 'error'
    }
  };

  const { text, color } = map[type];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const ClientRecommendations = () => (
  <Box>
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 1000 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Település
                </TableCell>
                <TableCell>
                  Típus
                </TableCell>
                <TableCell>
                  Üzletkötő
                </TableCell>
                <TableCell>
                  Azonosító
                </TableCell>
                <TableCell align="right">
                  Időpont
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {searchHistory?.map((product) => (
                <TableRow
                  hover
                  key={product.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {product.image
                        ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 100,
                              '& img': {
                                height: 'auto',
                                width: '100%'
                              }
                            }}
                          >
                            <img
                              alt="Product"
                              src={product.image}
                            />
                          </Box>
                        )
                        : (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              width: 100
                            }}
                          >
                            <ImageIcon fontSize="small" />
                          </Box>
                        )}
                      <Link
                        color="textPrimary"
                        underline="none"
                        sx={{ ml: 2 }}
                        variant="subtitle2"
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {product.location}
                  </TableCell>
                  <TableCell>
                    {getTypeLabel(product.type)}
                  </TableCell>
                  <TableCell>
                    {product.date}
                  </TableCell>
                  <TableCell>
                    {product.id}
                  </TableCell>
                  <TableCell align="right">
                    {product.date}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip
                      title="Tovább az ingatlan adatlapjára"
                      placement="top"
                    >
                      <IconButton>
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={searchHistory?.length || 0}
            onPageChange={() => {
            }}
            onRowsPerPageChange={() => {
            }}
            page={0}
            rowsPerPage={5}
            rowsPerPageOptions={[5, 10, 25]}
            labelDisplayedRows={
              ({ from, to, count }) => `Elem ${from}-${to} - Összesen: ${count}`
            }
            labelRowsPerPage=""
          />
        </Box>
      </Scrollbar>
    </Card>
  </Box>
);

export default ClientRecommendations;
