import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import deepCopy from '../utils/deepCopy';
import { toggleLoading } from './loading';

const initialState = {
  projects: [],
  selectedProjectId: null,
  selectedProject: {},
  files: [],
  projectLoading: [],
  loading: false
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getProjects(state, action) {
      state.projects = action.payload;
    },
    handleLoading(state, action) {
      state.loading = action.payload;
    },
    addProjectLoading(state, action) {
      state.projectLoading.push(action.payload);
    },
    removeProjectLoading(state, action) {
      // remove only one elem from array named action.payload
      /* eslint-disable */
      state.projectLoading.forEach((loading, index) => {
        if (loading === action.payload) {
          state.projectLoading.splice(index, 1);
          return
        }
      });
      /* eslint-enable */
      // state.projectLoading = state.projectLoading.filter((loading) => loading !== action.payload);
    },
    getProjectById(state, action) {
      state.selectedProject = action.payload;
    },
    clearProjectId(state) {
      state.selectedProjectId = null;
    },
    removeSelectedProject(state) {
      state.selectedProject = null;
    },
    createProject(state, action) {
      state.projects.push(action.payload);
    },
    updateProject(state, action) {
      const project = action.payload;
      state.projects = state.projects.map(((_project) => {
        if (_project.id === project.id) {
          return project;
        }
        return _project;
      }));
    },
    updateProjectStatus(state, action,) {
      const { id } = action.payload;
      state.projects = state.projects.map(((_project) => {
        if (_project.id === id) {
          _project.status = 0;
          return _project;
        }
        return _project;
      }));
    },
    updateProjectImage(state, action,) {
      const image = action.payload[0];
      const id = action.payload[1];
      state.projects = state.projects.map(((_project) => {
        if (_project.id === id) {
          _project.image = image;
          return _project;
        }
        return _project;
      }));
    },
    setProjectId(state, action) {
      state.selectedProjectId = action.payload;
    },
    createFile(state, action) {
      state.files.push(action.payload);
    },
    deleteFile(state, action) {
      state.files = state.files.filter((_file) => _file.id !== action.payload.fileId);
    },
    getFiles(state, action) {
      state.files = action.payload;
    },
    clearFiles(state) {
      state.files = [];
    },
  }
});

export const { reducer } = slice;

export const switchProjectLoading = (type, open) => async (dispatch) => {
  if (open) {
    dispatch(slice.actions.addProjectLoading(type));
  } else {
    dispatch(slice.actions.removeProjectLoading(type));
  }
};

export const getProjects = (pageNumber) => async (dispatch) => {
  dispatch(slice.actions.handleLoading(true));
  await axiosConfig.get(
    `projects?pageNumber=${pageNumber || 0}`
  ).then((response) => {
    const data = deepCopy(response.data.data);
    dispatch(slice.actions.getProjects(data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba');
    console.error(error);
  }).then(() => {
    dispatch(slice.actions.handleLoading(false));
    // always executed
  });
};
export const clearSelectedProject = () => async (dispatch) => {
  const answer = {};
  dispatch(slice.actions.getProjectById(answer));
};

export const getProjectByID = (id) => async (dispatch) => {
  await axiosConfig.get(
    `projects/${id}`
  ).then((response) => {
    const { data } = response;
    // console.log('getProjectById', data);
    dispatch(slice.actions.getProjectById(data));
    if (data.files) {
      dispatch(slice.actions.getFiles(data.files));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const deleteProjectImage = (projectId, imageId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_PROJECT_IMG' }));
  await axiosConfig.delete(
    `projects/${projectId}/images/${imageId}`
  ).then(() => {
    toast.success('Kép törölve');
    dispatch(getProjectByID(projectId));
    dispatch(getProjects());
  }).catch((error) => {
    // handle error
    toast.error('Hiba kép törlésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_PROJECT_IMG' }));
  });
};

export const deleteProject = (projectId) => async (dispatch) => {
  await axiosConfig.put(
    `projects/${projectId}/archive`
  ).then((response) => {
    dispatch(slice.actions.updateProjectStatus(response.data));
    toast.success('Project archiválva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba projekt archiválásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const updateProject = (data, id, canChange, imgId, navigateBack) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_PROJECT' }));
  await axiosConfig.put(
    `projects/${id}`,
    data
  ).then((response) => {
    dispatch(slice.actions.updateProject(response.data));
    toast.success('A Projekt frissítve');
    if (!imgId && data.image && canChange) {
      dispatch(toggleLoading({ type: 'UPLOAD_PROJECT_IMG' }));
      axiosConfig.post(
        `/projects/${response.data.id}/images`,
        data.image
      ).then((response2) => {
        dispatch(slice.actions.updateProject(response2.data));
        toast.success('Kép feltöltve');
      }).catch((error) => {
        // handle error
        toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
        // always executed
        dispatch(toggleLoading({ type: 'UPLOAD_PROJECT_IMG' }));
      });
    } else if (imgId && data.image && canChange) {
      dispatch(toggleLoading({ type: 'CHANGE_PROJECT_IMG' }));
      axiosConfig.put(
        `/projects/${response.data.id}/images/${imgId}`,
        data.image
      ).then((response2) => {
        dispatch(slice.actions.updateProject(response2.data));
        toast.success('Kép sikeresen cserélve');
        // dispatch(slice.actions.updateProject(response.data));
      }).catch((error) => {
        // handle error
        // toast.error('Hiba kép lecserélésekor');
        console.error(error);
      }).then(() => {
        // always executed
        dispatch(toggleLoading({ type: 'CHANGE_PROJECT_IMG' }));
      });
    }
    navigateBack();
  }).catch((error) => {
    // handle error
    toast.error('Hiba kép feltöltésekor 2 ');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_PROJECT' }));
  });
};

export const createProject = (data, canChange) => async (dispatch) => {
  await axiosConfig.post(
    '/projects',
    data
  ).then((response) => {
    dispatch(slice.actions.createProject(response.data));
    if (canChange) {
      axiosConfig.post(
        `/projects/${response.data.id}/images`,
        data.image
      ).then((response2) => {
        dispatch(slice.actions.updateProjectImage([response2.data, response.data.id]));
        toast.success('kép sikeresen feltöltve');
      }).catch((error) => {
        // handle error
        toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
        // always executed
      });
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba ');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const createProjectFile = (projectId, file) => async (dispatch) => {
  dispatch(slice.actions.addProjectLoading('UPLOAD_FILE'));
  const formData = new FormData();
  formData.append('file', file);
  formData.append('title', file.name);
  // formData.append('isPublic', true);
  // formData.append('category', values.category);
  await axiosConfig.post(
    `projects/${projectId}/files`,
    formData
  ).then((response) => {
    // const file = {
    //   fileName: response.data.originalFilename,
    //   id: response.data.id,
    //   relativeUrl: response.data.relativeUrl
    // };
    // console.log('createFile response data', response.data);
    toast.success('A dokumentum sikeresen mentve!');
    dispatch(slice.actions.createFile(response.data));
  }).catch((error) => {
    toast.error('A dokumentum mentése sikertelen');
    console.error(error);
  }).finally(() => {
    // switchProjectLoading('UPLOAD_FILE', false);
    dispatch(slice.actions.removeProjectLoading('UPLOAD_FILE'));
  });
};

export const deleteFile = (projectId, fileId) => async (dispatch) => {
  dispatch(slice.actions.addProjectLoading('DELETE_FILE'));
  await axiosConfig.delete(
    `projects/${projectId}/files/${fileId}`
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // console.log('deleteFile response data', response.data);
    dispatch(slice.actions.deleteFile({ fileId }));
    toast.success('A dokumentum sikeresen törölve');
  }).catch(() => {
    toast.error('Hiba a dokumentum törlésekor');
  }).finally(() => {
    dispatch(slice.actions.removeProjectLoading('DELETE_FILE'));
  });
};

export const clearFiles = () => (dispatch) => {
  dispatch(slice.actions.clearFiles());
};

export const setProjectId = (id) => (dispatch) => {
  if (id) {
    dispatch(slice.actions.setProjectId(id));
  } else {
    dispatch(slice.actions.setProjectId(null));
  }
};

export const clearProjectId = () => async (dispatch) => {
  dispatch(slice.actions.clearProjectId());
};

export const removeSelectedProject = () => async (dispatch) => {
  dispatch(slice.actions.removeSelectedProject());
};

export default slice;
