import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { addMinutes } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import DesktopDateTimePicker from "@material-ui/lab/DesktopDateTimePicker";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
// eslint-disable-next-line no-unused-vars
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../../slices/calendar";
import { useDispatch } from "../../../store";
import { removeHTMLTags } from "../../../utils/removeHTMLTags";

const selectReminderOptions = [
  {
    label: "Az esemény előtt",
    value: 0,
  },
  {
    label: "5 percel előtte",
    value: 5,
  },
  {
    label: "10 percel előtte",
    value: 10,
  },
  {
    label: "15 percel előtte",
    value: 15,
  },
  {
    label: "30 percel előtte",
    value: 30,
  },
  {
    label: "1 órával előtte",
    value: 60,
  },
  {
    label: "2 órával előtte",
    value: 120,
  },
  {
    label: "1 nappal előtte",
    value: 1440,
  },
];

const selectStatusOptions = [
  {
    label: "Folyamatban",
    value: "IN_PROGRESS",
  },
  {
    label: "Meghiúsult",
    value: "FAILED",
  },
  {
    label: "Megvalósult",
    value: "DONE",
  },
];

const getInitialValues = (event, range, clientName) => {
  if (event) {
    return {
      title: event.title || "",
      allDay: event.allDay || false,
      start: event.start ? new Date(event.start) : new Date(),
      end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      reminder: event.reminder || "",
      status: event.status || "",
      description: removeHTMLTags(event.description) || "",
      type: "appointment",
      submit: null,
    };
  }

  if (range) {
    return {
      title: `Ügyfél visszahívás - ${clientName || "nem felvett ügyfél"}`,
      allDay: false,
      start: new Date(range.start),
      end: new Date(range.end),
      reminder: "",
      status: "",
      description: "",
      type: "appointment",
      submit: null,
    };
  }

  return {
    title: `Ügyfél visszahívás - ${clientName}` || "",
    allDay: false,
    start: new Date(),
    end: addMinutes(new Date(), 30),
    reminder: "",
    status: "",
    description: "",
    type: "appointment",
    submit: null,
  };
};

const CalendarAppointmentEventForm = (props) => {
  const {
    clientName,
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
    onProcessModalComplete,
  } = props;
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      await dispatch(deleteEvent(event.id));
      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(event, range, clientName)}
      validationSchema={Yup.object().shape({
        allDay: Yup.bool(),
        description: Yup.string().max(5000),
        end: Yup.date()
          .typeError("Nincs helyesen megadva a záró dátum (éééé/hh/nn óó/pp)")
          .min(
            Yup.ref("start"),
            "Az esemény vége, nem lehet korábbi a kezdeténél."
          )
          .required("Záró dátum megadása kötelező"),
        start: Yup.date()
          .typeError("Nincs helyesen megadva a kezdő dátum (éééé/hh/nn óó/pp)")
          .required("Kezdő dátum megadása kötelező"),
        title: Yup.string().max(255).required("Esemény megnevezése kötelező"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            title: values.title,
            allDay: values.allDay,
            start: values.start.getTime(),
            end: values.end.getTime(),
            reminder: values.reminder,
            status: values.status || null,
            description: values.description,
            type: "appointment",
          };

          const callData = {
            appointment: values.start.getTime(),
          };
          if (event) {
            await dispatch(updateEvent(event.id, data));
          } else {
            /* NOTE: do nothing, createEvent is called in the onComplete(onProcessModalComplete) func in callWizard component */
            // await dispatch(createEvent(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Naptár frissítve!");

          if (!event && onAddComplete) {
            onAddComplete();
            onProcessModalComplete("callTask", callData, data);
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error("Naptár frissítése sikertelen!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event
                ? "Ügyfél visszahívásának szerkesztése"
                : "Ügyfél visszahívása"}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              label="Esemény neve"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.allDay}
                    color="primary"
                    name="allDay"
                    onChange={handleChange}
                  />
                }
                label="Egész nap"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Kezdete"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue("start", date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.start}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Vége"
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                ampm={false}
                onChange={(date) => setFieldValue("end", date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.end}
              />
            </Box>
            {/* <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.reminder && errors.reminder)}
                fullWidth
                helperText={touched.reminder && errors.reminder}
                label="Emlékeztetés"
                name="reminder"
                select
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reminder}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>Válassz...</em>
                </MenuItem>
                {selectReminderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box> */}
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.status && errors.status)}
                fullWidth
                helperText={touched.status && errors.status}
                label="Státusz"
                name="status"
                select
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
                variant="outlined"
              >
                <MenuItem value="">
                  <em>Válassz...</em>
                </MenuItem>
                {selectStatusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Megjegyzés"
                name="description"
                multiline
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>
            {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.end}</FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button color="primary" onClick={onCancel} variant="text">
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CalendarAppointmentEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
  onProcessModalComplete: PropTypes.func,
  clientName: PropTypes.any,
};

export default CalendarAppointmentEventForm;
