import { Avatar, Box, Button, Dialog, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { useDispatch, useSelector } from '../../store';
import { closeModal } from '../../slices/modals';
import { useNavigate } from 'react-router';

const ConfirmModal = (props) => {
  const { header, content, handleConfirm } = props;
  const { isConfirmModalOpen } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  const handleModalConfirm = () => {
    if (handleConfirm) {
      handleConfirm();
    } else {
      navigate(-1);
    }

    handleModalClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleModalClose}
      open={isConfirmModalOpen}
    >
      {isConfirmModalOpen && (
        <Paper elevation={12}>
          <Box
            sx={{
              display: 'flex',
              pb: 2,
              pt: 3,
              px: 3
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                color: 'error.main',
                mr: 2
              }}
            >
              <WarningIcon />
            </Avatar>
            <Box>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {header}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ mt: 1 }}
                variant="body2"
              >
                {content}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              px: 3,
              py: 1.5
            }}
          >
            <Button
              color="primary"
              sx={{ mr: 2 }}
              variant="outlined"
              onClick={handleModalClose}
            >
              Mégsem
            </Button>
            <Button
              sx={{
                backgroundColor: 'error.main',
                '&:hover': {
                  backgroundColor: 'error.dark'
                }
              }}
              variant="contained"
              onClick={handleModalConfirm}
            >
              Igen
            </Button>
          </Box>
        </Paper>

      )}
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
};

export default ConfirmModal;
