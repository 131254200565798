export const SCOPES = {
  canClientCreate: 'PRIVILEGE_CLIENT_CREATE',
  canClientUpdate: 'PRIVILEGE_CLIENT_UPDATE',
  canClientUpdateOwn: 'PRIVILEGE_CLIENT_UPDATE_OWN',
  canClientArchive: 'PRIVILEGE_CLIENT_ARCHIVE',
  canClientArchiveRead: 'PRIVILEGE_CLIENT_ARCHIVE_READ',
  canPropertyRead: 'PRIVILEGE_PROPERTY_READ',
  canPropertyCreate: 'PRIVILEGE_PROPERTY_CREATE',
  canPropertyUpdate: 'PRIVILEGE_PROPERTY_UPDATE',
  canPropertyUpdateOwn: 'PRIVILEGE_PROPERTY_UPDATE_OWN',
  canPropertyArchive: 'PRIVILEGE_PROPERTY_ARCHIVE',
  canPropertyApprove: 'PRIVILEGE_PROPERTY_ENDORSE',
  canOfficeCreate: 'PRIVILEGE_OFFICE_CREATE',
  canOfficeUpdate: 'PRIVILEGE_OFFICE_UPDATE',
  canUserCreate: 'PRIVILEGE_USER_CREATE',
  canUserUpdate: 'PRIVILEGE_USER_UPDATE',
  canHRCreate: 'PRIVILEGE_HR_CREATE',
  canHRUpdate: 'PRIVILEGE_HR_UPDATE',
};
