import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialSearchParameters: {
    id: '',
    onlyUser: false,
    onlyOffice: false,
    balaton_north_coast: false,
    balaton_south_coast: false,
    clientName: '',
    office: [],
    employee: [],
    currency: 'Ft',
    publicId: '',
    paymentType: '',
    propertyType: [],
    residential: '',
    business: '',
    industrial: '',
    agricultural: '',
    location: '',
    cities: [],
    street: '',
    streetNumber: '',
    floorAreaMin: '',
    floorAreaMax: '',
    landAreaMin: '',
    landAreaMax: '',
    parcelNumber: '',
    minPrice: '',
    maxPrice: '',
    numberOfRooms: '',
    numberOfRoomsFrom: '',
    numberOfRoomsTo: '',
    numberOfFloorFrom: '',
    numberOfFloorTo: '',
    floor: '',
    minWaterfront: '',
    maxWaterfront: '',
    railwayDistanceFrom: '',
    railwayDistanceTo: '',
    electricity: [],
    water: [],
    gas: [],
    sewer: [],
    constructionType: '',
    internalCondition: [],
    externalCondition: [],
    isPublic: '',
    publicLabels: [],
    privateLabels: [],
    heating: [],
    view: [],
    parking: [],
    accessibility: '',
    furnished: '',
    cooling: '',
    phone: '',
    energyCertificate: '',
    tv: '',
    internet: '',
    comfortLevel: '',
    state: [
      {
        id: 1,
        label: 'Feltöltés alatt',
        disable: false
      },
      {
        id: 2,
        label: 'Jóváhagyásra vár',
        disable: false
      },
      {
        id: 3,
        label: 'Aktív',
        disable: false
      }
    ]
  },
  defaultInitialSearchParameters: {
    id: '',
    onlyUser: false,
    onlyOffice: false,
    balaton_north_coast: false,
    balaton_south_coast: false,
    clientName: '',
    office: [],
    employee: [],
    currency: 'Ft',
    publicId: '',
    paymentType: '',
    propertyType: [],
    residential: '',
    business: '',
    industrial: '',
    agricultural: '',
    location: '',
    cities: [],
    street: '',
    streetNumber: '',
    floorAreaMin: '',
    floorAreaMax: '',
    landAreaMin: '',
    landAreaMax: '',
    parcelNumber: '',
    minPrice: '',
    maxPrice: '',
    numberOfRooms: '',
    numberOfRoomsFrom: '',
    numberOfRoomsTo: '',
    numberOfFloorFrom: '',
    numberOfFloorTo: '',
    floor: '',
    minWaterfront: '',
    maxWaterfront: '',
    minWaterToRailway: '',
    maxWaterToRailway: '',
    electricity: [],
    water: [],
    gas: [],
    sewer: [],
    constructionType: '',
    internalCondition: [],
    externalCondition: [],
    isPublic: '',
    publicLabels: [],
    privateLabels: [],
    heating: [],
    view: [],
    parking: [],
    accessibility: '',
    furnished: '',
    cooling: '',
    phone: '',
    energyCertificate: '',
    tv: '',
    internet: '',
    comfortLevel: '',
    state: [
      {
        id: 1,
        label: 'Feltöltés alatt',
        disable: false
      },
      {
        id: 2,
        label: 'Jóváhagyásra vár',
        disable: false
      },
      {
        id: 3,
        label: 'Aktív',
        disable: false
      }
    ]
  },
  clientSearchInitialParameters: {
    id: '',
    onlyUser: false,
    name: '',
    phone: '',
    email: '',
    city: '',
    locations: [],
    employee: [],
    office: [],
    clientType: '',
    state: [],
    transactionType: '',
    propertyType: [],
    salePriceFrom: '',
    salePriceTo: '',
    floorAreaFrom: '',
    floorAreaTo: '',
    landAreaFrom: '',
    landAreaTo: '',
    roomCountFrom: '',
    roomCountTo: '',
    floorLevelFrom: '',
    floorLevelTo: '',
    waterDistanceFrom: '',
    waterDistanceTo: '',
    currency: 'Ft',
  },
  clientSearchDefaultParameters: {
    id: '',
    onlyUser: false,
    name: '',
    phone: '',
    email: '',
    city: '',
    locations: [],
    employee: [],
    office: [],
    clientType: '',
    state: [],
    transactionType: '',
    propertyType: [],
    salePriceFrom: '',
    salePriceTo: '',
    floorAreaFrom: '',
    floorAreaTo: '',
    landAreaFrom: '',
    landAreaTo: '',
    roomCountFrom: '',
    roomCountTo: '',
    floorLevelFrom: '',
    floorLevelTo: '',
    waterDistanceFrom: '',
    waterDistanceTo: '',
    currency: 'Ft',
  },
  lastClientSearchDataResult: {},
  lastPropertySearchDataResult: {},
  defaultPropertySearchData: {
    circleCenter: null,
    circleRadus: null,
    polygon: null,
    officeId: null,
    // propertyId: null,
    publicId: null,
    street: null,
    cities: null,
    userId: null,
    streetNumber: null,
    floorAreaFrom: null,
    floorAreaTo: null,
    landAreaFrom: null,
    landAreaTo: null,
    salePriceFrom: null,
    salePriceTo: null,
    roomCountFrom: null,
    roomCountTo: null,
    waterDistanceFrom: null,
    waterDistanceTo: null,
    floorLevelFrom: null,
    floorLevelTo: null,
    parcelNumber: null,
    northCoast: null,
    southCoast: null,
    state: null,
    attributeOptionIds: []
  }
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateInitialSearchParameters(state, action) {
      if (action.payload) {
        state.initialSearchParameters = action.payload;
      } else {
        state.initialSearchParameters = initialState.defaultInitialSearchParameters;
      }
    },
    updateClientSearchInitialSearchParameters(state, action) {
      if (action.payload) {
        state.clientSearchInitialParameters = action.payload;
      } else {
        state.clientSearchInitialParameters = initialState.clientSearchDefaultParameters;
      }
    },
    setLastClientSearchData(state, action) {
      if (action.payload) {
        state.lastClientSearchDataResult = action.payload;
      } else {
        state.lastClientSearchDataResult = {};
      }
    },
    setLastPropertySearchData(state, action) {
      if (action.payload) {
        state.lastPropertySearchDataResult = action.payload;
      } else {
        state.lastPropertySearchDataResult = {};
      }
    }
  }
});

export const { reducer } = slice;

export const updateInitialSearchParameters = (update) => (dispatch) => {
  if (update) {
    dispatch(slice.actions.updateInitialSearchParameters(update));
  } else {
    dispatch(slice.actions.updateInitialSearchParameters(update));
  }
};

export const updateClientSearchInitialSearchParameters = (update) => (dispatch) => {
  if (update) {
    dispatch(slice.actions.updateClientSearchInitialSearchParameters(update));
  } else {
    dispatch(slice.actions.updateClientSearchInitialSearchParameters(update));
  }
};

export const setLastClientSearchData = (update) => (dispatch) => {
  if (update) {
    dispatch(slice.actions.setLastClientSearchData(update));
  } else {
    dispatch(slice.actions.setLastClientSearchData());
  }
};
export const setLastPropertySearchData = (update) => (dispatch) => {
  if (update) {
    dispatch(slice.actions.setLastPropertySearchData(update));
  } else {
    dispatch(slice.actions.setLastPropertySearchData());
  }
};

export default slice;
