import { createSlice } from "@reduxjs/toolkit";
import axiosUploadHandler from "../axiosUploadHandler";
import toast from "react-hot-toast";

const initialState = {
  images: {
    public_images: [],
    ipOnly_images: [],
    private_images: [],
    newImages: [],
  },
  videos: [],
  isModalOpen: false,
  selectedImage: {},
  defaultImages: {
    public_images: [],
    ipOnly_images: [],
    private_images: [],
    newImages: [],
  },
};

const getCategoryObjectFromId = (categoryId) => {
  const categoryObject = {
    3: {
      id: 3,
      label: "Publikus képek",
      value: "public_images",
    },
    2: {
      id: 2,
      label: "Csak IP-n publikus képek",
      value: "ipOnly_images",
    },
    1: {
      id: 1,
      label: "Privát képek",
      value: "private_images",
    },
    // 0: {
    //   id: 0,
    //   label: 'Privát képek',
    //   value: 'private_images'
    // }
  };

  return categoryObject[categoryId];
};

const slice = createSlice({
  name: "image",
  initialState,
  reducers: {
    getImages(state, action) {
      if (action.payload) {
        const images = action.payload;
        images.forEach((_image) => {
          if (_image.category) {
            const categoryObject = getCategoryObjectFromId(_image.category);
            state.images[categoryObject?.value].push(_image);
          } else {
            state.images.newImages.push(_image);
          }
        });
        state.images.public_images = state.images.public_images.sort(
          (a, b) => a?.ordering - b?.ordering,
        );
        state.images.ipOnly_images = state.images.ipOnly_images.sort(
          (a, b) => a?.ordering - b?.ordering,
        );
        state.images.private_images = state.images.private_images.sort(
          (a, b) => a?.ordering - b?.ordering,
        );
        state.images.newImages = state.images.newImages.sort(
          (a, b) => a?.ordering - b?.ordering,
        );
      } else {
        state.images = state.defaultImages;
      }
    },
    getVideos(state, action) {
      if (action.payload) {
        const videos = action.payload;
        videos.forEach((_video) => {
          state.videos.push(_video);
        });
      } else {
        state.videos = [];
      }
    },
    uploadImage(state, action) {
      const { data, value } = action.payload;
      if (value) {
        state.images[value].push(data);
      } else {
        state.images.newImages.push(data);
      }
    },
    uploadVideo(state, action) {
      const { data } = action.payload;
      const video = data;
      state.videos.push(video);
    },
    uploadPlaceHolderImage(state) {
      const data = {
        category: 0,
        ordering: 200 + state.images.newImages.length,
        isPublic: false,
        id: state.images.newImages.length,
        // title: `placeholder${state.images.newImages.length}`,
        title: "Töltés...",
        description: `placeholder${state.images.newImages.length}`,
        isPlaceHolder: true,
      };
      state.images.newImages.push(data);
    },
    uploadPlaceHolderVideo(state) {
      const data = {
        category: 0,
        ordering: 200 + state.videos.length,
        isPublic: false,
        id: state.videos.length,
        // title: `placeholder${state.videos.length}`,
        title: "Töltés...",
        description: `placeholder${state.videos.length}`,
        isPlaceHolder: true,
      };
      state.videos.push(data);
    },
    deletePlaceHolderImage(state) {
      state.images.newImages.filter((elem, i) => {
        if (elem?.isPlaceHolder === true) {
          return state.images.newImages.splice(i, 1);
        }
        return "";
      });
    },
    deletePlaceHolderVideo(state) {
      state.videos.filter((elem, i) => {
        if (elem?.isPlaceHolder === true) {
          return state.videos.splice(i, 1);
        }
        return "";
      });
    },
    selectImage(state, action) {
      state.selectedImage = action.payload;
    },
    cleanUpSelectImage(state) {
      state.selectedImage = {};
    },
    updateImage(state, action) {
      const { data, category, oldCategory } = action.payload;
      const image = data;
      if (oldCategory !== category.value) {
        state.images[oldCategory] = state.images[oldCategory].filter(
          (_image) => _image.id !== image.id,
        );
        state.images[category.value].push(image);
      }
      if (image.cover) {
        state.images[category.value] = state.images[category.value].filter(
          (_image2) => _image2.id !== image.id,
        );
        // set old cover to false
        state.images[category.value] = state.images[category.value].map(
          (_image) => {
            if (_image.id !== image.id) {
              return { ..._image, cover: false };
            }
            return _image;
          },
        );
        state.images[category.value] = [image, ...state.images[category.value]];
      }

      state.images[category.value] = state.images[category.value].map(
        (_image) => {
          if (_image.id === image.id) {
            return image;
          }

          return _image;
        },
      );
    },
    updateVideo(state, action) {
      const { data, category, oldCategory } = action.payload;
      const video = data;
      if (oldCategory !== category.value) {
        state.videos = state.videos.filter((_video) => _video.id !== video.id);
        state.videos.push(video);
      }

      state.videos = state.videos.map((_video) => {
        if (_video.id === video.id) {
          return video;
        }

        return _video;
      });
    },
    deleteImage(state, action) {
      const { imageId, categoryProp } = action.payload; // NOTE: It is questionable that we can use the image.category data insted categoryProp here if we want to
      state.images[categoryProp] = state.images[categoryProp].filter(
        (image) => image.id !== imageId,
      );
    },
    deleteVideo(state, action) {
      const { videoId } = action.payload;
      console.log(videoId);
      state.videos = state.videos.filter((video) => video.id !== videoId);
    },
    updateImageOrder(state, action) {
      const { update, category } = action.payload;
      state.images[category] = update;
    },
    updateVideoOrder(state, action) {
      const { update } = action.payload;
      state.videos = update;
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedRoomId = null;
    },
  },
});

export const { reducer } = slice;

// GET IMAGES

export const getImages = (images) => async (dispatch) => {
  dispatch(slice.actions.getImages(images));

  // await axiosUploadHandler.get(
  //   '/images'
  // ).then((response) => {
  //   // handle success
  //   if (filter === 'unapproved') {
  //     const { data, ...rest } = response;
  //     const unapprovedImages = data.data.filter((image) => image.status === '0');
  //     const filteredImages = {
  //       data: unapprovedImages,
  //       ...rest
  //     };

  //     dispatch(slice.actions.getImages(filteredImages, filter));
  //   } else {
  //     dispatch(slice.actions.getImages(response.data, filter));
  //   }
  // }).catch((error) => {
  //   // handle error
  //   toast.error('A képek betöltése sikertelen');
  //   console.error(error);
  // }).then(() => {
  //   // always executed
  // });
};
export const getVideos = (videos) => async (dispatch) => {
  dispatch(slice.actions.getVideos(videos));
};

// UPLOAD IMAGE

// export const uploadImage = (propertyId, payload) => async (dispatch) => {
//   const formData = new FormData();
//   formData.append("title", 'title');
//   formData.append("description", 'description');
//   formData.append("isPublic", false);
//   formData.append("file", payload);

//   const config = {
//     onUploadProgress: progressEvent => toast.loading(`${progressEvent.loaded}/${progressEvent.total}`)
//   }

//   await axiosUploadHandler.post(
//     `/properties/${propertyId}/images`,
//     formData,
//     config
//   ).then((response) => {
//     // handle success
//     dispatch(slice.actions.uploadImage(payload));
//     toast.success('Kép sikeresen feltöltve!');
//   }).catch((error) => {
//     // handle error
//     toast.error('Hiba a kép feltöltésekor!');
//     console.error(error);
//   }).then(() => {
//     // always executed
//   });
// };

export const uploadImage = (propertyId, images) => async (dispatch) => {
  for (let index = 0; index < images.length; index++) {
    dispatch(slice.actions.uploadPlaceHolderImage());
    const image = images[index];
    console.log("image", image);
    const formData = new FormData();
    // console.log('formData', formData);
    formData.append("title", image.name);
    formData.append("description", image.path);
    formData.append("isPublic", false);
    if (image?.categoryObject?.id) {
      formData.append("category", image.categoryObject.id);
    }
    formData.append("file", image);
    // eslint-disable-next-line
    await axiosUploadHandler
      .post(`/properties/${propertyId}/images`, formData)
      .then((response) => {
        const { data } = response;
        if (image?.categoryObject?.value) {
          const {
            categoryObject: { value },
          } = image;
          dispatch(slice.actions.uploadImage({ data, value }));
        } else {
          dispatch(slice.actions.uploadImage({ data }));
        }

        toast.success(
          `Kép (${index + 1}/${images.length}) sikeresen feltöltve!`,
        );
      })
      .catch((error) => {
        // const { response: { status } } = response && status && error;
        if (error?.response?.status === 409) {
          // toast.error(`Hiba a kép (${index + 1}/${images.length}) feltöltésekor! (500)`);
        }

        if (error?.response?.status === 500) {
          toast.error(
            `Hiba a kép (${index + 1}/${images.length}) feltöltésekor!`,
          );
        }
      })
      .finally(() => {
        dispatch(slice.actions.deletePlaceHolderImage());
      });
  }
};

export const uploadVideo = (propertyId, videos) => async (dispatch) => {
  for (let index = 0; index < videos.length; index++) {
    dispatch(slice.actions.uploadPlaceHolderVideo());
    const video = videos[index];
    const formData = new FormData();
    formData.append("title", video.name);
    formData.append("description", video.path);
    formData.append("isPublic", false);
    if (video?.categoryObject?.id) {
      formData.append("category", video.categoryObject.id);
    }
    formData.append("file", video);
    // eslint-disable-next-line
    await axiosUploadHandler
      .post(`/properties/${propertyId}/videos`, formData)
      .then((response) => {
        const { data } = response;
        if (video?.categoryObject?.value) {
          const {
            categoryObject: { value },
          } = video;
          dispatch(slice.actions.uploadVideo({ data, value }));
        } else {
          dispatch(slice.actions.uploadVideo({ data }));
        }

        toast.success(
          `Video (${index + 1}/${videos.length}) sikeresen feltöltve!`,
        );
      })
      .catch((error) => {
        // const { response: { status } } = response && status && error;
        if (error?.response?.status === 409) {
          // toast.error(`Hiba a kép (${index + 1}/${images.length}) feltöltésekor! (500)`);
        }

        if (error?.response?.status === 500) {
          toast.error(
            `Hiba a kép (${index + 1}/${videos.length}) feltöltésekor!`,
          );
        }
      })
      .finally(() => {
        dispatch(slice.actions.deletePlaceHolderVideo());
      });
  }
};

// GET THE DETAILS OF A SELECTED IMAGE

export const selectImage = (imageId) => async (dispatch) => {
  await axiosUploadHandler
    .get(`/images/${imageId}`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.selectImage(response.data));
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba kép betöltésekor!");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const selectVideo = (videoId) => async (dispatch) => {
  await axiosUploadHandler
    .get(`/videos/${videoId}`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.selectImage(response.data));
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba kép betöltésekor!");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

// CLEANUP SELECT IMAGE STATE

export const cleanUpSelectImage = () => async (dispatch) => {
  dispatch(slice.actions.cleanUpSelectImage());
};

// UPDATE SELECTED IMAGE

export const updateImage =
  (propertyId, imageId, update, oldCategory, destinationImagesArray, file) =>
    async (dispatch) => {
      const biggestOrder = Math.max(
        ...destinationImagesArray.map((image) => image.ordering),
      );
      const formData = new FormData();
      formData.append("title", update.title);
      formData.append("description", update.description);
      formData.append("category", update.category.id);
      formData.append("cover", update.cover);
      formData.append("floorPlan", update.floorPlan);
      if (biggestOrder % 1 === 0) {
        formData.append("ordering", biggestOrder + 1);
      } else {
        formData.append("ordering", destinationImagesArray.length + 1);
      }
      formData.append("isPublic", update.isPublic);
      if (file) {
        formData.append("file", file);
      }
      const resp = await axiosUploadHandler.put(
        `/properties/${propertyId}/images/${imageId}`,
        formData,
      );
      // .then((response) => {
      // handle success
      // }).catch((error) => {
      //   // handle error
      //   toast.error('Hiba a módosítások mentésekor');
      //   console.error(error);
      // }).then(() => {
      //   // always executed
      // });
      const { category } = update;
      const { data } = resp;
      dispatch(
        slice.actions.updateImage({ imageId, data, category, oldCategory }),
      );
      // toast.success('Kép sikeresen módosítva');
      return resp.data;
    };

export const updateVideo =
  (propertyId, videoId, update, oldCategory, destinationImagesArray, file) =>
    async (dispatch) => {
      const biggestOrder = Math.max(
        ...destinationImagesArray.map((image) => image.ordering),
      );
      const formData = new FormData();
      formData.append("title", update.title);
      formData.append("description", update.description);
      formData.append("category", update.category.id);

      if (biggestOrder % 1 === 0) {
        formData.append("ordering", biggestOrder + 1);
      } else {
        formData.append("ordering", destinationImagesArray.length + 1);
      }
      formData.append("isPublic", update.isPublic);
      if (file) {
        formData.append("file", file);
      }
      await axiosUploadHandler
        .put(
          `/properties/${propertyId}/videos/${videoId}`,
          formData,
          // update
        )
        .then((response) => {
          // handle success
          const { category } = update;
          const { data } = response;
          dispatch(
            slice.actions.updateVideo({ videoId, data, category, oldCategory }),
          );
          toast.success("Video sikeresen módosítva");
        })
        .catch((error) => {
          // handle error
          toast.error("Hiba a módosítások mentésekor");
          console.error(error);
        })
        .then(() => {
          // always executed
        });
    };

// DELETE SELECTED IMAGE

export const deleteImage =
  (propertyId, imageId, categoryProp) => async (dispatch) => {
    dispatch(slice.actions.deleteImage({ imageId, categoryProp })); // TODO: not so nice but looking fast
    await axiosUploadHandler
      .delete(`/properties/${propertyId}/images/${imageId}`)
      .then(() => {
        // handle success
        toast.success("Kép sikeresen törölve!");
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba a törlés végrehajtásakor");
        console.error(error);
      })
      .then(() => {
        // always executed
      });
  };

export const deleteVideo = (propertyId, videoId) => async (dispatch) => {
  dispatch(slice.actions.deleteVideo({ videoId })); // TODO: not so nice but looking fast
  await axiosUploadHandler
    .delete(`/properties/${propertyId}/videos/${videoId}`)
    .then(() => {
      // handle success
      toast.success("Video sikeresen törölve!");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a törlés végrehajtásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const updateImageOrder =
  (propertyId, update, category) => async (dispatch) => {
    const idList = [];
    for (let i = 0; i < update.length; i++) {
      idList.push(update[i].id);
    }
    dispatch(slice.actions.updateImageOrder({ update, category }));
    await axiosUploadHandler
      .put(`/properties/${propertyId}/images/reorder`, idList)
      .then(() => {
        // handle success
        // dispatch(slice.actions.updateImageOrder({ update, category }));
        toast.success("Képek sorrendje módosítva");
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba a sorrend módosításakor");
        console.error(error);
      })
      .then(() => {
        // always executed
      });
  };

export const updateVideoOrder = (propertyId, update) => async (dispatch) => {
  const idList = [];
  for (let i = 0; i < update.length; i++) {
    if (update[i]) idList.push(update[i].id);
  }
  dispatch(slice.actions.updateVideoOrder({ update }));
  await axiosUploadHandler
    .put(`/properties/${propertyId}/videos/reorder`, idList)
    .then(() => {
      // handle success
      // dispatch(slice.actions.updateImageOrder({ update, category }));
      toast.success("Videók sorrendje módosítva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a sorrend módosításakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const openModal = (type) => (dispatch) => {
  dispatch(slice.actions.openModal(type));
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
