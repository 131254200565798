import { cloneElement } from 'react';
import useAuth from '../../hooks/useAuth';
import PropTypes from 'prop-types';

// const isOwn = (scope) => (scope.split('_').pop() === 'OWN'); /** check the last word of the scope value */
const isOwn = (scope) => (scope.split('_').includes('OWN') && !scope.split('_').includes('CLIENT')); /** check the last word of the scope value */

const isOfficeUpdate = (scope) => (scope.split('_').includes('OFFICE') && scope.split('_').includes('UPDATE')); // TODO: test it

const isUserUpdate = (scope) => (scope.split('_').includes('USER') && scope.split('_').includes('UPDATE')); // TODO: test it

const isClientUpdateOwn = (scope) => (scope.split('_').includes('CLIENT') && scope.split('_').includes('UPDATE') && scope.split('_').includes('OWN')); // TODO: test it

const isOfficeManager = (roles) => (roles.includes('ROLE_OFFICE_MANAGER') && !roles.includes('ROLE_COMPANY_MANAGER')); /* COMPANY_MANAGER has also OFFICE_MANAGER role as well, therefore it should check it. */

const PermissionsGate = ({
  children,
  scopes = [],
  ownerId = null,
  officeId = null,
  employeeList = [],
  statusId = null,
  restrictionProps = null,
}) => {
  const { user } = useAuth();
  // console.log('user', user);
  const { permissions, roles } = user;
  const isMine = (id) => (user?.id === id || false);

  const isMineOffice = (id) => (user?.office?.id === id || false);

  const checkPermissionByStatus = () => {
    // if (statusId === 2 || statusId === 4) {
    //   return true;
    // }
    // if (statusId === 3) {
    //   return false;
    // }
    if (statusId === 4) {
      return false;
    }
    if (isMineOffice(officeId)) {
      // console.log('owenrId', ownerId);
      return isMine(ownerId);
    }
    return true;
  };

  const hasPermission = () => {
    if (user.roles.includes('ROLE_COMPANY_MANAGER')) {
      return true;
    }
    if (isOfficeManager(user.roles) && officeId) {
      if (isMineOffice(officeId)) {
        return true;
      }
      if (employeeList?.length > 1) {
        // console.log(employeeList.some((employeeItem) => employeeItem.employee?.office?.id === user?.office?.id));
        return employeeList.some((employeeItem) => employeeItem.employee?.office?.id === user?.office?.id);
      }

      return false;
    }
    const scopesMap = scopes.map((scope) => { /** NOTE: it returns an array with booleans. */
      if (permissions.includes(scope)) { /* NOTE: check whether user permissions satisfy at least one of the component scopes. */
        if (isClientUpdateOwn(scope)) {
          return checkPermissionByStatus();
        }
        if (isOwn(scope)) { /* NOTE: check, whether it has to check ownerShip */
          if (employeeList?.length > 1) {
            return employeeList.some((employeeItem) => employeeItem.employee?.office?.id === user?.office?.id);
          }
          return isMine(ownerId);
        }
        if (isOfficeUpdate(scope) || isUserUpdate(scope)) { // TODO: test it
          if (isOfficeManager(roles)) {
            return isMineOffice(officeId);
          }
          return true;
        }
        return true;
      }

      return false;
    });

    return scopesMap.some((scope) => scope === true); /** NOTE: return true if scopesMap has at least one true in it, else false */
  };

  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted && restrictionProps) {
    return cloneElement(children, { ...restrictionProps });
  }

  if (!permissionGranted) return <></>;

  return <>{children}</>;
};

PermissionsGate.propTypes = {
  children: PropTypes.any.isRequired,
  scopes: PropTypes.array.isRequired,
  ownerId: PropTypes.number,
  officeId: PropTypes.number,
  statusId: PropTypes.number,
  employeeList: PropTypes.array,
  restrictionProps: PropTypes.object,
};

export default PermissionsGate;
