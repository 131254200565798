import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { addMinutes } from 'date-fns';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DesktopDateTimePicker } from '@material-ui/lab';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import TrashIcon from '../../../icons/Trash';
import {
  deleteEvent,
  createHpEvent,
  updateEvent,
} from '../../../slices/calendar';
import { useDispatch, useSelector } from '../../../store';
import { useLocation } from 'react-router-dom';

const getInitialValues = (event, range, selectedClient) => {
  if (event) {
    return {
      color: event.color || '',
      eventDescription: event.description || '',
      biancoId: event.biancoId || '',
      eventEnd: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      eventStart: event.start ? new Date(event.start) : new Date(),
      eventTitle: event.title || '',
      type: 'hp',
      submit: null
    };
  }

  if (range) {
    return {
      color: '',
      eventDescription: '',
      biancoId: '',
      eventEnd: new Date(range.end),
      eventStart: new Date(range.start),
      eventTitle: `HP ${selectedClient?.name && selectedClient.name}`,
      type: 'hp',
      submit: null
    };
  }

  return {
    color: '',
    eventDescription: '',
    biancoId: '',
    eventEnd: addMinutes(new Date(), 30),
    eventStart: new Date(),
    eventTitle: `HP ${selectedClient?.name && selectedClient.name}`,
    type: 'hp',
    submit: null
  };
};

const HpEventForm = (props) => {
  const { event, onAddComplete, onCancel, onDeleteComplete, onEditComplete, range, setHpDate, selectedProperties, selectedClient, mainCalendarClose } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.ip);

  const handleDelete = async () => {
    try {
      await dispatch(deleteEvent(event.id));

      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(event, range, selectedClient)}
      validationSchema={Yup
        .object()
        .shape({
          // allDay: Yup.bool(),
          eventDescription: Yup.string().max(5000),
          // eventEnd: Yup // TODO: check dates
          //   .date()
          //   .when('eventStart',
          //     (eventStart, schema) => (eventStart && schema.min(eventStart,
          //       'Befejezés dátuma nem lehet korábbi, mint a kezdés dátuma'))),
          // eventStart: Yup.date(),
          eventTitle: Yup
            .string()
            .max(255)
            .required('Esemény megnevezése kötelező')
        })}
      onSubmit={(values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          if (setHpDate) {
            setHpDate(values.eventStart.getTime());
          }
          if (event) {
            const eventData = {
              // allDay: values.allDay,
              description: values.eventDescription,
              biancoId: values.biancoId,
              end: values.eventEnd.getTime(),
              start: values.eventStart.getTime(),
              title: values.eventTitle,
              type: 'hp',
              presentationId: event?.presentation?.id,
              // propertyIds: selectedProperties ? [...selectedProperties?.map((property) => property?.id)] : [...config?.properties?.map((property) => property?.id)],
              // clientId: selectedClient ? selectedClient.id : config?.client?.id
            };
            dispatch(updateEvent(event.id, eventData));
          } else {
            const data = {
              // allDay: values.allDay,
              eventDescription: values.eventDescription,
              biancoId: values.biancoId,
              eventEnd: values.eventEnd.getTime(),
              eventStart: values.eventStart.getTime(),
              eventTitle: values.eventTitle,
              type: 'hp',
              propertyIds: selectedProperties ? [...selectedProperties?.map((property) => property?.id)] : [...config?.properties?.map((property) => property?.id)],
              clientId: selectedClient ? selectedClient.id : config?.client?.id
            };
            dispatch(createHpEvent(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Naptár frissítve!');

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
          onCancel();
          if (mainCalendarClose && location.pathname.split('/').includes('ip')) {
            mainCalendarClose();
          }
        } catch (err) {
          console.error(err);
          toast.error('Naptár frissítése sikertelen!');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event
                ? 'Hp szerkesztése'
                : 'Hp hozzáadása'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              fullWidth
              autoFocus
              error={Boolean(touched.eventTitle && errors.eventTitle)}
              helperText={touched.eventTitle && errors.eventTitle}
              label="Esemény neve"
              name="eventTitle"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.eventTitle}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.eventDescription && errors.eventDescription)}
                fullWidth
                helperText={touched.eventDescription && errors.eventDescription}
                label="Leírás"
                name="eventDescription"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eventDescription}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                disabled={event}
                error={Boolean(touched.biancoId && errors.biancoId)}
                fullWidth
                helperText={touched.biancoId && errors.biancoId}
                label="Bianko azonosító"
                name="biancoId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.biancoId}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Kezdete"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue('eventStart', date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.eventStart}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Vége"
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                ampm={false}
                onChange={(date) => setFieldValue('eventEnd', date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.eventEnd}
              />
            </Box>
            {Boolean(touched.eventEnd && errors.eventEnd) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>
                  {errors.eventEnd}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

HpEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
  setHpDate: PropTypes.func,
  selectedProperties: PropTypes.array,
  selectedClient: PropTypes.any,
  mainCalendarClose: PropTypes.func
};

export default HpEventForm;
