/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  // Crop,
  // PixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Divider,
  IconButton,
  Tooltip,
  Slider,
  Grid,
  Typography,
  Box,
  Button
} from '@material-ui/core';
import { AspectRatio, Replay } from '@material-ui/icons';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
const centerAspectCrop = (
  mediaWidth,
  mediaHeight,
  aspect,
) => centerCrop(
  makeAspectCrop(
    {
      unit: '%',
      width: 100,
    },
    aspect,
    mediaWidth,
    mediaHeight,
  ),
  mediaWidth,
  mediaHeight,
);

const SimpleEditorIndex = ({ base64Src, saveBase64PicSrc, saveImageFile, onModalClose, onModalSave }) => {
  const [base64ImgSrc, setBase64ImgSrc] = useState(base64Src || '');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  // const [aspect, setAspect] = useState(9 / 8);
  const [aspect, setAspect] = useState();
  const [previewImg, setPreviewImg] = useState();

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    }
    setCompletedCrop({
      unit: 'px',
      x: 0,
      y: 0,
      width,
      height
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width
        && completedCrop?.height
        && imgRef?.current
        // && previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        const canvasPreviewCroppedImg = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
        if (canvasPreviewCroppedImg) {
          setPreviewImg(canvasPreviewCroppedImg);
        }
        // setCroppedImg(canvasPreviewCroppedImg.preview);
        // setFile(canvasPreviewCroppedImg); // NOTE: FIXME?: It will crop without click on the crop icon it can be good or bad depend on the user
        // if (!isDefualtValueSet.current) {
        //   setDefaultCroppedImage(canvasPreviewCroppedImg);
        //   isDefualtValueSet.current = true
        // }
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  const handleToggleAspectClick = () => {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      // NOTE: setCrop get percentage value, while setCompletedCrop needs px
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  };

  return (
    <div className="ImgEditor">
      <Grid
        container
        p={3}
      // backgroundColor="background.default"
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className="Crop-Controls">
            <Grid
              container
              spacing={3}
              mb={2}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={6}
                xs={12}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {/* <Tooltip title="Segédvonalak">
                  <IconButton
                    color={ruleOfThirdsState ? 'primary' : 'default'}
                    onClick={() => setRuleOfThirdsState(!ruleOfThirdsState)}
                  >
                    {ruleOfThirdsState ? <GridOnIcon /> : <GridOffIcon />}
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="Képarány zárolás">
                  <IconButton
                    color={aspect ? 'primary' : 'default'}
                    onClick={handleToggleAspectClick}
                  >
                    <AspectRatio />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Vágás">
                  <span>
                    <IconButton
                      color="primary"
                      disabled={!isCropLoading ? scale === 1 && rotate === 0 && isEqual(crop, fullImageCrop) : isCropLoading}
                      onClick={() => {
                        setTimeout(() => { // FIXME: cropClick can be faster then debounceEffect therfore we should wait for it...
                          handleCropClick();
                        }, 500);
                      }}
                    >
                      <ContentCutOutlinedIcon />
                    </IconButton>
                  </span>
                </Tooltip> */}
                {/* <Tooltip title="Forgatás 180°-al">
                  <IconButton
                    color={imgSrc ? 'primary' : 'default'}
                    onClick={() => {
                      if (rotateDefaultValue < 180) {
                        setIsCropLoading(true);
                        setRotate(180);
                        setRotateDefaultValue(180);
                      } else if (rotateDefaultValue >= 180) {
                        setIsCropLoading(true);
                        setRotate(0);
                        setRotateDefaultValue(0);
                      }
                    }}
                  >
                    <CropRotateIcon />
                  </IconButton>
                </Tooltip> */}
                {/* <Tooltip title="Alaphelyzetbe állítás">
                  <span>
                    <IconButton
                      disabled={isEqual(imageEdit, imgSrc) ? scale === 1 && rotate === 0 && isEqual(crop, fullImageCrop) : isEqual(imageEdit, imgSrc)}
                      color="primary"
                      onClick={handleReset}
                    >
                      <Replay />
                    </IconButton>
                  </span>
                </Tooltip> */}
              </Grid>
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={3}
                sm={3}
                xs={12}
              >
                <Typography>Kontraszt:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  min={-50}
                  max={50}
                  step={1}
                  defaultValue={0}
                  track={false}
                  id="scale-input"
                  type="number"
                  value={contrast}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setIsCropLoading(true);
                    setContrast(Number(e.target.value));
                  }}
                />
              </Grid> */}
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={3}
                sm={3}
                xs={12}
              >
                <Typography>Telítettség:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  min={-100}
                  max={200}
                  step={1}
                  defaultValue={0}
                  track={false}
                  id="scale-input"
                  type="number"
                  value={saturate}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setIsCropLoading(true);
                    setSaturate(Number(e.target.value));
                  }}
                />
              </Grid> */}
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <Typography>Fényerő:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  min={-100}
                  max={100}
                  step={1}
                  defaultValue={0}
                  track={false}
                  id="scale-input"
                  type="number"
                  value={brightening}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setIsCropLoading(true);
                    setBrightening(Number(e.target.value));
                  }}
                />
              </Grid> */}
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <Typography>Nagyítás:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  min={0.1}
                  max={2.5}
                  step={0.1}
                  defaultValue={1}
                  track={false}
                  id="scale-input"
                  type="number"
                  value={scale}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setIsCropLoading(true);
                    setScale(Number(e.target.value));
                  }}
                />
              </Grid> */}
              {/* <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
              >
                <Typography>Elforgatás:</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  min={rotateDefaultValue < 90 ? -90 : 90}
                  max={rotateDefaultValue < 90 ? 90 : 270}
                  defaultValue={rotateDefaultValue}
                  step={1}
                  getAriaValueText={valuetext}
                  track={false}
                  marks={rotateDefaultValue < 90 ? marks : marks180}
                  id="rotate-input"
                  type="number"
                  value={rotate}
                  disabled={!imgSrc}
                  onChange={(e) => {
                    setIsCropLoading(true);
                    setRotate(Math.min(360, Math.max(-360, e.target.value)));
                  }}
                />
              </Grid> */}
            </Grid>
          </div>
        </Grid>
        <Divider />
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          {(!base64ImgSrc) ? (
            <Box
              backgroundColor="#fff"
              sx={{
                width: '100%',
                height: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>A kép betöltése sikertelen.</Typography>
            </Box>
          ) : (
            <div
              style={{
                height: '100%',
                width: 'auto',
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => {
                  // setIsCropLoading(true);
                  setCompletedCrop(c);
                }}
                aspect={aspect}
                keepSelection
              // ruleOfThirds={ruleOfThirdsState}
              >
                <img
                  ref={imgRef}
                  // onError={() => setErrorImg(true)}
                  alt="Crop me"
                  src={base64ImgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    maxHeight: 425,
                    // filter: `brightness(${brightening + 100}%) saturate(${saturate + 100}%) contrast(${contrast + 100}%)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
        </Grid>
        <Divider />
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ m: 1 }}
      >
        <Button
          onClick={() => {
            onModalClose();
            // saveBase64PicSrc(previewImg.preview);
            // if (saveImageFile) {
            //   saveImageFile(previewImg);
            // }
          }}
        >
          Mégsem
        </Button>
        <Button
          onClick={() => {
            saveBase64PicSrc(previewImg.preview);
            if (saveImageFile) {
              saveImageFile(previewImg);
            }
            if (onModalSave) {
              onModalSave(previewImg);
            } else {
              onModalClose();
            }
          }}
        >
          Mentés
        </Button>
      </Box>
    </div>
  );
};

SimpleEditorIndex.propTypes = {
  base64Src: PropTypes.string,
  onModalClose: PropTypes.func,
  saveBase64PicSrc: PropTypes.func,
  saveImageFile: PropTypes.func,
  onModalSave: PropTypes.func,
};

export default SimpleEditorIndex;
