import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import HpEventForm from '../dashboard/calendar/HpEventForm';
import { useDispatch, useSelector } from '../../store';
import { closeModal } from '../../slices/calendar';

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }

  return null;
};

const HpModal = ({ setHpDate, selectedProperties, selectedClient, mainCalendarClose }) => {
  const dispatch = useDispatch();
  const { isHpModalOpen, selectedRange } = useSelector((state) => state.calendar);
  const selectedEvent = useSelector(selectedEventSelector);

  const handleModalClose = () => {
    console.log('modalClose');
    dispatch(closeModal());
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isHpModalOpen}
      >
        {isHpModalOpen && (
          <HpEventForm
            mainCalendarClose={mainCalendarClose}
            event={selectedEvent}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
            range={selectedRange}
            setHpDate={setHpDate}
            selectedProperties={selectedProperties}
            selectedClient={selectedClient}
          />
        )}
      </Dialog>
    </>
  );
};

HpModal.propTypes = {
  setHpDate: PropTypes.func,
  selectedProperties: PropTypes.array,
  selectedClient: PropTypes.any,
  mainCalendarClose: PropTypes.func
};

export default HpModal;
