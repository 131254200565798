import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Divider, Drawer, Link } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from './Logo';

import BriefcaseIcon from '../icons/Briefcase';
import CalendarIcon from '../icons/Calendar';
import ChartPieIcon from '../icons/ChartPie';
import ChartSquareBarIcon from '../icons/ChartSquareBar';
import ChatAltIcon from '../icons/ChatAlt';
import ClipboardListIcon from '../icons/ClipboardList';
import MailIcon from '../icons/Mail';
import ShoppingBagIcon from '../icons/ShoppingBag';
import UserIcon from '../icons/User';
import ShoppingCart from '../icons/ShoppingCart';
import NavSection from './NavSection';
import FolderOpen from '../icons/FolderOpen';

const navigationData = [
  {
    title: 'Lido',
    items: [
      {
        title: 'Ingatlanok',
        path: '/dashboard',
      },
      {
        title: 'Ügyfelek',
        children: [
          {
            title: 'Megbízó ügyfél',
            path: '/dashboard/customers'
          },
          {
            title: 'Kereső ügyfél',
            path: '/dashboard/customers/1'
          },
          {
            title: 'Összes ügyfél',
            path: '/dashboard/customers/1/edit'
          }
        ]
      },
      {
        title: 'Dokumentumtár',
        path: '/dashboard/documents',
        icon: <FolderOpen fontSize="small" />,
      },
      {
        title: 'Irodai Prezentáció',
        icon: <FolderOpen fontSize="small" />,
        path: '/dashboard/orders',
      },
      {
        title: 'További',
        children: [
          {
            title: 'Feladatok',
            path: '/dashboard/invoices'
          },
          {
            title: 'Magánhirdetések',
            path: '/dashboard/invoices/1'
          },
          {
            title: 'Tipp modul',
            path: '/dashboard/invoices/1'
          },
          {
            title: 'Híváslista',
            path: '/dashboard/invoices/1'
          },
          {
            title: 'Képzés',
            path: '/dashboard/invoices/1'
          },
          {
            title: 'Generálás',
            path: '/dashboard/projects/browse'
          }, {
            title: 'HR',
            path: '/dashboard/projects/1'
          },
          {
            title: 'Ingatlanok jóváhagyása',
            path: '/dashboard/projects/new'
          },
          {
            title: 'Tipp adás',
            path: '/dashboard/social/profile'
          },
        ]
      },
      {
        title: 'Statisztika',
        path: '/dashboard/kanban',
      },
      {
        title: 'Vállalkozás',
        path: '/dashboard/calendar',
      }
    ]
  },
  {
    title: '',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />
      },
      {
        title: 'Finance',
        path: '/dashboard/finance',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Customers',
        path: '/dashboard/customers',
        icon: <UserIcon fontSize="small" />
      },
      {
        title: 'List',
        path: '/dashboard/customers',
      },
      {
        title: 'Details',
        path: '/dashboard/customers/1',
      },
      {
        title: 'Edit',
        path: '/dashboard/customers/1/edit',
      }
    ]
  },
  {
    title: 'Products',
    items: [
      {
        title: 'List',
        path: '/dashboard/products',
        icon: <ShoppingCart fontSize="small" />
      },
      {
        title: 'Create',
        path: '/dashboard/products/news',
      },
    ]
  },
  {
    title: 'Platforms',
    items: [
      {
        title: 'Projects',
        path: '/dashboard/projects',
        icon: <BriefcaseIcon fontSize="small" />,
        children: [
          {
            title: 'Browse',
            path: '/dashboard/projects/browse'
          },
          {
            title: 'Details',
            path: '/dashboard/projects/1'
          },
          {
            title: 'Create',
            path: '/dashboard/projects/new'
          }
        ]
      },
    ]
  },
  {
    title: 'Apps',
    items: [
      {
        title: 'Kanban',
        path: '/dashboard/kanban',
        icon: <ClipboardListIcon fontSize="small" />
      },
      {
        title: 'Mail',
        path: '/dashboard/mail',
        icon: <MailIcon fontSize="small" />
      },
      {
        title: 'Chat',
        path: '/dashboard/chat',
        icon: <ChatAltIcon fontSize="small" />
      },
      {
        title: 'Calendar',
        path: '/dashboard/calendar',
        icon: <CalendarIcon fontSize="small" />
      }
    ]
  }
];

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: 256
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2
        }}
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Divider />
        <Box sx={{ p: 2 }}>
          {navigationData.map((item) => (
            <NavSection
              key={item.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...item}
            />
          ))}
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3
          }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/browse"
            underline="none"
            variant="body1"
          >
            Browse Components
          </Link>
          <Chip
            color="primary"
            label="NEW"
            size="small"
            sx={{
              maxHeight: 20,
              ml: 1,
              mr: 2
            }}
          />
        </Box>
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/docs"
          underline="none"
          variant="body1"
        >
          Documentation
        </Link>
        <Button
          color="primary"
          component="a"
          href="https://material-ui.com/store/items/devias-kit-pro"
          size="small"
          sx={{ mt: 4 }}
          target="_blank"
          variant="contained"
        >
          Get the kit
        </Button>
      </Box>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
