import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
import { toast } from 'react-hot-toast';

const initialState = {
  blogs: {},
  selectedBlog: null,
  blogContents: [],
  isBlogModalOpen: false,
  blogCategories: [],
  years: [],
  categoryFilter: null,
  yearFilter: null,
  search: null
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    getBlogs(state, action) {
      state.blogs = action.payload;
    },
    setSelectedBlog(state, action) {
      state.selectedBlog = action.payload;
      state.blogContents = action.payload?.sections;
    },
    createBlog(state, action) {
      const blog = action.payload;
      state.blogs.data = [blog, ...state.blogs.data];
    },
    updateBlog(state, action) {
      const blog = action.payload;
      state.blogs.data = state.blogs.data.map((_blog) => {
        if (_blog.id === blog.id) {
          return blog;
        }
        return _blog;
      });
    },
    createBlogContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = [...state.blogContents, blogContent];
    },
    updateBlogContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = state.blogContents.map((_content) => {
        if (_content.id === blogContent.id) {
          return blogContent;
        }
        return _content;
      });
    },
    deleteBlogContent(state, action) {
      const blogContentId = action.payload;
      state.blogContents = state.blogContents.filter((_content) => _content.id !== blogContentId);
    },
    uploadImageContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = state.blogContents.map((_content) => {
        if (_content.id === blogContent.id) {
          return blogContent;
        }
        return _content;
      });
    },
    uploadVideoContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = state.blogContents.map((_content) => {
        if (_content.id === blogContent.id) {
          return blogContent;
        }
        return _content;
      });
    },
    deleteImageContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = state.blogContents.map((_content) => {
        if (_content.id === blogContent.id) {
          return blogContent;
        }
        return _content;
      });
    },
    deleteVideoContent(state, action) {
      const blogContent = action.payload;
      state.blogContents = state.blogContents.map((_content) => {
        if (_content.id === blogContent.id) {
          return blogContent;
        }
        return _content;
      });
    },
    clearBlogContents(state) {
      state.blogContents = [];
    },
    setIsBlogModalOpen(state, action) {
      state.isBlogModalOpen = action.payload;
    },
    getBlogCategories(state, action) {
      state.blogCategories = action.payload;
    },
    setCategoryFilter(state, action) {
      state.categoryFilter = action.payload;
    },
    setYearFilter(state, action) {
      state.yearFilter = action.payload;
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    getYears(state, action) {
      state.years = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getBlogs = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  await axiosConfig.get(
    `blog/articles?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  ).then((response) => {
    dispatch(slice.actions.getBlogs(response.data));
  }).catch((error) => {
    toast.error('Hiba a blogposztok lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  });
};

export const searchBlogs = (pageNumber, pageSize, filters, sort) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  await axiosConfig.post(
    `blog/articles/search?pageNumber=${pageNumber}&pageSize=${pageSize}&sort=created:${sort}`,
    filters
  ).then((response) => {
    // console.log('getBlogs reponse', response);
    dispatch(slice.actions.getBlogs(response.data));
  }).catch((error) => {
    toast.error('Hiba az blogposztok lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  });
};

export const selectBlog = (blogId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_SELECTED_BLOG' }));
  await axiosConfig.get(
    `blog/articles/${blogId}`,
  ).then((response) => {
    dispatch(slice.actions.setSelectedBlog(response.data));
  }).catch((error) => {
    toast.error('Hiba a blogposzt lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_SELECTED_BLOG' }));
  });
};

export const createBlog = (data) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'CREATE_BLOG' }));
  await axiosConfig.post(
    '/blog/articles',
    data
  ).then((response) => {
    dispatch(slice.actions.setSelectedBlog(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('A blog létrhozása sikertelen');
    }
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'CREATE_BLOG' }));
  });
};

export const setIsBlogModalOpen = (isOpen) => async (dispatch) => {
  dispatch(slice.actions.setIsBlogModalOpen(isOpen));
};

export const updateBlog = (blogId, blogData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_BLOG' }));
  await axiosConfig.put(
    `blog/articles/${blogId}`,
    blogData,
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // dispatch(setIsBlogModalOpen(false));
    // dispatch(slice.actions.updateBlog(response.data)); // NOTE: now we do not have to update the blog lsit cuz we are in a different page.
  }).catch((error) => {
    toast.error('Hiba a blogposzt frissítése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_BLOG' }));
  });
};

export const createBlogContent = (blogId, blogContentData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'CREATE_BLOG_CONTENT' }));
  await axiosConfig.post( // TODO: api request
    `blog/articles/${blogId}/sections`,
    blogContentData,
  ).then((response) => {
    dispatch(slice.actions.createBlogContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a blogposzt tartalom létrehozása közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'CREATE_BLOG_CONTENT' }));
  });
};

export const updateBlogContent = (blogContentId, blogContentData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_BLOG_CONTENT' }));
  await axiosConfig.put( // TODO: api request
    `blog/sections/${blogContentId}`,
    blogContentData,
  ).then((response) => {
    dispatch(slice.actions.updateBlogContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a blogposzt tartalom frissítése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_BLOG_CONTENT' }));
  });
};

export const deleteBlogContent = (blogContentId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_BLOG_CONTENT' }));
  await axiosConfig.delete( // TODO: api request
    `blog/sections/${blogContentId}`,
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    dispatch(slice.actions.deleteBlogContent(blogContentId));
  }).catch((error) => {
    toast.error('Hiba a blogposzt tartalom törlése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_BLOG_CONTENT' }));
  });
};

export const uploadImageContent = (blogContentId, formData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPLOAD_IMAGE_CONTENT' }));
  await axiosConfig.post(
    `blog/sections/${blogContentId}/images`,
    formData,
  ).then((response) => {
    dispatch(slice.actions.uploadImageContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a kép feltöltése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPLOAD_IMAGE_CONTENT' }));
  });
};

export const uploadVideoContent = (blogContentId, formData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPLOAD_VIDEO_CONTENT' }));
  await axiosConfig.post(
    `blog/sections/${blogContentId}/videos`,
    formData,
  ).then((response) => {
    dispatch(slice.actions.uploadVideoContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a videó feltöltése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPLOAD_VIDEO_CONTENT' }));
  });
};

export const deleteImageContent = (blogContentId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_IMAGE_CONTENT' }));
  await axiosConfig.delete(
    `blog/sections/${blogContentId}/images`,
  ).then((response) => {
    dispatch(slice.actions.deleteImageContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a kép törlése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_IMAGE_CONTENT' }));
  });
};

export const deleteVideoContent = (blogContentId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_VIDEO_CONTENT' }));
  await axiosConfig.delete(
    `blog/sections/${blogContentId}/videos`,
  ).then((response) => {
    dispatch(slice.actions.deleteVideoContent(response.data));
  }).catch((error) => {
    toast.error('Hiba a videó törlése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_VIDEO_CONTENT' }));
  });
};

export const getBlogCategories = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  await axiosConfig.get(
    'blog/categories',
  ).then((response) => {
    dispatch(slice.actions.getBlogCategories(response.data));
  }).catch((error) => {
    toast.error('Hiba a blog kategóriák lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_BLOGS' }));
  });
};

export const clearSelectedBlog = () => async (dispatch) => {
  dispatch(slice.actions.setSelectedBlog(null));
};

export const clearBlogs = () => async (dispatch) => {
  dispatch(slice.actions.getBlogs(null));
};

export const clearBlogContents = () => async (dispatch) => {
  dispatch(slice.actions.clearBlogContents());
};

export const getYears = () => async (dispatch) => {
  await axiosConfig.get('/blog/articles/years')
    .then((response) => {
      dispatch(slice.actions.getYears(response.data));
    }).catch((error) => {
      toast.error('Az Évek lekérése sikertelen');
      console.error(error);
    });
};

export const setCategoryFilter = (categoryId) => async (dispatch) => {
  dispatch(slice.actions.setCategoryFilter(categoryId));
};

export const setYearFilter = (year) => async (dispatch) => {
  dispatch(slice.actions.setYearFilter(year));
};

export const setSearch = (value) => async (dispatch) => {
  dispatch(slice.actions.setSearch(value));
};

export const uploadCoverImage = (blogId, formData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPLOAD_COVER_IMAGE' }));
  await axiosConfig.post(
    `blog/articles/${blogId}/images`,
    formData,
  ).then((response) => {
    // console.log(response);
    dispatch(slice.actions.setSelectedBlog(response.data));
  }).catch((error) => {
    toast.error('Hiba a kép feltöltése közben!');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'UPLOAD_COVER_IMAGE' }));
  });
};

export const deleteCoverImage = (blogId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_COVER_IMAGE' }));
  await axiosConfig.delete(
    `blog/articles/${blogId}/images`,
  ).catch((error) => {
    toast.error('Hiba a kép törlése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_COVER_IMAGE' }));
  });
};


export const deleteBlog = (blogId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_BLOG' }));
  const response = await axiosConfig.delete(
    `blog/articles/${blogId}`,
  ).catch((error) => {
    toast.error('Hiba a blog törlése közben!');
    console.error(error);
  });
  dispatch(toggleLoading({ type: 'DELETE_BLOG' }));
  if (!response) return null;
  toast.success('Blog sikeresen törölve!');
  return response;
}