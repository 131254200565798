import { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

const PhoneNumberStyle = (props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    label="Telefonszám" // TODO: If secondary phone it should be Masodlaogs Telefonszam
    variant="outlined"
    name="phone"
  />
);

export default forwardRef(PhoneNumberStyle);
