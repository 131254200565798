import React, { useRef, useEffect, useState } from 'react';
import { Fireworks } from 'fireworks-js';
import { Box } from '@material-ui/core';
import toast from 'react-hot-toast';
import { useDispatch } from '../store';
import { useFireworks } from '../slices/training';
import PropTypes from 'prop-types';

const fireworksArrayChecker = (fireworksArray) => {
  const passedClasses = [];
  if (fireworksArray.length > 0) {
    fireworksArray?.forEach((firework) => {
      if (firework?.status === 'passed' && firework?.fireworkSeen === false) {
        passedClasses?.push(firework);
      }
    });
    if (passedClasses.length > 0) {
      console.log(passedClasses, 'passedClasses');
      const text = passedClasses.map((passedClass) => passedClass?.course?.name).join(', ');
      const ids = [];
      passedClasses?.forEach((passedClass) => {
        ids.push(passedClass?.userTrainingId);
      });
      return { text, ids };
      // return passedClasses;
    }
  }
  return false;
};

const Firework = ({ trainings }) => {
  const [canShoot, setCanShoot] = useState(!!fireworksArrayChecker(trainings));
  const fcontainer = useRef(null);
  const fireworkDuration = 5000;
  const dispatch = useDispatch();
  // const [fireworks, setFireworks] = useState(null);
  let fireworks;
  useEffect(() => {
    if (fcontainer.current && canShoot) {
      // setFireworks(new Fireworks(fcontainer.current, { /* options */ }));
      fireworks = new Fireworks(fcontainer.current, { /* options */ });
      toast.success('', {
        icon: `👏${fireworksArrayChecker(trainings)?.text} sikeres👏`,
        duration: fireworkDuration,
      });
      fireworksArrayChecker(trainings)?.ids.forEach((passedClass) => {
        dispatch(useFireworks(passedClass));
      });
      // if (fcontainer.current && testFinished && passed) {
      fireworks.setOptions({
        delay: { min: 5, max: 25 },
        traceSpeed: 8,
        rocketsPoint: 0,
        trace: 1,
        particles: 90,
        intensity: 30,
        decay: 0,
        // friction: 0.8,
        // explosion: 100,
        speed: 0.5,
        acceleration: 1.02,
        // size: 10
      });
      fireworks.start();
      // fireworks.stop()
      // fireworks.clear()
      // if (passed) {
    }
  }, []);

  useEffect(() => {
    if (canShoot) {
      setTimeout(() => {
        setCanShoot(false);
        fireworks?.stop();
      }, fireworkDuration);
    }
  }, []);

  return (
    <Box
      ref={fcontainer}
      display={canShoot ? 'block' : 'none'}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '95%',
        height: '95%',
        zIndex: 1000
        // backgroundColor: 'black'
      }}
    />
  );
};
Firework.propTypes = {
  trainings: PropTypes.array,
};

export default Firework;
