import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Link as MaterialLink,
  Grid,
  Tooltip,
} from '@material-ui/core';
import CallEvent from './steps/CallEvent';
import CallResultPickedUp from './steps/CallResultPickedUp';
import CallResultBusy from './steps/CallResultBusy';
import CallTask from './steps/CallTask';
import FalseCallTask from './steps/FalseCallTask';
import ConfirmModal from '../../../modals/ConfirmModal';
import createResourceId from '../../../../utils/createResourceId';
// eslint-disable-next-line no-unused-vars
import { saveCall, saveCallAdd, saveCallState, setPhone, setSelectedAddId, } from '../../../../slices/call';
import { useDispatch } from 'react-redux';
import { openModal, switchModal } from '../../../../slices/modals';
import getInitials from '../../../../utils/getInitials';
import parsePhoneNumber from 'libphonenumber-js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import useAuth from '../../../../hooks/useAuth';
import { useSelector } from '../../../../store';
import { correctPhoneValue, isValidPhone } from '../../../../utils/helperFunctions';
import { createEventAndCall } from '../../../../slices/calendar';

const CallWizard = (props) => {
  const { client, handleModalClose } = props;
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { selectedAddId, phone } = useSelector((state) => state.call);
  const [pickedUp, setPickedUp] = useState(null);
  const [successCall, setSuccessCall] = useState(false);
  // const { user } = useAuth();
  /* eslint-disable-next-line */
  const [config, setConfig] = useState({
    id: '',
    clientId: '',
    callEvent: {
      eventName: ''
    },
    callResult: {
      resultName: '',
      meeting: false,
      appointment: false
    },
    callTask: {
      description: '',
      appointment: '',
    }
  });
  const [tempId, setTempId] = useState(0);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const steps = [
    'Hívás Eseménye',
    'Hívás Eredménye',
    'Feladat'
  ];

  const handleOpenisConfrimModal = () => {
    dispatch(openModal());
  };

  const newCallProcess = config;

  const isStepFailed = (step) => step === 10;

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setPhone(null));
      dispatch(setSelectedAddId(null));
    };
  }, []);

  const handleComplete = (step, values, eventData) => {
    newCallProcess[step] = values;
    if (values.callResult) {
      newCallProcess.callResult = values.callResult;
    }

    // const callProc = {
    //   callResult: newCallProcess.callResult.resultName,
    //   description: newCallProcess.callTask.description,
    //   clientId: newCallProcess.clientId,
    //   userId: user.id
    // };

    // if (selectedAddId !== null && phone !== null) {
    //   callProc.phone = phone;
    //   dispatch(saveCallAdd(selectedAddId, callProc));
    // } else {
    //   dispatch(saveCallState(client.id, callProc));
    // }
    let sendablePhone = phone || null;

    if (phone) {
      // eslint-disable-next-line no-unused-expressions
      isValidPhone(phone) && (
        sendablePhone = correctPhoneValue(phone)
      );
    }

    const dataResult = {
      phoneNumber: client?.defaultPhone || sendablePhone.replace(/ /g, ''),
      description: newCallProcess.callTask.description,
      clientId: newCallProcess?.clientId || null,
      realmonitorId: selectedAddId || null,
      // date: null,
      result: newCallProcess.callResult.resultName,
    };
    // if (selectedAddId !== null && phone !== null) { // TODO: it'll have one post endpoint for both
    //   dispatch(saveCallAdd(selectedAddId, dataResult));
    // } else {
    //   dispatch(saveCallState(client?.id, dataResult));
    // }
    if (eventData) {
      /* NOTE: backend needs the eventId when create call, so this is a nested request first creates the event, then with the eventId (which comes from the response) call the createCall endpoint */
      dispatch(createEventAndCall(eventData, dataResult));
    } else {
      dispatch(saveCall(dataResult));
    }

    dispatch(switchModal('clear'));
    dispatch(setPhone(null));
    dispatch(setSelectedAddId(null));
    handleModalClose();
  };

  const noPickedUpDataCallResult = {
    resultName: 3,
    meeting: false,
    appointment: false
  }

  const busyDataCallResult = {
    resultName: 4,
    meeting: false,
    appointment: false
  }

  const handleNext = (step, values) => {
    newCallProcess[step] = values;
    if (!tempId) {
      const resourceId = createResourceId();
      setTempId(resourceId);
      newCallProcess.id = resourceId;
      newCallProcess.clientId = client?.id;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getAvatarStatus = (clientName, clientStatus) => {
    const statusMap = [
      {
        color: 'success.main',
        text: 'saját ügyfél'
      },
      {
        color: 'primary.main',
        text: 'irodai ügyfél'
      },
      {
        color: 'text.primary',
        text: 'inaktív'
      },
      {
        color: 'text.secondary',
        text: 'archivált'
      },
    ];

    const { text, color } = statusMap[clientStatus - 1] || statusMap[3];
    return (
      <>
        <Tooltip
          title={text || 'Nincs megadva'}
        >
          <Avatar
            alt="Author"
            sx={{ backgroundColor: color }}
          >
            {getInitials(clientName || 'Nincs megadva')}
          </Avatar>
        </Tooltip>
      </>
    );
  };

  return (
    <div>
      <Box
        sx={{
          m: 4
        }}
      >
        <Grid
          container
          padding={1}
          sx={{
            alignItems: 'center',
            display: 'flex',
            borderRadius: 1,
            backgroundColor: 'background.default',
            mb: 4
          }}
        >
          <Grid
            item
            md={4}
            sm={4}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent={smDown ? 'center' : 'flex-start'}
            px={2}
          >
            {client?.name && getAvatarStatus(client?.name, client?.state)}
            <Typography
              color="textPrimary"
              variant="h6"
              ml={2}
            >
              {client?.name}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={12}
            pl={smDown ? 3 : 0}
            display="flex"
            justifyContent="center"
          >
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {client?.defaultPhone && (
                parsePhoneNumber(client?.defaultPhone).formatInternational()
              )}
            </Typography>
            {console.log({ phone })}
            {phone && (
              // eslint-disable-next-line no-mixed-operators
              isValidPhone(phone) && (
                correctPhoneValue(phone)
              )
            )}
          </Grid>
          <Grid
            item
            md={4}
            sm={4}
            xs={12}
            pr={smDown ? 0 : 2}
            pl={smDown ? 6 : 0}
            display="flex"
            justifyContent={smDown ? 'center' : 'flex-end'}
          >
            <MaterialLink
              href={client?.email && `mailto:${client?.email}`}
            >
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {client?.email}
              </Typography>
            </MaterialLink>
          </Grid>
        </Grid>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    textAlign="center"
                    variant="caption"
                    color="error"
                  >
                    Hibás formátum
                  </Typography>
                </Box>
              );

              labelProps.error = true;
            }

            return (
              <Step
                key={label}
                // onClick={() => setActiveStep(index)}
                sx={{ cursor: 'pointer' }}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <>
        {activeStep === 0 && (
          <CallEvent
            onNext={handleNext}
            setPickedUp={setPickedUp}
            handleOpenisConfrimModal={handleOpenisConfrimModal}
          />
        )}
        {activeStep === 1 && (
          <>
            {pickedUp === 'pickedUp' && (
              <CallResultPickedUp
                setSuccessCall={setSuccessCall}
                onNext={handleNext}
                onBack={handleBack}
                handleOpenisConfrimModal={handleOpenisConfrimModal}
              />
            )}
            {pickedUp === 'noPickedUp' && (
              <FalseCallTask
                newCallProcess={{ ...newCallProcess, callResult: noPickedUpDataCallResult, }}
                onComplete={handleComplete}
                onBack={handleBack}
                handleOpenisConfrimModal={handleOpenisConfrimModal}
              />
            )}
            {pickedUp === 'busy' && (
              <FalseCallTask
                newCallProcess={{ ...newCallProcess, callResult: busyDataCallResult, }}
                onComplete={handleComplete}
                onBack={handleBack}
                handleOpenisConfrimModal={handleOpenisConfrimModal}
              />
              // <CallResultBusy
              //   client={client}
              //   handleModalClose={handleModalClose}
              //   onBack={handleBack}
              //   handleOpenisConfrimModal={handleOpenisConfrimModal}
              //   onComplete={handleComplete}
              //   newCallProcess={newCallProcess}
              // />
            )}
          </>
        )}
        {activeStep === 2 && (
          successCall ? (
            <CallTask
              client={client}
              newCallProcess={newCallProcess}
              onComplete={handleComplete}
              onBack={handleBack}
              handleOpenisConfrimModal={handleOpenisConfrimModal}
              handleFinish
            />
          )
            : (
              <FalseCallTask
                newCallProcess={newCallProcess}
                onComplete={handleComplete}
                onBack={handleBack}
                handleOpenisConfrimModal={handleOpenisConfrimModal}
              />
            )
        )}
      </>
      <ConfirmModal
        header="Biztos, hogy megszakítod a hívást?"
        content=""
        handleConfirm={handleModalClose}
      />
    </div>
  );
};

CallWizard.propTypes = {
  client: PropTypes.object,
  handleModalClose: PropTypes.func,
};

export default CallWizard;
