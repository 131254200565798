import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';

const initialState = {
  employees: { data: [] },
  isModalOpen: false,
  selectedEmployeeId: null,
  selectedEmployee: null,
  loggedInEmployee: {},
  roles: [],
  statistics: null,
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    getEmployees(state, action) {
      state.employees = action.payload;
    },
    getRoles(state, action) {
      state.roles = action.payload;
    },
    getEmployeeById(state, action) {
      state.selectedEmployee = action.payload;
    },
    loginEmployee(state, action) {
      state.loggedInEmployee = action.payload;
    },
    createEmployee(state, action) {
      state.employees.data.push(action.payload);
    },
    selectEmployee(state, action) {
      const { employeeId } = action.payload;
      state.selectedEmployeeId = employeeId;
      state.isModalOpen = true;
    },
    updateEmployee(state, action) {
      const employee = action.payload;
      state.employees.data = state.employees.data.map((_employee) => {
        if (_employee.id === employee.id) {
          return employee;
        }

        return _employee;
      });
    },
    updateEmployeeImage(state, action) {
      const image = action.payload[0];
      const id = action.payload[1];
      state.employees.data = state.employees.data.map(((_employee) => {
        if (_employee.id === id) {
          _employee.images[0] = image;
          return _employee;
        }
        return _employee;
      }));
    },
    setProjectId(state, action) {
      state.selectedProjectId = action.payload;
    },
    deleteEmployee(state, action) {
      state.employees.data = state.employees.data.filter((employee) => employee.id !== action.payload);
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEmployeeId = null;
    },
    statisticsSearch(state, action) {
      state.statistics = action.payload;
    }
  }
});

export const { reducer } = slice;

// GET EMPLOYEES

export const getEmployees = (page, filters, query, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
  const office = filters?.office; // TODO: do sg when we don't get filters or add filters always
  const officeIds = filters?.officeIds;
  await axiosConfig.get(
    `/users?pageNumber=${page || 0}&filter=${query || ''}&officeId=${office || ''}&pageSize=${pageSize || 24}&officeIds=${officeIds || ''}`
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getEmployees(response.data));
  }).catch((error) => {
    // handle error
    toast.error('A munkatársak betöltése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
    // always executed
  });
};

export const getAllEmployees = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
  await axiosConfig.get(
    `/users?pageSize=${100}`
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getEmployees(response.data));
  }).catch((error) => {
    // handle error
    toast.error('A munkatársak betöltése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
    // always executed
  });
};

// CREATE NEW EMPLOYEE

export const createEmployee = (data, canChange) => async (dispatch) => {
  await axiosConfig.post(
    '/users',
    data
  ).then((response) => {
    // handle success
    dispatch(slice.actions.createEmployee(response.data));
    toast.success('Új munkatárs sikeresen felvéve!');
    if (canChange) {
      axiosConfig.post(
        `users/${response.data.id}/images`,
        data.image
      ).then((response2) => {
        dispatch(slice.actions.updateEmployeeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen feltöltve');
      }).catch((error) => {
        toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
      });
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba a munkatárs felvételekor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getRoles = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_ROLES' }));
  await axiosConfig.get(
    '/roles'
  ).then((response) => {
    dispatch(slice.actions.getRoles(response.data));
  }).catch((error) => {
    toast.error('Hiba a szerepkörök lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_ROLES' }));
  });
};

// GET EMPLOYEES BY ID
export const getEmployeeById = (employeeId, isLoggedIn) => async (dispatch) => {
  await axiosConfig.get(
    `/users/${employeeId}`
  ).then((response) => {
    // handle success
    if (isLoggedIn) {
      dispatch(slice.actions.loginEmployee(response.data));
    }
    dispatch(slice.actions.getEmployeeById(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a munkatárs adatainak lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const clearEmployeeSlice = () => async (dispatch) => {
  dispatch(slice.actions.getEmployeeById(null));
};

// GET THE DETAILS OF A SELECTED EMPLOYEE

export const selectEmployee = (employeeId, type) => async (dispatch) => {
  await axiosConfig.get(
    `/users/${employeeId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.selectEmployee({ employeeId, type }));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a munkatárs adatainak lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// UPDATE SELECTED EMPLOYEE

export const updateEmployee = (employeeId, update, canChange, imgId) => async (dispatch) => {
  await axiosConfig.put(
    `/users/${employeeId}`,
    update
  ).then((response) => {
    // handle success
    dispatch(slice.actions.updateEmployee(response.data));
    if (update.image && !imgId && canChange) {
      axiosConfig.post(
        `users/${response.data.id}/images`,
        update.image
      ).then((response2) => {
        dispatch(slice.actions.updateEmployeeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen feltöltve');
      }).catch((error) => {
        toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
      });
    } else if (update.image && imgId && canChange) {
      axiosConfig.put(
        `users/${response.data.id}/images/${imgId}`,
        update.image
      ).then((response2) => {
        dispatch(slice.actions.updateEmployeeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen módosítva');
      }).catch((error) => {
        // toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
      });
    }
    toast.success('Munkatárs adatai sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// DELETE SELECTED EMPLOYEE

export const deleteEmployee = (employeeId) => async (dispatch) => {
  await axiosConfig.delete(
    `/users/${employeeId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.deleteEmployee(employeeId));
    toast.success('Munkatárs adatai sikeresen törölve!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a törlés végrehajtásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};
export const deleteEmployeeImage = (employeeId, imageId) => async () => {
  await axiosConfig.delete(
    `/users/${employeeId}/images/${imageId}`
  ).then(() => {
  }).catch((error) => {
    // handle error
    toast.error('Hiba a törlés végrehajtásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const statisticsSearch = (filters, controllerSignal) => async (dispatch) => {
  const { propertyTypeIds = null, officeId = null, userId = null, approvedFrom = null, approvedTo = null } = filters;
  dispatch(toggleLoading({ type: 'GET_STATISTICS' }));
  await axiosConfig.post(
    '/properties/statistics/users',
    { attributeOptionIds: propertyTypeIds, officeId, userId, approvedFrom, approvedTo },
    { signal: controllerSignal }
  ).then((response) => {
    dispatch(slice.actions.statisticsSearch(response.data));
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_STATISTICS' }));
  });
};

export const clearStatistics = () => (dispatch) => {
  dispatch(slice.actions.statisticsSearch(null));
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const clearEmployees = () => (dispatch) => {
  dispatch(slice.actions.getEmployees({ data: [] }));
};

// __fakeApi__/employeeApi

// export const getEmployees = (filters) => async (dispatch) => {
//   const data = await employeeApi.getEmployees(filters);

//   dispatch(slice.actions.getEmployees(data, filters));
// };

// export const createEmployee = (createData) => async (dispatch) => {
//   const data = await employeeApi.createEmployee(createData);

//   dispatch(slice.actions.createEmployee(data));
// };

// export const selectEmployee = (employeeId, type) => async (dispatch) => {
//   dispatch(slice.actions.selectEmployee({ employeeId, type }));
// };

// export const updateEmployee = (employeeId, update) => async (dispatch) => {
//   const data = await employeeApi.updateEmployee({
//     employeeId,
//     update
//   });

//   dispatch(slice.actions.updateEmployee(data));
// };

// export const deleteEmployee = (employeeId) => async (dispatch) => {
//   await employeeApi.deleteEmployee(employeeId);

//   dispatch(slice.actions.deleteEmployee(employeeId));
// };

export default slice;
