import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import CalendarMeetingEventForm from '../dashboard/calendar/CalendarMeetingEventForm';
import { useDispatch, useSelector } from '../../store';
import { closeModal } from '../../slices/calendar';

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }

  return null;
};

const CalendarMeetingEventModal = (props) => {
  const { clientName, onProcessModalComplete } = props;
  const dispatch = useDispatch();
  const { isCalendarMeetingEventModalOpen, selectedRange } = useSelector((state) => state.calendar);
  const selectedEvent = useSelector(selectedEventSelector);

  const handleModalClose = () => {
    dispatch(closeModal());
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isCalendarMeetingEventModalOpen}
      >
        {isCalendarMeetingEventModalOpen && (
          <CalendarMeetingEventForm
            clientName={clientName}
            event={selectedEvent}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
            range={selectedRange}
            onProcessModalComplete={onProcessModalComplete}
          />
        )}
      </Dialog>
    </>
  );
};

CalendarMeetingEventModal.propTypes = {
  clientName: PropTypes.any,
  onProcessModalComplete: PropTypes.func,
};

export default CalendarMeetingEventModal;
