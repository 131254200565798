import { Box } from '@material-ui/core';
import React, { useRef, useEffect } from 'react';
import { useSelector } from '../store';

const ScrollHere = () => {
  const { scrollToTop } = useSelector((state) => state.modals);
  const topRef = useRef(null);
  const forceScroll = () => {
    if (topRef !== null) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // This component scrolls where it is imported when in the modals slice the scrollTotTop() function is called.

  useEffect(() => {
    forceScroll();
  }, [scrollToTop]);

  return (
    <Box
      ref={topRef}
    />
  );
};

export default ScrollHere;
