// eslint-disable-next-line no-unused-vars
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, Divider, Link as MaterialLink, Tooltip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ExpandeMoreIcon from '@material-ui/icons/ExpandMore';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { copyToClipboard, makeClickableLink } from '../../../utils/helperFunctions';
import toast from 'react-hot-toast';
// import { isValidPhoneNumber } from 'react-phone-number-input';
// import { useDispatch } from '../../../store';
// import { getClients, selectClient } from '../../../slices/client';

export const callResultSwitch = (result) => {
  switch (result) {
    case 'STILL_THINKING':
      return 'Még gondolkodik';
    case 'OTHER':
      return 'Egyéb';
    case 'CALLBACK':
      return 'Ügyfél visszahívást kér';
    case 'WRONG_NUMBER':
      return ' Hibás telefonszám';
    case 'AGENT':
      return 'Ingatlanos (konkurencia)';
    case 'SUCCESS':
      return 'Találkozó megszervezése';
    case 'NO_ANSWER':
      return "Nem vette fel";
    case 'REFUSED':
      return 'Foglalt volt';
    default:
      return 'Nincs megadva';
  }
};

const CallCard = ({ call }) => {
  const { date, phoneNumber, result, description, user } = call;
  const { name } = call.client || {};
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getClients());
  // }, [getClients]);

  // TODO it is not the id of the client, but the id of the call, therefore
  // it can not open the modal
  // const handleClickOpen = () => {
  //   dispatch(selectClient(id));
  // };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandeMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid
            container
            p={1}
          >
            <Grid
              item
              lg={3}
              md={2}
              sm={3}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {format(date, 'yyyy.MM.dd HH:mm')}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={12}
            >
              {name}
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={3}
              xs={6}
            >
              {phoneNumber && phoneNumber.length < 15 && (
                <>
                  {phoneNumber.includes('*') || !isValidPhoneNumber(phoneNumber) ? (
                    phoneNumber
                  ) : (
                    // <MaterialLink
                    //   // href={`tel:${phoneNumber}`}
                    //   color="inherit"
                    //   onClick={(event) => {
                    //     // handleClickOpen();
                    //     event.stopPropagation();
                    //   }}
                    // >
                    <Tooltip title="Vágólapra másolás">
                      <Box
                        onClick={(event) => {
                          copyToClipboard(phoneNumber);
                          toast('Vágólapra másolva'); // TODO: toast doesn't work in contentSearch bc it is behinde the modal
                          event.stopPropagation();
                        }}
                      >
                        {parsePhoneNumber(phoneNumber).formatInternational()}
                      </Box>
                    </Tooltip>
                    // </MaterialLink>
                  )}
                </>
              )}
            </Grid>
            <Grid
              item
              lg={3}
              md={2}
              sm={2}
              xs={12}
            >
              {/* <Box>
                Munkatárs
              </Box> */}
              {user.name}
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={4}
              xs={12}
              textAlign="right"
            >
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {callResultSwitch(result)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Box>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Eredmény:
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {callResultSwitch(result)}
            </Typography>
          </Box>
          {description && (
            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle2"
                sx={{
                  mt: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2
                }}
              >
                Megjegyzés:
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {/* {description} */}
                {makeClickableLink(description) || 'nincs megjegyzés'}
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

CallCard.propTypes = {
  call: PropTypes.object.isRequired,
};

export default CallCard;
