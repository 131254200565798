import { useState, useEffect } from "react";
import { Avatar, Box, Card, CardHeader, Divider, Grid, Stack, TextField, Tooltip, Typography } from "@material-ui/core"
import { Button } from "@mui/material"
import getInitials from "../../utils/getInitials"
import { format } from "date-fns"
import huLocale from "date-fns/locale/hu";
import { useDispatch, useSelector } from "../../store";
import { createMemo, getMemos } from "../../slices/client";

export const ClientMessage = ({ clientId }) => {
  const dispatch = useDispatch();
  const { memos } = useSelector((state) => state.client);
  const [message, setMessage] = useState('');
  const memosData = memos[clientId];

  useEffect(() => {
    dispatch(getMemos(clientId));
  }, [clientId]);

  const createMemoSubmit = () => {
    dispatch(createMemo(clientId, message));
    setMessage('');
  }

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Üzenetek" />
      <Divider />
      <Box sx={{ mt: 2, p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
          <Box
            // maxHeight="350px"
            display="flex"
            flexDirection="column"
            sx={{ mt: 0, p: 4 }}
          >
            {memosData?.map((message) => (
              <Box display="flex" py={1}>
                <Tooltip title={message?.user?.name}>
                  <Avatar sx={{ width: 34, height: 34 }}>
                    {getInitials(
                      message?.user?.name || "Nincs megadva"
                    )}
                  </Avatar>
                </Tooltip>
                <Typography
                  sx={{
                    border: "1px solid #962F32",
                    borderRadius: "18px",
                    width: "fit-content",
                    px: 1.5,
                    py: 1,
                    ml: 1,
                  }}
                >
                  <Box>
                    {format(
                      message.created,
                      "yyyy.MM.dd HH:mm",
                      { locale: huLocale }
                    )}
                  </Box>
                  {message?.message}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Box>
      <form onSubmit={createMemoSubmit}>
        <Stack direction="row" spacing={2} p={1} justifyContent="space-between" sx={{ width: "100%", borderRadius: 18 }}>
          <TextField
            fullWidth
            label="Üzenet"
            name="message"
            size='small'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
          />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size='small'
            disabled={message.length < 1}
            sx={{ minWidth: 100 }}
            onClick={createMemoSubmit}
          >
            Küldés
          </Button>
        </Stack>
      </form>
    </Card>
  );
};
