import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Stack, Button } from '@material-ui/core';
import { useLocation, NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronUpIcon from '../icons/ChevronUp';
import ChevronDown from '../icons/ChevronDown';

const DashboardNavItem = ({ navigationData }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { title, children, disabled } = navigationData;
  const { pathname } = location;
  const anchorRef = React.useRef(null);
  const splittedPathName = pathname.split('/');

  const exactMatch = () => {
    if (navigationData.path && navigationData.path === `/${splittedPathName[1]}`) {
      return 'navigation.active';
    }
    if (!navigationData.path) {
      return children.map((item) => item.path).includes(pathname) ? 'navigation.active' : 'navigation.text';
    }
    return 'navigation.text';
  };

  const handleToggle = () => {
    if (!navigationData.path) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <div>
        <Button
          endIcon={!navigationData.path && !open ? <ChevronDown fontSize="small" />
            : !navigationData.path && <ChevronUpIcon fontSize="small" />}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          component={navigationData.path && RouterLink}
          to={RouterLink && navigationData.path}
          sx={{
            color: exactMatch,
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            textAlign: 'left',
            pr: '8px',
            py: '12px',
            textTransform: 'none',
            width: '100%',
          }}
          disabled={disabled}
        >
          {title}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal

        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              sx={{
                p: 1,
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'center top',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    sx={{ maxHeight: '80vh', overflow: 'auto', px: 1 }}
                    disabledItemsFocusable
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onClick={handleClose}
                  >
                    {children.map((item) => (
                      <MenuItem
                        key={item.title}
                        disabled={item.disabled}
                        component={RouterLink}
                        to={item.path}
                        sx={{ borderRadius: 1 }}
                      >
                        <Button
                          disableTouchRipple
                          startIcon={item.icon}
                          sx={{
                            color: item.path === pathname ? 'navigation.active' : 'navigation.text',
                            fontWeight: 'fontWeightMedium',
                            justifyContent: 'flex-start',
                            pr: '8px',
                            py: '12px',
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            backgroundColor: 'transparent',
                            '&:hover': { backgroundColor: 'transparent' }
                          }}
                          aria-label="Delete"
                        >
                          {item.title}
                        </Button>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

DashboardNavItem.propTypes = {
  navigationData: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
};

export default DashboardNavItem;
