import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, ButtonBase, Card, CardContent, Grid, Typography } from '@material-ui/core';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import PhoneIcon from '../../../../../icons/Phone';

const CallEvent = (props) => {
  const { onNext, setPickedUp, handleOpenisConfrimModal } = props;

  return (
    <Formik
      initialValues={
        {
          eventName: ''
        }
      }
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          eventName: values.eventName
        };
        try {
          setStatus({ success: true });
          setSubmitting(false);

          if (onNext) {
            onNext('callEvent', data);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid
                container
                spacing={3}
                display="flex"
                p={4}
              >
                <Grid
                  item
                  pt={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setPickedUp('pickedUp');
                      setFieldValue('eventName', 'Felvette');
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid
                            item
                            textAlign="center"
                          >
                            <PhoneIcon
                              fontSize="medium"
                              sx={{ color: 'green' }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Felvette
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setPickedUp('noPickedUp');
                      setFieldValue('eventName', 'NEMVETTEFEL');
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid
                            item
                            textAlign="center"
                          >
                            <PhoneMissedIcon
                              fontSize="medium"
                              color="error"
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Nem vette fel
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  pt={2}
                  md={4}
                  sm={4}
                  xs={12}
                >
                  <ButtonBase
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 1
                    }}
                    onClick={() => {
                      setPickedUp('busy');
                      setFieldValue('eventName', 'ELUTASITOTT');
                    }}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <Card
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          flexDirection="column"
                        >
                          <Grid item>
                            <PhoneDisabledIcon
                              fontSize="medium"
                              sx={{ color: '#ff9800' }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h6"
                            >
                              Foglalt volt
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pl: 3,
              mb: 3,
              mt: 3
            }}
          >
            <Button
              onClick={handleOpenisConfrimModal}
              color="inherit"
              sx={{ mr: 1 }}
            >
              Megszakítás
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CallEvent.propTypes = {
  onNext: PropTypes.func,
  setPickedUp: PropTypes.func,
  handleOpenisConfrimModal: PropTypes.func
};

export default CallEvent;
