import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useRef } from 'react';
import useAuth from '../hooks/useAuth';
import { differenceInSeconds } from 'date-fns';

const SessionTimeout = () => {
  const { logout, isAuthenticated } = useAuth();
  const timeInterval = useRef();

  const handleTimeOut = () => {
    if (isAuthenticated) {
      logout();
    }
  };

  const getLeftSeconds = (exp) => (differenceInSeconds(new Date(exp), new Date()));

  const timeChecker = () => {
    timeInterval.current = setInterval(() => {
      const token = Cookies.get('token');
      const { exp } = jwtDecode(token);
      const convertedExp = exp * 1000; // FIXME: backend sends the data in a wrong format
      const leftSeconds = getLeftSeconds(convertedExp);
      if (leftSeconds <= 2) {
        handleTimeOut();
        clearInterval(timeInterval.current);
      }
    }, 60000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      timeChecker();
    } else {
      clearInterval(timeInterval.current);
    }
    return () => clearInterval(timeInterval.current);
  }, [isAuthenticated]);

  return (
    <></>
  );
};

export default SessionTimeout;
