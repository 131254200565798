import React from 'react';
import { Dialog } from '@material-ui/core';
import EmployeeForm from '../dashboard/account/EmployeeForm';
import { useDispatch, useSelector } from '../../store';
import { closeModal } from '../../slices/employee';

// const selectedEmployeeSelector = (state) => {
//   const { employees, selectedEmployeeId } = state.employee;
//   console.log('sliceemp', employees);
//   if (selectedEmployeeId) {
//     return employees.find((_employee) => _employee.id === selectedEmployeeId);
//   }

//   return null;
// };

const EmployeeModal = () => {
  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => state.employee);
  const { selectedEmployee } = useSelector((state) => state.employee);

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <Dialog
        fullWidth
        onClose={handleModalClose}
        open={isModalOpen}
        maxWidth="md"
      >
        {isModalOpen && (
          <EmployeeForm
            employee={selectedEmployee}
            onAddComplete={handleModalClose}
            onCancel={handleModalClose}
            onDeleteComplete={handleModalClose}
            onEditComplete={handleModalClose}
          />
        )}
      </Dialog>
    </>
  );
};

export default EmployeeModal;
