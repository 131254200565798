import React, { useEffect, Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import DesktopDatePicker from "@material-ui/lab/DesktopDatePicker";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberStyle from "../../phoneInput/PhoneNumberStyle";
import CustomCountrySelect from "../../phoneInput/CustomCountrySelect";
import { useNavigate } from "react-router-dom";
import {
  checkEmail,
  checkPhone,
  createClient,
  resetInitialValues,
  updateClient,
} from "../../../slices/client";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { LoadingButton } from "@material-ui/lab";
import { selectLoading } from "../../../slices/loading";

const selectClientTypeOption = [
  {
    label: "Természetes személy",
    value: 1,
  },
  {
    label: "Jogi Személy",
    value: 2,
  },
  {
    label: "Meghatalmazott",
    value: 3,
  },
];

const selectLegalTypeOption = [
  {
    label: "Kereső",
    value: 1,
  },
  {
    label: "Megbízó",
    value: 2,
  },
  {
    label: "Mindkettő",
    value: 3,
  },
];

const selectIdTypeOptions = [
  {
    label: "Személyi Igazolvány",
    value: 1,
  },
  {
    label: "Jogosítvány",
    value: 2,
  },
  {
    label: "Útlevél",
    value: 3,
  },
];

const selectriskLevelOptions = [
  {
    label: "Átlagos",
    value: 1,
  },
  {
    label: "Közép",
    value: 2,
  },
  {
    label: "Magas",
    value: 3,
  },
];

const selectWeeklyStatOptions = [
  {
    label: "Igen kér",
    value: 1,
  },
  {
    label: "Nem, nem kér vagy nincs e-mail címe",
    value: 2,
  },
];
const unsubscribedOptions = [
  {
    label: "Hírlevél letiltva",
    value: true,
  },
  {
    label: "Hírlevél szabadon küldhető",
    value: false,
  },
];

const getClientTypeDefaultValue = (filter) => {
  if (filter === "buy") {
    return 1;
  }
  if (filter === "sell") {
    return 2;
  }
  return "";
};

const getInitialValues = (customer, filter, initialValues) => {
  if (customer) {
    const {
      id,
      nameTitle,
      address,
      birthDate,
      birthPlace,
      contactPerson,
      state,
      description,
      defaultEmail,
      emails,
      idCardNumber,
      idCardType,
      motherName,
      name,
      defaultPhone,
      phones,
      riskRate,
      taxNumber,
      legalType,
      clientType,
      webSite,
      weeklyStatistics,
      companyRegister,
      unsubscribed,
    } = customer;
    // const { country, city, postalCode, district, street, streetNumber, floor, door } = address;
    return {
      id,
      legalType: legalType || "",
      clientType: clientType || "",
      name: name || "",
      nameTitle: nameTitle || "",
      defaultEmail: defaultEmail || "",
      defaultPhone: defaultPhone || "",
      emails: emails || [],
      phones: phones || [],
      webSite: webSite || "",
      description: description || "",
      motherName: motherName || "",
      birthPlace: birthPlace || "",
      birthDate: birthDate ? new Date(birthDate) : null,
      idCardNumber: idCardNumber || "",
      idCardType: idCardType || "",
      taxNumber: taxNumber || "",
      contactPerson: contactPerson || "",
      riskRate: riskRate || "",
      weeklyStatistics: weeklyStatistics || "",
      unsubscribed: unsubscribed || false,
      country: address?.country || "",
      postalCode: address?.postalCode || "",
      city: address?.city || "",
      district: address?.district || "",
      street: address?.street || "",
      streetNumber: address?.streetNumber || "",
      floor: address?.floor || "",
      door: address?.door || "",
      companyRegister: companyRegister || "",
      state: state || 1,
      submit: null,
    };
  }
  if (initialValues.client && initialValues.canChangeOwner) {
    const {
      id,
      nameTitle,
      address,
      birthDate,
      birthPlace,
      contactPerson,
      state,
      description,
      defaultEmail,
      emails,
      idCardNumber,
      idCardType,
      motherName,
      name,
      defaultPhone,
      phones,
      riskRate,
      taxNumber,
      legalType,
      clientType,
      webSite,
      weeklyStatistics,
      companyRegister,
      unsubscribed
    } = initialValues.client;
    return {
      id,
      legalType: legalType || "",
      clientType: clientType || "",
      name: name || "",
      nameTitle: nameTitle || "",
      defaultEmail: defaultEmail || "",
      defaultPhone: defaultPhone || "",
      emails: emails || [],
      phones: phones || [],
      webSite: webSite || "",
      description: description || "",
      motherName: motherName || "",
      birthPlace: birthPlace || "",
      birthDate: birthDate ? new Date(birthDate) : null,
      idCardNumber: idCardNumber || "",
      idCardType: idCardType || "",
      taxNumber: taxNumber || "",
      contactPerson: contactPerson || "",
      riskRate: riskRate || "",
      weeklyStatistics: weeklyStatistics || "",
      unsubscribed: unsubscribed || false,
      country: address?.country || "",
      postalCode: address?.postalCode || "",
      city: address?.city || "",
      district: address?.district || "",
      street: address?.street || "",
      streetNumber: address?.streetNumber || "",
      floor: address?.floor || "",
      door: address?.door || "",
      companyRegister: companyRegister || "",
      state: state || 1,
      submit: null,
    };
  }
  return {
    legalType: "",
    clientType: getClientTypeDefaultValue(filter),
    name: "",
    nameTitle: "",
    defaultEmail: "",
    emails: [],
    defaultPhone: "",
    phones: [],
    webSite: "",
    description: "",
    motherName: "",
    birthPlace: "",
    birthDate: null,
    idCardNumber: "",
    idCardType: "",
    taxNumber: "",
    contactPerson: "",
    riskRate: selectriskLevelOptions[0].value,
    weeklyStatistics: 1,
    unsubscribed: false,
    country: "",
    postalCode: "",
    city: "",
    district: "",
    street: "",
    streetNumber: "",
    floor: "",
    door: "",
    companyRegister: "",
    state: 1,
    submit: null,
  };
};

const CustomerEditForm = (props) => {
  const {
    standAlone,
    customer,
    onCancel,
    onAddComplete,
    onEditComplete,
    setIsClientSelected,
    filter,
  } = props;
  const isLoadingArray = useSelector(selectLoading);
  const { initialValues, selectedSuggestionId } = useSelector(
    (state) => state.client
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [snakbarOpen, setSnakbarOpen] = useState(false);
  const [errorSnakbarOpen, setErrorSnakbarOpen] = useState(false);

  const handleOpenSnakbar = () => {
    setSnakbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnakbarOpen(false);
  };

  const handleOpenErrorSnakbar = () => {
    setErrorSnakbarOpen(true);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnakbarOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(resetInitialValues());
    },
    []
  );

  useEffect(() => {
    if (initialValues.client && !customer) {
      if (initialValues.hasMatch && initialValues.canChangeOwner) {
        handleOpenSnakbar();
      }
      if (!initialValues.canChangeOwner) {
        handleOpenErrorSnakbar();
      }
    }
  }, [initialValues]);


  return (
    <Formik
      ref={formikRef}
      enableReinitialize
      initialValues={getInitialValues(customer, filter, initialValues)}
      validationSchema={Yup.object().shape({
        legalType: Yup.string().max(255),
        clientType: Yup.string().max(255),
        name: Yup.string()
          .max(255)
          .matches(
            /^[a-záéúóüűöőíäẞ.A-Z-ÁÉÚÓÜŰÖŐÍÄß0-9 ]+$/,
            "A név nem tartalmazhat számokat és speciális karaktereket kivéve kötőjelet."
          ).required('A név megadása kötelező.'),
        defaultEmail: Yup.string()
          .email("Létező e-mail-nek kell lennie.")
          .max(255),
        emails: Yup.array()
          .of(Yup.string().email("Létező e-mail-nek kell lennie."))
          .required(), // bc it is required on backend btw it should not be required if the phone number and address is given
        defaultPhone: Yup.string().max(20),
        webSite: Yup.string().max(255),
        description: Yup.string().max(500),
        motherName: Yup.string().max(255),
        birthPlace: Yup.string().max(255),
        birthDate: Yup.date()
          .nullable()
          .typeError("Nincs helyesen megadva a kezdő dátum (éééé/hh/nn óó/pp)")
          .max(new Date(), "A születési idő nem valós."),
        // .required('A születés megadása kötelező.'), /* FIXME: just bc now it is required on backend, however required logic on frontend is in the inputFields, not nice but working, according to the given roles */
        idCardNumber: Yup.string().max(255),
        idCardType: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable(),
        taxNumber: Yup.string().max(255),
        contactPerson: Yup.string().max(255),
        riskRate: Yup.string().max(255),
        // .required('Kockázati besorolás mező megadása kötelező.'),
        weeklyStatistics: Yup.string().max(255),
        postalCode: Yup.string().max(20),
        country: Yup.string(),
        city: Yup.string().max(255),
        street: Yup.string().max(255),
        streetNumber: Yup.string().max(255),
        floor: Yup.string().max(255),
        door: Yup.string().max(255),
        companyRegister: Yup.string().max(255),
      })}
      validate={(values) => {
        const errors = {};

        const { defaultEmail, defaultPhone, city, country } = values;
        if (!defaultEmail || !defaultPhone) {
          if (!country) errors.country = "Az ország megadása kötelező.";
          if (!city) errors.city = "A település megadása kötelező.";
        }

        if ((!defaultEmail || !city || !country) && !defaultPhone)
          errors.defaultPhone = "A telefonszám megadása kötelező.";

        if ((!defaultPhone || !city || !country) && !defaultEmail)
          errors.defaultEmail = "A e-mail megadása kötelező.";

        return errors;
      }}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        const getData = () => {
          if (values.legalType === 2) {
            return {
              legalType: values.legalType,
              clientType: values.clientType,
              suggestionId: selectedSuggestionId,
              name: values.name,
              defaultEmail: values.defaultEmail,
              emails: values.emails || [],
              defaultPhone: values.defaultPhone,
              phones: values.phones || [],
              webSite: values.webSite,
              description: values.description,
              taxNumber: values.taxNumber,
              contactPerson: values.contactPerson,
              riskRate: values.riskRate,
              weeklyStatistics: values.weeklyStatistics,
              unsubscribed: values.unsubscribed || false,
              address: {
                country: values.country,
                postalCode: values.postalCode,
                city: values.city,
                street: values.street,
                streetNumber: values.streetNumber,
                floor: values.floor,
                door: values.door,
              },
              companyRegister: values.companyRegister,
              state: values.state,
            };
          }
          return {
            legalType: values.legalType,
            clientType: values.clientType,
            name: values.name,
            nameTitle: values.nameTitle,
            suggestionId: selectedSuggestionId,
            defaultEmail: values.defaultEmail,
            emails: values.emails || [],
            defaultPhone: values.defaultPhone,
            phones: values.phones || [],
            webSite: values.webSite,
            description: values.description,
            motherName: values.motherName,
            birthPlace: values.birthPlace,
            birthDate: values.birthDate ? values.birthDate?.getTime() : null,
            idCardNumber: values.idCardNumber,
            idCardType: values.idCardType,
            riskRate: values.riskRate,
            weeklyStatistics: values.weeklyStatistics || 1,
            unsubscribed: values.unsubscribed || false,
            address: {
              country: values.country,
              postalCode: values.postalCode,
              city: values.city,
              street: values.street,
              streetNumber: values.streetNumber,
              floor: values.floor,
              door: values.door,
            },
            state: values.state,
          };
        };
        try {
          const data = getData();
          if (customer && !standAlone) {
            dispatch(updateClient(customer.id, data));
          } else if (customer && standAlone) {
            dispatch(updateClient(customer.id, data, navigate));
          } else if (initialValues.client) {
            dispatch(updateClient(initialValues.client.id, data));
          } else if (setIsClientSelected) {
            const ip = true;
            dispatch(createClient(data, filter, ip));
          } else {
            dispatch(createClient(data, filter));
          }
          if (setIsClientSelected) {
            setIsClientSelected(data);
          }
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          if (!customer && onAddComplete) {
            onAddComplete();
          }

          if (customer && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Box mb={5}>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                {customer || initialValues.client
                  ? "Ügyfél szerkesztése"
                  : "Új ügyfél"}
              </Typography>
            </Box>
            <Grid container spacing={3} mb={2}>
              <Grid item md={6} xs={12}>
                <Box>
                  <Typography
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Alapadatok
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(touched.legalType && errors.legalType)}
                    fullWidth
                    helperText={touched.legalType && errors.legalType}
                    label="Ügyfél típusa"
                    name="legalType"
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.legalType}
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Válassz...</em>
                    </MenuItem>
                    {selectClientTypeOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(touched.clientType && errors.clientType)}
                    fullWidth
                    helperText={touched.clientType && errors.clientType}
                    label="Ügyfél jellege"
                    name="clientType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.clientType}
                    variant="outlined"
                    select
                    sx={{ mr: 2 }}
                  >
                    <MenuItem value="">
                      <em>Válassz...</em>
                    </MenuItem>
                    {selectLegalTypeOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mt: 2 }}>
                  {values.legalType !== 2 ? (
                    <Grid container spacing={1}>
                      <Grid item md={3} sm={2} xs={4}>
                        <TextField
                          error={Boolean(touched.nameTitle && errors.nameTitle)}
                          fullWidth
                          helperText={touched.nameTitle && errors.nameTitle}
                          label="Titulus"
                          name="nameTitle"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.nameTitle}
                          variant="outlined"
                          select
                        >
                          <MenuItem value="">
                            <em>Válassz...</em>
                          </MenuItem>
                          {[
                            {
                              id: 1,
                              name: "dr.",
                            },
                            {
                              id: 2,
                              name: "Dr.",
                            },
                            {
                              id: 3,
                              name: "Prof.",
                            },
                            {
                              id: 4,
                              name: "Id.",
                            },
                            {
                              id: 5,
                              name: "Ifj.",
                            },
                            {
                              id: 6,
                              name: "Özv.",
                            },
                          ].map((option) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={9} sm={10} xs={8}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          label={values.legalType === 2 ? "Cégnév" : "Név"}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      fullWidth
                      label={values.legalType === 2 ? "Cégnév" : "Név"}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  )}
                </Box>
                {values.legalType !== 2 && (
                  <>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        error={Boolean(touched.motherName && errors.motherName)}
                        fullWidth
                        helperText={touched.motherName && errors.motherName}
                        label="Anyja neve"
                        name="motherName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.motherName}
                        variant="outlined"
                      />
                    </Box>
                    <Grid container spacing={2} mt={0}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <TextField
                          error={Boolean(
                            touched.birthPlace && errors.birthPlace
                          )}
                          fullWidth
                          helperText={touched.birthPlace && errors.birthPlace}
                          label="Születési hely"
                          name="birthPlace"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.birthPlace}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <DesktopDatePicker
                          label="Születési idő"
                          inputFormat="yyyy/MM/dd"
                          mask="____/__/__"
                          onChange={(date) => setFieldValue("birthDate", date)}
                          renderInput={(params) => (
                            <TextField
                              name="birthDate"
                              fullWidth
                              variant="outlined"
                              {...params}
                              helperText="éééé/hh/nn"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: null,
                              }}
                            />
                          )}
                          value={values.birthDate}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2} mt={0}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label={`Ország${values.defaultEmail && values.defaultPhone ? "" : " *"
                        }`}
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label={`Település${values.defaultEmail && values.defaultPhone ? "" : " *"
                        }`}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      // required={!values.defaultEmail || !values.defaultPhone}
                      label="Irányítószám"
                      name="postalCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postalCode}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      error={Boolean(touched.street && errors.street)}
                      // required={!values.defaultEmail || !values.defaultPhone}
                      fullWidth
                      helperText={touched.street && errors.street}
                      label="Utca"
                      name="street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.street}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0}>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.streetNumber && errors.streetNumber
                      )}
                      // required={!values.defaultEmail || !values.defaultPhone}
                      fullWidth
                      helperText={touched.streetNumber && errors.streetNumber}
                      label="Házszám"
                      name="streetNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.streetNumber}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      error={Boolean(touched.floor && errors.floor)}
                      fullWidth
                      helperText={touched.floor && errors.floor}
                      label="Emelet"
                      name="floor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.floor}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      error={Boolean(touched.door && errors.door)}
                      fullWidth
                      helperText={touched.door && errors.door}
                      label="Ajtó"
                      name="door"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.door}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {values.legalType === 2 ? (
                  <>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        error={Boolean(touched.taxNumber && errors.taxNumber)}
                        fullWidth
                        helperText={touched.taxNumber && errors.taxNumber}
                        label="Adószám"
                        name="taxNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.taxNumber}
                        variant="outlined"
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        error={Boolean(
                          touched.companyRegister && errors.companyRegister
                        )}
                        fullWidth
                        helperText={
                          touched.companyRegister && errors.companyRegister
                        }
                        label="Cégjegyzékszám"
                        name="companyRegister"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyRegister}
                        variant="outlined"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        error={Boolean(touched.idCardType && errors.idCardType)}
                        fullWidth
                        helperText={touched.idCardType && errors.idCardType}
                        label="Azonosító okmány típusa"
                        name="idCardType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.idCardType}
                        variant="outlined"
                        select
                      >
                        <MenuItem value="">
                          <em>Válassz...</em>
                        </MenuItem>
                        {selectIdTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        error={Boolean(
                          touched.idCardNumber && errors.idCardNumber
                        )}
                        fullWidth
                        helperText={touched.idCardNumber && errors.idCardNumber}
                        label="Azonosító okmány száma"
                        name="idCardNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.idCardNumber}
                        variant="outlined"
                      />
                    </Box>
                  </>
                )}

                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(touched.riskRate && errors.riskRate)}
                    fullWidth
                    helperText={touched.riskRate && errors.riskRate}
                    label="Kockázati besorolás"
                    // required
                    name="riskRate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.riskRate}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="">
                      <em>Válassz...</em>
                    </MenuItem>
                    {selectriskLevelOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(
                      touched.weeklyStatistics && errors.weeklyStatistics
                    )}
                    fullWidth
                    helperText={
                      touched.weeklyStatistics && errors.weeklyStatistics
                    }
                    label="Heti statisztikát kér?"
                    name="weeklyStatistics"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.weeklyStatistics}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="">
                      <em>Válassz...</em>
                    </MenuItem>
                    {selectWeeklyStatOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(
                      touched.unsubscribed && errors.unsubscribed
                    )}
                    fullWidth
                    helperText={
                      touched.unsubscribed && errors.unsubscribed
                    }
                    label="Hírlevelet kér?"
                    name="unsubscribed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unsubscribed}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="">
                      <em>Válassz...</em>
                    </MenuItem>
                    {unsubscribedOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box>
                  <Typography
                    align="left"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Elérhetőségek
                  </Typography>
                </Box>
                {values.legalType === 2 && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(
                        touched.contactPerson && errors.contactPerson
                      )}
                      fullWidth
                      helperText={touched.contactPerson && errors.contactPerson}
                      label="Kapcsolat tartó neve"
                      name="contactPerson"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contactPerson}
                      variant="outlined"
                    />
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <TextField
                    /* eslint-disable-next-line */
                    error={
                      Boolean(touched.defaultEmail && errors.defaultEmail) ||
                      (initialValues.canChangeOwner &&
                        !initialValues.canChangeOwner)
                    }
                    helperText={touched.defaultEmail && errors.defaultEmail}
                    fullWidth
                    label={`E-mail cím${values.city && values.country && values.defaultPhone
                      ? ""
                      : " *"
                      }`}
                    name="defaultEmail"
                    onBlur={(e) => {
                      handleBlur(e);
                      dispatch(checkEmail(values.defaultEmail));
                    }}
                    onChange={handleChange}
                    value={values.defaultEmail}
                    variant="outlined"
                  />
                </Box>
                <FieldArray
                  name="emails"
                  /* eslint-disable-next-line */
                  render={(arrayHelpers) => (
                    <Grid container justifyContent="space-between">
                      {values.emails && values.emails.length > 0 ? (
                        values.emails.map((emailItem, index) => (
                          <Fragment key={emailItem?.id || index}>
                            <Grid item xs={12}>
                              <TextField
                                sx={{ mt: 2 }}
                                /* eslint-disable-next-line */
                                error={
                                  Boolean(
                                    touched?.emails &&
                                    errors?.emails &&
                                    errors.emails[index]
                                  ) ||
                                  (initialValues.canChangeOwner &&
                                    !initialValues.canChangeOwner)
                                }
                                helperText={
                                  touched?.emails &&
                                  errors?.emails &&
                                  errors.emails[index]
                                }
                                fullWidth
                                label="Másodlagos e-mail cím"
                                name={`emails.${index}`}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  dispatch(checkEmail(values.emails[index]));
                                }}
                                onChange={(event) =>
                                  setFieldValue(
                                    `emails.${index}`,
                                    event.target.value
                                  )
                                }
                                value={values.emails[index]}
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      <IconButton
                                        sx={{
                                          position: "absolute",
                                          top: 3,
                                          right: 3,
                                        }}
                                        size="small"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <CloseIcon fontSize="small" />
                                      </IconButton>
                                    </>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {index + 1 === values.emails.length && (
                                <Button
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, "")
                                  }
                                >
                                  Másodlagos e-mail hozzáadása
                                </Button>
                              )}
                            </Grid>
                          </Fragment>
                        ))
                      ) : (
                        <Button onClick={() => arrayHelpers.push("")}>
                          Másodlagos e-mail hozzáadása
                        </Button>
                      )}
                    </Grid>
                  )}
                />
                <Box sx={{ mt: 2 }}>
                  <PhoneInput
                    onBlur={(e) => {
                      handleBlur(e);
                      if (!values.defaultPhone) return;
                      dispatch(checkPhone(values.defaultPhone));
                    }}
                    required={
                      !(values.city && values.country && values.defaultEmail)
                    }
                    countryCallingCodeEditable={false}
                    international
                    defaultCountry="HU"
                    value={values.defaultPhone}
                    error={Boolean(
                      touched.defaultPhone &&
                      errors.defaultPhone &&
                      (isPossiblePhoneNumber(values.defaultPhone)
                        ? undefined
                        : "Nem létező telefonszám!")
                    )}
                    helperText={
                      touched?.defaultPhone &&
                      errors?.defaultPhone &&
                      (isPossiblePhoneNumber(values.defaultPhone)
                        ? undefined
                        : "A telefonszám megadása kötelező.")
                    }
                    onChange={(value) => {
                      // if (value) {
                      setFieldValue("defaultPhone", value || "");
                      // }
                    }}
                    inputComponent={PhoneNumberStyle}
                    countrySelectComponent={CustomCountrySelect}
                  />
                </Box>
                <FieldArray
                  name="phones"
                  /* eslint-disable-next-line */
                  render={(arrayHelpers) => (
                    <Grid container justifyContent="space-between">
                      {values.phones && values.phones.length > 0 ? (
                        values.phones.map((phoneItem, index) => (
                          <Fragment key={phoneItem?.id || index}>
                            <Grid item xs={12}>
                              <PhoneInput
                                sx={{ mt: 2 }}
                                name={`phones.${index}`}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  dispatch(checkPhone(values.phones[index]));
                                }}
                                countryCallingCodeEditable={false}
                                international
                                defaultCountry="HU"
                                value={values.phones[index]}
                                error={Boolean(
                                  values.phones[index] &&
                                  (isPossiblePhoneNumber(values.phones[index])
                                    ? undefined
                                    : "Nem létező telefonszám!")
                                )}
                                helperText={
                                  touched?.phones &&
                                  values.phones[index] &&
                                  (isPossiblePhoneNumber(values.phones[index])
                                    ? undefined
                                    : "Nem létező telefonszám!")
                                }
                                onChange={(value) => {
                                  if (value) {
                                    setFieldValue(`phones.${index}`, value);
                                  }
                                }}
                                inputComponent={PhoneNumberStyle}
                                countrySelectComponent={CustomCountrySelect}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      <IconButton
                                        sx={{
                                          position: "absolute",
                                          top: 3,
                                          right: 3,
                                        }}
                                        size="small"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <CloseIcon fontSize="small" />
                                      </IconButton>
                                    </>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {index + 1 === values.phones.length && (
                                <Button
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, "")
                                  }
                                >
                                  Másodlagos telefonszám hozzáadása
                                </Button>
                              )}
                            </Grid>
                          </Fragment>
                        ))
                      ) : (
                        <Button onClick={() => arrayHelpers.push("")}>
                          Másodlagos telefonszám hozzáadása
                        </Button>
                      )}
                    </Grid>
                  )}
                />
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(touched.webSite && errors.webSite)}
                    fullWidth
                    helperText={touched.webSite && errors.webSite}
                    label="Weboldal"
                    name="webSite"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.webSite}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>https://</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    fullWidth
                    label="Megjegyzés"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                    multiline
                    rows={5}
                  />
                </Box>
                {Boolean(
                  initialValues.client && !initialValues.canChangeOwner
                ) && (
                    <Box>
                      <FormHelperText error /* TODO: test it */>
                        Az ügyfél nem szekerszthető
                      </FormHelperText>
                    </Box>
                  )}
              </Grid>
            </Grid>
            {Boolean(touched.birthDate && errors.birthDate) && (
              <Box>
                <FormHelperText error>{errors.birthDate}</FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={standAlone ? () => navigate(-1) : onCancel}
              variant="text"
            >
              Mégsem
            </Button>
            <LoadingButton
              loading={isLoadingArray.includes("UPDATE_CLIENT")}
              color="primary"
              // disabled={
              //   Boolean(
              //     initialValues?.client !== null &&
              //       initialValues?.canChangeOwner === false
              //   ) || isSubmitting
              // }
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {customer || initialValues.client
                ? "Mentés"
                : "Ügyfél hozzáadása"}
            </LoadingButton>
          </Box>
          <Snackbar
            open={snakbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="info">
              Az ügyfél adatai már léteznek az adatbázisban ezért azok
              betöltésre kerültek!
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorSnakbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseErrorSnackbar}
          >
            <Alert onClose={handleCloseErrorSnackbar} severity="error">
              Az ügyfél adatai már léteznek az adatbázisban de nem lehet a
              felhasználóhoz rendelni!
            </Alert>
          </Snackbar>
        </form>
      )}
    </Formik>
  );
};

CustomerEditForm.propTypes = {
  customer: PropTypes.object,
  onCancel: PropTypes.func,
  onAddComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  standAlone: PropTypes.bool,
  setIsClientSelected: PropTypes.func,
  filter: PropTypes.string,
};

export default CustomerEditForm;
