import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { createEmployee, deleteEmployee, updateEmployee, deleteEmployeeImage, clearEmployeeSlice, getRoles } from '../../../slices/employee';
import { getSimplifiedOffices } from '../../../slices/office';
import { useDispatch, useSelector } from '../../../store';
import {
  Avatar,
  Card,
  CardHeader,
  Collapse,
  CircularProgress,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  TextField,
  Typography,
  // MenuItem,
  Tab,
  Table,
  TableBody,
  TableRow,
  Tabs,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  InputAdornment,
  Autocomplete,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import TrashIcon from '../../../icons/Trash';
import PhoneInput, { isValidPhoneNumber, } from 'react-phone-number-input';
import PhoneNumberStyle from '../../phoneInput/PhoneNumberStyle';
import CustomCountrySelect from '../../phoneInput/CustomCountrySelect';
import FileDropzone from '../../FileDropzone';
import { openModal as openConfirmModal } from '../../../slices/modals';
import ConfirmModal from '../../modals/ConfirmModal';
import { toast } from 'react-hot-toast';
import { VisibilityOff } from '@material-ui/icons';
import Visibility from '@material-ui/icons/Visibility';
import { TableHead, TableCell } from '@mui/material';
import PlusIcon from '../../../icons/Plus';
import MentoredModal from './MentoredModal';
import useAuth from '../../../hooks/useAuth';
import { selectLoading } from '../../../slices/loading';
import QuillEditor from '../../QuillEditor';
// import MultipleSelectChip from '../../MultipleChipSelect';
import { isOfficeManager, isOfficeUser } from '../../../utils/helperFunctions';
import SimpleEditorIndex from '../../imageEditor/SimpleEditorIndex';

const { REACT_APP_FILE_URL } = process.env;

const mentoreds = [
  {
    id: 0,
    name: 'Test Name1',
    hiredDate: '2022.08.22',
    dueDate: '2023.02.22',
  },
  {
    id: 1,
    name: 'Test Name2',
    hiredDate: '2022.08.22',
    dueDate: '2023.02.22',
  },
  {
    id: 2,
    name: 'Test Name3',
    hiredDate: '2022.08.22',
    dueDate: '2023.02.22',
  },
];

const getIsMentore = (employee) => (employee?.roles && employee.roles.some((role) => role.id === 7)); /** NOTE: id 7 = mentore */

const profileVisibilityOptions = [
  {
    label: 'Aktív - megjelenik az oldalon',
    value: 1
  },
  {
    label: 'Inaktív - még megjelenik kívül, törlés előkészítése',
    value: 2
  },
  {
    label: 'Törölt - már nem jelenik meg kívül ',
    value: 3
  }
];

const blockOptions = [
  {
    value: 0,
    label: 'Engedélyezett'
  },
  {
    value: 1,
    label: 'Nem engedélyezett'
  },
];

const getInitialValues = (employee) => {
  const { user } = useAuth();
  const userOffice = user.office;
  if (employee) {
    const emp = { ...employee };
    emp.password = '';
    emp.confirmPassword = '';
    emp.office = emp.office || null;
    emp.state = profileVisibilityOptions[employee.state - 1] || ''; // TODO: refact
    emp.block = blockOptions[employee.block] || '';// TODO: refact
    // emp.languages?.forEach((language) => {
    //   if (language.id === 1) {
    //     emp.english = true;
    //   } else {
    //     emp.english = false;
    //   }
    //   if (language.id === 2) {
    //     emp.german = true;
    //   } else {
    //     emp.german = false;
    //   }
    //   if (language.id === 3) {
    //     emp.french = true;
    //   } else {
    //     emp.french = false;
    //   }
    //   if (language.id === 4) {
    //     emp.spanish = true;
    //   } else {
    //     emp.spanish = false;
    //   }
    //   if (language.id === 5) {
    //     emp.italian = true;
    //   } else {
    //     emp.italian = false;
    //   }
    //   if (language.id === 6) {
    //     emp.russian = true;
    //   } else {
    //     emp.russian = false;
    //   }
    //   if (language.id === 7) {
    //     emp.romanian = true;
    //   } else {
    //     emp.romanian = false;
    //   }
    //   if (language.id === 8) {
    //     emp.slovak = true;
    //   } else {
    //     emp.slovak = false;
    //   }
    // });
    emp.english = emp.languages.some((language) => language.id === 1); // TODO: refact maybe use select instead of checkboxes
    emp.german = emp.languages.some((language) => language.id === 2);
    emp.french = emp.languages.some((language) => language.id === 3);
    emp.spanish = emp.languages.some((language) => language.id === 4);
    emp.italian = emp.languages.some((language) => language.id === 5);
    emp.russian = emp.languages.some((language) => language.id === 6);
    emp.romanian = emp.languages.some((language) => language.id === 7);
    emp.slovak = emp.languages.some((language) => language.id === 8);
    return emp;
  }

  return {
    name: '',
    phone: '',
    phoneCountry: 'HU',
    email: '',
    state: '',
    block: '',
    title: '',
    office: userOffice || '',
    description: '',
    english: false,
    german: false,
    french: false,
    spanish: false,
    italian: false,
    russian: false,
    romanian: false,
    slovak: false,
    accessLevel: '',
    roles: [],
    profileVisibility: '',
    password: '',
    confirmPassword: '',
  };
};

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const EmployeeForm = (props) => {
  const { user } = useAuth();
  const { employee, onAddComplete, onCancel, onDeleteComplete, onEditComplete } = props;
  const { simplifiedOffices } = useSelector((state) => state.office);
  const { roles } = useSelector((state) => state.employee);
  const isLoadingArray = useSelector(selectLoading);
  const [currentTab, setCurrentTab] = useState('data');
  const [image, setImage] = useState(employee?.images.length > 0 ? employee?.images[0] : null);
  const imgLink = employee?.images?.length > 0 ? `${REACT_APP_FILE_URL}${employee?.images[0].source?.original}` : null;
  const [passwordSTR, setPasswordSTR] = useState(0);
  const [avatar, setAvatar] = useState(imgLink || null);
  // const [avatar, setAvatar] = useState(employee?.images?.length > 0 ? `${REACT_APP_FILE_URL}${employee?.images[0].source?.original}` : null);
  const [password1, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [barColor, setBarColor] = useState('inherit');
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const dataErrors = ['name', 'phone', 'notes', 'title', 'email', 'office'];
  const manageErrors = ['accesLevel', 'confirmPassword', 'password', 'roles', 'profileVisibility'];
  const dispatch = useDispatch();
  const [handleMentoreds, setHandleMentoreds] = useState(false);
  const [openImgEditor, setOpenImgEditor] = useState(false);

  const handleClickOpenImgEditor = () => {
    setOpenImgEditor(true);
  };
  const handleCloseImgEditor = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOpenImgEditor(false);
  };

  const tabs = [
    { label: 'Adatok', value: 'data', disabled: false },
    { label: 'Kezelés', value: 'manage', disabled: isOfficeUser(user.roles) },
    { label: 'Mentoráltak', value: 'mentored', disabled: !getIsMentore(employee) },
  ];

  useEffect(() => {
    if (avatar === null && imgLink !== null) {
      setAvatar(imgLink);
    }
  }, [imgLink]);

  useEffect(() => {
    dispatch(getSimplifiedOffices());
    dispatch(getRoles());
  }, []);

  /* eslint-disable-next-line */
  useEffect(() => {
    return () => {
      dispatch(clearEmployeeSlice());
    };
  }, []);

  function checkPasswordStrength() {
    let passwordStrength = 0;
    passwordStrength = 0;
    const value = password1;
    if (value !== undefined && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (/\d/.test(value)) {
          passwordStrength += 15;
        } else if (value[i] === value[i].toLowerCase()) {
          passwordStrength += 10;
        } else if (value[i] === value[i].toUpperCase()) {
          passwordStrength += 20;
        }
      }
      if (value === value.toLowerCase() || !(/\d/.test(value)) || value === value.toUpperCase()) {
        passwordStrength /= 2;
      }
      if (value.length < 6) {
        passwordStrength /= 2;
      }
      if (passwordStrength > 100) {
        passwordStrength = 100;
      }
      setPasswordSTR(passwordStrength);
      if (passwordStrength >= 70) {
        setBarColor('success');
      } else if (passwordStrength < 70 && passwordStrength >= 30) {
        setBarColor('warning');
      } else {
        setBarColor('error');
      }
    }
  }

  const changeTabs = (errors) => {
    const ErrorsKeys = Object.keys(errors);
    if (!(ErrorsKeys.some((error) => dataErrors.includes(error))) && (ErrorsKeys.some((error) => manageErrors.includes(error)))) {
      setCurrentTab('manage');
    }
    if ((ErrorsKeys.some((error) => dataErrors.includes(error))) && !(ErrorsKeys.some((error) => manageErrors.includes(error)))) {
      setCurrentTab('data');
    }
  };

  // const generatePassword = (setFieldValue) => {
  //   const passwordCharacters = 'qwertzuiopőúöüóasdfghjkléáűíyxcvbnm12345678900123456789'.split('');
  //   const passwordLength = 8;
  //   const randomBigLetters = [];
  //   const howManyBigLetters = 5;
  //   for (let i = 1; i < howManyBigLetters; i++) {
  //     randomBigLetters.push(Math.floor(Math.random() * passwordLength));
  //   }
  //   let generatedPassword = '';
  //   for (let i = 0; i < passwordLength; i++) {
  //     const random = Math.floor(Math.random() * passwordCharacters.length);
  //     if (randomBigLetters.includes(i)) {
  //       generatedPassword += passwordCharacters[random].toUpperCase();
  //     } else {
  //       generatedPassword += passwordCharacters[random];
  //     }
  //   }
  //   setPassword(generatedPassword);
  //   setFieldValue('password', generatedPassword);
  //   setFieldValue('confirmPassword', generatedPassword);
  //   setShowPassword(true);
  //   setShowPasswordConfirm(true);
  // };

  useEffect(() => {
    checkPasswordStrength();
  }, [password1]);

  const handleDropCover = async ([file]) => {
    if (file) {
      setImage(file);
      const data = await toBase64(file);
      setAvatar(data);
      handleClickOpenImgEditor();
    } else {
      toast.error('Hiba a fájl feltöltése során.');
    }
  };

  const handleRemoveCover = () => {
    setAvatar(null);
  };

  const handleOpenConfirmModal = () => {
    dispatch(openConfirmModal());
  };

  // NOTE: Telephone numbering plans can and sometimes do change, meaning that isValidPhoneNumber()function may one day become outdated
  const handleTabsChange = (event, value, setFieldValue, values) => {
    if (values.phone && !isValidPhoneNumber(values.phone)) {
      setFieldValue('phoneCountry', 'HU');
      setFieldValue('phone', '');
    }
    setCurrentTab(value);
  };

  const handleDelete = async () => {
    try {
      dispatch(deleteEmployee(employee.id));
      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(employee)}
        enableReinitialize
        validationSchema={Yup
          .object()
          .shape({
            name: Yup.string().max(255),
            // .required('Mező kitöltése kötelező'),
            // phone: Yup.string().max(255),
            // .required('Mező kitöltése kötelező'),
            email: Yup.string().required('Mező kitöltése kötelező').email('Az e-mail cím formátuma nem megfelelő'),
            // title: Yup.string().max(255),
            // .required('Mező kitöltése kötelező'),
            // office: Yup.string().max(255),
            // .required('Mező kitöltése kötelező'),
            // accessLevel: Yup.string().max(255),
            // .required('Mező kitöltése kötelező'),
            // roles: Yup.string().max(255), // .required('Mező kitöltése kötelező'),
            // profileVisibility: Yup.string().max(255), // .required('Mező kitöltése kötelező'),
            password: Yup.string(),
            // .required('Jelszó megadása kötelező').min(8, 'A jelszónak minimum 8 karakter hosszúnak kell lennie'),
            confirmPassword: Yup.string()
              .when('password', {
                is: (password) => password?.length > 0,
                then: Yup.string()
                  .required('jelszó megerősítése kötelező!')
              })
              .oneOf([Yup.ref('password'), null], 'A jelszavak nem egyeznek')
            // description: Yup.string().max(500),
            // .required('Mező kitöltése kötelező'),
            // english: Yup.boolean(),
            // german: Yup.boolean(),
            // french: Yup.boolean(),
            // spanish: Yup.boolean(),
            // italian: Yup.boolean(),
            // russian: Yup.boolean(),
            // romanian: Yup.boolean(),
            // slovak: Yup.boolean(),
          })}
        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
          try {
            const formData = new FormData();
            formData.append('title', values.title);
            formData.append('file', image);
            formData.append('isPublic', true);
            const data = {
              name: values.name,
              phone: values.phone,
              email: values.email,
              title: values.title || '',
              officeId: values?.office?.id || '',
              description: values.description,
              languageIds: [],
              image: avatar && formData,
              // english: values.english,
              // german: values.german,
              // french: values.french,
              // spanish: values.spanish,
              // italian: values.italian,
              // russian: values.russian,
              // romanian: values.romanian,
              // slovak: values.slovak,
              // roleIds: [],
              accessLevel: values.accessLevel,
              roles: values.roles || '',
              state: values.state.value || '',
              block: values.block.value === 0 ? 0 : values.block.value || '',
              password: values.password,
            };
            if (values.english) {
              data.languageIds.push(1);
            } if (values.german) {
              data.languageIds.push(2);
            } if (values.french) {
              data.languageIds.push(3);
            } if (values.spanish) {
              data.languageIds.push(4);
            } if (values.italian) {
              data.languageIds.push(5);
            } if (values.russian) {
              data.languageIds.push(6);
            } if (values.romanian) {
              data.languageIds.push(7);
            } if (values.slovak) {
              data.languageIds.push(8);
            }
            if (!avatar && employee?.images?.length > 0 && employee?.images[0].id && employee?.id) {
              await dispatch(deleteEmployeeImage(employee?.id, employee?.images[0].id));
            }
            if (employee) {
              if (employee?.images?.length > 0 && avatar) {
                dispatch(updateEmployee(employee.id, data, !!avatar, employee?.images[0].id));
              } else {
                dispatch(updateEmployee(employee.id, data, !!avatar));
              }
            } else {
              dispatch(createEmployee(data, !!avatar));
            }
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            if (!employee && onAddComplete) {
              onAddComplete();
            }

            if (employee && onEditComplete) {
              onEditComplete();
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                {employee
                  ? 'Munkatárs adatainak módosítása'
                  : 'Új munkatárs hozzáadása'}
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Tabs
                indicatorColor="primary"
                onChange={(event, value) => handleTabsChange(event, value, setFieldValue, values)}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    disabled={tab.disabled}
                  />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Collapse in={currentTab === 'data'}>
                <Fade in={currentTab === 'data'}>
                  <Grid
                    container
                    sx={{ p: 3 }}
                    spacing={4}
                  >
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      {avatar
                        ? (
                          <>
                            <Box mt={3}>
                              <Avatar
                                src={avatar}
                                sx={{
                                  height: 185,
                                  width: '100%',
                                  borderRadius: 1,
                                }}
                              />
                              {!isOfficeUser(user.roles) && (
                                <Grid
                                  container
                                  justifyContent="center"
                                  mt={2}
                                >
                                  <Button
                                    color="primary"
                                    onClick={handleRemoveCover}
                                    variant="text"
                                  >
                                    Kép eltávolítása
                                  </Button>
                                </Grid>
                              )}
                            </Box>
                          </>
                        )
                        : (
                          <Box mt={3}>
                            <FileDropzone
                              avatar
                              accept="image/*"
                              maxFiles={1}
                              onDrop={!isOfficeUser(user.roles) && handleDropCover}
                              onRemove={!isOfficeUser(user.roles) && handleRemoveCover}
                            />
                          </Box>
                        )}
                    </Grid>
                    <Grid
                      item
                      xl={5}
                      lg={5}
                      md={5}
                      sm={6}
                      xs={12}
                    >
                      <Box sx={{ mt: 3 }}>
                        <TextField
                          autoFocus
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          fullWidth
                          label="Név"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={isOfficeUser(user.roles)}
                          value={values.name}
                          variant="outlined"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <PhoneInput
                          countryCallingCodeEditable={false}
                          international
                          disabled={isOfficeUser(user.roles)}
                          defaultCountry={values.phoneCountry || 'HU'}
                          value={values.phone}
                          error={Boolean(values.phone && (isValidPhoneNumber(values.phone) ? undefined : 'Nem létező telefonszám!'))}
                          // helperText={touched.phoneNumber && errors.phoneNumber}
                          onChange={(value) => {
                            if (value) {
                              setFieldValue('phone', value);
                            }
                          }}
                          inputComponent={PhoneNumberStyle}
                          countrySelectComponent={CustomCountrySelect}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          disabled={isOfficeUser(user.roles)}
                          label="E-mail cím"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          variant="outlined"
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Autocomplete
                          // getOptionLabel={(option) => option}
                          options={[
                            'Tulajdonos',
                            'Irodavezető',
                            'Asszisztens',
                            'Értékesítő',
                            'Ingatlanértékesítő'
                          ]}
                          freeSolo
                          disabled={isOfficeUser(user.roles)}
                          onChange={(_, newValue) => setFieldValue('title', newValue || '')}
                          value={values.title}
                          renderInput={(params) => (
                            <TextField
                              error={Boolean(touched.title && errors.title)}
                              helperText={touched.title && errors.title}
                              fullWidth
                              label="Pozíció"
                              onChange={handleChange}
                              name="title"
                              variant="outlined"
                              {...params}
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Autocomplete
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option.name || ''}
                          name="office"
                          value={values.office}
                          disableClearable
                          disabled={isOfficeManager(user.roles) || isOfficeUser(user.roles)}
                          onChange={(_, newValue) => setFieldValue('office', newValue || '')}
                          options={simplifiedOffices}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Iroda"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                    >
                      <Box>
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          sx={{ my: 2 }}
                        >
                          Beszélt nyelvek
                        </Typography>
                        <Grid container>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            display="flex"
                            flexDirection="column"
                          >
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.english}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.english}
                                />
                              )}
                              name="english"
                              label="Angol"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.german}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.german}
                                />
                              )}
                              name="german"
                              label="Német"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.french}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.french}
                                />
                              )}
                              name="french"
                              label="Francia"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.spanish}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.spanish}
                                />
                              )}
                              name="spanish"
                              label="Spanyol"
                            />
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            display="flex"
                            flexDirection="column"
                          >
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.italian}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.italian}
                                />
                              )}
                              name="italian"
                              label="Olasz"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.russian}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.russian}
                                />
                              )}
                              name="russian"
                              label="Orosz"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.romanian}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.romanian}
                                />
                              )}
                              name="romanian"
                              label="Román"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  color="primary"
                                  disabled={isOfficeUser(user.roles)}
                                  checked={values.slovak}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.slovak}
                                />
                              )}
                              name="slovak"
                              label="Szlovák"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Box sx={{ mt: 2, p: 2 }}>
                        {/* <TextField
                          error={Boolean(touched.description && errors.description)}
                          fullWidth
                          helperText={touched.description && errors.description}
                          label="Rövid bemutatkozás..."
                          multiline
                          rows={6}
                          name="description"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          variant="outlined"
                        /> */}
                        <Paper
                          sx={{ mt: 3 }}
                          variant="outlined"
                        >
                          <QuillEditor
                            disabled={isOfficeUser(user.roles)}
                            onChange={(value) => setFieldValue('description', value)}
                            value={values.description}
                            sx={{ height: 200 }}
                            maxLength={300}
                            placeholder="Rövid leírás..."

                          />
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                </Fade>
              </Collapse>
              <Collapse in={currentTab === 'manage'}>
                <Fade in={currentTab === 'manage'}>
                  <Grid
                    container
                    sx={{ p: 3 }}
                    spacing={4}
                  >
                    {/* <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          sx={{ my: 2 }}
                        >
                          Rendszer hozzáférés
                        </Typography>
                      </Box>
                      <Grid
                        container
                        spacing={4}
                      >
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            Aktuális állapot
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            Jogosultsági szint
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            Külső megjelenés
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            Utolsó belépés
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            {values.accessLevel || 'Nincs adat'}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            {values.roles || 'Nincs adat'}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            {values.profileVisibility || 'Nincs adat'}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{ my: 2 }}
                          >
                            {values.lastLoginDate || 'Nincs adat'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          sx={{ my: 2 }}
                        >
                          Beállítások
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Autocomplete
                          name="state"
                          value={values.state}
                          onChange={(_, newValue) => setFieldValue('state', newValue || '')}
                          options={profileVisibilityOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Külső Megjelenés"
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Autocomplete
                          name="block"
                          value={values.block}
                          onChange={(_, newValue) => setFieldValue('block', newValue || '')}
                          options={blockOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Beléphet adminra"
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Autocomplete
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          multiple
                          options={roles}
                          loading={isLoadingArray.includes('GET_ROLES')}
                          disableCloseOnSelect
                          loadingText="Töltés"
                          noOptionsText="Nincs találat"
                          onChange={(_, newValue) => setFieldValue('roles', newValue || '')}
                          value={values.roles}
                          getOptionLabel={(option) => option.label || option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Szerepkörök"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isLoadingArray.includes('GET_ROLES') ? (
                                      <CircularProgress
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          sx={{ my: 2 }}
                        >
                          Jelszó
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          label="Jelszó"
                          name="password"
                          onBlur={handleBlur}
                          onChange={(event) => {
                            handleChange(event);
                            setPassword(event.target.value);
                          }}
                          value={values.password}
                          variant="outlined"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            autoComplete: 'new-password',
                            form: {
                              autoComplete: 'off',
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                >
                                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>)
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          fullWidth
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          label="Jelszó megerősítése"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmPassword}
                          variant="outlined"
                          type={showPasswordConfirm ? 'text' : 'password'}
                          InputProps={{
                            autoComplete: 'new-password',
                            form: {
                              autoComplete: 'off',
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                >
                                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>)
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{ my: 2 }}
                        >
                          Jelszó erőssége
                        </Typography>
                        <LinearProgress
                          color={barColor}
                          value={passwordSTR}
                          variant="determinate"
                        />
                        {/* <Button
                          onClick={() => generatePassword(setFieldValue)}
                          sx={{ mt: 4 }}
                          rel="noreferrer"
                          variant="outlined"
                          color="primary"
                          fullWidth
                        >
                          Új jelszó generálása
                        </Button> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Fade>
              </Collapse>
              <Collapse in={currentTab === 'mentored'}>
                <Fade in={currentTab === 'mentored'}>
                  <Grid
                    container
                    sx={{
                      pr: 3,
                      pl: 3,
                      mb: 5,
                    }}
                    spacing={4}
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Card sx={{ mt: 4, height: '100%' }}>
                        <CardHeader
                          title="Mentoráltak"
                          action={(
                            <Button
                              color="primary"
                              startIcon={<PlusIcon fontSize="small" />}
                              sx={{ mr: 1 }}
                              onClick={() => setHandleMentoreds(true)}
                              variant="outlined"
                            >
                              Mentoráltak kezelése
                            </Button>
                          )}
                        />
                        <MentoredModal
                          mentoreds={mentoreds}
                          open={handleMentoreds}
                          setOpen={setHandleMentoreds}
                        />
                        <Divider />
                        <Box>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  ID
                                </TableCell>
                                <TableCell>
                                  Név
                                </TableCell>
                                <TableCell>
                                  Felvétel napja
                                </TableCell>
                                <TableCell>
                                  Mentorális dátum vége
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {mentoreds.map((mentored) => (
                                <TableRow key={mentored.id}>
                                  <TableCell>
                                    {mentored.id}
                                  </TableCell>
                                  <TableCell>
                                    {mentored.name}
                                  </TableCell>
                                  <TableCell>
                                    {mentored.hiredDate}
                                  </TableCell>
                                  <TableCell>
                                    {mentored.dueDate}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Fade>
              </Collapse>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
                pr: 3,
                pb: 3
              }}
            >
              {errors && Object.keys(errors).length > 0 && !errors.email && !errors.submit && (
                <FormHelperText error>
                  A mezők kitöltése nem megfelelő
                </FormHelperText>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2
              }}
            >
              {employee && (
                <IconButton
                  onClick={() => handleOpenConfirmModal()}
                  disabled={isOfficeUser(user.roles)}
                >
                  <TrashIcon fontSize="small" />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Mégsem
              </Button>
              <Button
                onClick={() => changeTabs(errors)}
                color="primary"
                disabled={isSubmitting || isOfficeUser(user.roles)}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                Mentés
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {
        employee && (
          <ConfirmModal
            header="Munkatárs törlésének megerősítése"
            content={`Biztos, hogy törölni szeretnéd a(z) "${employee.name}" nevű munkatársat?`}
            handleConfirm={handleDelete}
          />
        )
      }
      <Dialog
        open={openImgEditor}
        onClose={handleCloseImgEditor}
        disableEscapeKeyDown
      >
        <DialogTitle>Kép szerekesztés</DialogTitle>
        <DialogContent>
          <SimpleEditorIndex
            base64Src={avatar}
            saveBase64PicSrc={setAvatar}
            saveImageFile={setImage}
            onModalClose={handleCloseImgEditor}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

EmployeeForm.propTypes = {
  employee: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
};

export default EmployeeForm;
