import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  TablePagination,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomerSearchesRow from '../customer/CustomerSearchesRow';
import { getClientSearches } from '../../../slices/client';
import { useDispatch, useSelector } from '../../../store';
import { selectLoading } from '../../../slices/loading';

const applyPagination = (clients, page, limit) => clients && clients
  .slice(page * limit, page * limit + limit);

const ClientSearches = (props) => {
  const { clientId } = props;
  const { clientSearches } = useSelector((state) => state.client);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(3);
  const dispatch = useDispatch();
  const isLoadingArray = useSelector(selectLoading);
  const [clientSearchesByClient, setclientSearchesByClient] = useState([]);

  useEffect(() => {
    dispatch(getClientSearches(clientId));
  }, []);

  useEffect(() => {
    clientSearches.forEach((element) => {
      if (element.clientId === clientId) {
        setclientSearchesByClient(element.data);
      }
    });
  }, [clientSearches]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };
  const paginatedClients = applyPagination(clientSearchesByClient, page, limit);
  return (
    <Box>
      <Card>
        <Grid
          component="table"
          width="100%"
        >
          <Grid
            component="tbody"
          >
            {!isLoadingArray.includes(`GET_CLIENT_SEARCHES${clientId}`) ? (
              paginatedClients.length > 0 && paginatedClients?.map((search) => (
                <CustomerSearchesRow
                  key={search.id}
                  search={search}
                />
              ))
            ) : (
              <Grid
                container
                width="100%"
                justifyContent="center"
                alignContent="center"
                minHeight={200}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* {paginatedClients.length === 0 && !isLoadingArray.includes(`GET_CLIENT_SEARCHES${clientId}`) && (
          <Grid
            container
            justifyContent="center"
            mt={5}
            mb={5}
          >
            <Typography
              fontSize={20}
            >
              Nem található keresés
            </Typography>
          </Grid>
        )} */}
        <TablePagination
          component="div"
          count={clientSearchesByClient ? clientSearchesByClient.length : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPageOptions={[3, 6, 25]}
          rowsPerPage={limit}
          labelDisplayedRows={
            ({ from, to, count }) => `Elem ${from}-${to} - Összesen: ${count}`
          }
          labelRowsPerPage=""
        />
      </Card>
    </Box>
  );
};
ClientSearches.propTypes = {
  clientId: PropTypes.number
};
export default ClientSearches;
