import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card, CardHeader, TextField, Typography
} from '@material-ui/core';

export const callResultSwitch = (result) => {
  switch (result) {
    case 9:
      return 'Még gondolkodik';
    case 10:
      return 'Egyéb';
    case 5:
      return 'Hibás telefonszám';
    case 6:
      return 'Ingatlanos (konkurencia)';
    case 3:
      return 'Nem vette fel';
    case 4:
      return 'Foglalt volt';
    default:
      return 'Nincs megadva';
  }
};

const FalseCallTask = (props) => {
  const { newCallProcess, onComplete, onBack, handleOpenisConfrimModal } = props;
  const { callResult } = newCallProcess;


  return (
    <Formik
      initialValues={
        {
          description: '',
          appointment: '',
        }
      }
      validationSchema={Yup
        .object()
        .shape({
          description: Yup.string().max(500)
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        const data = {
          callResult: callResult,
          description: values.description,
          appointment: values.appointment
        };
        try {
          setStatus({ success: true });
          setSubmitting(false);

          if (onComplete) {
            onComplete('callTask', data);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Box p={3}>
            <Card>
              <CardHeader
                title={(
                  <Box>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Esemény neve
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    // sx={{
                    //   textTransform: 'capitalize'
                    // }}
                    >
                      {callResultSwitch(callResult.resultName)}
                    </Typography>
                  </Box>
                )}
              />
              <Box p={2}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  fullWidth
                  label="Leírás"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                  multiline
                  rows={5}
                  sx={{ mt: 1 }}
                />
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 3,
              mt: 2.4
            }}
          >
            {onBack && (
              <Button
                color="primary"
                onClick={onBack}
                size="large"
                variant="text"
              >
                Vissza
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={handleOpenisConfrimModal}
              color="inherit"
              sx={{ mr: 1 }}
            >
              Megszakítás
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

FalseCallTask.propTypes = {
  newCallProcess: PropTypes.object,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
  handleOpenisConfrimModal: PropTypes.func,
};

export default FalseCallTask;
