/* eslint-disable arrow-body-style */
import {
  Box,
  // Button,
  // Collapse,
  Typography
} from '@material-ui/core';
import React from 'react';
// import { useState } from 'react';
import PropTypes from 'prop-types';

const WebPresentationDetailsList = ({ list, nameMap }) => {
  // const [extend, setExtend] = useState(false)

  return (
    <>
      {/* <Collapse
        in={extend}
        collapsedSize={280}
      > */}
      {list.map((item) => (
        <Box
          key={item.id}
          padding={1}
        >
          <Typography
            variant="h6"
          >
            {nameMap[item.attributeName]}
          </Typography>
          <Typography
            variant="body1"
          >
            {item.name}
          </Typography>
        </Box>
      ))}
      {/* </Collapse> */}
      {/* {list.length > 4 && (
        <Button
          onClick={() => setExtend(!extend)}
          variant="text"
          size="small"
          color="inherit"
          disableFocusRipple
          disableElevation
          disableRipple
          sx={{ ml: '2px' }}
        >
          Továbbiak megjelenítése
        </Button>
      )} */}
    </>
  );
};

WebPresentationDetailsList.propTypes = {
  list: PropTypes.array,
  nameMap: PropTypes.object
};

export default WebPresentationDetailsList;
