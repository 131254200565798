import deepCopy from '../utils/deepCopy';
import createResourceId from '../utils/createResourceId';

const now = new Date();
let generations = [
  {
    id: createResourceId(),
    type: 'eladó',
    propertyType: 'családi ház',
    salePrice: '4500000',
    currency: 'HUF',
    city: 'Kaposvár',
    floorarea: '350',
    status: 'ingatlan átadás',
    landarea: '1750',
    issueDate: now.getTime(),
    name: 'Csepregi Dominik',
    properties: [
      {
        id: '155006253'
      },
      {
        id: '181702220'
      },
      {
        id: '182600315'
      },
    ],
  },
  {
    id: createResourceId(),
    type: 'bérelhető',
    propertyType: 'lakás',
    salePrice: '3500000',
    currency: 'HUF',
    city: 'Kaposvár',
    floorarea: '350',
    status: 'ingatlan átadás',
    landarea: '1750',
    issueDate: now.getTime(),
    name: 'ifjabb Csepregi Dominik',
    properties: [
      {
        id: '155002253'
      },
      {
        id: '182323315'
      },
    ],
  },
];
class GenerationApi {
  getGenerations() {
    return Promise.resolve(generations);
  }

  createGeneration(data) {
    return new Promise((resolve, reject) => {
      try {
        const clonedGenerations = deepCopy(generations);

        const generation = data;

        clonedGenerations.push(generation);

        generations = clonedGenerations;

        resolve(deepCopy(generation));
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  updateGeneration({ generationId, update }) {
    return new Promise((resolve, reject) => {
      try {
        const clonedGenerations = deepCopy(generations);

        const generation = generations.find((_generation) => _generation.id === generationId);

        if (!generation) {
          reject(new Error('Generation not found'));
          return;
        }

        const newGen = { ...generation, ...update };
        // const newGen2 = Object.assign({}, generation, update);

        generations = clonedGenerations;

        resolve(deepCopy(newGen));
      } catch (err) {
        console.error(err);
        reject(new Error('Internal server error'));
      }
    });
  }

  deleteGeneration(generationId) {
    console.log(generationId);
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedGenerations = deepCopy(generations);

        // Find the generations that will be removed
        const generation = generations.find((_generation) => _generation.id === generationId);

        if (!generation) {
          reject(new Error('generations not found'));
          return;
        }

        generations = generations.filter((_generation) => _generation.id !== generationId);

        // Save changes
        generationId = clonedGenerations;

        resolve(true);
      } catch (err) {
        console.error(err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const generationApi = new GenerationApi();
