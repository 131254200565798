import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../../slices/calendar";
import { useDispatch, useSelector } from "../../../store";
import { DesktopDateTimePicker } from "@material-ui/lab";
import AOCAutocomplete from "../../AOCAutocomplete";
import { searchClients, getClients } from "../../../slices/client";
import { selectLoading } from "../../../slices/loading";
import { selectHp } from "../../../slices/generation";
import NumberFormatCustom from "../../NumberFormatCustom";
import { generateBianco } from "../../../slices/property";
import { Stack } from "@mui/material";
import { parsePhoneNumber } from "react-phone-number-input";

const getInitialValues = (event) => {
  if (event) {
    // FIXME: I think there is impossible to edit cuz it is generating sg so event doen't make sense
    return {
      date: event.date || new Date().getTime(),
      client: event.client || null,
      submit: null,
    };
  }

  return {
    date: new Date().getTime(),
    client: null,
    submit: null,
  };
};

function hasNumber(myString) {
  return /\d/.test(myString);
}

const BrokerageAgreementEventForm = (props) => {
  const {
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
    generateDataSheet,
    generation,
    selectedProperties,
    setSelectedProperties,
  } = props;
  const [clientSearchName, setClientSearchName] = useState();
  const { clientSearchDefaultParameters } = useSelector(
    (state) => state.search
  );
  const { clients } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const isLoadingArray = useSelector(selectLoading);
  console.log(clients);
  useEffect(() => {
    const abortController = new AbortController()
    const delayDebounceFn = setTimeout(() => {
      if (clientSearchName) {
        const defaultSearch = { ...clientSearchDefaultParameters };
        if (hasNumber(clientSearchName)) {
          defaultSearch.phone = clientSearchName;
        } else {
          defaultSearch.name = clientSearchName;
        }
        // const buyClients = 1; // Not good for those whose value it is 3 which means thay are searching and buying as well
        // defaultSearch.clientType = buyClients;
        // defaultSearch.name = clientSearchName;
        dispatch(searchClients(defaultSearch, "", 0, 50, "", abortController));
      }
    }, 400);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [clientSearchName]);

  useEffect(() => {
    dispatch(getClients());
  }, []);

  // TODO it is here just for airbnb
  // const handleClick = () => console.log('button clicked');

  const handleDelete = async () => {
    try {
      await dispatch(deleteEvent(event.id));

      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  const removeProperty = (property) => {
    const deepSelectedProperties = [...selectedProperties];
    const index = deepSelectedProperties.indexOf(property);
    if (index > -1) {
      deepSelectedProperties.splice(index, 1);
    }
    // console.log(deepSelectedProperties, index);
    setSelectedProperties(deepSelectedProperties);
  };

  return (
    <Formik
      initialValues={getInitialValues(event, range)}
      validationSchema={Yup.object().shape({
        // allDay: Yup.bool(),
        description: Yup.string().max(5000),
        end: Yup.date()
          .nullable()
          .when(
            "start",
            (start, schema) =>
              start &&
              schema.min(start, "End date must be later than start date")
          ),
        start: Yup.date(),
        title: Yup.string().max(255),
        // .required('Title is required')
      })}
      onSubmit={(
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            client: values.client,
            date: values.date,
            title: values.title,
          };
          if (event) {
            dispatch(updateEvent(event.id, data));
          } else {
            dispatch(createEvent(data));
          }
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Naptár frissítve!");

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error("Naptár frissítése sikertelen!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        values,
        // isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event
                ? "Közvetítési nyilatkozat szerkesztése"
                : "Közvetítési nyilatkozat generálása"}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                display: "flex",
                p: 1,
              }}
            >
              <AOCAutocomplete
                filterOptions={(options) => options}
                fullWidth
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Stack
                    {...props}
                    key={option.id}
                    alignItems="flex-start !important"
                  >
                    <Typography variant="subtitle2" align="left">
                      {option.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.disabled"
                      align="left"
                    >
                      {
                        option?.defaultPhone && parsePhoneNumber(option.defaultPhone)
                          ? parsePhoneNumber(option.defaultPhone).formatInternational()
                          : option.defaultPhone
                      }
                    </Typography>
                  </Stack>
                )}
                options={clients && clients.data}
                textFieldOnChange={(e) => setClientSearchName(e.target.value)}
                onChange={(_, newValue) => {
                  setFieldValue("client", newValue || null);
                }}
                value={values.client}
                label="Ügyfél"
                placeholder="Kezdje el beírni a ügyfél nevét."
                loadingArray={
                  isLoadingArray.includes("GET_CLIENTS_PAGE") ||
                  isLoadingArray.includes("GET_CLIENTS")
                }
              />
              <DesktopDateTimePicker
                label="Dátum (előre generálás esetén)"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue("date", date?.getTime())}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                    sx={{ ml: 2 }}
                  />
                )}
                value={values.date}
              />
            </Box>
            {Boolean(touched.end && errors.end) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.end}</FormHelperText>
              </Box>
            )}
          </Box>
          {/* <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pb: 3,
              pl: 3,
              pr: 3,
            }}
          >
            <Button
              component={generation ? null : RouterLink}
              to="/generation/new"
              color="primary"
              onClick={() => {
                if (generation) {
                  dispatch(selectHp(values));
                  generateDataSheet('brokerage-declaration-empty', values);
                  onCancel();
                } else {
                  dispatch(selectHp(values));
                  onCancel();
                }
              }}
              disabled={!values.client || !values.date}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              HP
            </Button>
            <Button
              color="primary"
              onClick={handleClick}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              Biankó HP
            </Button>
          </Box> */}
          {selectedProperties?.length > 0 && (
            <>
              <Table>
                <TableHead>
                  <TableRow hover>
                    <TableCell>ID</TableCell>
                    <TableCell>Cím</TableCell>
                    <TableCell align="right">Ingatlan ára</TableCell>
                    <TableCell align="right">Törlés</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProperties.map((property) => (
                    <TableRow key={property.id} hover>
                      <TableCell>{property.publicId}</TableCell>
                      <TableCell>
                        {/* {property.address.formattedAddress || "nincs megadva"} */}
                        {property?.address?.city}
                        {' '}
                        {property?.address?.suburb}
                        {' '}
                        {property?.address?.street}
                      </TableCell>
                      <TableCell align="right">
                        {/* {!property.salePrice && !property.rentPrice ? 'nincs megadva' : property.salePrice ? property.salePrice + ' FT eladási ár' : property.rentPrice + ' FT kiadási ár'} */}
                        <Grid container flexDirection="column">
                          {property.salePrice && (
                            <NumberFormatCustom
                              value={property.salePrice}
                              displayType="text"
                              // suffix={`${property.basicDetails.currency === 'HUF' ? ' Ft' : '€'}`}
                              suffix=" HUF eladási ár"
                            />
                          )}
                          {property.rentPrice && (
                            <NumberFormatCustom
                              value={property.rentPrice}
                              displayType="text"
                              // suffix={`${property.basicDetails.currency === 'HUF' ? ' Ft' : '€'}`}
                              suffix=" HUF kiadási ár"
                            />
                          )}
                          {!property.salePrice && !property.rentPrice && (
                            <Typography variant="subtitle2">
                              Nincs megadva
                            </Typography>
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => {
                            removeProperty(property);
                          }}
                        >
                          Törlés
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Divider />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2,
            }}
          >
            {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Button color="primary" onClick={onCancel} variant="text">
              Bezárás
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button> */}
            <Button
              component={generation ? null : RouterLink}
              to="/generation/new"
              color="primary"
              onClick={() => {
                if (generation) {
                  dispatch(selectHp(values));
                  generateDataSheet("brokerage-declaration-empty", values);
                  onCancel();
                } else {
                  dispatch(selectHp({ ...values, selectedProperties }));
                  onCancel();
                }
              }}
              disabled={!values.client || !values.date}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              HP
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const data = {
                  // userId: user.id,
                  // officeId: user.office?.id || null,
                  // clientId: selectedHp?.client?.id || null,
                  generationDate: values?.date || new Date().getTime(),
                };
                dispatch(generateBianco(data));
              }}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              Biankó
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

BrokerageAgreementEventForm.propTypes = {
  event: PropTypes.object,
  generateDataSheet: PropTypes.func,
  generation: PropTypes.bool,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
  selectedProperties: PropTypes.array,
  setSelectedProperties: PropTypes.func,
};

export default BrokerageAgreementEventForm;
