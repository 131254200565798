import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { toggleLoading } from './loading';
import { ipApi } from '../__fakeApi__/ipApi';

const { REACT_APP_FILE_URL } = process.env;

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const initialState = {
  ips: {},
  propertyPresentations: {},
  presentationClients: {},
  selectedIp: null,
  selectedIpId: null,
  // ipDrafts: [],
  // selectedDraftIpId: null,
  isClientSelected: false,
  defaultConfig: {
    status: '',
    presentationDate: null,
    sent: null,
    userId: '',
    officeId: '',
    clientId: '',
    propertyIds: []
  },
  config: {
    status: '',
    presentationDate: null,
    sent: null,
    userId: 0,
    officeId: 2, // TODO: it is siófok
    clientId: '',
    propertyIds: [],
  },
};

const slice = createSlice({
  name: 'ip',
  initialState,
  reducers: {
    getIps(state, action) {
      state.ips = action.payload;
    },
    createIp(state, action) {
      state.ips.push(action.payload);
    },
    selectIp(state, action) {
      state.selectedIp = action.payload;
    },
    setIpId(state, action) {
      state.selectedIpId = action.payload;
    },
    addToPropertyPresentations(state, action) {
      const { propertyId, propertyPresentation } = action.payload;
      state.propertyPresentations[propertyId] = propertyPresentation;
    },
    addToPropertyPresentationClients(state, action) {
      const { propertyId, propertyPresentationClients } = action.payload;
      state.presentationClients[propertyId] = propertyPresentationClients;
    },
    updateConfig(state, action) {
      if (!action.payload) {
        state.config = initialState.defaultConfig;
      } else {
        state.config = action.payload;
      }
    },
    updateIp(state, action) {
      const ip = action.payload;

      state.ips = state.ips.map((_ip) => {
        if (_ip.id === ip.id) {
          return ip;
        }

        return _ip;
      });
    },
    deleteIp(state, action) {
      state.ips = state.ips.filter((event) => event.id !== action.payload);
    },

    // updateIp(state, action) {
    //   const ip = action.payload;

    //   state.ips = state.ips.map((_ip) => {
    //     if (_ip.id === ip.id) {
    //       return ip;
    //     }

    //     return _ip;
    //   });
    // },

    // <-------- (actions) only getDraftIp is used for ipList (where listing draft ip-s) ------------------->
    //   getDraftIps(state, action) {
    //     state.ipDrafts = action.payload;
    //   },
    //   createDraftIp(state, action) {
    //     state.ipDrafts.push(action.payload);
    //   },
    //   selectDraftIp(state, action) {
    //     const { draftIpId } = action.payload;
    //     state.selectedDraftIpId = draftIpId;
    //   },
    //   updateDraftIp(state, action) {
    //     const ipDraft = action.payload;
    //     state.ipDrafts = state.ipDrafts.map((_ipDraft) => {
    //       if (_ipDraft.id === ipDraft.id) {
    //         return ipDraft;
    //       }

    //       return _ipDraft;
    //     });
    //   },
    //   deleteDraftIp(state, action) {
    //     state.ipDrafts = state.ipDrafts.filter((ipDraft) => ipDraft.id !== action.payload);
    //   },
    // <-------- (actions) only getDraftIp is used for ipList (where listing draft ip-s) ------------------->
  }
});

export const { reducer } = slice;

// export const getIps = () => async (dispatch) => {
//   const data = await ipApi.getIps();

//   dispatch(slice.actions.getHints(data));
// };

// GET IPS

export const getIps = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_IPS' }));
  await axiosConfig.get(
    '/presentations'
  ).then((response) => {
    // console.log('getIps respponse', response);
    dispatch(slice.actions.getIps(response.data));
  }).catch((error) => {
    // handle error
    // toast.error('Az irodai prezentációk betöltése sikertelen'); // TODO: comment it back when backend working properly
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_IPS' }));
  });
};

// GET IPS BY PARAMs

export const getIpsByParams = (pageNumber, pageSize) => async (dispatch) => {
  // const { client = null, employee = null, endData = null, office = null, startDate = null } = filters && {};
  // console.log('rbnb', client, employee, office, startDate, endData); // TODO: it is just for test purposes could be good for filter
  dispatch(toggleLoading({ type: 'GET_IPS' }));
  await axiosConfig.get(
    `/presentations?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24}`
  ).then((response) => {
    // console.log('getIps respponse', response);
    dispatch(slice.actions.getIps(response.data));
  }).catch((error) => {
    // handle error
    // toast.error('Az irodai prezentációk betöltése sikertelen'); // TODO: comment it back when backend working properly
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_IPS' }));
  });
};

export const clearIps = () => (dispatch) => {
  dispatch(slice.actions.getIps({}));
};

export const getPresentationFilter = (pageNumber, pageSize, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_IPS' }));
  const { client, office, start, end, employee, } = filters;
  const data = {
    clientId: client?.id || null,
    officeId: office?.id || null,
    userId: employee?.id || null,
    dateFrom: start?.getTime() || null,
    dateTo: end?.getTime() || null,
    type: filters.type ? filters.type : !filters?.property?.id && 'IP',
    propertyIds: filters?.property?.id && [filters?.property?.id],
  };
  await axiosConfig.post(
    `presentations/search?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 0}`,
    data
  ).then((response) => {
    dispatch(slice.actions.getIps(response.data));
    dispatch(slice.actions.addToPropertyPresentations({ propertyId: filters?.property?.id, propertyPresentation: response.data }));
  }).catch((error) => {
    console.log(error);
    toast.error('Hiba a közetítési nyilatkozatok betöltésekor');
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_IPS' }));
  });
};

export const getPresentationClients = (filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_IPS_CLIENTS' }));
  const { client, office, start, end, employee, } = filters;
  const data = {
    clientId: client?.id || null,
    officeId: office?.id || null,
    userId: employee?.id || null,
    dateFrom: start?.getTime() || null,
    dateTo: end?.getTime() || null,
    type: filters.type ? filters.type : !filters?.property?.id && 'IP',
    propertyIds: filters?.property?.id && [filters?.property?.id],
  };
  await axiosConfig.post(
    'presentations/search/clients',
    data
  ).then((response) => {
    dispatch(slice.actions.addToPropertyPresentationClients({ propertyId: filters?.property?.id, propertyPresentationClients: response.data }));
  }).catch((error) => {
    console.log(error);
    // toast.error('Hiba a közetítési nyilatkozatok  betöltésekor');
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_IPS_CLIENTS' }));
  });
}

// CREATE IP

export const createIp = (data) => async (dispatch) => {
  // console.log('dataCreate', data);
  if (data === null) {
    dispatch(slice.actions.selectIp(data));
    return;
  }
  await axiosConfig.post(
    '/presentations/IP',
    data
  ).then((response) => {
    dispatch(slice.actions.selectIp(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Új irodai prezentáció sikeresen létrehozva!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az irodai prezentáció létrehozásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET THE DETAILS OF A SELECTED IP

export const selectIp = (ipId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_SELECTIP' }));
  await axiosConfig.get(
    `/presentations/${ipId}`
  ).then((response) => {
    // handle success
    if (ipId) {
      dispatch(slice.actions.selectIp(response.data));
      dispatch(slice.actions.updateConfig(response.data));
    } else {
      dispatch(slice.actions.selectIp(null));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba az irodi prezentáció adatainak lekérésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_SELECTIP' }));
  });
};

// UPDATE SELECTED IP

export const updateIp = (ipId, update) => async (dispatch) => {
  await axiosConfig.put(
    `/presentations/${ipId}`,
    update
  ).then((response) => {
    // handle success
    // console.log('IP UPDATE RESPONSE', response);
    dispatch(slice.actions.selectIp(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Irodai prezentáció sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const createIpStatement = (id) => async (dispatch) => { // TODO: wait for backend
  await axiosConfig.post(
    `/presentations/IP/${id}/statement`,
  ).then((response) => {
    openInNewTab(`${REACT_APP_FILE_URL}${response.data.statement.originalFileSource}`);
    if (id) {
      dispatch(slice.actions.selectIp(response.data));
      dispatch(slice.actions.updateConfig(response.data));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan(ok) generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const setIpId = (id) => (dispatch) => {
  dispatch(slice.actions.setIpId(id));
  if (!id) {
    dispatch(slice.actions.selectIp([]));
  }
};

export const deleteIp = (hintId) => async (dispatch) => {
  await ipApi.deleteIp(hintId);

  dispatch(slice.actions.deleteIp(hintId));
};

export const updateConfig = () => (dispatch) => {
  dispatch(slice.actions.updateConfig());
};

// <-------- only getDraftIp is used for ipList (where listing draft ip-s) ------------------->
// export const getDraftIps = (filters) => async (dispatch) => {
//   const data = await ipApi.getDraftIps(filters);

//   dispatch(slice.actions.getDraftIps(data, filters));
// };

// export const createDraftIp = (createData) => async (dispatch) => {
//   const data = await ipApi.createDraftIp(createData);

//   dispatch(slice.actions.createDraftIp(data));
// };

// export const selectDraftIp = (draftIpId, type) => async (dispatch) => {
//   dispatch(slice.actions.selectDraftIp({ draftIpId, type }));
// };

// export const updateDraftIp = (draftIpId, update) => async (dispatch) => {
//   const data = await ipApi.updateDraftIp({
//     draftIpId,
//     update
//   });

//   dispatch(slice.actions.updateDraftIp(data));
// };

// export const deleteDraftIp = (draftIpId) => async (dispatch) => {
//   await ipApi.deleteDraftIp(draftIpId);

//   dispatch(slice.actions.deleteDraftIp(draftIpId));
// };
// <-------- only getDraftIp is used for ipList (where listing draft ip-s) ------------------->

// export const createIp = (createData) => async (dispatch) => {
//   const data = await ipApi.createIp(createData);

//   dispatch(slice.actions.createIp(data));
// };

// export const updateIp = (hintId, update) => async (dispatch) => {
//   const data = await ipApi.updateIp({
//     hintId,
//     update
//   });

//   dispatch(slice.actions.updateIp(data));
// };

export default slice;
