export const removeHTMLTags = (text) => {
  const cleanedText = text.replace(/<[^>]*>/g, function (match) {
    if (match.startsWith("<a ") || match === "<a>") {
      // Extract href and label from anchor tag
      const hrefMatch = match.match(/href=["'](.*?)["']/);
      if (hrefMatch) return hrefMatch[1] + " ";
    }

    // If not an anchor tag, remove it
    return "";
  });

  return cleanedText;
};
