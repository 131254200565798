import { createSlice } from "@reduxjs/toolkit";
// import { clientApi } from '../__fakeApi__/clientApi';
import toast from "react-hot-toast";
import axiosConfig from "../axiosConfig";
import { toggleLoading } from "./loading";
import { convertEmptyStringsToNull } from "../utils/helperFunctions";
import { setLastClientSearchData } from "./search";

// TODO: CallApi does not use this!
const initialState = {
  selectedSuggestionId: null,
  clients: { data: [] },
  selectedClientId: null,
  selectedClient: null,
  isCallProcessModalOpen: false,
  isSearchLoading: false,
  isSearching: false,
  usingSearchClientsEndpoint: false,
  phoneSearches: {},
  clientSearches: [],
  clientProperties: [],
  initialValues: {
    canChangeOwner: false,
    client: null,
    hasMatch: false,
  },
  defaultInitialValues: {
    canChangeOwner: false,
    client: null,
    hasMatch: false,
  },
  clientPresentations: [],
  clientLogs: null,
  propertyUrlParam: null,
  propertyDataResultUrlParam: null,
  typeUrlParam: null,
  clientManualContacts: {},
  memos: {}
};

const slice = createSlice({
  name: "client",
  initialState,
  reducers: {
    getClients(state, action) {
      state.clients = action.payload;
    },
    getContentClients(state, action) {
      state.phoneSearches = action.payload;
    },
    setSelectedSuggestionId(state, action) {
      state.selectedSuggestionId = action.payload;
    },
    getLazyContentClients(state, action) {
      for (let i = 0; i < action.payload.data.length; i++) {
        state.phoneSearches.data.push(action.payload.data[i]);
      }
    },
    getClientSearchesForDashboard(state, action) {
      state.clientSearches = action.payload;
    },
    getClientSearches(state, action) {
      let isInAlready = false;
      state.clientSearches.forEach((clientSearch) => {
        if (clientSearch.clientId === action.payload.clientId) {
          isInAlready = true;
        }
      });
      if (!isInAlready) {
        state.clientSearches.push(action.payload);
      }
    },
    getClientSearchesClear(state) {
      state.clientSearches = [];
    },
    getClientProperties(state, action) {
      let isInAlready = false;
      state.clientProperties.forEach((clientProperty) => {
        if (clientProperty.clientId === action.payload.clientId) {
          isInAlready = true;
        }
      });
      if (!isInAlready) {
        state.clientProperties.push(action.payload);
      }
    },
    getClientPresentations(state, action) {
      const { data, clientId } = action.payload;
      state.clientPresentations[clientId] = data;
      // let isInAlready = false;
      // state.clientPresentations.forEach((element) => {
      //   if (element.clientId === action.payload.clientId) {
      //     isInAlready = true;
      //   }
      // });
      // if (!isInAlready) {
      //   state.clientPresentations.push(action.payload);
      // }
    },
    createClientSearches(state, action) {
      // TODO: put it on the first place not in the end
      // console.log(action.payload);
      const clientSearch = action.payload;
      if (state.clientSearches.length > 0) {
        const newClientsSearchesData = [clientSearch, ...state.clientSearches];
        state.clientSearches = newClientsSearchesData;
      } else {
        state.clientSearches.push(action.payload);
      }
    },
    updateClientSearch(state, action) {
      const search = action.payload;
      state.clientSearches = state.clientSearches.map((_search) => {
        if (_search.id === search.id) {
          return search;
        }
        return _search;
      });
    },
    deleteClientSearch(state, action) {
      state.clientSearches = state.clientSearches.filter(
        (search) => search.id !== action.payload
      );
    },
    handleIsSearchLoading(state, action) {
      state.isSearchLoading = action.payload;
    },
    setIsSearching(state, action) {
      state.isSearching = action.payload;
    },
    setUsingSearchClientsEndpoint(state, action) {
      state.usingSearchClientsEndpoint = action.payload;
    },
    createClient(state, action) {
      // TODO: test it and refact
      const { data, filter } = action.payload;
      const client = data;
      const statusCodeFilter = {
        buy: 1,
        sell: 2,
      };
      if (filter && filter !== "all") {
        if (statusCodeFilter[filter] === client.clientType) {
          /* NOTE: check the page where the user at. If the user see the 'kereso' users and create a 'megbizo' user it wont add to the array */
          // NOTE: Create a new clients.data array with our new client object at the beginning without mutating the original clients.data array
          if (state.clients.data) {
            const newClientsData = [client, ...state.clients.data];
            state.clients.data = newClientsData;
          } else {
            state.clients = { data: [client] };
          }
        }
      } else if (state.clients.data) {
        const newClientsData = [client, ...state.clients.data];
        state.clients.data = newClientsData;
      } else {
        state.clients = { data: [client] };
      }
    },
    deleteClient(state, action) {
      state.clients.data = state.clients.data.filter(
        (client) => client.id !== action.payload
      );
    },
    selectClientId(state, action) {
      const { clientId, searchComponent } = action.payload;
      if (searchComponent) {
        state.isCallProcessModalOpen = true;
      }
      state.selectedClientId = clientId;
    },

    selectClient(state, action) {
      const client = action.payload;
      state.selectedClient = client;
    },
    updateClient(state, action) {
      const client = action.payload;
      state.clients.data = state.clients?.data?.map((_client) => {
        if (_client.id === client.id) {
          // state.selectedClient = client; FIXME
          return client;
        }
        return _client;
      });
    },
    checkEmailAndPhone(state, action) {
      // TODO: check if email is already existing but the owner is the same, then do not override the client's data
      if (action.payload) {
        state.initialValues = action.payload;
      }
    },
    resetInitialValues(state) {
      state.initialValues = initialState.defaultInitialValues;
    },
    openCallProcessModal(state) {
      state.isCallProcessModalOpen = true;
    },

    closeModal(state) {
      state.isCallProcessModalOpen = false;
      state.selectedClientId = null;
    },
    getClientLogs(state, action) {
      state.clientLogs = action.payload;
    },
    setClientUrlParams(state, action) {
      const { propertyUrlParam, propertyDataResultUrlParam, typeUrlParam } = action.payload;
      state.propertyUrlParam = propertyUrlParam;
      state.propertyDataResultUrlParam = propertyDataResultUrlParam;
      state.typeUrlParam = typeUrlParam;
    },
    setClientManualContacts(state, action) {
      const { data, clientId } = action.payload;
      state.clientManualContacts[clientId] = data;
    },
    setMemos(state, action) {
      const { data, clientId } = action.payload;
      state.memos[clientId] = data;
    }
  },
});

export const { reducer } = slice;

// TODO: consider to merge getClients with getClientsPage, would it be better for maintaining?

// GET CLIENTS LIST

const clientEndpoint = (filter, pageNumber) => {
  switch (filter) {
    case "buy":
      return `/clients/type2/1?pageNumber=${pageNumber | 0}`;
    case "sell":
      return `/clients/type2/2?pageNumber=${pageNumber | 0}`;
    default:
      return `/clients?pageNumber=${pageNumber | 0}`;
  }
};

export const setSelectedSuggestionId = (id) => (dispatch) => {
  dispatch(slice.actions.setSelectedSuggestionId(id));
};

export const assignSuggestionToClient =
  (clientId, suggestionId) => async (dispatch) => {
    dispatch(toggleLoading({ type: "ASSIGN_SUGGESTION_TO_CLIENT" }));
    await axiosConfig
      .put(`/clients/${clientId}/suggestion`, { suggestionId })
      .then(() => {
        // handle success
        toast.success("A tipp hozzárendelése sikeres");
      })
      .catch((error) => {
        // handle error
        toast.error("A tipp hozzárendelése sikertelen");
        console.error(error);
      })
      .finally(() => {
        dispatch(toggleLoading({ type: "ASSIGN_SUGGESTION_TO_CLIENT" }));
      });
  };

export const getClients =
  (filters, controller, pageNumber) => async (dispatch) => {
    dispatch(
      slice.actions.getClients({}, filters)
    ); /** it could be usefull when clients state already has values and while loading the new data it doesn't show anyting. (as a sign of loading, however loading skeleton is impletented)  */
    // dispatch(slice.actions.setIsSearching(true)); // TODO: Test it. But getClients is not searching therefore it should switch it only to false, but never true. (Problem occured on cancelling request, switching 'all' clients to 'buy' or 'sell')
    dispatch(toggleLoading({ type: "GET_CLIENTS" }));
    await axiosConfig
      .get(clientEndpoint(filters, pageNumber), {
        signal: controller?.signal,
      })
      .then((response) => {
        // handle success
        dispatch(slice.actions.getClients(response.data, filters));
        dispatch(setLastClientSearchData());
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          // FIXME: getting TypeError  instead AbortError
          toast.error("Az ügyfelek lekérése megszakítva");
        }
        if (
          error?.response?.status === 500 &&
          error?.response?.status === 403
        ) {
          toast.error("Az ügyfelek betöltése sikertelen");
        }
      })
      .then(() => {
        // always executed
        dispatch(slice.actions.setIsSearching(false));
        dispatch(toggleLoading({ type: "GET_CLIENTS" }));
      });
  };

// getClientPage Endpoint

const clientPageEndpoint = (filter, pageNumber, sortBy, pageSize) => {
  if (sortBy && sortBy !== "default") {
    switch (filter) {
      case "buy":
        return `/clients/type2/1?pageNumber=${pageNumber}&sort=name ${sortBy}&pageSize=${pageSize || 24
          }`;
      case "sell":
        return `/clients/type2/2?pageNumber=${pageNumber}&sort=name ${sortBy}&pageSize=${pageSize || 24
          }`;
      default:
        return `/clients?pageNumber=${pageNumber}&sort=name ${sortBy}&pageSize=${pageSize || 24
          }`;
    }
  } else {
    switch (filter) {
      case "buy":
        return `/clients/type2/1?pageNumber=${pageNumber}&pageSize=${pageSize || 24
          }`;
      case "sell":
        return `/clients/type2/2?pageNumber=${pageNumber}&pageSize=${pageSize || 24
          }`;
      default:
        return `/clients?pageNumber=${pageNumber}&pageSize=${pageSize || 24}`;
    }
  }
};

// GET CLIENTS LIST PER PAGE

export const getClientsPage =
  (filters, pageNumber, sortBy, pageSize) => async (dispatch) => {
    // dispatch(slice.actions.getClients({}, filters));
    dispatch(slice.actions.setIsSearching(true)); // FIXME: it will be better use the same loading slice where we do not need another one
    dispatch(slice.actions.handleIsSearchLoading(true));
    dispatch(toggleLoading({ type: "GET_CLIENTS" }));
    await axiosConfig
      .get(clientPageEndpoint(filters, pageNumber, sortBy, pageSize))
      .then((response) => {
        // handle success
        dispatch(slice.actions.getClients(response.data, filters));
      })
      .catch((error) => {
        if (
          error?.response?.status === 500 &&
          error?.response?.status === 403
        ) {
          toast.error("Az ügyfelek betöltése sikertelen");
        }
      })
      .then(() => {
        // always executed
        dispatch(slice.actions.setIsSearching(false));
        dispatch(slice.actions.handleIsSearchLoading(false));
        dispatch(toggleLoading({ type: "GET_CLIENTS" }));
      });
  };

// GET THE DETAILS OF A SELECTED Client

export const selectClient = (clientId, type) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_SELECTED_CLIENT" }));
  await axiosConfig
    .get(`/clients/${clientId}`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.selectClient(response.data, type));
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba az ügyfél adatainak lekérdezésekor");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_SELECTED_CLIENT" }));
    });
};

export const clearSelectClient = () => async (dispatch) => {
  dispatch(slice.actions.selectClient(null));
};

// GET CLIENT SEARCHES

export const getClientSearches = (clientId, dashboard) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_CLIENT_SEARCHES${clientId}` }));
  await axiosConfig
    .get(`/clients/${clientId}/searches`)
    .then((response) => {
      // handle success
      /* eslint-disable-next-line */
      const data = response.data;
      if (dashboard) {
        // NOTE: Dashboard means the clientPage datasheet
        dispatch(slice.actions.getClientSearchesForDashboard(data));
      } else {
        dispatch(slice.actions.getClientSearches({ data, clientId }));
      }
    })
    .catch((error) => {
      if (error?.response?.status === 500 && error?.response?.status === 403) {
        toast.error("Az ügyfelek betöltése sikertelen");
      }
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: `GET_CLIENT_SEARCHES${clientId}` }));
    });
};
export const getClientSearchesClear = () => async (dispatch) => {
  dispatch(slice.actions.getClientSearchesClear());
};
export const getClientProperties = (clientId) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_CLIENT_PROPERTIES${clientId}` }));
  await axiosConfig
    .get(
      // `/clients/${clientId}/assignments`,
      `/properties/client/${clientId}?pageSize=50`
    )
    .then((response) => {
      // handle success
      /* eslint-disable-next-line */
      const data = response.data;
      dispatch(slice.actions.getClientProperties({ data, clientId }));
    })
    .catch((error) => {
      if (error?.response?.status === 500 && error?.response?.status === 403) {
        toast.error("Az ügyfelek betöltése sikertelen");
      }
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: `GET_CLIENT_PROPERTIES${clientId}` }));
    });
};

export const getClientPresentations = (clientId, pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_CLIENT_PRESENTATIONS${clientId}` }));
  await axiosConfig
    .get(`/clients/${clientId}/presentations?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24}`)
    .then((response) => {
      // handle success
      // console.log(response, 'resp');
      // dispatch(slice.actions.getClientSearches(response.data));
      /* eslint-disable-next-line */
      const data = response.data;
      dispatch(slice.actions.getClientPresentations({ data, clientId }));
    })
    .catch((error) => {
      if (error?.response?.status === 500 && error?.response?.status === 403) {
        toast.error("Az ügyfelek betöltése sikertelen");
      }
    })
    .then(() => {
      dispatch(toggleLoading({ type: `GET_CLIENT_PRESENTATIONS${clientId}` }));
      // always executed
    });
};

// POST SEARCH FOR CLIENTS

const getSearchClientEndpoints = (pageNumber, pageSize, sort) => {
  if (sort) {
    return `/clients/search?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24
      }&sort=name ${sort}`;
  }
  return `/clients/search?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24
    }`;
};

export const searchClients =
  (data, type, pageNumber, pageSize, sort, abortController) => async (dispatch) => {
    data =
      convertEmptyStringsToNull(data); /** backend only accepts null values */
    dispatch(
      slice.actions.setUsingSearchClientsEndpoint(true)
    ); /** on clients/browse pages we should know that clients coming from getClients or from searchClients */
    dispatch(slice.actions.setIsSearching(true));
    if (type !== "lazyContentSearch") {
      dispatch(slice.actions.handleIsSearchLoading(true));
      dispatch(toggleLoading({ type: "GET_CLIENTS_PAGE" }));
    } else {
      dispatch(toggleLoading({ type: "GET_LAZY_CLIENTS_PAGE" }));
    }
    await axiosConfig
      .post(getSearchClientEndpoints(pageNumber, pageSize, sort), data, {
        signal: abortController && abortController.signal
      })
      .then((response) => {
        if (type === "contentSearch") {
          dispatch(slice.actions.getContentClients(response.data));
        } else if (type === "lazyContentSearch") {
          dispatch(slice.actions.getLazyContentClients(response.data));
        } else {
          dispatch(slice.actions.getClients(response.data));
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 500 &&
          error?.response?.status === 403
        ) {
          toast.error("Az ügyfelek betöltése sikertelen");
        }
        // dispatch(slice.actions.setUsingSearchClientsEndpoint(false)); // FIXME: Does it needed?
      })
      .then(() => {
        // always executed
        dispatch(slice.actions.setIsSearching(false));
        if (type !== "lazyContentSearch") {
          dispatch(slice.actions.handleIsSearchLoading(false));
          dispatch(toggleLoading({ type: "GET_CLIENTS_PAGE" }));
        } else {
          dispatch(toggleLoading({ type: "GET_LAZY_CLIENTS_PAGE" }));
        }
      });
  };

// CREATE NEW CLIENT

export const createClient = (clientData, filter, ip) => async (dispatch) => {
  await axiosConfig
    .post("/clients", clientData)
    .then((response) => {
      // handle success
      const { data } = response;
      dispatch(slice.actions.createClient({ data, filter }));
      if (ip) {
        dispatch(slice.actions.selectClientId({ clientId: response.data.id }));
        dispatch(slice.actions.selectClient(response.data));
      }
      toast.success("Új ügyfél sikeresen létrehozva!");
    })
    .catch((error) => {
      // handle error
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      toast.error("Hiba az ügyfél létrehozásakor!");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

// POST CLIENT'S SEARCH DATA

export const createClientSearch = (data, id) => async (dispatch) => {
  await axiosConfig
    .post(`/clients/${id}/searches`, data)
    .then((response) => {
      dispatch(slice.actions.createClientSearches(response.data));
    })
    .catch((error) => {
      if (error?.response?.status === 500 && error?.response?.status === 403) {
        toast.error("Az ügyfelek betöltése sikertelen");
      }
    })
    .then(() => {
      // always executed
    });
};

// UPDATE SELECTED CLIENT

export const updateClientSearch =
  (clientId, searchId, update) => async (dispatch) => {
    // TODO: later, we will not need to pass the id in th update data
    dispatch(toggleLoading({ type: "UPDATE_CLIENT" }));
    await axiosConfig
      .put(`/clients/${clientId}/searches/${searchId}`, update)
      .then((response) => {
        // handle success
        // console.log('response', response); // TODO: Backend fix needed, after test it on front end
        dispatch(slice.actions.updateClientSearch(response.data));
        toast.success("Ügyfél adatai sikeresen módosítva");
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba a módosítások mentésekor");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "UPDATE_CLIENT" }));
      });
  };

// UPDATE SELECTED CLIENT

export const updateClient =
  (clientId, update, navigate) => async (dispatch) => {
    update.id = clientId;
    const response = await axiosConfig
      .put(`/clients/${clientId}`, update)
      .catch((error) => {
        if (error?.response?.data.message) {
          const splitted = error.response.data.message.split(':')
          if (splitted[0] === "Contacts data must be unique") {
            toast.error("Az ügyfél adata(i) (email vagy telefonszám) már létezik/léteznek a rendszerben.");
          }
        }
        toast.error("Hiba a módosítások mentésekor");
        console.error(error);
      });

    if (!response?.data) return null;
    dispatch(slice.actions.updateClient(response.data));
    dispatch(slice.actions.selectClient(response.data));
    navigate?.(-1);
    toast.success("Ügyfél adatai sikeresen módosítva");

    return response.data;
  };

export const clientUnsubscribe = (clientId) => async (dispatch) => {
  await axiosConfig
    .put(`/clients/${clientId}/subscribe`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.updateClient(response.data));
      toast.success("Ügyfél leiratkoztatva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a leiratkoztatás során");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};


// DELETE CLIENT SEARCH

export const deleteClientSearch = (clientId, searchId) => async (dispatch) => {
  await axiosConfig
    .delete(`/clients/${clientId}/searches/${searchId}`)
    .then(() => {
      // handle success
      dispatch(slice.actions.deleteClientSearch(searchId));
      toast.success("Keresés sikeresen törölve!");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a törlés végrehajtásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const checkEmail = (email) => async (dispatch) => {
  dispatch(toggleLoading({ type: "CHECK_CLIENT_EMAIL" }));
  await axiosConfig
    .get(`/clients/check/email/${email}`)
    .then((response) => {
      dispatch(slice.actions.checkEmailAndPhone(response.data));
    })
    .catch((error) => {
      console.error(error);
    })
    .then(() => {
      dispatch(toggleLoading({ type: "CHECK_CLIENT_EMAIL" }));
    });
};

export const checkPhone = (phone) => async (dispatch) => {
  dispatch(toggleLoading({ type: "CHECK_CLIENT_PHONE" }));
  await axiosConfig
    .get(`/clients/check/phone/${phone}`)
    .then((response) => {
      dispatch(slice.actions.checkEmailAndPhone(response.data));
    })
    .catch((error) => {
      console.error(error);
    })
    .then(() => {
      dispatch(toggleLoading({ type: "CHECK_CLIENT_PHONE" }));
    });
};

// DELETE CLIENT

export const deleteClient = (clientId) => async (dispatch) => {
  await axiosConfig
    .delete(`/clients/${clientId}`)
    .then(() => {
      // handle success
      dispatch(slice.actions.deleteClient(clientId));
      toast.success("Ügyfél törölve!");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a törlés végrehajtásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

// ARCHIVE CLIENT

export const archiveClient = (clientId) => async (dispatch) => {
  await axiosConfig
    .put(`/clients/${clientId}/archive`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.updateClient(response.data)); // TODO: test it archive clinet csak akkor látszódik ha a keresőben az archive ki van választva
      // dispatch(slice.actions.updateClientSearch(response.data)); // TODO: Test it i dont remember it is needed or not
      toast.success("Ügyfél sikeresen archiválva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba az archiváláskor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const activateClient = (clientId) => async (dispatch) => {
  await axiosConfig
    .put(`/clients/${clientId}/activate`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.updateClient(response.data));
      // dispatch(slice.actions.updateClientSearch(response.data)); // TODO: Test it i dont remember it is needed or not
      toast.success("Ügyfél sikeresen aktiválva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba az aktiváláskor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

// CHANGE THE USER OF A CLIENT

export const updateUserClient = (clientId, userId) => async (dispatch) => {
  await axiosConfig
    .put(`/clients/${clientId}/employee/${userId}`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.selectClient(response.data));
      toast.success("Ügyfél sikeresen átadva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba az ügyfél átadásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const getClientLogs =
  (clientId, pageNumber, pageSize) => async (dispatch) => {
    dispatch(toggleLoading({ type: "PROPERTY_LOGS" }));
    await axiosConfig
      .get(
        `clients/${clientId}/logs?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      .then((response) => {
        dispatch(slice.actions.getClientLogs(response.data));
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba az eseménynapló betöltése során");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "PROPERTY_LOGS" }));
      });
  };

// eslint-disable-next-line no-unused-vars
export const getLog = (clientId, logId) => async (dispatch) => {
  await axiosConfig
    .get(`clients/${clientId}/logs/${logId}`)
    .then((response) => {
      axiosConfig
        .put(`/clients/${clientId}`, response.data.recoveryData)
        .then((updateResponse) => {
          // handle success
          dispatch(slice.actions.updateClient(updateResponse.data));
          toast.success("Ingatlan sikeresen módosítva");
        })
        .catch((error) => {
          // handle error
          toast.error("Hiba a módosítások mentésekor");
          console.error(error);
        })
        .then(() => {
          // always executed
          // dispatch(toggleLoading({ type: 'UPDATE_CLIENT' }));
        });
      // dispatch(updateProperty)
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba az eseménynapló betöltése során");
      console.error(error);
    })
    .then(() => {
      // always executed
      window.location.reload(); /* FIXME: not the best user experience but do the job for now */
    });
};

export const getUserClients = (userId) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_CLIENTS" }));
  await axiosConfig
    .get(`/clients/users/${userId}`)
    .then((response) => {
      dispatch(slice.actions.getClients(response.data));
    })
    .catch((error) => {
      // handle error
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_CLIENTS" }));
    });
};

export const getPublicUserClients = (userId) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_CLIENTS" }));
  await axiosConfig
    .get(`/clients/public/users/${userId}`)
    .then((response) => {
      dispatch(slice.actions.getClients(response.data));
    })
    .catch((error) => {
      // handle error
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_CLIENTS" }));
    });
};

export const clearClients = () => (dispatch) => {
  dispatch(slice.actions.getClients({ data: [] }));
};

export const resetInitialValues = () => async (dispatch) => {
  dispatch(slice.actions.resetInitialValues());
};

export const selectClientId =
  (clientId, searchComponent) => async (dispatch) => {
    dispatch(slice.actions.selectClientId({ clientId, searchComponent }));
  };

export const setUsingSearchClientsEndpoint = (data) => async (dispatch) => {
  dispatch(slice.actions.setUsingSearchClientsEndpoint(data));
};

export const openCallProcessModal = () => (dispatch) => {
  dispatch(slice.actions.openCallProcessModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const setClientUrlParams = (urlParams, dataResultsUrlParam, typeUrlParam) => (dispatch) => {
  dispatch(slice.actions.setClientUrlParams({ propertyUrlParam: urlParams, propertyDataResultUrlParam: dataResultsUrlParam, typeUrlParam }));
}

export const getClientManualContacts = (clientId, pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_CLIENT_MANUAL_CONTACTS" }));
  await axiosConfig
    .post(`/clients/${clientId}/interactions/search`, { pageNumber, pageSize })
    .then((response) => {
      dispatch(slice.actions.setClientManualContacts({ data: response.data, clientId }));
    })
    .catch((error) => {
      // handle error
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_CLIENT_MANUAL_CONTACTS" }));
    });
}

export const createManualContact = (clientId, data) => async (dispatch) => {
  await axiosConfig
    .post(`/clients/${clientId}/interactions`, data)
    .then((response) => {
      dispatch(getClientManualContacts(clientId, 0, 25));
      toast.success("Kapcsolattartás sikeresen létrehozva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a kapcsolattartás létrehozásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
}

export const updateManualContact = (clientId, intersectionId, data) => async (dispatch) => {
  await axiosConfig
    .put(`/clients/${clientId}/interactions/${intersectionId}`, data)
    .then((response) => {
      dispatch(getClientManualContacts(clientId, 0, 25));
      toast.success("Kapcsolattartás sikeresen módosítva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a módosítások mentésekor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
}

export const getMemos = (clientId) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_MEMOS${clientId}` }));
  await axiosConfig
    .get(`memos/clients/${clientId}`)
    .then((response) => {
      const data = response.data;
      dispatch(slice.actions.setMemos({ data, clientId }));
    })
    .catch((error) => {
      // handle error
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: `GET_MEMOS${clientId}` }));
    });
}

export const createMemo = (clientId, message) => async (dispatch) => {
  await axiosConfig
    .post(`memos/clients/${clientId}`, { message: message })
    .then((response) => {
      dispatch(getMemos(clientId));
      toast.success("Megjegyzés sikeresen létrehozva");
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba a megjegyzés létrehozásakor");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
}


// export const selectClient = (clientId) => async (dispatch) => {
//   dispatch(slice.actions.selectClient({ clientId }));
// };

// export const updateClient = (clientId, update) => async (dispatch) => {
//     const data = await clientApi.updateClient({
//       clientId,
//       update
//     });
//     dispatch(slice.actions.updateClient(data));
//   };

// export const getClientsByType = (filters) => async (dispatch) => {
//   const data = await clientApi.getClientsByType(filters);

//   dispatch(slice.actions.getClientsByType(data, filters));
// };

//   dispatch(slice.actions.deleteClient(clientId));
// };

// export const getClients = (filters) => async (dispatch) => {
//   const data = await clientApi.getClients(filters);

//   dispatch(slice.actions.getClients(data, filters));
// };

// export const createClient = (createData) => async (dispatch) => {
//   const data = await clientApi.createClient(createData);

//   dispatch(slice.actions.createClient(data));
// };

// GET CLIENTS BY THEIR CLIENT TYPE LIST

// export const getClientsByType = (clientTypeId, filters) => async (dispatch) => {
//   dispatch(slice.actions.getClientsByType([], filters));
//   await axiosConfig.get(
//     `/clients/type2/${clientTypeId}`
//   ).then((response) => {
//     // handle success
//     dispatch(slice.actions.getClientsByType(response.data.data, filters));
//   }).catch((error) => {
//     // handle error
//     toast.error('Az ügyfelek betöltése sikertelen');
//     console.error(error);
//   }).then(() => {
//     // always executed
//   });
// };

export default slice;
