import { createSlice } from '@reduxjs/toolkit';
// import { calendarApi } from '../__fakeApi__/calendarApi';
import toast from 'react-hot-toast';
import axiosConfig from '../axiosConfig';
import { saveCall } from './call';
import { toggleLoading } from './loading';
import useAuth from '../hooks/useAuth';

const { REACT_APP_FILE_URL } = process.env;

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const initialState = {
  calendars: [],
  calendarTypes: [],
  events: [],
  isModalOpen: false,
  isDutyModalOpen: false,
  isTaskModalOpen: false,
  isHpModalOpen: false,
  isCalendarMeetingEventModalOpen: false,
  isCalendarAppointmentEventModalOpen: false,
  selectedEventId: null,
  selectedEvent: null,
  selectedRange: null,
};

const handleEventData = (event, isAvailableToEdit) => {
  if (isAvailableToEdit && !isAvailableToEdit(event)) {
    event.editable = false;
  }
  if (event.calendar.color) {
    event.color = event.calendar.color;
  }
  if (event.type === 'task') {
    event.durationEditable = false;
  }
  if (event.type === 'training') {
    event.overlap = false;
  }
  if (event?.calendar?.type === 'MEETING') {
    event.overlap = false;
  }
  if (event?.calendar?.type === 'TRAINING') {
    event.overlap = false;
    event.durationEditable = false;
    event.editable = false;
  }
  return event;
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { data, isAvailableToEdit } = action.payload;
      const timestempEvents = [];
      data.forEach((event) => {
        const handledEvent = handleEventData(event, isAvailableToEdit);
        timestempEvents.push(handledEvent);
      });
      state.events = timestempEvents;
    },
    getCalendars(state, action) {
      const calendars = action.payload;
      state.calendars = calendars;
    },
    getCalendarTypes(state, action) {
      state.calendarTypes = action.payload;
    },
    addEvent(state, action) {
      if (action?.payload) {
        const event = action.payload;
        state.events.push(event);
      }
    },
    createEvent(state, action) {
      const event = action.payload;
      const handledEvent = handleEventData(event);
      state.events.push(handledEvent);
    },
    selectEvent(state, action) {
      // const { data, type } = action.payload;
      const { data } = action.payload;
      const { id, type } = data;
      if (type === 'duty') {
        state.isDutyModalOpen = true;
      } else if (type === 'task') {
        state.isTaskModalOpen = true;
      } else if (type === 'hp') {
        state.isHpModalOpen = true;
      } else if (type === 'HP') {
        state.isHpModalOpen = true;
      } else if (type === 'meeting') {
        state.isCalendarMeetingEventModalOpen = true;
      } else if (type === 'appointment') {
        state.isCalendarAppointmentEventModalOpen = true;
      } else {
        state.isModalOpen = true;
      }
      state.selectedEventId = id;
      state.selectedEvent = data;
    },
    updateEvent(state, action) {
      const event = action.payload;
      const handledEvent = handleEventData(event);
      state.events = state.events.map((_event) => {
        if (_event.id === handledEvent.id) {
          return handledEvent;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      state.events = state.events.filter((event) => event.id !== action.payload);
    },
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isModalOpen = true;

      state.selectedRange = {
        start,
        end
      };
    },

    selectCallEventRange(state, action) {
      const { start, end, meeting, appointment } = action.payload;
      if (meeting) {
        state.isCalendarMeetingEventModalOpen = true;
      } else if (appointment) {
        state.isCalendarAppointmentEventModalOpen = true;
      } else {
        state.isModalOpen = true;
      }
      state.selectedRange = {
        start,
        end
      };
    },

    selectHpEventRange(state, action) {
      const { start, end } = action.payload;
      state.isHpModalOpen = true;

      state.selectedRange = {
        start,
        end
      };
    },

    openModal(state, action) {
      if (action.payload === 'duty') {
        state.isDutyModalOpen = true;
      } else if (action.payload === 'task') {
        state.isTaskModalOpen = true;
      } else if (action.payload === 'hp') {
        state.isHpModalOpen = true;
      } else if (action.payload === 'meeting') {
        state.isCalendarMeetingEventModalOpen = true;
      } else if (action.payload === 'appointment') {
        state.isCalendarAppointmentEventModalOpen = true;
      } else {
        state.isModalOpen = true;
      }
    },

    closeModal(state) {
      state.isModalOpen = false;
      state.isDutyModalOpen = false;
      state.isTaskModalOpen = false;
      state.isHpModalOpen = false;
      state.isCalendarMeetingEventModalOpen = false;
      state.isCalendarAppointmentEventModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    selectEventId(state, action) {
      state.selectedEventId = action.payload;
    },
    clearEventId(state) {
      state.selectedEventId = null;
    }
  }
});

export const { reducer } = slice;

// const getEndpontByType = (type) => {
//   if (type === 'task') {
//     return 'tasks';
//   }
//   return 'events';
// };

/* eslint-disable-next-line */
export const getEvents = (dates, filters) => async (dispatch) => {
  const { start, end } = dates;
  await axiosConfig.get(
    `/events?from=${start}&to=${end}`
  ).then((response) => {
    dispatch(slice.actions.getEvents(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az események betöltése sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const searchEvents = (dates, filters, isAvailableToEdit) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'SEARCH_CALENDAR' }));
  const data = { ...dates, ...filters };
  // const { start, end } = dates;
  await axiosConfig.post(
    'events/search',
    data
  ).then((response) => {
    const data = response.data
    dispatch(slice.actions.getEvents({ data, isAvailableToEdit }));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az események betöltése sikertelen');
    }
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'SEARCH_CALENDAR' }));
  });
};

export const getCalendars = () => async (dispatch) => {
  await axiosConfig.get(
    '/calendars',
  ).then((response) => {
    dispatch(slice.actions.getCalendars(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az kalendárok betöltése sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const getCalendarTypes = () => async (dispatch) => {
  await axiosConfig.get(
    '/calendars/types',
  ).then((response) => {
    dispatch(slice.actions.getCalendarTypes(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az kalendárok betöltése sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const searchCalendars = (filters) => async (dispatch) => { // NOTE: for exmaple officeManager can see the users calendars, which can be training or personal for example, this filters is used for this
  dispatch(toggleLoading({ type: 'GET_CALENDARS' }));
  const { calendarTypes } = filters || {};
  await axiosConfig.post(
    '/calendars/search',
    { types: calendarTypes }
  ).then((response) => {
    dispatch(slice.actions.getCalendars(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az kalendárok betöltése sikertelen');
    }
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_CALENDARS' }));
  });
};

export const createEvent = (data) => async (dispatch) => {
  await axiosConfig.post(
    '/events',
    data
  ).then((response) => {
    // dispatch(slice.actions.selectEventId(response.data.id));
    dispatch(slice.actions.createEvent(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az esemény létrhozása sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const createEventAndCall = (eventData, callData) => async (dispatch) => {
  await axiosConfig.post(
    '/events',
    eventData
  ).then((response) => {
    // dispatch(slice.actions.selectEventId(response.data.id));
    dispatch(slice.actions.createEvent(response.data));
    dispatch(saveCall(callData, response.data.id));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az esemény létrhozása sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const createDuty = (data) => async (dispatch) => {
  await axiosConfig.post(
    '/duties',
    data
  ).then((response) => {
    dispatch(slice.actions.createEvent(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az esemény létrhozása sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const createTaskEvent = (data) => async (dispatch) => {
  await axiosConfig.post(
    '/tasks',
    data
  ).then((response) => {
    dispatch(slice.actions.createEvent(response.data));
  }).catch((error) => {
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az esemény létrhozása sikertelen');
    }
  }).then(() => {
    // always executed
  });
};

export const selectEvent = (eventId) => async (dispatch) => {
  await axiosConfig.get(
    // `/${getEndpontByType(type)}/${eventId}`
    `/events/${eventId}`
  ).then((response) => {
    // handle success
    // const { data } = response;
    dispatch(slice.actions.selectEvent(response));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a naptár esemény lekérésekor');
    console.error(error);
  }).then(() => {
    // always executed
    // dispatch(toggleLoading({ type: 'GET_SELECTIP' }));
  });
};

export const updateEvent = (eventId, update) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_EVENT' }));
  await axiosConfig.put(
    `/events/${eventId}`,
    update
  ).then((response) => {
    dispatch(slice.actions.updateEvent(response.data));
    toast.success('Naptár esemény sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a naptár esemény módosításakor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_EVENT' }));
  });
};

export const updateTaskEvent = (eventId, update) => async (dispatch) => {
  await axiosConfig.put(
    `/tasks/${eventId}`,
    update
  ).then((response) => {
    dispatch(slice.actions.updateEvent(response.data));
    toast.success('Naptár esemény sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a naptár esemény módosításakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const deleteEvent = (eventId) => async (dispatch) => {
  await axiosConfig.delete(
    `/events/${eventId}`
  ).then(() => {
    dispatch(slice.actions.deleteEvent(eventId));
    toast.success('Esemény sikeresen törölve');
  }).catch((error) => {
    toast.error('Hiba az esemény törlésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const deleteTaskEvent = (eventId) => async (dispatch) => {
  await axiosConfig.delete(
    `/tasks/${eventId}`
  ).then(() => {
    dispatch(slice.actions.deleteEvent(eventId));
    toast.success('Esemény sikeresen törölve');
  }).catch((error) => {
    toast.error('Hiba az esemény törlésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const createHpEvent = (data) => async (dispatch) => {
  await axiosConfig.post(
    '/presentations',
    data
  ).then((response) => {
    openInNewTab(`${REACT_APP_FILE_URL}${response.data.statement.originalFileSource}`);
    dispatch(slice.actions.addEvent(response.data));
    toast.success('Sikeres HP generálás');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a HP generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const clearCalendars = () => (dispatch) => {
  dispatch(slice.actions.getCalendars([]));
};

export const clearCalendarTypes = () => (dispatch) => {
  dispatch(slice.actions.getCalendarTypes([]));
};

export const selectRange = (start, end, defaultRangeType, meeting, appointment) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end, defaultRangeType, meeting, appointment }));
};

export const selectCallEventRange = (start, end, meeting, appointment) => (dispatch) => {
  dispatch(slice.actions.selectCallEventRange({ start, end, meeting, appointment }));
};

export const selectHpEventRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectHpEventRange({ start, end }));
};

export const openModal = (type) => (dispatch) => {
  dispatch(slice.actions.openModal(type));
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const clearEventId = () => (dispatch) => {
  dispatch(slice.actions.clearEventId());
};

export default slice;

// export const getEvents = (filters) => async (dispatch) => {
//   const data = await calendarApi.getEvents(filters);

//   dispatch(slice.actions.getEvents(data, filters));
// };

// export const updateEvent = (eventId, update) => async (dispatch) => {
//   const data = await calendarApi.updateEvent({
//     eventId,
//     update
//   });

//   dispatch(slice.actions.updateEvent(data));
// };

// export const selectEvent = (eventId, type) => async (dispatch) => {
//   dispatch(slice.actions.selectEvent({ eventId, type }));
// };

// export const deleteEvent = (eventId) => async (dispatch) => {
//   await calendarApi.deleteEvent(eventId);

//   dispatch(slice.actions.deleteEvent(eventId));
// };
