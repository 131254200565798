import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
// import { officeApi } from '../__fakeApi__/officeApi';

const initialState = {
  offices: [],
  officers: [],
  officeGroups: [],
  simplifiedOffices: [],
  isModalOpen: false,
  selectedOfficeId: null,
  address: {
    lon: null,
    lat: null,
    city: '',
    county: '',
    parcelNumber: null,
    postalCode: null,
  },
  thirdPartyConnections: null,
};

const slice = createSlice({
  name: 'office',
  initialState,
  reducers: {
    getOffices(state, action) {
      state.offices = action.payload;
    },
    getOfficers(state, action) {
      state.officers = action.payload;
    },
    getOfficeGroups(state, action) {
      state.officeGroups = action.payload;
    },
    getSimplifiedOffices(state, action) {
      state.simplifiedOffices = action.payload;
    },
    setPosition(state, action) {
      if (action.payload) {
        state.address.lon = action.payload.data2.data[0]?.lon;
        state.address.lat = action.payload.data2.data[0]?.lat;
        if (action.payload.variant === 'postalCode') {
          state.address.postalCode = action.payload.data2.data[0]?.postalCode;
        }
        state.address.city = action.payload.data2.data[0]?.city;
        if (action.payload.data2.data[0]?.county) {
          state.address.county = action.payload.data2.data[0].county?.replace(' megye', ' vármegye');
        }
        state.address.parcelNumber = action.payload.data2.data[0]?.parcelNumber;
      }
    },
    clearAddress(state, action) {
      state.address = action.payload;
    },
    createOffice(state, action) {
      state.offices.push(action.payload);
    },
    selectOffice(state, action) {
      const { officeId } = action.payload;

      state.selectedOfficeId = officeId;
      state.isModalOpen = true;
    },
    updateOffice(state, action) {
      const office = action.payload;

      state.offices = state.offices.map((_office) => {
        if (_office.id === office.id) {
          return office;
        }

        return _office;
      });
    },
    updateOfficeVideo(state, action) {
      const video = action.payload[0];
      const id = action.payload[1];
      state.offices = state.offices.map(((_office) => {
        if (_office.id === id) {
          _office.videos[0] = video;
          return _office;
        }
        return _office;
      }));
    },
    deleteOfficeVideo(state, action) {
      const id = action.payload;
      state.offices = state.offices.map(((_office) => {
        if (_office.id === id) {
          _office.videos = [];
          return _office;
        }
        return _office;
      }));
    },
    updateOfficeImage(state, action) {
      const image = action.payload[0];
      const id = action.payload[1];
      state.offices = state.offices.map(((_office) => {
        if (_office.id === id) {
          _office.images[0] = image;
          return _office;
        }
        return _office;
      }));
    },
    deleteOffice(state, action) {
      state.offices = state.offices.filter((office) => office.id !== action.payload);
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedOfficeId = null;
    },
    getOfficeSync(state, action) {
      state.thirdPartyConnections = action.payload;
    }
  }
});

export const { reducer } = slice;

// GET OFFICES

export const getOffices = (filters) => async (dispatch) => {
  await axiosConfig.get(
    '/offices'
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getOffices(response.data.data, filters));
  }).catch((error) => {
    // handle error
    toast.error('Az irodák betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const addOfficeVideo = (officeId, video) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'OFFICEVIDEO' }));
  await axiosConfig.post(
    `/offices/${officeId}/videos`,
    video
  ).then((response) => {
    // handle success
    dispatch(slice.actions.updateOfficeVideo([response.data, officeId]));
    toast.success('Az iroda videója sikeresen feltöltve');
  }).catch((error) => {
    // handle error
    toast.error('Az iroda videója nem lett feltöltve');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'OFFICEVIDEO' }));
    // always executed
  });
};

export const deleteOfficeVideo = (officeId, videoId) => async (dispatch) => {
  await axiosConfig.delete(
    `/offices/${officeId}/videos/${videoId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.deleteOfficeVideo(officeId));
    toast.success('Az iroda videója sikeresen törölve');
  }).catch((error) => {
    // handle error
    toast.error('Az iroda videója nem lett törölve');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET OFFICES HANDLERS

export const getOfficers = (id) => async (dispatch) => { // TODO: test it
  dispatch(toggleLoading({ type: 'GET_OFFICERS' }));
  await axiosConfig.get(
    `/offices/${id}/officers`
  ).then((response) => {
    // handle success
    // console.log('officers response', response);
    dispatch(slice.actions.getOfficers(response.data));
  }).catch((error) => {
    // handle error
    // toast.error('Az irodák betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_OFFICERS' }));
  });
};

// GET OFFICE GROUPS

export const getOfficeGroups = () => async (dispatch) => {
  await axiosConfig.get(
    '/offices/officegroups'
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getOfficeGroups(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Az irodák csoprtok betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET SIMPLIFIED OFFICES

export const getSimplifiedOffices = (filters) => async (dispatch) => {
  await axiosConfig.get(
    '/offices/simple'
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getSimplifiedOffices(response.data.data, filters));
  }).catch((error) => {
    // handle error
    toast.error('Az iroda opciók betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getFilteredSimpleOffices = (filters) => async (dispatch) => {
  const { name = null, isAvailableOnline = null, onlyUser = null } = filters || {};
  await axiosConfig.post(
    '/offices/simple',
    { name, isAvailableOnline, onlyUser }
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getSimplifiedOffices(response.data.data, filters));
  }).catch((error) => {
    // handle error
    toast.error('Az iroda opciók betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// set position

const getVariant = (variant, data) => {
  switch (variant) {
    case 'postalCode':
      return `/addresses/postal/${data.postalCode}`;
    case 'city':
      return `/addresses/city/${data.city}`;
    default:
      return '';
  }
};
export const getAdress = (data, variant) => async (dispatch) => {
  await axiosConfig.get(
    getVariant(variant, data.address)
  ).then((response) => {
    const data2 = response.data;
    dispatch(slice.actions.setPosition({ data2, variant }));
  }).catch((error) => {
    // handle error
    // toast.error('Az ingatlanok betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const clearAddress = () => async (dispatch) => {
  const data = {
    lon: null,
    lat: null,
    city: '',
    county: '',
    parcelNumber: null,
    postalCode: null,
  };
  dispatch(slice.actions.clearAddress(data));
};

// CREATE NEW OFFICE

export const createOffice = (data, canChange) => async (dispatch) => {
  await axiosConfig.post(
    '/offices',
    data
  ).then((response) => {
    // handle success
    dispatch(slice.actions.createOffice(response.data));
    toast.success('Új iroda sikeresen létrehozva!');
    if (canChange) {
      axiosConfig.post(
        `offices/${response.data.id}/images`,
        data.image
      ).then((response2) => {
        dispatch(slice.actions.updateOfficeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen feltöltve');
      }).catch((error) => {
        toast.error('Hiba kép feltöltésekor ');
        console.error(error);
      }).then(() => {
      });
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba az iroda létrehozásakor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET THE DETAILS OF A SELECTED OFFICE

export const selectOffice = (officeId, type) => async (dispatch) => {
  await axiosConfig.get(
    `/offices/${officeId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.selectOffice({ officeId, type }));
  }).catch((error) => {
    // handle error
    toast.error('Hiba az iroda adatainak lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// UPDATE SELECTED OFFICE

export const updateOffice = (officeId, update, canChange, imgId) => async (dispatch) => {
  await axiosConfig.put(
    `/offices/${officeId}`,
    {
      id: officeId,
      ...update
    }
  ).then((response) => {
    // handle success
    // console.log(response);
    dispatch(slice.actions.updateOffice(response.data));
    if (update.image && !imgId && canChange) {
      axiosConfig.post(
        `offices/${response.data.id}/images`,
        update.image
      ).then((response2) => {
        dispatch(slice.actions.updateOfficeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen feltöltve');
      }).catch((error) => {
        toast.error('Hiba kép feltöltésekor '); // TODO: office form always update
        console.error(error);
      }).then(() => {
      });
    } else if (update?.image && imgId && canChange) {
      axiosConfig.put(
        `offices/${response.data.id}/images/${imgId}`,
        update.image
      ).then((response2) => {
        dispatch(slice.actions.updateOfficeImage([response2.data, response.data.id]));
        toast.success('Kép sikeresen módosítva');
      }).catch((error) => {
        toast.error('Hiba kép módosításakor '); // TODO: office form always update
        console.error(error);
      }).then(() => {
      });
    }
    toast.success('Iroda sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// DELETE SELECTED OFFICE IMAGE

export const deleteOfficeImage = (officeId, imageId) => async (dispatch) => {
  await axiosConfig.delete(
    `/offices/${officeId}/images/${imageId}`
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    dispatch(slice.actions.updateOfficeImage([imageId, officeId]));
    toast.success('Kép sikeresen törölve!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a törlés végrehajtásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// DELETE SELECTED OFFICE

export const deleteOffice = (officeId) => async (dispatch) => {
  await axiosConfig.delete(
    `/offices/${officeId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.deleteOffice(officeId));
    toast.success('Iroda sikeresen törölve!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a törlés végrehajtásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getOfficeSync = (office) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'OFFICE_SYNC' }));
  await axiosConfig.get(
    `offices/${office}/sync`,
  ).then((response) => {
    dispatch(slice.actions.getOfficeSync(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a hírdetőoldalak betöltése során');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'OFFICE_SYNC' }));
  });
};

export const officeSync = (officeId, data, handleOpenSnakbar, handleOpenErrorSnakbar) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  await axiosConfig.put(
    `/offices/${officeId}/sync`,
    data.data
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // handle success
    // dispatch(slice.actions.selectProperty(response.data));
    // dispatch(slice.actions.updateConfig(response.data));
    handleOpenSnakbar();
    // toast.success('Iroda sikeresen módosítva');
  }).catch((error) => {
    // handle error
    handleOpenErrorSnakbar();
    // toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  });
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

// __fakeApi__/officeApi

// export const getOffices = (filters) => async (dispatch) => {
//   const data = await officeApi.getOffices(filters);

//   dispatch(slice.actions.getOffices(data, filters));
// };

// export const createOffice = (createData) => async (dispatch) => {
//   const data = await officeApi.createOffice(createData);

//   dispatch(slice.actions.createOffice(data));
// };

// export const selectOffice = (officeId, type) => async (dispatch) => {
//   dispatch(slice.actions.selectOffice({ officeId, type }));
// };

// export const updateOffice = (officeId, update) => async (dispatch) => {
//   const data = await officeApi.updateOffice({
//     officeId,
//     update
//   });

//   dispatch(slice.actions.updateOffice(data));
// };

// export const deleteOffice = (officeId) => async (dispatch) => {
//   await officeApi.deleteOffice(officeId);

//   dispatch(slice.actions.deleteOffice(officeId));
// };

export default slice;
