import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import TransferList from '../../TransferList';

const MentoredModal = ({ mentoreds, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Munkatársak kezelése
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 3 }}>
          <TransferList
            participants={mentoreds}
            leftText="Gyakornokok"
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            Mégsem
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
          >
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MentoredModal.propTypes = {
  mentoreds: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default MentoredModal;
