/* eslint-disable no-unused-vars */
import { Typography } from '@material-ui/core';
import { format, toDate } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import Label from '../components/Label';
import CheckIcon from '@mui/icons-material/Check';

export const findMyFolder = (folder, action) => {
  folder.folders.forEach((subFolder) => {
    if (subFolder.id === action.payload.id) {
      return subFolder.folders.push(action.payload.data);
    }
    if (subFolder.folders.length > 0) {
      findMyFolder(subFolder, action);
    }
    return null;
  });
};

export const getStatusColorTraining = (applicantStatus) => {
  const map = {
    started: 'warning.main',
    passed: 'success.main',
    // 'not-started': 'info.main',
    // submitted: 'info.main',
    failed: 'error.main'
  };
  return map[applicantStatus];
};

export const getStatusLabelTraining = (applicantStatus) => {
  const map = {
    started: {
      color: 'warning',
      text: 'Folyamatban'
    },
    passed: {
      color: 'success',
      text: 'Sikeres vizsga'
    },
    'not-started': {
      color: 'info',
      text: 'Nem kezdte el'
    },
    submitted: {
      color: 'info',
      text: 'Beadva'
    },
    failed: {
      color: 'error',
      text: 'Sikertelen vizsga'
    }
  };

  const { text, color } = map[applicantStatus];
  return (
    <Label
      color={color}
      sx={{
        height: '25px',
        alignItems: 'center',
      }}
    >
      <CheckIcon
        sx={{
          opacity: 1,
          // color: 'success.main',
          display: applicantStatus === 'passed' ? 'block' : 'none',
          mr: 0.5,
          height: '20px',
        }}
      />
      {text}
    </Label>
  );
};

export const hintStates = [
  { label: 'Elfogadva', id: 1 },
  { label: 'Létrehozva', id: 2 },
  { label: 'Kiosztva', id: 3 },
  { label: 'Feldolgozás alatt', id: 4 },
  { label: 'Behozva', id: 5 },
  { label: 'Eladva', id: 6 },
  { label: 'Kifizetve', id: 7 },
  { label: 'Elutasítva', id: 8 },
];

// export const getHintState = (hint) => {
//   hintStates.map((state) => {
//     if (hint === state.id) {
//       console.log(state.label, hint);
//       return state;
//     }
//     return 'Nincs megadva';
//   });
// };

export const customerTypeOption = (index) => {
  switch (index) {
    case 1:
      return 'Természetes személy';
    case 2:
      return 'Jogi személy';
    case 3:
      return 'Meghatalmazott';
    default:
      return 'Nincs megadva';
  }
};

export const suggestionType = (index) => {
  switch (index) {
    case 1:
      return 'Ingatlan';
    case 2:
      return 'Kereső ügyfél';
    default:
      return 'Nincs megadva';
  }
};

export const callResultPretty = (result) => {
  switch (result) {
    case 'SIKERES':
      return 'Sikeres';
    case 'VISSZAHIVASTKER':
      return 'Vissza hívást kér';
    case 'NEMVETTEFEL':
      return 'Nem vette fel';
    case 'ELUTASITOTT':
      return 'Elutatsított';
    case 'HIBASTELSZAM':
      return 'Hibás telefon szám';
    case 'INGATLANOS':
      return 'Ingatlanos';
    case 'MARBENTVAN':
      return 'Már bent van';
    case 'MASIKIRODANALVAN':
      return 'Másik irodánál van';
    case 'MEGGONDOLKODIK':
      return 'Még gondolkodik';
    case 'EGYEB':
      return 'Egyéb';
    case 'ELADVA':
      return 'Eladva';
    default:
      return 'Nincs megadva';
  }
};

export const hrStatusOptions = [
  {
    label: 'Új jelentkezés',
    value: 'new',
    status: 1,
  },
  {
    label: 'Felvéve',
    value: 'approved',
    status: 2
  },
  {
    label: 'Telefonon előszűrve',
    value: 'filtered',
    status: 3
  },
  {
    label: 'Interjú egyeztetve',
    value: 'interview',
    status: 4
  },
  {
    label: 'Elutasítva',
    value: 'declined',
    status: 5
  },
];

export const randomID = () => {
  const randID = Math.floor(Math.random() * 10000000);
  return randID;
};

export const isDividable = (number, divNumber) => {
  const res = (number % divNumber === 0);
  return res;
};

export const dateFormatter = (date) => {
  if (date) {
    const ret = (format(toDate(date), 'yyyy.MM.dd HH:mm', { locale: huLocale }));
    return ret;
  }
  return null;
};

export const selectedEmployeeSelector = async (state, id) => {
  const { employees } = state.employee;
  if (id) {
    return employees.data.find((_employee) => _employee.id === id);
  }

  return null;
  // const { selectedEmployee } = state.employee;
  // return selectedEmployee;
};
export const selectedClientSelector = (state, id) => {
  const { clients } = state.client;
  if (id) {
    return clients.data.find((_client) => _client.id === id);
  }
  return null;
};
export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

/* eslint-disable-next-line */
export const convertEmptyStringsToNull = (data) => {
  return data = Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key] === '' ? null : data[key]; return acc;
  }, {});
};

export const isOfficeManager = (roles) => (roles?.includes('ROLE_OFFICE_MANAGER') && !roles?.includes('ROLE_COMPANY_MANAGER')); /* COMPANY_MANAGER has also OFFICE_MANAGER role as well, therefore it should check it */

export const isOfficeManagerOrAbove = (roles) => (roles?.includes('ROLE_OFFICE_MANAGER')); /* COMPANY_MANAGER has also OFFICE_MANAGER role as well, therefore it should check it. */

export const isOfficeUser = (roles) => (roles?.includes('ROLE_OFFICE_USER') && !roles?.includes('ROLE_OFFICE_MANAGER'));

export const isCompanyManagerOrAbove = (roles) => (roles?.includes('ROLE_COMPANY_MANAGER'));

// export const isMentore = (roles) => (roles?.includes('ROLE_OFFICE_MENTOR'));

export const isCompanyManager = (roles) => (roles?.includes('ROLE_COMPANY_MANAGER'));

export const canIApproveProperty = (permissions) => (permissions?.includes('PRIVILEGE_PROPERTY_ENDORSE'));

export const canIArchiveProperty = (permissions) => (permissions?.includes('PRIVILEGE_PROPERTY_ARCHIVE'));

export const getTransactionTypeById = (id) => {
  if (id) {
    if (id === 1) {
      return {
        id: 1,
        label: 'Eladó'
      };
    }
    if (id === 2) {
      return {
        id: 2,
        label: 'Kiadó'
      };
    }
  }
  return null;
};

export const transactionTypeOptions = () => [
  {
    id: 1,
    label: 'Eladó'
  },
  {
    id: 2,
    label: 'Kiadó'
  }
];

export const makeClickableLink = (str) => {
  // Regular expression to match URLs in the string
  const urlRegex = /(?:^|[\s])([^\s]*\.[^\s]*\w)/g;

  // Split the string by URLs and wrap them in <a> tags
  const parts = str.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      const url = part.match(/^https?:\/\//i) ? part : `http://${part}`;
      return (
        <>
          {' '}
          <a
            key={part}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        </>
      );
    }
    return part;
  });

  // Join the parts back together into a single string
  return <>{parts}</>;
};

export const getPropertyCardLabel = (property, cardType, orderNumber) => {
  const map = {
    1: [ /* Családi ház */
      {
        label: 'Szobák száma',
        value: property?.numberOfHalfRooms ? `${property?.numberOfRooms}+${property?.numberOfHalfRooms}` : property?.numberOfRooms || ''
      },
      {
        label: 'Építési mód',
        value: property?.attributeOptions?.constructionType?.[0]?.name || ''
      },
      {
        label: 'Szintek száma',
        value: property?.numberOfFloors || ''
      },
      {
        label: 'Vízpart',
        value: property?.waterDistance || '',
        suffix: ' m'
      }
    ],
    2: [ /* Lakás kártya */
      {
        label: 'Szobák száma',
        value: property?.numberOfHalfRooms ? `${property?.numberOfRooms}+${property?.numberOfHalfRooms}` : property?.numberOfRooms || ''
      },
      {
        label: 'Emelet',
        value: property?.address?.floor || ''
      },
      {
        label: 'Építés éve',
        value: property?.buildYear || ''
      },
      {
        label: 'Vízpart',
        value: property?.waterDistance || '',
        suffix: ' m'
      }
    ],
    3: [ /* Építési telek */
      {
        label: 'Alapterület',
        value: property?.floorArea || '',
        suffix: ' m²'
      },
      {
        label: 'Telekterület',
        value: property?.landArea || '',
        suffix: ' m²'
      },
      {
        label: 'Vízpart',
        value: property?.waterDistance || '',
        suffix: ' m'
      }
    ],
    4: [ /* Zárt kert */
      {
        label: 'Szobák száma',
        value: property?.numberOfHalfRooms ? `${property?.numberOfRooms}+${property?.numberOfHalfRooms}` : property?.numberOfRooms || ''
      },
      {
        label: 'Építési mód',
        value: property?.attributeOptions?.constructionType?.[0]?.name || ''
      },
      {
        label: 'Szintek száma',
        value: property?.numberOfFloors || ''
      },
      {
        label: 'Vízpart',
        value: property?.waterDistance || '',
        suffix: ' m'
      }
    ],
    5: [ /* Nyaraló */
      {
        label: 'Szobák száma',
        value: property?.numberOfHalfRooms ? `${property?.numberOfRooms}+${property?.numberOfHalfRooms}` : property?.numberOfRooms || ''
      },
      {
        label: property?.numberOfFloors ? 'Emelet' : 'Építési mód',
        value: property?.numberOfFloors ? property?.address?.floor : property?.attributeOptions?.constructionType?.[0]?.name || ''
      },
      {
        label: property?.numberOfFloors ? 'Szintek száma' : 'Építés éve',
        value: property?.numberOfFloors ? property?.numberOfFloors : property?.buildYear || ''
      },
      {
        label: 'Vízpart',
        value: property?.waterDistance || '',
        suffix: ' m'
      }
    ],
    6: [ /* Garázs */
      {
        label: 'Építési mód',
        value: property?.attributeOptions?.constructionType?.[0]?.name || ''
      },
      {
        label: 'Villany',
        value: property?.attributeOptions?.electricity?.[0]?.name || ''
      }
    ]
  };

  const handleValues = () => {
    if (cardType && map[cardType][orderNumber]) {
      return map[cardType][orderNumber];
    }
    if (!cardType) {
      return map[1][orderNumber];
    }
    return { label: '', value: '' };
  };

  const { label, value, suffix } = handleValues();
  if (suffix) {
    return (
      <>
        <Typography
          color="textSecondary"
          variant="body2"
          mr={1}
        >
          {label}
        </Typography>
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          {value.toLocaleString('hu-HU')}
          {value && suffix}
        </Typography>
      </>
    );
  }
  return (
    <>
      <Typography
        color="textSecondary"
        variant="body2"
        mr={1}
      >
        {label}
      </Typography>
      <Typography
        color="textPrimary"
        variant="subtitle2"
      >
        {value}
      </Typography>
    </>
  );
};

export const copyToClipboard = (text) => {
  // const textField = document.createElement('textarea');
  // textField.innerText = text;
  // document.body.appendChild(textField);
  // textField.select();
  // document.execCommand('copy');
  // textField.remove();
  if (text) {
    navigator?.clipboard?.writeText(text);
  }
};

export const correctPhoneValue = (phone) => {
  const parsed = parsePhoneNumber(phone, { defaultCountry: 'HU' });
  if (parsed) {
    return parsed.formatInternational();
  }
  return phone;
};

export const isValidPhone = (phone) => {
  if (isValidPhoneNumber(phone)) {
    return true;
  }
  if (isValidPhoneNumber(`+36${phone}`)) {
    return true;
  }
  return false;
};

export const oTrainingStatusOptions = [
  {
    id: 1,
    label: 'Nem kezdte el',
    value: 'not-started',
    isChoosable: false
  },
  {
    id: 2,
    label: 'El kezdte',
    value: 'started',
    isChoosable: false
  },
  {
    id: 3,
    label: 'Sikeres vizsga',
    value: 'passed',
    isChoosable: true
  },
  {
    id: 4,
    label: 'Sikertelen vizsga',
    value: 'failed',
    isChoosable: true
  },
  {
    id: 5,
    label: 'Beküldött',
    value: 'submitted',
    isChoosable: false
  },
];

export const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

export const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

export const union = (a, b) => [...a, ...not(b, a)];

export const handleUrlChange = (name, value) => {
  const url = new URL(window.location);
  url.searchParams.set(name, value);
  window.history.pushState({}, '', url);
};

export const createDatetimeFromInputStr = (inputStr) => {
  if (!inputStr) {
    return null;
  }
  let fixedInputStr = inputStr;
  if (inputStr.length === 5) {
    // NOTE: we do not need  the last char which is a "/"
    fixedInputStr = inputStr.substring(0, 4);
  }
  let returnDate;
  try {
    // NOTE: Check if fixedInputStr contains only year (YYYY) or year and month (YYYY/MM)
    if (/^\d{4}(\/\d{2})?$/.test(fixedInputStr)) {
      fixedInputStr = fixedInputStr.replace(/(^\d{4})/, '$1/01/01'); // NOTE: Add month and day if not present
    }
    returnDate = new Date(fixedInputStr.replace(/(\d{4})\/?(\d{2})?\/?(\d{2})?/, '$1-$2-$3'));
  } catch (err) {
    returnDate = null;
  }
  return returnDate;
};

export const sortAccordingOrder = (array) => array.sort((a, b) => a?.ordering - b?.ordering);

export const objectStringifyAndEncodeForUrl = (obj) => {
  const str = JSON.stringify(obj);
  const encoded = encodeURIComponent(str);
  return encoded;
}

export const objectDecodeAndParseFromUrl = (str) => {
  const decoded = decodeURIComponent(str);
  const obj = JSON.parse(decoded);
  return obj;
};

export const createQueryString = (name, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(name, value);
  window.history.pushState({}, "", url);
};

export const deleteQueryString = (name) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(name);
  window.history.pushState({}, "", url);
};

export const getQueryString = (name) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
}
