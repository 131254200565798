import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router";
import { selectIp } from "../../slices/ip";
import { useDispatch, useSelector } from "../../store";
import ClientSearches from "../dashboard/client/ClientSearches";
import Logo from "../Logo";
import WebPresentationBrowseResults from "./WebPresentationBrowseResults";

const WebPresentationBrowse = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const { id = null } = urlParams;
  const { selectedIp } = useSelector((state) => state.ip);
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (id) {
      dispatch(selectIp(id));
    }
  }, []);

  return (
    <Container>
      <Grid container display="flex" justifyContent="center" mt={5}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Logo
            sx={{
              width: 400,
              height: 130,
            }}
          />
        </Grid>
        <Grid
          container
          spacing={5}
          paddingX={mobileDevice ? 0 : 10}
          mt={10}
          alignItems="center"
        >
          <Grid item md={7} xs={12}>
            <Typography variant="h4">
              {`Tisztelt ${selectedIp?.client?.name}!`}
            </Typography>
            <Typography variant="h6" fontWeight={400} mt={2}>
              Kollégáink a következő ingatlanokat szeretnék figyelmébe ajánlani.
              Görgessen lejjebb az ingatlanok megtekintéséhez. Amennyiben
              valamelyik ingatlan felkelti érdeklődését, kérjen helyszíni
              bemutatót, üzletkötőnk fel fogja keresni időpontegyeztetés
              céljából.
            </Typography>
          </Grid>
          <Grid item md={5} xs={12} display="flex" justifyContent="center">
            <Card
              variant="outlined"
              // sx={{ maxWidht: '100%' }}
              sx={{
                padding: 2,
              }}
            >
              <CardHeader
                title={
                  <>
                    <Box display="flex" alignItems="center">
                      <Grid>
                        <Avatar
                          // src={user?.images?.length > 0 ? `http://lidohome.aocsystem.com:8081${user?.images[0].source?.original}` : null}
                          // src='https://bo.lidohome.hu/public-images/iroda-1630/felhasznalok/56-v2/hortobagyi-eva.jpg'
                          // src="https://bo.lidohome.hu/public-images/iroda-1630/felhasznalok/195-v2/jpg.jpg"
                          sx={{
                            height: 150,
                            width: 150,
                            mr: 2,
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Typography
                          // variant="body1"
                          variant="h6"
                        >
                          {selectedIp?.user?.name}
                          {/* Csepregi Dominik */}
                        </Typography>
                        <Typography
                          // variant="body1"
                          variant="subtitle1"
                        >
                          {/* {user?.title || 'LidoHome'} */}
                          {/* Ingatlanértékesítő */}
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                }
              />
              <Divider />
              <CardContent>
                <Box sx={{ mt: 1 }}>
                  <Box display="flex">
                    <Typography variant="h6" sx={{ mr: 2 }}>
                      Telefon:
                    </Typography>
                    <Typography variant="body1" mt={0.4}>
                      {/* {selectedIp.user.phone ? (
                        user.phone.includes('*') ? user.phone : parsePhoneNumber(user?.phone).formatInternational()
                      ) : (
                        'Nincs megadva'
                      )} */}
                      {/* {user.phone || '+36 20 443 5564'} */}
                      +36 20 443 5564
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="h6" sx={{ mr: 3 }}>
                      E-mail:
                    </Typography>
                    <Typography variant="body1" mt={0.4}>
                      {/* {selectedIp.user.email || 'testemail@lidohome.hu'} */}
                      testemail@lidohome.hu
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              {/* <Box mt={1}>
                <Button
                  variant="contained"
                  fullWidth
                >
                  Helyszíni bemutatót igényelek!
                </Button>
              </Box> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={20}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography variant="h6">
            Önnek a megadott paraméterek alapján keresünk ingatlant
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ mt: 2 }}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          {selectedIp && selectedIp.client && selectedIp.client.id && (
            <ClientSearches clientId={selectedIp.client.id} />
          )}
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 10, marginBottom: 10 }} />
      <Box paddingTop={10} paddingBottom={10} mt={10}>
        {selectedIp?.properties.map((property) => (
          <Fragment key={property.id}>
            <WebPresentationBrowseResults property={property} />
            <Divider
              sx={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Fragment>
        ))}
        {/* <WebPresentationBrowseResults property={selectedIp?.properties[0]} />
        <WebPresentationBrowseResults property={selectedIp?.properties[0]} /> */}
      </Box>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-evenly"
        > */}
        <Grid item padding={1}>
          <Button variant="contained">
            Szeretném módosítani a keresési paramétereimet
          </Button>
        </Grid>
        <Grid item padding={1}>
          <Typography
            // variant="contained"
            // color="error"
            // component={RouterLink} // TODO: probaby these three will needed but find out later
            // to={`/properties/${search?.id}`}
            // target="_blank"
            variant="body2"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Nem szeretnék ilyen jellegű leveleket kapni
          </Typography>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} display="flex" justifyContent="center" padding={2}>
          <img src="\static\idaminta.png" alt="indaminta" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebPresentationBrowse;
