import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from '../../store';
import { closeModal } from '../../slices/client';
import CallWizard from '../dashboard/calls/wizard/CallWizard';
import { setPhone, setSelectedAddId } from '../../slices/call';
import { useEffect } from 'react';

const selectedCallSelector = (state) => {
  const { phoneSearches, selectedClientId } = state?.client;
  const clients = phoneSearches?.data;
  if (selectedClientId) {
    return clients?.find((_client) => _client?.id === selectedClientId);
  }

  return null;
};

const CallProcessModal = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const { isCallProcessModalOpen } = useSelector((state) => state.client);
  const selectedClient = useSelector(selectedCallSelector);

  const handleModalClose = (_, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    dispatch(closeModal());
    // TODO find out that close the contentSearch component is needed or not
    if (handleClose) {
      handleClose();
      dispatch(setPhone(null));
      dispatch(setSelectedAddId(null));
    }
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setPhone(null));
      dispatch(setSelectedAddId(null));
    };
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        disableEscapeKeyDown
        onClose={handleModalClose}
        open={isCallProcessModalOpen}
        maxWidth="md"
      >
        {isCallProcessModalOpen && (
          <CallWizard
            client={selectedClient}
            handleModalClose={handleModalClose}
          />
        )}
      </Dialog>
    </>
  );
};

CallProcessModal.propTypes = {
  handleClose: PropTypes.func,
};

export default CallProcessModal;
