import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
  Grid
} from '@material-ui/core';
import ArchiveIcon from '../../../icons/Archive';
import PencilAltIcon from '../../../icons/PencilAlt';
import TrashIcon from '../../../icons/Trash';
import { format } from 'date-fns';
import huLocale from 'date-fns/locale/hu';

const ClientBasicDetails = (props) => {
  const { client, ...other } = props;
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const getRiskLevel = () => {
    if (client?.riskRate === 1) {
      return 'Átlagos';
    }
    if (client?.riskRate === 2) {
      return 'Közép';
    }
    if (client?.riskRate === 3) {
      return 'Magas';
    }
    return 'Ismeretlen';
  };

  const getWeeklyStatistics = () => {
    if (client?.weeklyStatistics === 1) {
      return 'Igen, kér';
    }
    if (client?.weeklyStatistics === 2) {
      return 'Nem, nem kér vagy nincs e-mail címe';
    }
    return 'Ismeretlen';
  };

  const getIdCardType = () => {
    if (client?.idCardType === 1) {
      return 'Személyi Igazolvány';
    }
    if (client?.idCardType === 2) {
      return 'Jogosítvány';
    }
    if (client?.idCardType === 3) {
      return 'Útlevél';
    }
    return 'Ismeretlen';
  };
  const riskLevelLabel = getRiskLevel();
  const weklyStatisticLabel = getWeeklyStatistics();
  const idCardTypeLabel = getIdCardType();
  return (
    <Card {...other}>
      <CardContent>
        <div>
          <Box
            sx={{
              px: 3,
              py: 2
            }}
          >
            <Grid
              container
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              {client.legalType === 2 ? (
                <>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Grid
                      alignItems="flex-start"
                      container
                      justifyContent="space-between"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Adószám:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.taxNumber || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Cégjegyzékszám:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.companyRegister || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Grid
                      alignItems="flex-start"
                      container
                      justifyContent="space-between"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Kockázati besorolás:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {riskLevelLabel || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Heti statisztikát kér?
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {weklyStatisticLabel || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Grid
                      alignItems="flex-start"
                      container
                      justifyContent="space-between"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Anyja neve:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.motherName || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Születési hely:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.birthPlace || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Születési idő:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.birthDate ? format(client.birthDate, 'yyyy MMMM dd', { locale: huLocale }) : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Grid
                      alignItems="flex-start"
                      container
                      justifyContent="space-between"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Cím:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2" // TODO: get formattedAddress or address and concat the values
                        >
                          {client?.address?.formattedAddress ? client?.address?.formattedAddress : `${client?.address?.postalCode || ''} ${client?.address?.city || ''}${client?.address?.street ? ',' : ''} ${client?.address?.street || ''} ${client?.address?.streetNumber || ''}${client?.address?.streetNumber ? '.' : ''} ${client?.address?.floor || ''}${client?.address?.floor && client?.address?.door ? '/' : ''}${client?.address?.door || ''}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Azonosító okmány típusa:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {idCardTypeLabel || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Azonosító okmány száma:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {client.idCardNumber || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                  >
                    <Grid
                      alignItems="flex-start"
                      container
                      justifyContent="space-between"
                      flexDirection="column"
                      spacing={3}
                    >
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Kockázati besorolás:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {riskLevelLabel || ''}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Heti statisztikát kér?
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {weklyStatisticLabel || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </div>
      </CardContent>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 250
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuItem divider>
          <ListItemIcon>
            <PencilAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Adatlap" />
        </MenuItem>
        <MenuItem divider>
          <ListItemIcon>
            <TrashIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Szerkesztés" />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Archiválás" />
        </MenuItem>
      </Menu>
    </Card>
  );
};

ClientBasicDetails.propTypes = {
  client: PropTypes.object.isRequired
};

export default ClientBasicDetails;
