import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  Typography
  // Button
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import UsersIcon from '../../icons/Users';
import HomeIcon from '../../icons/Home';
import DotsHorizontalIcon from '../../icons/DotsHorizontal';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { isOfficeManagerOrAbove } from '../../utils/helperFunctions';
// import { selectedEmployeeSelector } from '../../utils/helperFunctions';
// import { useSelector } from '../../store';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChatAltIcon from '../../icons/ChatAlt';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
// import UserIcon from '../../icons/User';

const { REACT_APP_FILE_URL } = process.env;

// const sections = [
//   {
//     title: 'Lido',
//     items: [
//       {
//         title: 'Kezdőlap',
//         icon: <HomeIcon fontSize="small" />,
//         path: '/',
//       },
//       {
//         title: 'Ingatlanok',
//         icon: <ClipboardListIcon fontSize="small" />,
//         path: '/properties',
//       },
//       {
//         title: 'Ügyfelek',
//         icon: <UsersIcon fontSize="small" />,
//         children: [
//           {
//             title: 'Megbízó ügyfelek',
//             path: '/clients/sell'
//           },
//           {
//             title: 'Kereső ügyfelek',
//             path: '/clients/buy'
//           },
//           {
//             title: 'Összes ügyfél',
//             path: '/clients/browse'
//           }
//         ]
//       },
//       {
//         title: 'Dokumentumtár',
//         path: '/documents',
//         icon: <FolderOpenIcon fontSize="small" />,
//       },
//       {
//         title: 'Irodai Prezentáció',
//         icon: <ChartSquareBarIcon fontSize="small" />,
//         path: '/ip',
//       },
//       {
//         title: 'További',
//         icon: <DotsHorizontalIcon fontSize="small" />,
//         children: [
//           {
//             title: 'Feladatok',
//             path: '/tasks',
//             // disabled: true
//           },
//           {
//             title: 'Generálás',
//             path: '/generation/new',
//           },
//           {
//             title: 'HR',
//             path: '/hr'
//           },
//           {
//             title: 'Híváslista',
//             path: '/calls'
//           },
//           {
//             title: 'Közvetítési nyilatkozatok',
//             path: '/statements'
//           },
//           {
//             title: 'Ingatlanok jóváhagyása',
//             path: '/properties-unapproved'
//           },
//           {
//             title: 'Saját képzéseim',
//             path: '/owntrainings',
//             // disabled: true
//           },
//           {
//             title: 'Képzés',
//             path: '/trainings',
//             disabled: isOfficeManagerOrAbove(user.roles)
//           },
//           {
//             title: 'Magánhirdetések',
//             path: '/invoices',
//             // disabled: true
//           },
//           {
//             title: 'Projektek',
//             path: '/projects/browse'
//           },
//           {
//             title: 'Tipp adás',
//             path: '/add-hint',
//             // disabled: true
//           },
//           {
//             title: 'Értékelés',
//             path: '/ratings',
//             // disabled: true
//           },
//           {
//             title: 'Blog',
//             path: '/blog',
//             // disabled: true
//           },
//           // {
//           //   title: 'Tipp modul',
//           //   path: '/hint'
//           // }
//         ]
//       },
// {
//   title: 'Statisztika',
//   icon: <ChartPieIcon fontSize="small" />,
//   path: '/kanban',
// },
// {
//   title: 'Vállalkozás',
//   icon: <ClipboardListIcon fontSize="small" />,
//   path: '/calendar',
// }
//   ]
// },
// {
//   title: 'General',
//   items: [
//     {
//       title: 'Overview',
//       path: '/dashboard',
//       icon: <ChartSquareBarIcon fontSize="small" />
//     },
//     {
//       title: 'Analytics',
//       path: '/dashboard/analytics',
//       icon: <ChartPieIcon fontSize="small" />
//     },
//     {
//       title: 'Finance',
//       path: '/dashboard/finance',
//       icon: <ShoppingBagIcon fontSize="small" />
//     },
//     {
//       title: 'Account',
//       path: '/dashboard/account',
//       icon: <UserIcon fontSize="small" />
//     }
//   ]
// },
// {
//   title: 'Management',
//   items: [
//     {
//       title: 'Customers',
//       path: '/dashboard/customers',
//       icon: <UsersIcon fontSize="small" />,
//       children: [
//         {
//           title: 'List',
//           path: '/dashboard/customers'
//         },
//         {
//           title: 'Details',
//           path: '/dashboard/customers/1'
//         },
//         {
//           title: 'Edit',
//           path: '/dashboard/customers/1/edit'
//         }
//       ]
//     },
//     {
//       title: 'Products',
//       path: '/dashboard/products',
//       icon: <ShoppingCartIcon fontSize="small" />,
//       children: [
//         {
//           title: 'List',
//           path: '/dashboard/products'
//         },
//         {
//           title: 'Create',
//           path: '/dashboard/products/new'
//         }
//       ]
//     },
//     {
//       title: 'Orders',
//       icon: <FolderOpenIcon fontSize="small" />,
//       path: '/dashboard/orders',
//       children: [
//         {
//           title: 'List',
//           path: '/dashboard/orders'
//         },
//         {
//           title: 'Details',
//           path: '/dashboard/orders/1'
//         }
//       ]
//     },
//     {
//       title: 'Invoices',
//       path: '/dashboard/invoices',
//       icon: <ReceiptIcon fontSize="small" />,
//       children: [
//         {
//           title: 'List',
//           path: '/dashboard/invoices'
//         },
//         {
//           title: 'Details',
//           path: '/dashboard/invoices/1'
//         }
//       ]
//     }
//   ]
// },
// {
//   title: 'Platforms',
//   items: [
//     {
//       title: 'Projects',
//       path: '/dashboard/projects',
//       icon: <BriefcaseIcon fontSize="small" />,
//       children: [
//         {
//           title: 'Browse',
//           path: '/dashboard/projects/browse'
//         },
//         {
//           title: 'Details',
//           path: '/dashboard/projects/1'
//         },
//         {
//           title: 'Create',
//           path: '/dashboard/projects/new'
//         }
//       ]
//     },
//     {
//       title: 'Social',
//       path: '/dashboard/social',
//       icon: <ShareIcon fontSize="small" />,
//       children: [
//         {
//           title: 'Profile',
//           path: '/dashboard/social/profile'
//         },
//         {
//           title: 'Feed',
//           path: '/dashboard/social/feed'
//         }
//       ]
//     }
//   ]
// },
// {
//   title: 'Apps',
//   items: [
//     {
//       title: 'Kanban',
//       path: '/dashboard/kanban',
//       icon: <ClipboardListIcon fontSize="small" />
//     },
//     {
//       title: 'Mail',
//       path: '/dashboard/mail',
//       icon: <MailIcon fontSize="small" />
//     },
//     {
//       title: 'Chat',
//       path: '/dashboard/chat',
//       icon: <ChatAltIcon fontSize="small" />
//     },
//     {
//       title: 'Calendar',
//       path: '/dashboard/calendar',
//       icon: <CalendarIcon fontSize="small" />
//     }
//   ]
// }
// ];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const sections = [
    {
      title: 'Lido',
      items: [
        {
          title: 'Kezdőlap',
          icon: <HomeIcon fontSize="small" />,
          path: '/',
        },
        {
          title: 'Ingatlanok',
          icon: <ClipboardListIcon fontSize="small" />,
          path: '/properties',
        },
        {
          title: 'Ügyfelek',
          icon: <UsersIcon fontSize="small" />,
          children: [
            {
              title: 'Megbízó ügyfelek',
              path: '/clients/sell'
            },
            {
              title: 'Kereső ügyfelek',
              path: '/clients/buy'
            },
            {
              title: 'Összes ügyfél',
              path: '/clients/browse'
            }
          ]
        },
        {
          title: 'Dokumentumtár',
          path: '/documents',
          icon: <FolderOpenIcon fontSize="small" />,
        },
        {
          title: 'Irodai Prezentáció',
          icon: <ChartSquareBarIcon fontSize="small" />,
          path: '/ip',
        },
        {
          title: 'További',
          icon: <DotsHorizontalIcon fontSize="small" />,
          children: [
            {
              title: 'Feladatok',
              path: '/tasks',
              // disabled: true
            },
            {
              title: 'Generálás',
              path: '/generation/new',
            },
            {
              title: 'HR',
              path: '/hr'
            },
            {
              title: 'Híváslista',
              path: '/calls'
            },
            {
              title: 'Közvetítési nyilatkozatok',
              path: '/statements'
            },
            {
              title: 'Ingatlanok jóváhagyása',
              path: '/properties-unapproved'
            },
            {
              title: 'Saját képzéseim',
              path: '/owntrainings',
              // disabled: true
            },
            {
              title: 'Képzés',
              path: '/trainings/otrainings',
              disabled: user.isPartner || !isOfficeManagerOrAbove(user.roles)
            },
            {
              title: 'Magánhirdetések',
              path: '/invoices',
              // disabled: true
            },
            {
              title: 'Projektek',
              path: '/projects/browse',
              // disabled: !isOfficeManagerOrAbove(user.roles)
              disabled: false,
            },
            {
              title: 'Tipp adás',
              path: '/add-hint',
              // disabled: true
            },
            {
              title: 'Értékelés',
              path: '/ratings',
              disabled: !isOfficeManagerOrAbove(user?.roles)
            },
            {
              title: 'Blog',
              path: '/blog',
              disabled: !(!user.isPartner && user?.roles?.includes('ROLE_CONTENT_MANAGER'))
            },
            {
              title: 'Statisztikák',
              path: '/statistics',
              disabled: !isOfficeManagerOrAbove(user?.roles)
            },
            // {
            //   title: 'Tipp modul',
            //   path: '/hint'
            // }
          ]
        },
        // {
        //   title: 'Statisztika',
        //   icon: <ChartPieIcon fontSize="small" />,
        //   path: '/kanban',
        // },
        // {
        //   title: 'Vállalkozás',
        //   icon: <ClipboardListIcon fontSize="small" />,
        //   path: '/calendar',
        // }
      ]
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 50,
                width: 150
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            {/* <RouterLink to="/account"> */}
            {/* {console.log(user, 'userdataimage', user.images[0].source?.original)} */}
            <Avatar
              src={user?.images?.length > 0 ? `${REACT_APP_FILE_URL}${user?.images[0].source?.original}` : null}
              sx={{
                // cursor: 'pointer',
                height: 48,
                width: 48
              }}
            />
            {/* </RouterLink> */}
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {/* Your plan: */}
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              disabled={section.disabled}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        {/* <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  // if (lgUp) {
  //   return (
  //     <Drawer
  //       anchor="left"
  //       open
  //       PaperProps={{
  //         sx: {
  //           backgroundColor: 'background.paper',
  //           height: 'calc(100% - 64px) !important',
  //           top: '64px !Important',
  //           width: 280
  //         }
  //       }}
  //       variant="permanent"
  //     >
  //       {content}
  //     </Drawer>
  //   );
  // }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
