import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
// import Cookies from 'js-cookie'; // TODO remove comments

// const loggerMiddleware = (storeAPI) => (next) => (action) => { // FIXME: for test purposes
//   console.log('storeAPI', storeAPI);
//   console.log('dispatching', action);
//   const result = next(action);
//   console.log('next state', storeAPI.getState());
//   return result;
// };

// const checkTokenExpirationMiddleware = store => next => action => { // NOTE: get the expired date of the token, later on it could be usefull somewhere
//   const token = Cookies.get('token');
//   if (token) {
//     const payloadBase64 = token.split('.')[1];
//     const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
//     const decoded = JSON.parse(decodedJson)
//     const exp = decoded.exp;
//     const expired = (Date.now() >= exp * 1000)
//     console.log('expired', expired);
//     console.log(Math.floor((new Date).getTime() / 1000));
//     if (Math.floor((new Date).getTime() / 1000) >= 1658754720) {
//       next(action);
//       console.log('hello');
//       // Cookies.set('token', '');
//       Cookies.remove('token', { path: '' })
//       Cookies.set('user', JSON.stringify({
//         id: '',
//         name: '',
//         email: '',
//         avatar: '',
//         plan: ''
//       }));
//       window.location.reload(); // TODO test it
//     }
//   }
//   next(action);
// };

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware), // FIXME: for test purposes
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(checkTokenExpirationMiddleware), // FIXME: for test purposes
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
