import React, { Fragment } from "react";
import {
  Box,
  Button,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Label from "../../Label";
import PropTypes from "prop-types";
import { format, toDate } from "date-fns";
import huLocale from "date-fns/locale/hu";
import NumberFormatCustom from "../../NumberFormatCustom";
import { useDispatch, useSelector } from "../../../store";
import {
  searchProperty,
  setUsingPropertySearchEndpoint,
} from "../../../slices/property";
import {
  setLastPropertySearchData,
  updateInitialSearchParameters,
} from "../../../slices/search";
import { scrollToTop } from "../../../slices/modals";
import { objectStringifyAndEncodeForUrl } from "../../../utils/helperFunctions";

const searchNameDict = {
  city: "Város:",
  cities: "Települések:",
  locations: "Település:",
  floorAreaFrom: "Alapterület (-től):",
  floorAreaTo: "Alapterület (-ig):",
  floorLevelFrom: "Emelet (-től):",
  floorLevelTo: "Emelet (-ig):",
  landAreaFrom: "Telekterület (-től):",
  landAreaTo: "Telekterület (-ig):",
  northCoast: "Északi part:",
  southCoast: "Déli part:",
  roomCountFrom: "Szobák(-tól):",
  roomCountTo: "Szobák(-ig):",
  salePriceFrom: "Ár(-tól):",
  salePriceTo: "Ár(-ig):",
  waterDistanceFrom: "Vízparttól való távolság (-tól):",
  waterDistanceTo: "Vízparttól való távolság (-ig):",
  accessibility: "Akadálymentesítés:",
  cooling: "Klíma:",
  electricity: "Villany:",
  energyCertificate: "Energetikai tanúsítvány:",
  externalCondition: "Külső állapot:",
  furnishing: "Bútorozottság:",
  gas: "Gáz:",
  heating: "Fűtés:",
  internalCondition: "Belső állapot:",
  internet: "Internet:",
  parking: "Parkolás:",
  phone: "Telefon:",
  privateLabels: "Privát tulajdonságok:",
  propertyType: "Ingatlan típusa:",
  publicTags: "Nyilvános címkék:",
  polygon: "Poligon",
  circleCenter: "Kör",
  circleRadius: "Kör sugara méterben",
  sewer: "Csatorna:",
  transactionTypeId: "Tranzakció típusa:", // TODO: u will get different values then before
  tv: "TV:",
  view: "Kilátás:",
  water: "Víz:",
  created: "Létrehozva",
  modified: "Módosítva",
  constructionType: "Építési mód",
  residential: "Lakóingatlan típus",
  business: "Üzleti ingatlan típus",
  industrial: "Ipari ingatlan típus",
  agricultural: "Mezőgazdasági ingatlan típus",
  comfortLevel: "Komfort",
};

const labelColorsMap = {
  1: "success",
  0: "warning",
};

const CustomerSearchesRow = (props) => {
  const { search, handleModalOpen } = props;
  const dispatch = useDispatch();
  const { selectedClient } = useSelector((state) => state.client);

  const getStateSearchValue = (states) => {
    if (states?.length > 0) {
      return states?.map((state) => state.id || null);
    }
    return null;
  };
  const getCitiesString = (cities) => {
    const citiesArray = [];
    cities.forEach((city) => {
      citiesArray.push(city.name);
    });
    return citiesArray.join(",");
  };

  const getAttributeOptionIds = (attributeOptions) => {
    const ids = [];
    if (attributeOptions) {
      Object.keys(attributeOptions).forEach((key) => {
        attributeOptions[key].map((option) => ids.push(option.id));
      });
    }
    return ids;
  };

  const handleSearchProperties = (data) => {
    const dataResults = {
      onlyUser: data?.onlyUser || null,
      onlyOffice: data?.onlyOffice || null,
      // officeId: data?.office?.id || null,
      currency: data?.currency || "Ft",
      publicId: Number(data?.publicId) || null,
      street: data?.street || null,
      // clientName: data?.clientName || null,
      cities: data?.locations ? getCitiesString(data?.locations) : null,
      userId: data?.employee?.id || null,
      streetNumber: data?.streetNumber || null,
      isPublic: data?.isPublic || null,
      floorAreaFrom: Number(data?.floorAreaFrom) || null,
      floorAreaTo: Number(data.floorAreaTo) || null,
      landAreaFrom: Number(data.landAreaFrom) || null,
      landAreaTo: Number(data.landAreaTo) || null,
      salePriceFrom: Number(data.salePriceFrom) || null,
      salePriceTo: Number(data.salePriceTo) || null,
      roomCountFrom: Number(data.roomCountFrom) || null,
      roomCountTo: Number(data.roomCountTo) || null,
      waterDistanceFrom: Number(data.waterDistanceFrom) || null,
      waterToRailwayFrom: Number(data.waterToRailwayFrom) || null,
      waterToRailwayTo: Number(data.waterToRailwayTo) || null,
      waterDistanceTo: Number(data.waterDistanceTo) || null,
      floorLevelFrom: Number(data.floorLevelFrom) || null,
      floorLevelTo: Number(data.floorLevelTo) || null,
      parcelNumber: Number(data.parcelNumber) || null,
      northCoast: data.northCoast || null,
      southCoast: data.southCoast || null,
      circleCenter: data?.circleCenter?.coordinates || null,
      circleRadius: data?.circleRadius || null,
      polygon: data?.polygon?.coordinates[0] || null,
      // state: getStateSearchValue(data?.state),
      state: [3],
      transactionType: data?.transactionTypeId || null, // TODO: not send
      attributeOptionIds: getAttributeOptionIds(data?.attributeOptions),
    };
    data?.publicLabels?.forEach((element) => {
      dataResults?.attributeOptionIds.push(element.id);
    });
    data?.privateLabels?.forEach((element) => {
      dataResults?.attributeOptionIds.push(element.id);
    });
    dispatch(setUsingPropertySearchEndpoint(true));
    dispatch(setLastPropertySearchData(dataResults));
    dispatch(searchProperty(dataResults));
    return dataResults;
  };

  const getPropertyTypes = (data) => {
    const {
      residential = [],
      business = [],
      industrial = [],
      agricultural = [],
    } = data.attributeOptions;
    return [...residential, ...business, ...industrial, ...agricultural];
  };

  const updateFormikSearchParameters = () => {
    const formikParameters = {
      id: "",
      onlyUser: false,
      onlyOffice: false,
      balaton_north_coast: search?.northCoast || false,
      balaton_south_coast: search?.southCoast || false,
      // clientName: '',
      office: [],
      employee: [],
      currency: "Ft",
      publicId: "",
      paymentType: search?.transactionTypeId || "",
      propertyType: getPropertyTypes(search),
      location: "",
      cities: search?.locations || [],
      street: "",
      streetNumber: "",
      floorAreaMin: search?.floorAreaFrom || "",
      floorAreaMax: search?.floorAreaTo || "",
      landAreaMin: search?.landAreaFrom || "",
      landAreaMax: search?.landAreaTo || "",
      parcelNumber: "",
      minPrice: search?.salePriceFrom || "",
      maxPrice: search?.salePriceTo || "",
      numberOfRooms: "",
      numberOfRoomsFrom: search?.roomCountFrom || "",
      numberOfRoomsTo: search?.roomCountTo || "",
      numberOfFloorFrom: search?.floorLevelFrom || "",
      numberOfFloorTo: search?.floorLevelTo || "",
      floor: "",
      minWaterfront: search?.waterDistanceFrom || "",
      maxWaterfront: search?.waterDistanceTo || "",
      minWaterToRailway: "",
      maxWaterToRailway: "",
      electricity: search?.attributeOptions?.electricity
        ? search?.attributeOptions?.electricity
        : [],
      water: search?.attributeOptions?.water
        ? search?.attributeOptions?.water
        : [],
      gas: search?.attributeOptions?.gas ? search?.attributeOptions?.gas : [],
      sewer: search?.attributeOptions?.sewer
        ? search?.attributeOptions?.sewer
        : [],
      constructionType: search?.attributeOptions?.constructionType
        ? search?.attributeOptions?.constructionType[0].id
        : "",
      internalCondition: search?.attributeOptions?.internalCondition
        ? search?.attributeOptions?.internalCondition
        : [],
      externalCondition: search?.attributeOptions?.externalCondition
        ? search?.attributeOptions?.externalCondition
        : [],
      publicLabels: search?.attributeOptions?.publicTags
        ? search?.attributeOptions?.publicTags
        : [],
      privateLabels: search?.attributeOptions?.privateLabels
        ? search?.attributeOptions?.privateLabels
        : [],
      heating: search?.attributeOptions?.heating
        ? search?.attributeOptions?.heating
        : [],
      view: search?.attributeOptions?.view
        ? search?.attributeOptions?.view
        : [],
      parking: search?.attributeOptions?.parking
        ? search?.attributeOptions?.parking
        : [],
      accessibility: search?.attributeOptions?.accessibility
        ? search?.attributeOptions?.accessibility[0].id
        : "",
      furnished: search?.attributeOptions?.furnished
        ? search?.attributeOptions?.furnished[0].id
        : "",
      cooling: search?.attributeOptions?.cooling
        ? search?.attributeOptions?.cooling[0].id
        : "",
      phone: search?.attributeOptions?.phone
        ? search?.attributeOptions?.phone[0].id
        : "",
      energyCertificate: search?.attributeOptions?.energyCertificate
        ? search?.attributeOptions?.energyCertificate[0].id
        : "",
      tv: search?.attributeOptions?.tv
        ? search?.attributeOptions?.tv[0].id
        : "",
      internet: search?.attributeOptions?.internet
        ? search?.attributeOptions?.internet[0].id
        : "",
      comfortLevel: search?.attributeOptions?.comfortLevel
        ? search?.attributeOptions?.comfortLevel[0].id
        : "",
      state: [
        {
          id: 3,
          label: "Aktív",
          disable: false,
        },
      ],
      shapes: search?.circleCenter?.coordinates
        ? [search?.circleCenter?.coordinates, search?.circleRadius]
        : search?.polygon?.coordinates[0],
    };
    dispatch(updateInitialSearchParameters(formikParameters));
    return formikParameters;
  };

  const searchProperties = (event) => {
    event.stopPropagation();
    dispatch(scrollToTop());
    const dataResultsData = handleSearchProperties(search);
    const urlParamData = updateFormikSearchParameters();
    const dataResults = objectStringifyAndEncodeForUrl(dataResultsData);
    const urlParam = objectStringifyAndEncodeForUrl(urlParamData);
    const stringifySelectedClient = objectStringifyAndEncodeForUrl(selectedClient);
    window.open(`/properties?searchParams=${urlParam}&dataResults=${dataResults}&selectedClient=${stringifySelectedClient}`, "_blank");
  };
  return (
    <TableRow
      key={search?.id}
      hover
      onClick={(_) => {
        if (handleModalOpen) {
          handleModalOpen(_, search);
        }
      }}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    // sx={{ '&:hover': { backgroundColor: 'background.default', cursor: 'pointer' } }}
    >
      <TableCell>
        <Grid container>
          <Grid item sx={{ p: 1 }}>
            {search?.status === 1 ? (
              <Label color={labelColorsMap[search.status]}>aktív keresés</Label>
            ) : (
              <Label color={labelColorsMap[search.status || 0]}>
                inaktív keresés
              </Label>
            )}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid item sx={{ p: 1 }} display="flex">
            <Typography sx={{ mr: 1 }} color="textSecondary" variant="body2">
              Értékesítő:
            </Typography>
            <Typography color="textPrimary" variant="subtitle2">
              {search?.user?.name || "Nincs megadva"}
            </Typography>
          </Grid>
          <Button onClick={(e) => searchProperties(e)}>
            Ingatlanok kilistázása
          </Button>
        </Grid>
        <Grid container display="flex">
          {Object.entries(search).map(([keyName, keyValue]) => (
            <React.Fragment key={keyName}>
              {keyName !== "id" &&
                keyName !== "status" &&
                keyName !== "clientId" &&
                keyName !== "cities" &&
                keyName !== "externalKey" &&
                keyName !== "currency" &&
                (typeof search[keyName] !== "object" ||
                  keyName === "attributeOptions" ||
                  keyName === "polygon" ||
                  keyName === "circleCenter" ||
                  keyName === "locations") && (
                  <>
                    {keyValue && (
                      <>
                        {keyName === "attributeOptions" ? (
                          <>
                            {Object.entries(keyValue).map(([name, value]) => (
                              <Grid
                                item
                                key={name}
                                sx={{
                                  p: 1,
                                  m: 1,
                                  border: (theme) =>
                                    `1px solid ${theme.palette.divider}`,
                                  borderRadius: "10px",
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {searchNameDict[name]}
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {value.map((attribute, i, { length }) => (
                                    <React.Fragment key={attribute.id}>
                                      {i + 1 === length
                                        ? attribute.name
                                        : `${attribute.name}, `}
                                    </React.Fragment>
                                  ))}
                                </Typography>
                              </Grid>
                            ))}
                          </>
                        ) : (
                          <>
                            {keyName === "polygon" ||
                              keyName === "circleCenter" ? (
                              <Grid
                                item
                                sx={{
                                  p: 1,
                                  m: 1,
                                  border: (theme) =>
                                    `1px solid ${theme.palette.divider}`,
                                  borderRadius: "10px",
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  Forma:
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {searchNameDict[keyName]}
                                </Typography>
                              </Grid>
                            ) : (
                              <>
                                {keyName === "locations" ? (
                                  <>
                                    {keyValue.length > 0 && (
                                      <Grid
                                        item
                                        sx={{
                                          p: 1,
                                          m: 1,
                                          border: (theme) =>
                                            `1px solid ${theme.palette.divider}`,
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <Typography
                                          color="textSecondary"
                                          variant="body2"
                                        >
                                          {searchNameDict[keyName]}
                                        </Typography>
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle2"
                                        >
                                          {keyValue.map((value, index) => (
                                            <Fragment key={value.id}>
                                              {`${value.name}${value?.postalCode !== null
                                                ? "("
                                                : ""
                                                }${value?.postalCode !== null
                                                  ? value?.postalCode
                                                  : ""
                                                }${value?.postalCode !== null
                                                  ? ")"
                                                  : ""
                                                }${index === keyValue.length - 1
                                                  ? ""
                                                  : ", "
                                                }`}
                                            </Fragment>
                                          ))}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      sx={{
                                        p: 1,
                                        m: 1,
                                        border: (theme) =>
                                          `1px solid ${theme.palette.divider}`,
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Typography
                                        color="textSecondary"
                                        variant="body2"
                                      >
                                        {searchNameDict[keyName]}
                                      </Typography>
                                      <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                      >
                                        {keyName === "northCoast" ||
                                          keyName === "southCoast" ? (
                                          <>Igen</>
                                        ) : (
                                          <>
                                            {keyName === "created" ||
                                              keyName === "modified" ? (
                                              <>
                                                {format(
                                                  toDate(keyValue),
                                                  "yyyy.MM.dd HH:mm",
                                                  { locale: huLocale }
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {keyName === "salePriceFrom" ||
                                                  keyName === "salePriceTo" ? (
                                                  <>
                                                    {keyValue ? (
                                                      <NumberFormatCustom
                                                        value={keyValue}
                                                        displayType="text"
                                                        // suffix={`${property.basicDetails.currency === 'HUF' ? ' Ft' : '€'}`}
                                                        suffix=" Ft"
                                                      />
                                                    ) : (
                                                      "Nincs megadva"
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {keyName ===
                                                      "transactionTypeId" ? (
                                                      <>
                                                        {keyValue === 1
                                                          ? "Eladó"
                                                          : "Kiadó"}
                                                      </>
                                                    ) : (
                                                      <>{keyValue}</>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
            </React.Fragment>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};
CustomerSearchesRow.propTypes = {
  search: PropTypes.any,
  handleModalOpen: PropTypes.func,
};
export default CustomerSearchesRow;
