import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const OverflowTip = (props) => {
  const { children } = props;
  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip
      title={children}
      disableHoverListener={!hoverStatus}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

OverflowTip.propTypes = {
  children: PropTypes.node
};

export default OverflowTip;
