import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  // Menu,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
// import CogIcon from '../../icons/Cog';
// import UserIcon from '../../icons/User';
import MoonIcon from '../../icons/Moon';
import SunIcon from '../../icons/Sun';
import HomeIcon from '../../icons/Home';
import UsersIcon from '../../icons/Users';
// import LanguagePopover from './LanguagePopover';
import useSettings from '../../hooks/useSettings';
// import EmployeeModal from '../modals/EmployeeModal';
// import { getEmployeeById, openModal } from '../../slices/employee';
// import {
//   useDispatch,
//   // useSelector
// } from '../../store';
// import { selectedEmployeeSelector } from '../../utils/helperFunctions';

const { REACT_APP_FILE_URL } = process.env;

const getValues = (settings) => ({
  theme: settings.theme,
  roundedCorners: settings.roundedCorners
});

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  // const { loggedInEmployee, selectedEmployee } = useSelector((state) => state.employee);
  // const selectedEmployee = loggedInEmployee;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  // const dispatch = useDispatch();
  // const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  // const settingsOpen = Boolean(settingsAnchorEl);
  // const handleSettingsClick = (event) => {
  //   setSettingsAnchorEl(event.currentTarget);
  // };
  // const handleSettingsClose = () => {
  //   setSettingsAnchorEl(null);
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  // const handleEditEmployee = async () => {
  //   await dispatch(getEmployeeById(user.id));
  //   dispatch(openModal());
  // };
  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Sikertelen kijelentkezés');
    }
  };

  const handleChangeTheme = () => {
    setValues({
      ...values,
      theme: settings.theme === 'DARK' ? 'LIGHT' : 'DARK',
      roundedCorners: true
    });
  };

  useEffect(() => {
    saveSettings(values);
  }, [values]);

  const handleCloseAllMenu = () => {
    // handleSettingsClose();
    handleClose();
  };

  useEffect(() => {
    if (open) {
      document.getElementById('root').style.filter = 'blur(2px)';
      document.getElementById('root').style.transition = '0.3s';
    } else {
      document.getElementById('root').style.filter = 'blur(0px)';
      document.getElementById('root').style.transition = '0.3s';
    }
  }, [open]);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          src={user?.images?.length > 0 ? `${REACT_APP_FILE_URL}${user?.images[0].source?.thumb}` : null}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 240,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {user?.office?.name || 'LidoHome'}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2, mb: 2 }}>
          {/* <MenuItem
            // component={RouterLink}
            // to="/profile"
            onClick={handleEditEmployee}
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Profil
                </Typography>
              )}
            />
          </MenuItem> */}
          {/* <MenuItem
            onClick={handleSettingsClick}
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Beállítások
                </Typography>
              )}
            />
          </MenuItem> */}
          <MenuItem
            onClick={handleCloseAllMenu}
            component={RouterLink}
            to="/settings/offices"
          >
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Irodák
                </Typography>
              )}
            />
          </MenuItem>
          <MenuItem
            onClick={handleCloseAllMenu}
            component={RouterLink}
            to="/settings/employees"
          >
            <ListItemIcon>
              <UsersIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Munkatársak
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem onClick={() => handleChangeTheme()}>
            <ListItemIcon>
              {settings.theme === 'DARK'
                ? <SunIcon fontSize="small" />
                : <MoonIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Téma beállítása
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        {/* <Box>
          <LanguagePopover
            label
            component={MenuItem}
          />
        </Box> */}
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Kijelentkezés
          </Button>
        </Box>
      </Popover>
      {/* <Menu
        id="basic-menu"
        anchorEl={settingsAnchorEl}
        open={settingsOpen}
        onClose={handleSettingsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // NOTE: add spacing between accountMenu and settingDropDown
        PaperProps={{
          style: {
            transform: 'translateX(-3px) translateY(0px)',
          }
        }}
      >
        <MenuItem
          onClick={handleCloseAllMenu}
          component={RouterLink}
          to="/settings/offices"
        >
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Irodák
              </Typography>
            )}
          />
        </MenuItem>
        <MenuItem
          onClick={handleCloseAllMenu}
          component={RouterLink}
          to="/settings/employees"
        >
          <ListItemIcon>
            <UsersIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Munkatársak
              </Typography>
            )}
          />
        </MenuItem> */}
      {/* <MenuItem
          onClick={handleCloseAllMenu}
          component={RouterLink}
          to="/settings/contacts"
        >
          Kontaktok
        </MenuItem>
        <MenuItem
          onClick={handleCloseAllMenu}
          component={RouterLink}
          to="/settings/notification"
        >
          Éretesítések
        </MenuItem> */}
      {/* </Menu> */}
    </>
  );
};

export default AccountPopover;
