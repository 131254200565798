import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { findMyFolder } from '../utils/helperFunctions';
// import { findTheFolder } from '../components/TreeView';

const initialState = {
  folders: [],
  openFolder: null,
  isModalOpen: false,
  isDutyModalOpen: false,
  isTaskModalOpen: false,
  openFolderDetails: {},
  selectedFolderId: null,
  parentId: false,
  switcher: false,
};

const slice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
    setSwitch(state) {
      state.switcher = !state.switcher;
    },
    getFolders(state, action) {
      state.folders = action.payload;
    },
    selectFolder(state, details) {
      const folderId = details.payload.id;
      state.selectedFolderId = folderId;
      state.openFolderDetails = details;
    },
    setSelectFolderId(state, details) {
      const folderId = details?.payload?.id;
      state.selectedFolderId = folderId;
    },
    createFolder(state, action) {
      state.folders.push(action.payload);
    },
    createSubFolder(state, action) {
      // state.folders.push(action.payload);
      state.folders.forEach((folder,) => {
        if (folder.id === action.payload.id) {
          // state.folders[i].folders.push(action.payload.data);
          folder.folders.push(action.payload.data);
        }
      });

      for (let i = 0; i < state.folders.length; i++) {
        findMyFolder(state.folders[i], action);
      }

      // let found = false;
      // let folder = state.folders;
      // for (let i = 0; i < folder.length; i++) {
      //   if (folder[0].id === action.payload.id) {
      //     folder[i].folders.push(action.payload.data);
      //     found = true;
      //   }
      // }
      // folder = [...state.folders];
      // sFolder = [...folder];
      // while(found === false){
      //   for (let i = 0; i < folder.length; i++) {
      //     if (folder[0].id === action.payload.id) {
      //       folder[i].folders.push(action.payload.data);
      //       found = true;
      //     }
      //   }
      // }
    },
    editFolder(state, action) {
      const folderId = action.payload;

      state.selectedFolderId = folderId;
      state.isModalOpen = true;
    },
    updateFolder(state, action) {
      const folder = action.payload;
      state.openFolderDetails = folder;

      state.folders = state.folders.map((_folder) => {
        if (_folder.id === folder.id) {
          return folder;
        }

        return _folder;
      });
    },
    deleteFolder(state, action) {
      state.folders = state.folders.filter((folder) => folder.id !== action.payload);
      state.openFolderDetails = action.payload;
    },
    createFile(state, action) {
      const file = action.payload;
      // const folderId = action.payload;
      state.openFolderDetails.payload.documents.push(file);
    },
    updateFile(state, action) {
      const file = action.payload;
      state.openFolderDetails.payload.documents = state.openFolderDetails.payload.documents.map((_file) => {
        if (_file.id === file.id) {
          return file;
        }
        return _file;
      });
    },
    deleteFile(state, action) {
      state.openFolderDetails.payload.documents = state.openFolderDetails.payload.documents.filter((file) => file.id !== action.payload.fileId);
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      // state.selectedFolderId = null;
    },
    setParentId(state, action) {
      state.parentId = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getFolders = () => async (dispatch) => {
  await axiosConfig.get(
    'folders'
  ).then((response) => {
    dispatch(slice.actions.getFolders(response.data));
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
};
export const selectFolderId = (id) => async (dispatch) => {
  dispatch(slice.actions.setSelectFolderId(id));
};

export const setParentId = (id) => (dispatch) => {
  dispatch(slice.actions.setParentId(id));
};

export const createSubFolder = (createData, parentId) => async (dispatch) => {
  await axiosConfig.put(
    `folders/${parentId}`,
    createData
  ).then((response) => {
    toast.success('Mappa sikeresen létrehozva');
    dispatch(slice.actions.updateFolder(response.data));
    dispatch(slice.actions.setSwitch());
    // findTheFolder();
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
  // const data = await folderApi.createFolder(createData, parent);
  // dispatch(slice.actions.createFolder(data));
};

export const createFolder = (createData) => async (dispatch) => {
  await axiosConfig.post(
    'folders',
    createData
  ).then((response) => {
    toast.success('Mappa sikeresen létrehozva');
    dispatch(slice.actions.createFolder(response.data));
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
  // const data = await folderApi.createFolder(createData, parent);
};

export const selectFolder = (folder) => (dispatch) => {
  dispatch(slice.actions.selectFolder(folder));
};

export const editFolder = (folderId) => (dispatch) => {
  dispatch(slice.actions.editFolder(folderId));
};

export const createFolderFile = (id, values) => async (dispatch) => {
  const formData = new FormData();
  formData.append('file', values.file);
  formData.append('title', values.title);
  formData.append('category', values.type);
  await axiosConfig.post(
    `folders/${id}/files`,
    formData
  ).then((response) => {
    // const file = {
    //   fileName: response.data.originalFilename,
    //   id: response.data.id,
    //   relativeUrl: response.data.relativeUrl
    // };
    // dispatch(slice.actions.createFile(response.data));
    dispatch(slice.actions.updateFolder(response.data));
    toast.success('A dokumentum sikeresen mentve!');
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
};

export const updateFolder = (folderId, update) => async (dispatch) => {
  await axiosConfig.put(
    `folders/${folderId}/rename?folderName=${update.folderName}`
  ).then((response) => {
    dispatch(slice.actions.updateFolder(response.data));
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
};

export const updateFile = (fileId, update, folderId) => async (dispatch) => {
  await axiosConfig.put(
    `folders/${folderId}/files/${fileId}?title=${update}`
  ).then((response) => {
    // dispatch(slice.actions.updateFile(response.data));
    dispatch(slice.actions.updateFolder(response.data));
    toast.success('Fájl átnevezése sikeres');
  }).catch((error) => {
    console.error(error);
    toast.error('Fájl átnevezése sikertelen');
  }).finally(() => {

  });
};

export const deleteFolder = (folderId) => async (dispatch) => {
  await axiosConfig.delete(
    `folders/${folderId}`
  ).then((response) => {
    toast.success('Mappa sikeresen törölve');
    // dispatch(slice.actions.deleteFolder(folderId));
    dispatch(slice.actions.setSelectFolderId(folderId - 1));
    dispatch(slice.actions.updateFolder(response.data));
    dispatch(getFolders());
  }).catch((error) => {
    toast.error('Hiba a mappa törlésekor');
    console.error(error);
  }).finally(() => {

  });

  // await folderApi.deleteFolder(folderId);
};

export const deleteFile = (folderId, fileId) => async (dispatch) => {
  await axiosConfig.delete(
    `folders/${folderId}/files/${fileId}`
  ).then((response) => {
    // dispatch(slice.actions.deleteFile({ folderId, fileId }));
    dispatch(slice.actions.updateFolder(response.data));
    toast.success('Fájl sikeresen törölve');
  }).catch(() => {
    toast.error('Hiba a fájl törlésekor');
  }).finally(() => {

  });
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
