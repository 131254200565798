import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gtm from '../../lib/gtm';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import {
  Card,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { alpha, experimentalStyled } from '@material-ui/core/styles';
import CalendarFilter from '../../components/dashboard/calendar/CalendarFilter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from '../../store';
import {
  searchEvents,
  selectEvent,
  selectRange,
  updateEvent,
} from '../../slices/calendar';
import huLocale from '@fullcalendar/core/locales/hu';
import HpModal from '../../components/modals/HpModal';
import toast from 'react-hot-toast';
import { selectLoading } from '../../slices/loading';
import useAuth from '../../hooks/useAuth';
import { isOfficeManagerOrAbove } from '../../utils/helperFunctions';

const typeOptions = [
  {
    id: 1,
    value: 'USER',
    label: 'Kollégák'
  },
  {
    id: 2,
    value: 'OFFICE',
    label: 'Irodai'
  },
  {
    id: 3,
    value: 'DUTY',
    label: 'Ügyelet'
  },
  {
    id: 4,
    value: 'MEETING',
    label: 'Meeting'
  },
  {
    id: 5,
    value: 'Training',
    label: 'Képzés'
  },
];

const FullCalendarWrapper = experimentalStyled('div')(({ theme }) => ({
  '& .fc-license-message': {
    display: 'none'
  },
  '& .fc': {
    '--fc-bg-event-opacity': 1,
    '--fc-border-color': theme.palette.divider,
    '--fc-daygrid-event-dot-width': '10px',
    '--fc-list-event-hover-bg-color': theme.palette.background.default,
    '--fc-neutral-bg-color': theme.palette.background.default,
    '--fc-page-bg-color': theme.palette.background.default,
    '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily
  },
  '& .fc .fc-col-header-cell-cushion': {
    paddingBottom: '5px',
    paddingTop: '5px',
    fontSize: '12px'
  },
  '& .fc .fc-timegrid-slot-label-frame': {
    textAlign: 'center',
    fontSize: '12px'
  },
  '& .fc .fc-list-event': {
    cursor: 'pointer',
  },
  '& .fc .fc-timegrid-axis-cushion': {
    textAlign: 'center',
    fontSize: '12px'
  },
  '& .fc .fc-day-other .fc-daygrid-day-top': {
    color: theme.palette.text.secondary
  },
  '& .fc .fc-popover': {
    zIndex: 999
  },
  '& .fc-daygrid-event': {
    padding: '5px',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight
  },
}));

const MainCalendar = (props) => {
  const { handleSelect, editable, clickable, setHpDate, selectedProperties, selectedClient, mainCalendarClose } = props;
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { user } = useAuth();
  const calendarRef = useRef(null);
  const { events } = useSelector((state) => state.calendar);
  const [view, setView] = useState(mobileDevice
    ? 'listWeek'
    : 'timeGridWeek');
  const [viewTitle, setViewTitle] = useState();
  const [filters, setFilters] = useState({
    // types: {
    //   default: true,
    //   private: true,
    //   duty: true,
    //   training: true,
    //   office: true
    // },
    calendarTypes: ['OFFICE', 'DUTY', 'MEETING', 'Training'],
    officeId: null,
    userId: user.id,
  });
  const [date, setDate] = useState(new Date());
  const isLoadingArray = useSelector(selectLoading);

  const isAvailableToEdit = (event) => {
    if (isOfficeManagerOrAbove(user.roles) || event?.user?.id === user.id) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getCalendarDateRange = (calendar) => {
    const { activeStart, activeEnd } = calendar.view;
    const from = activeStart.getTime();
    const to = activeEnd.getTime();
    const dates = { from, to };
    return dates;
  };

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const dates = getCalendarDateRange(calendarApi);
      // dispatch(getEvents(dates, filters));
      dispatch(searchEvents(dates, filters, isAvailableToEdit));
      const newViewTitle = calendarApi.currentDataManager.data.viewTitle;
      setViewTitle(newViewTitle);
    }
  }, [date, view, filters]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? 'listWeek' : 'timeGridWeek';

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  const handleEventSelect = (arg) => {
    if (isOfficeManagerOrAbove(user.roles) || arg.event.extendedProps?.user?.id === user.id) {
      dispatch(selectEvent(arg.event.id));
    }
  };

  const handleEventDate = async ({ event }, isResize) => { // TODO: cannot create or edit training event on top of another event
    try {
      const { type } = event.extendedProps;
      const data = {
        title: event.title,
        allDay: event.allDay,
        start: event.start.getTime(),
        end: event.end.getTime(),
        ...event.extendedProps
      };
      if (type === 'task' && isResize) { /* FIXME: task events are not resizable so ideally this code never runs */
        toast.error('A feladatnak csak a határideje módosítható!');
      } else if (event?.extendedProps?.calendar?.type === 'TRAINING') {
        toast.error('Képzés eseményeket csak a képzés listában lehet módosítani!');
      } else {
        dispatch(updateEvent(event.id, data));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRangeSelect = (arg) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.unselect();
    }

    if (handleSelect) {
      handleSelect(arg);
    } else {
      dispatch(selectRange(arg.start.getTime(), arg.end.getTime()));
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <Tooltip title={`${eventInfo.timeText} - ${eventInfo.event.title} ${eventInfo.event.extendedProps?.user?.name ? ' - ' : ''} ${eventInfo.event.extendedProps?.user?.name ? eventInfo.event.extendedProps?.user?.name : ''}`} placeholder='top'>
        <div style={{ overflow: 'hidden', maxHeight: '-webkit-fill-available' }}>
          <Typography fontSize='12px'>{eventInfo.timeText}</Typography>
          <Typography fontSize='14px'>{eventInfo.event.title}</Typography>
          <Typography fontSize='14px'>{`${eventInfo.event.extendedProps?.user?.name ? ' - ' : ''} ${eventInfo.event.extendedProps?.user?.name ? eventInfo.event.extendedProps?.user?.name : ''}`}</Typography>
        </div>
      </Tooltip>
    )
  }

  return (
    <>
      <Card sx={{ px: 2, pt: 1, pb: 2 }}>
        <FullCalendarWrapper sx={{ opacity: isLoadingArray.includes('SEARCH_CALENDAR') ? 0.5 : 1 }}>
          <Box sx={{ mb: 1 }}>
            <CalendarFilter
              defaultUser={user}
              filters={filters}
              setFilters={setFilters}
              checkboxOptions={typeOptions}
              date={date}
              viewTitle={viewTitle}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              onDateToday={handleDateToday}
              onViewChange={handleViewChange}
              view={view}
              calendar={calendarRef.current}
            />
          </Box>
          {isLoadingArray.includes('SEARCH_CALENDAR') && (
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <CircularProgress
                size={50}
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: 400,
                  zIndex: 2,
                }}
              />
            </Box>
          )}
          <FullCalendar
            allDayMaintainDuration
            eventContent={renderEventContent}
            dayMaxEventRows={3}
            droppable
            editable={!isLoadingArray.includes('UPDATE_EVENT') && editable}
            // editable={(event) => isAvailableToEdit(event) && editable}
            eventClick={clickable && handleEventSelect}
            eventDisplay="block"
            eventDrop={handleEventDate}
            eventResizableFromStart
            eventResize={(event) => handleEventDate(event, true)}
            events={events}
            eventTextColor="#fff"
            headerToolbar={false}
            height={800}
            // slotDuration="00:60:00"
            initialDate={date}
            initialView={view}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              listPlugin,
              timeGridPlugin,
              timelinePlugin
            ]}
            ref={calendarRef}
            rerenderDelay={10}
            select={handleRangeSelect}
            selectable
            weekends
            locale={huLocale}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            nowIndicator
            scrollTime="08:00:00"
          // slotMinTime="08:00:00"
          // slotMaxTime="21:00:00"
          // businessHours={{
          //   daysOfWeek: [1, 2, 3, 4, 5], // Monday, Tuesday, Wednesday...
          //   startTime: '08:00', // 8am
          //   endTime: '20:00' // 8pm
          // }}
          // eventDidMount={(info) => { // FIXME: if i am needed, it adds a whole column or a word about the type of the event (neather one is looking cool enough to use i guess)
          //   if (info.view.type === 'listWeek') { // NOTE: word
          //     const titleCell = info.el.getElementsByClassName('fc-list-event-title');
          //     titleCell[0].innerText += ` (${info.event.extendedProps.calendar.type})`;
          //     // const columnElement = document.createElement('td'); // NOTE: column
          //     // info.el.append(`(${info.event.extendedProps.calendar.type})`);
          //   }
          // }}
          />
        </FullCalendarWrapper>
      </Card>
      <HpModal
        mainCalendarClose={mainCalendarClose}
        setHpDate={setHpDate}
        selectedProperties={selectedProperties}
        selectedClient={selectedClient}
      />
    </>
  );
};

MainCalendar.propTypes = {
  handleSelect: PropTypes.func,
  editable: PropTypes.bool,
  clickable: PropTypes.bool,
  setHpDate: PropTypes.func,
  selectedProperties: PropTypes.array,
  selectedClient: PropTypes.any,
  mainCalendarClose: PropTypes.func
};

export default MainCalendar;
