import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  Slide,
  Toolbar,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, Keyboard, EffectFade } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import '../styles/styles.scss';

const { REACT_APP_FILE_URL } = process.env;

SwiperCore.use([Navigation, Pagination, Scrollbar, Keyboard, EffectFade]);

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

const FullScreenImageVideoSwiper = ({ images, videos, open, handleOnClose, currentIndex }) => {
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    handleOnClose();
  }, [mobileDevice]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleOnClose}
      TransitionComponent={Transition}
      PaperProps={{
        backgroundColor: '#020202',
      }}
    >
      <AppBar
        sx={{
          position: 'absolute',
          backgroundColor: '#020202'
        }}
      >
        <Toolbar>
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="inherit"
            variant="outlined"
            onClick={handleOnClose}
            sx={{ mt: 2 }}
          >
            Kilépés
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'black',
          height: '100%',
        }}
      >
        <Swiper
          slidesPerView="1"
          style={{
            height: '100%',
            width: '100vw'
          }}
          fadeEffect={{ crossFade: true }}
          effect={!mobileDevice && 'fade'}
          navigation={!mobileDevice}
          keyboard
          initialSlide={currentIndex || 0}
          pagination={{
            type: 'fraction',
          }}
          arrow
        // modules={[Pagination, Navigation]}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log('slide change')}
        >
          {images && images.map((image) => (
            <SwiperSlide>
              <Grid
                container
                key={image.id}
                display="flex"
                alingItems="center"
                justifyContent="center"
                sx={{
                  height: '100%',
                }}
                mt={13}
              >
                <Grid
                  item
                  display="flex"
                  alingItems="center"
                  justifyContent="center"
                >
                  <img
                    alt={image.description}
                    src={`${REACT_APP_FILE_URL}${image?.source?.large}`}
                    style={{
                      objectFit: 'contain',
                      maxHeight: '70vh',
                    }}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          {videos && videos.map((video) => (
            <SwiperSlide>
              <Grid
                container
                key={video.id}
                display="flex"
                alingItems="center"
                justifyContent="center"
                sx={{
                  height: '100%',
                }}
                mt={13}
              >
                <Grid
                  item
                  display="flex"
                  alingItems="center"
                  justifyContent="center"
                >
                  <video
                    controls
                    width="100%"
                    style={{
                      objectFit: 'contain',
                      maxHeight: '70vh',
                    }}
                  >
                    <source
                      src={`${REACT_APP_FILE_URL}${video?.source}`}
                      type="video/mp4"
                    />
                    <track kind="captions" />
                  </video>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Dialog>
  );
};

FullScreenImageVideoSwiper.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  open: PropTypes.bool,
  handleOnClose: PropTypes.func,
  currentIndex: PropTypes.number
};

export default FullScreenImageVideoSwiper;
