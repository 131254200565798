import { Box, Card, CardContent, Grid, Pagination, Skeleton, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { getAllCalls } from '../../slices/call';
import { getEmployees } from '../../slices/employee';
import { useDispatch, useSelector } from '../../store';
import AOCAutocomplete from '../AOCAutocomplete';
import { selectLoading } from '../../slices/loading';
import StatementCard from './StatementCard';
import { getClients, searchClients } from '../../slices/client';
import { DesktopDatePicker } from '@material-ui/lab';
import { getStatementsByFilter, clearGenerations } from '../../slices/generation';
import { getSimplifiedOffices } from '../../slices/office';
import ScrollHere from '../ScrollHere';
import { scrollToTop } from '../../slices/modals';

const StatementsResults = () => {
  const { statements } = useSelector((state) => state.generation);
  const isLoadingArray = useSelector(selectLoading);
  const [page, setPage] = useState(1);
  const { simplifiedOffices } = useSelector((state) => state.office);
  const { employees } = useSelector((state) => state.employee);
  const { clients } = useSelector((state) => state.client);
  const { clientSearchDefaultParameters } = useSelector((state) => state.search);
  const [clientSearchName, setClientSearchName] = useState();
  const [employeeSearchName, setEmployeeSearchName] = useState();
  const [office, setOffice] = useState({ office: null });
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    client: null,
    office: null,
    start: null,
    end: null,
    id: '',
    type: null,
    employee: null,
    signedUploaded: null
  });

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getClients());
    dispatch(getSimplifiedOffices());
    return () => {
      dispatch(clearGenerations());
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController()
    const delayDebounceFn = setTimeout(() => {
      if (clientSearchName) {
        const defaultSearch = { ...clientSearchDefaultParameters };
        defaultSearch.name = clientSearchName;
        dispatch(searchClients(defaultSearch, '', 0, 50, '', abortController));
      }
    }, 400);

    return () => {
      abortController.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [clientSearchName]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      dispatch(getEmployees(0, office, employeeSearchName));
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [employeeSearchName, office]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      dispatch(getStatementsByFilter(0, filters));
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [filters]);

  const handlePageChange = (nextPage) => {
    if (page !== nextPage) {
      setPage(nextPage);
      if (nextPage) {
        dispatch(getStatementsByFilter(nextPage - 1, filters));
      }
    }
  };

  return (
    <>
      <ScrollHere />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Typography
          color="textPrimary"
          sx={{
            position: 'relative',
            '&:after': {
              backgroundColor: 'primary.main',
              bottom: '-8px',
              content: '" "',
              height: '3px',
              left: 0,
              position: 'absolute',
              width: '48px'
            }
          }}
          variant="h5"
        >
          Közvetítési nyilatkozatok száma:
          {' '}
          {statements.totalElements}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
        >
          <Card>
            <CardContent
              sx={{
                p: 2,
              }}
            >
              <AOCAutocomplete
                options={clients?.data}
                getOptionLabel={(option) => option.name}
                textFieldOnChange={(event) => setClientSearchName(event.target.value)}
                onChange={(_, value) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    client: value
                  }));
                }}
                value={filters.client}
                fullWidth
                label="Ügyfél"
                placeholder="Kezdje el beírni a ügyfél nevét."
                loadingArray={isLoadingArray.includes('GET_CLIENTS_PAGE')}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Azonosító"
                onChange={(e) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    id: e.target.value
                  }));
                }}
                value={filters.id}
                variant="outlined"
              />
              <AOCAutocomplete
                sx={{ mt: 2 }}
                options={simplifiedOffices}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => {
                  setOffice({ office: value?.id || null });
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    office: value
                  }));
                }}
                value={filters.office}
                fullWidth
                label="Iroda"
              />
              <AOCAutocomplete
                sx={{ mt: 2 }}
                options={employees?.data && employees?.data}
                fullWidth
                onChange={(_, value) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    employee: value
                  }));
                }}
                textFieldOnChange={(event) => setEmployeeSearchName(event.target.value)}
                placeholder="Kezdje el beírni a munkatárs nevét."
                label="Munkatárs"
                loadingArray={isLoadingArray.includes('GET_EMPLOYEES')}
                value={filters.employee}
              />
              <AOCAutocomplete
                sx={{ mt: 2 }}
                options={[
                  {
                    id: 1,
                    label: 'IP'
                  },
                  {
                    id: 2,
                    label: 'HP'
                  },
                ]}
                fullWidth
                onChange={(_, value) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    type: value
                  }));
                }}
                label="Típus"
                value={filters.type}
              />
              <AOCAutocomplete
                sx={{ mt: 2 }}
                options={[
                  {
                    id: 1,
                    label: 'Feltöltve',
                    status: true
                  },
                  {
                    id: 2,
                    label: 'Nincs feltöltve',
                    status: false
                  },
                ]}
                fullWidth
                onChange={(_, value) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    signedUploaded: value
                  }));
                }}
                label="Aláírt dokumentum feltöltve"
                value={filters.signedUploaded}
              />
              <DesktopDatePicker
                label="Dátum (tól)"
                ampm={false}
                inputFormat="yyyy/MM/dd"
                mask="____/__/__"
                onChange={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    start: date
                  }));
                }}
                value={filters.start}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
              />
              <DesktopDatePicker
                label="Dátum (ig)"
                ampm={false}
                inputFormat="yyyy/MM/dd"
                mask="____/__/__"
                onChange={(date) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    end: date
                  }));
                }}
                value={filters.end}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
              />
            </CardContent>
          </Card>
        </Grid>
        {isLoadingArray.includes('GET_STATEMENTS') ? (
          <Grid
            item
            lg={9}
            md={9}
            sm={12}
            xs={12}
          >
            {[...Array(23)].map((e, i) => (
              <Skeleton
                key={i}
                animation="wave"
                height={120}
                sx={{ marginBottom: -5, marginTop: -3 }}
              />
            ))}
          </Grid>
        ) : (
          <Grid
            item
            lg={9}
            md={9}
            sm={12}
            xs={12}
          >
            {statements?.data?.map((statement) => (
              <Box
                mb={1}
                key={statement.id}
              >
                <StatementCard statement={statement} />
              </Box>
            ))}
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 6
        }}
      >
        <Pagination
          count={statements?.totalPages - 1 || 1}
          page={page}
          showFirstButton
          showLastButton
          onChange={(_, nextPage) => {
            handlePageChange(nextPage);
            dispatch(scrollToTop());
            // forceScroll(); // TODO: add scroll to top
          }}
        />
      </Box>
    </>
  );
};

StatementsResults.propTypes = {
};

export default StatementsResults;
