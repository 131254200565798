import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { toggleLoading } from './loading';
import { convertEmptyStringsToNull, sortAccordingOrder } from '../utils/helperFunctions';
// import { propertyApi } from '../__fakeApi__/propertyApi';

const { REACT_APP_FILE_URL } = process.env;

const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const initialState = {
  selectedSuggestionId: null,
  pdf: null,
  immoweltPdf: null,
  properties: [],
  clientProperties: [],
  tempClientProperties: [],
  searches: [],
  rooms: [],
  memos: {},
  roomTypes: [],
  isSearching: false,
  isModalOpen: false,
  selectedProperty: null,
  presentations: [],
  selectedPropertyId: null,
  selectedRoomId: null,
  locationOptions: [],
  usingPropertySearchEndpoint: false,
  defaultConfig: {
    id: null,
    publicId: null,
    suggestionId: null,
    houseKey: '',
    approved: false,
    status: '',
    title: '',
    englishTitle: '',
    germanTitle: '',
    hungarianPrimary: '',
    hungarianSecondary: '',
    englishPrimary: '',
    englishSecondary: '',
    germanPrimary: '',
    germanSecondary: '',
    description: '',
    descriptionHtml: '',
    descriptions: [],
    // images: [],
    // videos: [],
    // documents: [],
    rooms: [],
    floorArea: null,
    landArea: null,
    minPrice: null,
    salePrice: null,
    rentPrice: null,
    commonCost: null,
    currency: 'Ft',
    deposit: null,
    commission: null,
    created: '',
    modified: '',
    isPublic: false,
    publish: false,
    state: 1,
    archiveReason: 0,
    roomCount: null,
    numberOfFloors: null,
    numberOfRooms: null,
    numberOfHalfRooms: null,
    buildYear: null,
    renovationYear: null,
    waterDistance: null,
    elevator: false,
    balcony: false,
    utilityCost: null,
    railwayDistance: null,
    client: null,
    office: null,
    user: null,
    userId: null,
    registrationNumber: '',
    contractNumber: '',
    contractDate: null,
    transactionType: null,
    attributeOptionIds: [],
    attributeOptions: {},
    address: {
      building: '',
      city: '',
      country: '',
      county: '',
      district: '',
      door: '',
      floor: '',
      // formattedAddress: null,
      id: null,
      lat: 0,
      locationId: null,
      lon: 0,
      originalId: 0,
      parcelNumber: '',
      postalCode: '',
      staircase: null,
      status: null,
      street: '',
      suburb: '',
      streetNumber: '',
      // address: [],
      tmpAddress: null,
      position: null,
    },
    externalKey: '',
    projectFlatId: '',
    projectId: null,
    landRegistryCheckedAt: null,
    landRegistryStatus: null,
    landRegistryComment: '',
    approveComment: '',
    cardType: null,
    featured: null,
  },
  config: {
    id: null,
    suggestionId: null,
    publicId: null,
    houseKey: '',
    approved: false,
    status: '',
    title: '',
    englishTitle: '',
    germanTitle: '',
    hungarianPrimary: '',
    hungarianSecondary: '',
    englishPrimary: '',
    englishSecondary: '',
    germanPrimary: '',
    germanSecondary: '',
    description: '',
    descriptionHtml: '',
    descriptions: [],
    floorArea: null,
    landArea: null,
    minPrice: null,
    salePrice: null,
    rentPrice: null,
    commonCost: null,
    currency: 'Ft',
    deposit: null,
    commission: null,
    created: '',
    modified: '',
    isPublic: false,
    publish: false,
    state: 1, // TODO: finde out 0 or null is good of u
    archiveReason: 0,
    roomCount: null,
    numberOfFloors: null,
    numberOfRooms: null,
    numberOfHalfRooms: null,
    buildYear: null,
    renovationYear: null,
    waterDistance: null,
    elevator: false,
    balcony: false,
    utilityCost: null,
    // images: [],
    // videos: [],
    // documents: [],
    rooms: [],
    railwayDistance: 0,
    client: null,
    office: null,
    user: null,
    userId: null,
    registrationNumber: '',
    contractNumber: '',
    contractDate: null,
    transactionType: null,
    attributeOptionIds: [],
    attributeOptions: {},
    address: {
      building: '',
      city: '',
      country: '',
      county: '',
      district: '',
      door: '',
      floor: '',
      // formattedAddress: null,
      id: null,
      lat: 0,
      locationId: '',
      lon: 0,
      originalId: 0,
      parcelNumber: '',
      postalCode: '',
      staircase: null,
      status: null,
      street: '',
      suburb: '',
      streetNumber: '',
      // address: [],
      tmpAddress: null,
      position: null,
    },
    externalKey: '',
    projectFlatId: '',
    projectId: null,
    landRegistryCheckedAt: null,
    landRegistryStatus: null,
    landRegistryComment: '',
    approveComment: '',
    cardType: null,
    featured: null,
  },
  autocompleteLocationData: {
    building: null,
    city: [],
    country: [],
    county: [],
    district: null,
    door: null,
    floor: null,
    formattedAddress: null,
    id: null,
    lat: null,
    locationId: null,
    lon: null,
    parcellNumber: null,
    postalCode: null,
    street: null,
    streetNumber: null,
    suburb: null
  },
  defaultAutocompleteLocationData: {
    building: null,
    city: [],
    country: [],
    county: [],
    district: null,
    door: null,
    floor: null,
    formattedAddress: null,
    id: null,
    lat: null,
    locationId: null,
    lon: null,
    parcellNumber: null,
    postalCode: null,
    street: null,
    streetNumber: null,
    suburb: null,
  },
  currencies: {
    Ft: {
      value: 'Ft',
      thousandSeparator: ' ',
      decimalSeparator: false,
      label: 'Ft',
    },
    '€': {
      value: '€',
      thousandSeparator: ' ',
      decimalSeparator: ',',
      label: '€',
    }
  },
  immowelt: null,
  immoweltAttributes: null,
  propertyLogs: null,
  thirdPartyConnections: null,
  isApprovedProperty: false,
  propertyUrlParam: null,
  propertyDataResultUrlParam: null,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    getProperties(state, action) {
      state.properties = action.payload;
    },
    getPropertiesByClient(state, action) {
      state.clientProperties = action.payload;
    },
    setPdf(state, action) {
      state.pdf = action.payload;
    },
    getPresentations(state, action) {
      state.presentations = action.payload;
    },
    setsuggestionId(state, action) {
      // state.defaultConfig.suggestionId = action.payload;
      // state.config.suggestionId = action.payload;
      state.selectedSuggestionId = action.payload;
    },
    getLocationOptions(state, action) {
      state.locationOptions = action.payload;
    },
    clearLocationOptions(state) {
      state.locationOptions = [];
    },
    getPropertySearches(state, action) {
      state.searches[action.payload.propertyId] = action.payload.data;
    },
    createProperty(state, action) {
      state.properties.push(action.payload);
    },
    getMemos(state, action) {
      state.memos = action.payload;
    },
    createMemo(state, action) {
      // state.memos.data.push(action.payload);
      state.memos.data.unshift(action.payload);
    },
    selectProperty(state, action) {
      state.selectedProperty = action.payload;
      if (!action.payload) {
        state.config = initialState.defaultConfig; /** on selectProperty we update the config as well, with the selected Propery data, therefore on unmount we should clear them as well */
      }
    },
    updateConfig(state, action) {
      if (!action.payload) {
        state.config = initialState.defaultConfig;
      } else {
        state.config = action.payload;
        // if (action.payload.address === null) { // TODO: sometimes address is null in this case we should create a initialObgject for it
        //   action.payload.address = {};
        // }

        // const test1 = initialState.defaultConfig;
        // const a = Object.keys(state.config);
        // const b = Object.keys(test1);
        // const found = [];
        // for (let i = 0; i < a.length; i++) {
        //   let isit = false;
        //   for (let j = 0; j < b.length; j++) {
        //     if (a[i] === b[j]) {
        //       found.push(b[j]);
        //       isit = true;
        //     }
        //   }
        // }

      }
    },
    updateConfigClient(state, action) {
      if (action.payload) {
        const client = action.payload;
        state.config = { ...initialState.defaultConfig, client };
      }
    },
    setTempClientProperties(state, action) {
      // state.tempClientProperties = action.payload;
      state.config.client = action.payload;
    },
    setPosition(state, action) {
      if (action.payload) {
        state.config.address.lon = action.payload.data2.data[0]?.lon;
        state.config.address.lat = action.payload.data2.data[0]?.lat;
        if (action.payload.variant === 'postalCode') {
          state.config.address.postalCode = action.payload.data2.data[0]?.postalCode;
        }
        // if()
        state.config.address.city = action.payload.data2.data[0]?.city;
        if (action.payload.data2.data[0]?.county) {
          // state.config.address.county = action.payload.data2.data.data[0].county;
          state.config.address.county = action.payload.data2.data[0].county?.replace(' megye', ' vármegye'); // TODO: it think we don`t use this. This func in office slice as well whihc is used in officeForm
        }
        state.config.address.parcelNumber = action.payload.data2.data[0]?.parcelNumber;
      }
    },
    setPropertyId(state, action) {
      state.selectedPropertyId = action.payload;
    },
    updateProperty(state, action) {
      const property = action.payload;
      state.properties.data = state.properties.data.map((_property) => {
        if (_property.id === property.id) {
          return property;
        }

        return _property;
      });
    },
    updatePropertyState(state, action) {
      /* eslint-disable-next-line */
      const id = action.payload?.propertyId || undefined;
      const { newState } = action.payload;
      if (id) {
        state.properties.data = state.properties.data.map((_property) => {
          if (_property.id === id) {
            const initialPropertyValues = { ..._property };
            initialPropertyValues.state = newState;
            return initialPropertyValues;
          }

          return _property;
        });
      }
      const initialConfigValues = { ...state.config };
      initialConfigValues.state = newState;
      state.config = initialConfigValues;
    },
    deleteProperty(state, action) {
      state.properties = state.properties.filter((property) => property.id !== action.payload);
    },
    getRooms(state, action) {
      state.rooms = sortAccordingOrder(action.payload);
    },
    getRoomTypes(state, action) {
      state.roomTypes = action.payload;
    },
    createRoom(state, action) {
      state.rooms.push(action.payload);
      state.rooms = sortAccordingOrder(state.rooms); // TODO: test is
    },
    createDescriptions(state, action) {
      state.config.descriptions.push(action.payload);
    },
    selectRoom(state, action) {
      const { roomId } = action.payload;

      state.selectedRoomId = roomId;
      state.isModalOpen = true;
    },
    updateRoom(state, action) {
      const room = action.payload;

      state.rooms = state.rooms.map((_room) => {
        if (_room.id === room.id) {
          return room;
        }

        return _room;
      });
      state.rooms = sortAccordingOrder(state.rooms); // TODO: test is
    },
    deleteRoom(state, action) {
      state.rooms = state.rooms.filter((room) => room.id !== action.payload);
    },
    getAdressData(state, action) {
      const datas = action.payload.data;
      state.autocompleteLocationData = datas;
      Object.keys(datas).forEach((key) => {
        if (datas[key]) {
          if (Array.isArray(datas[key]) && datas[key].length === 1) {
            if (key === 'county' || key === 'country' || key === 'postalCode') {
              /* eslint-disable-next-line */
              state.config.address[key] = datas[key][0].name;
            } else if (key !== 'suburb') {
              /* eslint-disable-next-line */
              state.config.address[key] = datas[key][0];
            }
            if (key === 'street' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveGPS) {
              state.config.address.lat = datas[key][0].lat;
              state.config.address.lon = datas[key][0].lon;
            } else if (key === 'suburb' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveGPS) {
              state.config.address.lat = datas[key][0].lat;
              state.config.address.lon = datas[key][0].lon;
            } else if (key === 'city' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveCityGps) {
              state.config.address.lat = datas[key][0].lat;
              state.config.address.lon = datas[key][0].lon;
            }
          }
        }
        if (key === 'suburb' && !datas[key]) {
          state.config.address.suburb = null;
          state.defaultConfig.address.suburb = null;
        }
      });
    },
    getAdressDataSimple(state, action) {
      const datas = action.payload.data;
      state.autocompleteLocationData = datas;
      // Object.keys(datas).forEach((key) => {
      //   if (datas[key]) {
      //     if (Array.isArray(datas[key]) && datas[key].length === 1) {
      //       if (key === 'county' || key === 'country' || key === 'postalCode') {
      //         /* eslint-disable-next-line */
      //         state.config.address[key] = datas[key][0].name;
      //       } else if (key !== 'suburb') {
      //         /* eslint-disable-next-line */
      //         state.config.address[key] = datas[key][0];
      //       }
      //       if (key === 'street' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveGPS) {
      //         state.config.address.lat = datas[key][0].lat;
      //         state.config.address.lon = datas[key][0].lon;
      //       } else if (key === 'suburb' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveGPS) {
      //         state.config.address.lat = datas[key][0].lat;
      //         state.config.address.lon = datas[key][0].lon;
      //       } else if (key === 'city' && datas[key][0].lat && datas[key][0].lon && !action.payload.noSaveCityGps) {
      //         state.config.address.lat = datas[key][0].lat;
      //         state.config.address.lon = datas[key][0].lon;
      //       }
      //     }
      //   }
      //   if (key === 'suburb' && !datas[key]) {
      //     state.config.address.suburb = null;
      //     state.defaultConfig.address.suburb = null;
      //   }
      // });
    },
    createFile(state, action) {
      state.config.documents.push(action.payload);
    },
    updateFile(state, action) {
      const file = action.payload;
      state.config.documents = state.config.documents.map((_file) => {
        if (_file.id === file.id) {
          return file;
        }
        return _file;
      });
    },
    deleteFile(state, action) {
      state.config.documents = state.config.documents.filter((file) => file.id !== action.payload.fileId);
    },
    setAutocompleteLocationDataToDefault(state) {
      state.autocompleteLocationData = initialState.defaultAutocompleteLocationData;
    },
    setIsSearching(state, action) {
      state.isSearching = action.payload;
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedRoomId = null;
    },
    setUsingPropertySearchEndpoint(state, action) {
      state.usingPropertySearchEndpoint = action.payload;
    },
    getImmowelt(state, action) {
      state.immowelt = action.payload;
    },
    getImmoweltAttributes(state, action) {
      if (action.payload) {
        state.immoweltAttributes = action.payload;
      }
    },
    getImmoweltPdf(state, action) {
      state.immoweltPdf = action.payload;
    },
    getPropertyLogs(state, action) {
      state.propertyLogs = action.payload;
    },
    getPropertySync(state, action) {
      state.thirdPartyConnections = action.payload;
    },
    setIsApprovedProperty(state, action) {
      state.isApprovedProperty = action.payload;
    },
    setPropertyUrlParams(state, action) {
      const { propertyUrlParam, propertyDataResultUrlParam } = action.payload;
      state.propertyUrlParam = propertyUrlParam;
      state.propertyDataResultUrlParam = propertyDataResultUrlParam;
    }
  }
});

export const { reducer } = slice;

export const setClientProps = (data) => (dispatch) => {
  dispatch(slice.actions.setTempClientProperties(data));
};

// GET PROPERTIES TODO: filter remove depricated nem jó
const getFilterStatus = (filter, pageNumber) => {
  switch (filter) {
    case 'draft':
      return `/properties/status/1?pageNumber=${pageNumber | 0}`;
    case 'unapproved':
      return `/properties/status/2?pageNumber=${pageNumber | 0}`;
    case 'approved':
      return `/properties/status/3?pageNumber=${pageNumber | 0}`;
    case 'markArchive':
      return `/properties/status/4?pageNumber=${pageNumber | 0}`;
    case 'archived':
      return `/properties/status/5?pageNumber=${pageNumber | 0}`;
    default:
      return `/properties?pageNumber=${pageNumber | 0}`;
  }
};

export const setsuggestionId = (suggestionId) => (dispatch) => {
  dispatch(slice.actions.setsuggestionId(suggestionId));
};

export const assignSuggestionToProperty = (propertyId, suggestionId,) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'ASSIGN_SUGGESTION_TO_PROPERTY' }));
  await axiosConfig.put(
    `properties/${propertyId}/suggestion/`,
    { suggestionId }
  ).then(() => {
    toast.success('A tipp hozzárendelése sikeres');
  }).catch((error) => {
    // handle error
    toast.error('A tipp hozzárendelése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'ASSIGN_SUGGESTION_TO_PROPERTY' }));
    // always executed
  });
};

export const getPropertySearches = (propertyId, pageNumber, pageSize, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_PROPERTY_SEARCHES${propertyId}` }));
  await axiosConfig.get(
    `searches/property/${propertyId}?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 5}${filters.ownClientOnly ? '&ownClientOnly=' : ''}${filters.ownClientOnly ? filters.ownClientOnly : ''}${filters.notOfferedYet ? '&notOfferedYet=' : ''}${filters.notOfferedYet ? filters.notOfferedYet : ''}`
  ).then((response) => {
    /* eslint-disable-next-line */
    const data = response.data;
    dispatch(slice.actions.getPropertySearches({ data, propertyId }));
  }).catch((error) => {
    // handle error
    // toast.error('A keresések betöltése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: `GET_PROPERTY_SEARCHES${propertyId}` }));
    // always executed
  });
};

// export const getPropertyPresentations = (body) => async (dispatch) => {
//   dispatch(toggleLoading({ type: 'GET_PROPERTY_PRESENTATIONS' }));
//   await axiosConfig.post(
//     'presentations/search',
//     body
//   ).then((response) => {
//     /* eslint-disable-next-line */
//     const data = response.data;
//     dispatch(slice.actions.getPresentations({ data }));
//   }).catch((error) => {
//     // handle error
//     // toast.error('A keresések betöltése sikertelen');
//     console.error(error);
//   }).then(() => {
//     dispatch(toggleLoading({ type: 'GET_PROPERTY_PRESENTATIONS' }));
//     // always executed
//   });
// };

export const getMemos = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_MEMOS${propertyId}` }));
  await axiosConfig.get(
    `memos/properties/${propertyId}`
  ).then((response) => {
    /* eslint-disable-next-line */
    const data = response.data;
    dispatch(slice.actions.getMemos({ data, propertyId }));
  }).catch((error) => {
    // handle error
    // toast.error('A keresések betöltése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: `GET_MEMOS${propertyId}` }));
    // always executed
  });
};

export const createMemo = (propertyId, memo) => async (dispatch) => {
  dispatch(toggleLoading({ type: `CREATE_MEMO${propertyId}` }));
  const sendData = {
    message: memo
  };
  await axiosConfig.post(
    `memos/properties/${propertyId}`,
    sendData
  ).then((response) => {
    /* eslint-disable-next-line */
    const data = response.data;
    dispatch(slice.actions.createMemo(data));
  }).catch((error) => {
    // handle error
    // toast.error('A keresések betöltése sikertelen');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: `CREATE_MEMO${propertyId}` }));
    // always executed
  });
};

export const getPropertyMapSearchPolygon = (polygon) => async (dispatch) => {
  // dispatch(toggleLoading({ type: 'GET_PROPERTY_MAP_SEARCHES$' }));
  await axiosConfig.post(
    'properties/search/polygon',
    polygon
  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data, 'approved'));
    toast.success('keresés sikeres!');
    /* eslint-disable-next-line */
    // const data = response.data.data;
    // dispatch(slice.actions.getPropertySearches({ data, propertyId }));
  }).catch((error) => {
    // handle error
    toast.error('A keresések betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // dispatch(toggleLoading({ type: 'GET_PROPERTY_MAP_SEARCHES$' }));
    // always executed
  });
};

export const getProperties = (filter, controller, pageNumber) => async (dispatch) => {
  dispatch(slice.actions.getProperties([], null));
  dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  await axiosConfig.get(
    getFilterStatus(filter, pageNumber),
    {
      signal: controller?.signal,
    }
  ).then((response) => {
    // handle success
    // if (filter === 'unapproved') {
    //   const { data, ...rest } = response;
    //   const unapprovedProperties = data.data.filter((property) => property.status === '0');
    //   const filteredProperties = {
    //     data: unapprovedProperties,
    //     ...rest
    //   };

    //   dispatch(slice.actions.getProperties(filteredProperties, filter));
    // } else {
    //   dispatch(slice.actions.getProperties(response.data, filter));
    // }
    dispatch(slice.actions.getProperties(response.data, filter));
  }).catch((error) => {
    // handle error
    if (!controller.signal.aborted) {
      toast.error('Az ingatlanok betöltése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  });
};

export const getPropertiesPage = (filter, page) => async (dispatch) => {
  // dispatch(slice.actions.getProperties([], null)); // TODO: Test it
  dispatch(toggleLoading({ type: 'GET_PROPERTIES_PAGE' }));
  await axiosConfig.get(
    // `/properties?pageNumber=${page}`
    `${getFilterStatus(filter)}?pageNumber=${page}`

  ).then((response) => {
    // handle success
    if (filter === 'unapproved') {
      const { data, ...rest } = response;
      const unapprovedProperties = data.data.filter((property) => property.status === '0');
      const filteredProperties = {
        data: unapprovedProperties,
        ...rest
      };

      dispatch(slice.actions.getProperties(filteredProperties, filter));
    } else {
      dispatch(slice.actions.getProperties(response.data, filter));
    }
  }).catch((error) => {
    // handle error
    toast.error('Az ingatlanok betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES_PAGE' }));
  });
};

// SEARCH PROPERTY

export const searchProperty = (data, pageNumber, controller) => async (dispatch) => {
  data = convertEmptyStringsToNull(data);
  dispatch(toggleLoading({ type: 'GET_SEARCH_PROPERTIES' }));
  dispatch(slice.actions.setIsSearching(true));
  dispatch(slice.actions.setUsingPropertySearchEndpoint(true));
  await axiosConfig.post(
    `/properties/search?pageNumber=${pageNumber || 0}`,
    data,
    {
      signal: controller?.signal,
    }
    // sortedBy: 'id: DESC'

  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data));
    // toast.success('Ingatlan sikeresen megtalálva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan keresésekor');
    console.error(error);
  }).then(() => {
    dispatch(slice.actions.setIsSearching(false));
    dispatch(toggleLoading({ type: 'GET_SEARCH_PROPERTIES' }));
    // always executed
  });
};

export const clearProperty = () => (dispatch) => {
  dispatch(slice.actions.getProperties([]));
};
// GET PROPERTIES BY CLIENT

export const getPropertiesByClient = (clientId) => async (dispatch) => {
  await axiosConfig.get(
    `/properties/client/${clientId}`
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getPropertiesByClient(response.data.data));
  }).catch((error) => {
    // handle error
    toast.error('A megbízások betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// CREATE PROPERTY

export const createProperty = (data) => async (dispatch) => {
  await axiosConfig.post(
    '/properties',
    data
  ).then((response) => {
    dispatch(slice.actions.selectProperty(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Új ingatlan sikeresen létrehozva!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan létrehozásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GENERATE/PRINT PROPERTY DATASHEETS

export const createDataSheet = (data) => async (dispatch) => {
  /** NOTE: data = { propertyIds: [], templates: '' } */
  /** NOTE: potential dataSheetTypes/tempaltes
  data-sheet
  data-sheet-image
  simple-list
  blog-list
  a4-premium
  a4-vitrin
  a5-vitrin
  brokerage-declaration-empty
  */
  dispatch(toggleLoading({ type: 'VITRIN_LOADING' }));
  await axiosConfig.post(
    '/properties/print',
    data,
    { responseType: 'blob' }
  ).then((response) => {
    dispatch(slice.actions.setPdf(response.data));
    // toast.success('Sikeres generálás');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan(ok) generálásakor');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'VITRIN_LOADING' }));
    // always executed
  });
};

export const createIpEmail = (data) => async () => {
  await axiosConfig.post(
    `/presentations/IP/${data.presentationId}/email`,
    data,
  ).then(() => {
    toast.success('Sikeres email küldés');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan(ok) generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const createEmail = (data) => async () => {
  await axiosConfig.post(
    '/presentations',
    data
  ).then(() => {
    toast.success('Sikeres generálás');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const generateHp = (data) => async () => {
  await axiosConfig.post(
    '/statements',
    data,
  ).then((response) => {
    openInNewTab(`${REACT_APP_FILE_URL}${response.data.originalFileSource}`);
    toast.success('Sikeres generálás');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan(ok) generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const generateBianco = (data) => async () => {
  await axiosConfig.post(
    '/statements/bianco',
    data,
  ).then((response) => {
    openInNewTab(`${REACT_APP_FILE_URL}${response.data.originalFileSource}`);
    toast.success('Sikeres biankó generálás');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a biankó generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const emptyPdf = () => (dispatch) => {
  dispatch(slice.actions.setPdf(null));
};

// GET THE DETAILS OF A SELECTED PROPERTY

export const selectProperty = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_SELECTED_PROPERTY' }));
  await axiosConfig.get(
    `/properties/${propertyId}`
  ).then((response) => {
    // handle success
    if (propertyId) {
      dispatch(slice.actions.selectProperty(response.data));
      dispatch(slice.actions.updateConfig(response.data));
    } else {
      dispatch(slice.actions.selectProperty(null));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan adatainak lekérésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_SELECTED_PROPERTY' }));
  });
};

// UPDATE SELECTED PROPERTY

export const updateProperty = (propertyId, update, stepTitle) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_PROPERTY' }));
  await axiosConfig.put(
    `/properties/${propertyId}?step=${stepTitle}`,
    update
  ).then((response) => {
    // handle success
    dispatch(slice.actions.selectProperty(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Ingatlan sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_PROPERTY' }));
  });
};

export const updateConfigWithPropertyClient = (clientId) => async (dispatch) => {
  await axiosConfig.get(
    `/clients/${clientId}`
  ).then((response) => {
    // handle success
    dispatch(slice.actions.updateConfigClient(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ügyfél adatainak lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const updatePropertyStatus = (propertyId, status) => async (dispatch) => { // TODO: deprecated endpoint
  await axiosConfig.put(
    `/properties/${propertyId}/status?status=${status}`, // TODO: not existing endpoint
    // `/properties/${propertyId}/${status}`,
    // update
  ).then((response) => {
    // handle success
    dispatch(slice.actions.selectProperty(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    dispatch(slice.actions.updateProperty(response.data));
    toast.success('Ingatlan sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// DELETE SELECTED PROPERTY

export const deleteProperty = (propertyId) => async (dispatch) => {
  await axiosConfig.delete(
    `/properties/${propertyId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.deleteProperty(propertyId));
    toast.success('Ingatlan sikeresen törölve!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a törlés végrehajtásakor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET ROOMS

export const getRooms = (propertyId) => async (dispatch) => {
  await axiosConfig.get(
    `properties/${propertyId}/rooms`
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getRooms(response.data));
  }).catch((error) => {
    // handle error
    toast.error('A szobák betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET ROOMSTYPES

export const getRoomTypes = () => async (dispatch) => {
  await axiosConfig.get(
    '/rooms/roomtypes'
  ).then((response) => {
    // handle success
    dispatch(slice.actions.getRoomTypes(response.data));
  }).catch((error) => {
    // handle error
    toast.error('A szobák betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// CREATE A DESCRIPTION

export const createDescriptions = (propertyId, data) => async (dispatch) => {
  await axiosConfig.post(
    `properties/${propertyId}/descriptions`,
    data
  ).then((response) => {
    // handle success
    dispatch(slice.actions.createDescriptions(response.data));
    toast.success('leírás hozzáadva!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a leírás hozzáadásakor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// CREATE NEW ROOM

export const createRoom = (propertyId, data) => async (dispatch) => {
  await axiosConfig.post(
    `properties/${propertyId}/rooms`,
    data
  ).then((response) => {
    // handle success
    dispatch(slice.actions.createRoom(response.data));
    toast.success('Helyiség hozzáadva!');
    if (propertyId) {
      dispatch(getRooms(propertyId));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba a helyiség hozzáadásakor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// GET THE DETAILS OF A SELECTED ROOM

export const selectRoom = (propertyId, roomId) => async (dispatch) => {
  dispatch(slice.actions.selectRoom({ propertyId, roomId }));
  await axiosConfig.get(
    // `/properties/${propertyId}/rooms/${roomId}`
    `/rooms/${roomId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.selectRoom({ propertyId, roomId }));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a szoba adatainak lekérdezésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// UPDATE SELECTED ROOM

export const updateRoom = (roomId, update, propertyId) => async (dispatch) => {
  await axiosConfig.put(
    // `/properties/${propertyId}/rooms/${roomId}`
    `/rooms/${roomId}`,
    update
  ).then((response) => {
    // handle success
    dispatch(slice.actions.updateRoom(response.data));
    toast.success('Helyiség módosítva!');
    if (propertyId) {
      dispatch(getRooms(propertyId));
    }
  }).catch((error) => {
    // handle error
    toast.error('Hiba a helyiség módosításakor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

// DELETE SELECTED ROOM

export const deleteRoom = (propertyId, roomId) => async (dispatch) => {
  await axiosConfig.delete(
    `/properties/${propertyId}/rooms/${roomId}`
  ).then(() => {
    // handle success
    dispatch(slice.actions.deleteRoom(roomId));
    toast.success('Helyiség törölve!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a helyiség törlésekor!');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

const getVariant = (variant, data) => {
  switch (variant) {
    case 'postalCode':
      return `/addresses/postal/${data.postalCode}`;
    case 'city':
      return `/addresses/city/${data.city}`;
    default:
      return '';
  }
};

export const getAdress = (data, variant) => async (dispatch) => {
  await axiosConfig.get(
    getVariant(variant, data)
    // `/addresses/postal/${data.postalCode}`,
    // data.postalCode
  ).then((response) => {
    const data2 = response.data;
    dispatch(slice.actions.setPosition({ data2, variant }));
  }).catch((error) => {
    // handle error
    toast.error('Az ingatlanok betöltése sikertelen');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getLocationOptions = (data, withSuburbs = true, withCounty = false, withRegion = false) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_LOCATION_OPTIONS' }));
  await axiosConfig.get(
    // `/locations/name/${data}?pageSize=10&sort=adminLevel:desc&level=10`,
    `/locations/name/${data}?level=8${withSuburbs ? ',9' : ''}${withCounty ? ',6' : ''}${withRegion ? ',7' : ''}&pageSize=100`,
  ).then((response) => {
    dispatch(slice.actions.getLocationOptions(response.data.data));
  }).catch((error) => {
    // handle error
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az adatok betöltése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_LOCATION_OPTIONS' }));
  });
};

export const clearLocationOptions = () => (dispatch) => {
  dispatch(slice.actions.clearLocationOptions());
};

export const getAdressData = (responseData, noSaveGPS, noSaveCityGps) => async (dispatch) => {
  await axiosConfig.post(
    '/locations/autocomplete',
    responseData
  ).then((response) => {
    const { data } = response;
    dispatch(slice.actions.getAdressData({ data, noSaveGPS, noSaveCityGps }));
  }).catch((error) => {
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getAdressDataSimple = (responseData, noSaveGPS, noSaveCityGps) => async (dispatch) => {
  //   await axiosConfig.post(
  //     '/locations/autocomplete',
  //     responseData
  //   ).then((response) => {
  //     const { data } = response;
  //     dispatch(slice.actions.getAdressDataSimple({ data, noSaveGPS, noSaveCityGps }));
  //     return data;
  //   }).catch((error) => {
  //     console.error(error);
  //     return error;
  //   }).then(() => {
  //     // always executed
  //   });
  const response = await axiosConfig.post(
    '/locations/autocomplete',
    responseData
  );
  const { data } = response;
  dispatch(slice.actions.getAdressDataSimple({ data, noSaveGPS, noSaveCityGps }));
  return data;
};

export const createPropertyFile = (id, values) => async (dispatch) => {
  const formData = new FormData();
  formData.append('file', values.file);
  formData.append('title', values.title);
  formData.append('category', values.category);
  await axiosConfig.post(
    `properties/${id}/files`,
    formData
  ).then((response) => {
    // const file = {
    //   fileName: response.data.originalFilename,
    //   id: response.data.id,
    //   relativeUrl: response.data.relativeUrl
    // };
    toast.success('A dokumentum sikeresen mentve!');
    dispatch(slice.actions.createFile(response.data));
  }).catch((error) => {
    console.error(error);
  }).finally(() => {

  });
};
export const updateFile = (propertyId, fileId, update) => async (dispatch) => {
  const formData = new FormData();
  // formData.append('file', update.file);
  formData.append('title', update.title);
  formData.append('category', update.category);
  await axiosConfig.put(
    `properties/${propertyId}/files/${fileId}`,
    formData
  ).then((response) => {
    dispatch(slice.actions.updateFile(response.data));
    toast.success('Fájl módosítása sikeres');
  }).catch((error) => {
    console.error(error);
    toast.error('Fájl módosítása sikertelen');
  }).finally(() => {

  });
};
export const deleteFile = (propertyId, fileId) => async (dispatch) => {
  await axiosConfig.delete(
    `properties/${propertyId}/files/${fileId}`
  ).then(() => {
    dispatch(slice.actions.deleteFile({ fileId }));
    toast.success('Fájl sikeresen törölve');
  }).catch(() => {
    toast.error('Hiba a fájl törlésekor');
  }).finally(() => {

  });
};

export const approveProperty = (propertyId, data, isConfig) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/approve`,
    data
  ).then((response) => {
    if (isConfig) {
      dispatch(slice.actions.updateConfig(response.data));
    } else {
      dispatch(slice.actions.updateProperty(response.data));
    }
    dispatch(slice.actions.setIsApprovedProperty(true));
    toast.success('Ingatlan sikeresen jóváhagyva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const setIsApproved = (isApproved) => (dispatch) => {
  dispatch(slice.actions.setIsApprovedProperty(isApproved));
};

export const activateProperty = (propertyId, configUpdate) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/activate`,
  ).then((response) => {
    if (configUpdate) {
      dispatch(slice.actions.updateConfig(response.data));
    } else {
      dispatch(slice.actions.updateProperty(response.data));
    }
    toast.success('Sikeres művelet');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const archiveProperty = (propertyId, data, configUpdate = false) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/archive`,
    data
  ).then((response) => {
    if (configUpdate) {
      dispatch(slice.actions.updateConfig(response.data));
    } else {
      dispatch(slice.actions.updateProperty(response.data));
    }
    toast.success('Ingatlan sikeresen arhiválva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};


export const requestApproveProperty = (propertyId, data) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/request/approve`,
    data
  ).then((response) => {
    // dispatch(slice.actions.updatePropertyState({ newState })); // TODO: i think this is depricated we don't need it anymore insted we using update property
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Jóváhagyás kérése sikeres');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a jóváhagyás kérésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const requestArchiveProperty = (propertyId, data) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/request/archive`,
    data
  ).then((response) => {
    dispatch(slice.actions.updateConfig(response.data));
    dispatch(slice.actions.updateProperty(response.data));
    toast.success('Jóváhagyás archiválásának kérése sikeres');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az archiválás kérésekor');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const duplicateProperty = (propertyId) => async () => {
  await axiosConfig.post(
    `/properties/${propertyId}/duplicate`,
  ).then(() => {
    // dispatch(slice.actions.updateConfig(response.data));
    toast.success('Az ingatlan duplikálása sikeres.');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan duplikálásakor.');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const assignUser = (propertyId, data) => async (dispatch) => {
  await axiosConfig.put(
    `/properties/${propertyId}/assignUser`,
    data
  ).then((response) => {
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Ingatlan felelős sikeresen frissítve.');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan felelős frissítése kor.');
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const setAutocompleteLocationDataToDefault = (dispatch) => {
  dispatch(slice.actions.setAutocompleteLocationDataToDefault());
};

export const openModal = (type) => (dispatch) => {
  dispatch(slice.actions.openModal(type));
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const setPropertyId = (id) => (dispatch) => {
  dispatch(slice.actions.setPropertyId(id));
  if (!id) {
    dispatch(slice.actions.selectProperty([]));
  }
};

export const updateConfig = () => (dispatch) => {
  dispatch(slice.actions.updateConfig());
};

export const setUsingPropertySearchEndpoint = (data) => async (dispatch) => {
  dispatch(slice.actions.setUsingPropertySearchEndpoint(data));
};

export const clearSelectProperty = () => async (dispatch) => {
  dispatch(slice.actions.selectProperty(null));
};

export const getImmoweltAttributes = () => async (dispatch) => {
  await axiosConfig.get(
    'attributes/immowelt',
  ).then((response) => {
    dispatch(slice.actions.getImmoweltAttributes(response.data));
  }).catch((error) => {
    // handle error
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az adatok betöltése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getImmowelt = (propertyId) => async (dispatch) => {
  await axiosConfig.get(
    `immowelt/property/${propertyId}`,
  ).then((response) => {
    dispatch(slice.actions.getImmowelt(response.data));
  }).catch((error) => {
    // handle error
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az adatok betöltése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const clearImmowelt = () => (dispatch) => {
  dispatch(slice.actions.getImmowelt(null));
};

export const createImmowelt = (data) => async (dispatch) => {
  await axiosConfig.post(
    'immowelt',
    data
  ).then((response) => {
    dispatch(slice.actions.getImmowelt(response.data));
  }).catch((error) => {
    // handle error
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az adatok betöltése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const updateImmowelt = (immoweltId, data) => async (dispatch) => {
  await axiosConfig.put(
    `immowelt/${immoweltId}`,
    data
  ).then((response) => {
    toast.success('Az adatok sikeresen frissítve.');
    dispatch(slice.actions.getImmowelt(response.data));
  }).catch((error) => {
    // handle error
    if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error('Az adatok mentése sikertelen');
    }
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getImmoweltPdf = (immoweltId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'IMMOWELT_LOADING' }));
  await axiosConfig.get(
    `immowelt/${immoweltId}/print`,
    { responseType: 'blob' }
  ).then((response) => {
    dispatch(slice.actions.getImmoweltPdf(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba a pdf generálásakor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'IMMOWELT_LOADING' }));
  });
};

export const sendEmailBatch = (data, propertyId, pageNumber, pageSize, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'EMAIL_BATCH' }));
  await axiosConfig.post(
    '/presentations/email/batch',
    data
  ).then(() => {
    // handle success
    dispatch(getPropertySearches(propertyId, pageNumber, pageSize, filters))
    toast.success('Az ingatlanok sikeresen ki letteki ajánlva az ügyfeleknek!');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a kiajánlás során!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'EMAIL_BATCH' }));
  });
};

export const emptyImmoweltPdf = () => (dispatch) => {
  dispatch(slice.actions.getImmoweltPdf(null));
};

export const getPropertyLogs = (propertyId, pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'PROPERTY_LOGS' }));
  await axiosConfig.get(
    `properties/${propertyId}/logs?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  ).then((response) => {
    dispatch(slice.actions.getPropertyLogs(response.data));
  }).catch((error) => {
    // handle error
    toast.error('Hiba az eseménynapló betöltése során');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'PROPERTY_LOGS' }));
  });
};

// eslint-disable-next-line no-unused-vars
export const getLog = (propertyId, logId) => async (dispatch) => {
  await axiosConfig.get(
    `properties/${propertyId}/logs/${logId}`,
  ).then((response) => {
    const attributeOptionArray = Object.values(response.data.recoveryData.attributeOptions);
    const attributeOptionIdsArray = [];
    if (attributeOptionArray?.length >= 1) {
      attributeOptionArray.forEach((attribute) => {
        attribute?.map((item) => (
          attributeOptionIdsArray.push(item?.id)
        ));
      });
    }
    axiosConfig.put(
      `/properties/${propertyId}`,
      { ...response.data.recoveryData, attributeOptionIds: attributeOptionIdsArray }
    ).then((updateResponse) => {
      // handle success
      dispatch(slice.actions.selectProperty(updateResponse.data));
      dispatch(slice.actions.updateConfig(updateResponse.data));
      toast.success('Ingatlan sikeresen módosítva');
    }).catch((error) => {
      // handle error
      toast.error('Hiba a módosítások mentésekor');
      console.error(error);
    }).then(() => {
      // always executed
      // dispatch(toggleLoading({ type: 'UPDATE_PROPERTY' }));
    });
    // dispatch(updateProperty)
  }).catch((error) => {
    // handle error
    toast.error('Hiba az eseménynapló betöltése során');
    console.error(error);
  }).then(() => {
    // always executed
    window.location.reload();
  });
};

export const getPropertySync = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'PROPERTY_SYNC' }));
  await axiosConfig.get(
    `properties/${propertyId}/sync`,
  ).then((response) => {
    dispatch(slice.actions.getPropertySync(response.data));
  }).catch((error) => {
    // handle error
    // toast.error('Hiba a hírdetőoldalak betöltése során');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'PROPERTY_SYNC' }));
  });
};

export const propertySync = (propertyId, data) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  await axiosConfig.put(
    `/properties/${propertyId}/sync`,
    data.data
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // handle success
    if (response.data) {
      dispatch(slice.actions.getPropertySync(response.data));
    }
    // dispatch(slice.actions.selectProperty(response.data));
    // dispatch(slice.actions.updateConfig(response.data));
    toast.success('Ingatlan sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  });
};

export const propertyForceSync = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  await axiosConfig.post(
    `/properties/${propertyId}/forcesync`,
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // handle success
    if (response.data) {
      dispatch(slice.actions.getPropertySync(response.data));
    }
    // dispatch(slice.actions.selectProperty(response.data));
    // dispatch(slice.actions.updateConfig(response.data));
    toast.success('Szinkronizálás sikeres');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  });
};

export const propertyIcomForce = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  await axiosConfig.post(
    `/export/${propertyId}/icom-force`,
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // handle success
    // if (response.data) {
    //   dispatch(slice.actions.getPropertySync(response.data));
    // }
    // dispatch(slice.actions.selectProperty(response.data));
    // dispatch(slice.actions.updateConfig(response.data));
    toast.success('Mentés sikeres');
  }).catch((error) => {
    // handle error
    toast.error('Hiba a módosítások mentésekor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'SYNC_PROPERTY' }));
  });
};

export const handlePropertyFeatured = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_PROPERTY_FEATURED' }));
  await axiosConfig.put(
    `/properties/${propertyId}/featured`,
  ).then((response) => {
    // handle success
    dispatch(slice.actions.selectProperty(response.data));
    dispatch(slice.actions.updateConfig(response.data));
    toast.success('Ingatlan kiemelése sikeresen módosítva');
  }).catch((error) => {
    // handle error
    toast.error('Hiba az ingatlan kiemelésének módosításakor');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_PROPERTY_FEATURED' }));
  });
};

export const getInprogressProperties = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  await axiosConfig.get(
    '/properties/inprogress',
  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data));
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  });
};

export const getApproveProperties = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  await axiosConfig.get(
    '/properties/approve',
  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data));
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  });
};

export const getArchiveProperties = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  await axiosConfig.get(
    '/properties/archive',
  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data));
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  });
};

export const getUserProperties = (userId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  await axiosConfig.get(
    `/properties/users/${userId}`,
  ).then((response) => {
    dispatch(slice.actions.getProperties(response.data));
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_PROPERTIES' }));
  });
};

export const setPropertyUrlParams = (urlParams, dataResultsUrlParam) => (dispatch) => {
  dispatch(slice.actions.setPropertyUrlParams({ propertyUrlParam: urlParams, propertyDataResultUrlParam: dataResultsUrlParam }));
}

// export const getProperties = (filters) => async (dispatch) => {
//   const data = await propertyApi.getProperties(filters);

//   dispatch(slice.actions.getProperties(data, filters));
// };

// export const createProperty = (createData) => async (dispatch) => {
//   const data = await propertyApi.createProperty(createData);

//   dispatch(slice.actions.createProperty(data));
// };

// export const selectProperty = (propertyId, type) => async (dispatch) => {
//   dispatch(slice.actions.selectProperty({ propertyId, type }));
// };

// export const updateProperty = (propertyId, update) => async (dispatch) => {
//   const data = await propertyApi.updateProperty({
//     propertyId,
//     update
//   });
//   dispatch(slice.actions.updateProperty(data));
// };

// export const deleteProperty = (propertyId) => async (dispatch) => {
//   await propertyApi.deleteProperty(propertyId);

//   dispatch(slice.actions.deleteProperty(propertyId));
// };

// export const selectRange = (start, end) => (dispatch) => {
//   dispatch(slice.actions.selectRange({ start, end }));
// };

// export const createProperty = (values) => (dispatch) => {
//   dispatch(slice.actions.createProperty(values));
// };

export default slice;
