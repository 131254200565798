import React, { useEffect, useRef, useState } from 'react';
import { getAllCalls } from '../../../slices/call';
import { useDispatch, useSelector } from '../../../store';
import { PropTypes } from 'prop-types';
import {
  Box,
  Grid,
  Pagination,
  CircularProgress
  // Skeleton
} from '@material-ui/core';
import { selectLoading } from '../../../slices/loading';
import CallCard from '../calls/CallCard';

const ClientCallList = ({ clientId }) => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const { clientCalls } = useSelector((state) => state.call);
  const isLoadingArray = useSelector(selectLoading);
  const topRef = useRef(null);

  const calls = clientCalls[clientId];

  useEffect(() => {
    setPageNumber(1);
    dispatch(getAllCalls(0, { clientId }, 9, 'clientCallList'));
  }, []);

  const handlePageChange = (nextPage) => {
    if (pageNumber !== nextPage) {
      setPageNumber(nextPage);
      if (nextPage) {
        dispatch(getAllCalls(nextPage - 1, { clientId }, 9, 'clientCallList'));
      }
    }
  };

  const forceScroll = () => {
    if (topRef !== null) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box ref={topRef}>
      {isLoadingArray.includes(`GET_CALLS_${clientId}`) ? (
        <Grid
          container
          width="100%"
          justifyContent="center"
          alignContent="center"
          minHeight={200}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          {calls?.data?.map((call) => (
            <Box
              mb={1}
              key={call.id}
            >
              <CallCard call={call} />
            </Box>
          ))}
        </Grid>
      )}
      {!isLoadingArray.includes(`GET_CALLS_${clientId}`) && calls?.data?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Pagination
            count={calls?.totalPages || 1}
            page={pageNumber}
            showFirstButton
            showLastButton
            onChange={(_, nextPage) => {
              handlePageChange(nextPage);
              forceScroll();
            }}
          />
        </Box>
      )}
    </Box>
  );
};

ClientCallList.propTypes = {
  clientId: PropTypes.string
};

export default ClientCallList;
