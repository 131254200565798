import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// import toast from 'react-hot-toast';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
// import toast from 'react-hot-toast';

const initialState = {
  tasks: [],
  activeTasks: {},
  notifications: {}
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    getTasks(state, action) {
      state.tasks = action.payload;
    },
    getActiveTasks(state, action) {
      state.activeTasks = action.payload;
    },
    checkNewActiveTasks(state, action) {
      const activeTasks = action.payload;
      const oldActiveTasksTotalItems = Object.keys(state.activeTasks).map((key) => state.activeTasks[key].length).reduce((a, b) => a + b, 0);
      const newActiveTasksTotalItems = Object.keys(activeTasks).map((key) => activeTasks[key].length).reduce((a, b) => a + b, 0);
      if (oldActiveTasksTotalItems !== newActiveTasksTotalItems) {
        toast.success('Új feladat érkezett!');
        state.activeTasks = activeTasks;
      }
    },
    getNotifications(state, action) {
      state.notifications = action.payload;
    },
    pushNotifications(state, action) {
      const notifications = action.payload;
      state.notifications.data = state.notifications?.data?.concat(notifications);
    },
    removeTask(state, action) {
      const task = action.payload;
      console.log('action.payload task', task);
      state.activeTasks[task.taskType] = state.activeTasks[task.taskType].filter((_task) => _task.id !== task.id); // FIXME: doesnt work
    },
    updateNotification(state, action) {
      const notification = action.payload;
      state.notifications.data = state.notifications?.data?.map((_notification) => {
        if (_notification.id === notification.id) {
          state.notifications.totalElements -= 1;
          return notification;
        }
        return _notification;
      });
    },
    checkNewActiveNotifications(state, action) {
      const notifications = action.payload;
      if (state.notifications.totalElements !== notifications.totalElements) {
        toast.success('Új értesítés érkezett!');
        state.notifications = notifications;
      }
    },
    clearNotificationsData(state) {
      state.notifications.data = [];
    }
  }
});

export const { reducer } = slice;

export const getTasks = () => async (dispatch) => {
  await axiosConfig.get( // TODO: add from to param
    `/tasks?from=${1679868000000}&to=${1680472800000}`,
  ).then((response) => {
    // console.log('getTasks reponse', response);
    dispatch(slice.actions.getTasks(response.data));
  }).catch((error) => {
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const getActiveTasks = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_ACTIVE_TASKS' }));
  await axiosConfig.get(
    '/tasks/active',
  ).then((response) => {
    // console.log('getTasks reponse', response);
    dispatch(slice.actions.getActiveTasks(response.data));
  }).catch((error) => {
    // toast.error(error);
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_ACTIVE_TASKS' }));
  });
};

export const checkNewActiveTasks = () => async (dispatch) => {
  await axiosConfig.get( // TODO: add from to param
    '/tasks/active',
  ).then((response) => {
    // console.log('getTasks reponse', response);
    dispatch(slice.actions.checkNewActiveTasks(response.data));
  }).catch((error) => {
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export const setTaskDone = (taskId) => async (dispatch) => {
  console.log('setTaskDone', taskId);
  await axiosConfig.put(
    `/events/${taskId}/done`,
  ).then((response) => {
    dispatch(slice.actions.removeTask(response.data));
  }).catch((error) => {
    toast.error('Hiba a feladat elvégzése közben!');
    console.error(error);
  }).then(() => {

  });
};

export const getActiveNotifications = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_NOTIFICATIONS' }));
  await axiosConfig.get(
    `/notifications/active?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 50}`,
  ).then((response) => {
    dispatch(slice.actions.getNotifications(response.data));
  }).catch((error) => {
    toast.error('Hiba az értesítések lekérése közben!');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_NOTIFICATIONS' }));
  });
};

export const checkNewActiveNotifications = (pageNumber, pageSize) => async (dispatch) => {
  await axiosConfig.get(
    `/notifications/active?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 50}`,
  ).then((response) => {
    dispatch(slice.actions.checkNewActiveNotifications(response.data));
  }).catch((error) => {
    console.error(error);
  }).then(() => {
  });
};

export const getActiveNotificationsNextPage = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_NEXTPAGE_NOTIFICATIONS' }));
  await axiosConfig.get(
    `/notifications/active?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 50}`,
  ).then((response) => {
    dispatch(slice.actions.pushNotifications(response.data.data));
  }).catch((error) => {
    toast.error('Hiba az értesítések lekérése közben!');
    console.error(error);
  }).then(() => {
    dispatch(toggleLoading({ type: 'GET_NEXTPAGE_NOTIFICATIONS' }));
  });
};

export const notificationSeen = (notificationId) => async (dispatch) => {
  await axiosConfig.put(
    `/notifications/${notificationId}/seen`,
  ).then((response) => {
    dispatch(slice.actions.updateNotification(response.data));
  }).catch((error) => {
    toast.error('Hiba az értesítések megtekintése közben!');
    console.error(error);
  }).then(() => {

  });
};

export const clearNotifications = () => async (dispatch) => {
  dispatch(slice.actions.clearNotificationsData());
};
