import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  // TablePagination,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import ImageIcon from '../../../icons/Image';
import PropTypes from 'prop-types';
import { format, toDate } from 'date-fns';
import { getClientProperties } from '../../../slices/client';
import { useDispatch, useSelector } from '../../../store';
import { selectLoading } from '../../../slices/loading';
import { Link as RouterLink } from 'react-router-dom';

const { REACT_APP_FILE_URL } = process.env;

const ClientOrders = (props) => {
  const { clientId } = props;
  const { clientProperties } = useSelector((state) => state.client);
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(3);
  const dispatch = useDispatch();
  const isLoadingArray = useSelector(selectLoading);
  const [clientPropertiesByClient, setClientPropertiesByClient] = useState([]);
  useEffect(() => {
    dispatch(getClientProperties(clientId));
  }, []);

  useEffect(() => {
    clientProperties.forEach((element) => {
      if (element.clientId === clientId) {
        setClientPropertiesByClient(element.data);
      }
    });
  }, [clientProperties]);

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleLimitChange = (event) => {
  //   setLimit(parseInt(event.target.value, 10));
  // };

  return (
    <Box>
      <Card>
        {isLoadingArray.includes(`GET_CLIENT_PROPERTIES${clientId}`) ? (
          <>
            <Grid
              container
              width="100%"
              justifyContent="center"
              alignContent="center"
              minHeight={200}
            >
              <CircularProgress />
            </Grid>
          </>
        ) : (
          <>
            {clientPropertiesByClient?.data?.length > 0 ? (
              <Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Időpont
                      </TableCell>
                      <TableCell>
                        Helyszín
                      </TableCell>
                      <TableCell>
                        Értékesítő neve
                      </TableCell>
                      <TableCell>
                        Ingatlan
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientPropertiesByClient?.data?.map((assignment) => (
                      <TableRow
                        hover
                        key={assignment?.id}
                        component={RouterLink}
                        to={`/properties/${assignment?.id}`}
                        target="_blank"
                        sx={{ textDecoration: 'none' }}
                      >
                        {console.log(assignment, 'search')}
                        <TableCell>
                          {assignment?.created ? format(toDate(assignment.created), 'yyy.MM.dd. HH:mm',) : 'ismeretlen dátum'}
                        </TableCell>
                        <TableCell>
                          {assignment?.office?.name || 'ismeretlen iroda'}
                        </TableCell>
                        <TableCell>
                          {assignment?.user?.name || 'ismeretlen értékesítő'}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            {assignment?.images?.length > 0
                              ? (
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    // backgroundColor: 'background.default',
                                    display: 'flex',
                                    height: 100,
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    borderRadius: '8px',
                                    width: 130,
                                    '& img': {
                                      height: 'auto',
                                      width: '100%'
                                    }
                                  }}
                                >
                                  <img
                                    style={{
                                      borderRadius: '8px',
                                    }}
                                    alt="kep"
                                    src={`${REACT_APP_FILE_URL}${assignment?.images[0]?.source?.thumb}`}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    backgroundColor: 'background.default',
                                    display: 'flex',
                                    height: 100,
                                    justifyContent: 'center',
                                    borderRadius: '8px',
                                    width: 130
                                  }}
                                >
                                  <ImageIcon fontSize="small" />
                                </Box>
                              )}
                            <Box
                              sx={{
                                ml: 3
                              }}
                            >
                              {
                                assignment?.address !== null ? (
                                  <>
                                    <Box>
                                      {assignment?.address?.city || ' ismeretlen'}
                                    </Box>
                                    <Box>
                                      ID:
                                      {assignment?.publicId || ' ismeretlen'}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Box>
                                      nincs adat
                                    </Box>
                                  </>
                                )
                              }
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      // <CustomerSearches customerID={search.id} />
                    ))}
                  </TableBody>
                </Table>
                {/* <TablePagination
                  component="div"
                  count={clientPropertiesByClient ? clientPropertiesByClient.length : 0}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPageOptions={[3, 6, 25]}
                  rowsPerPage={limit}
                  labelDisplayedRows={
                    ({ from, to, count }) => `Elem ${from}-${to} - Összesen: ${count}`
                  }
                  labelRowsPerPage=""
                /> */}
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="center"
                mt={5}
                mb={5}
              >
                <Typography
                  fontSize={20}
                >
                  Nem található megbízás
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

ClientOrders.propTypes = {
  clientId: PropTypes.number
};

export default ClientOrders;
