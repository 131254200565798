import { createSlice } from '@reduxjs/toolkit';
import { trainingApi } from '../__fakeApi__/trainingApi';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
import { toast } from 'react-hot-toast';
import { searchEvents } from './calendar';

const initialState = {
  oTrainings: {}, /* NOTE: these ones are the oTrainings */
  onlineTrainings: {}, /* NOTE: these ones are the trainings */
  offlineTrainings: {}, /* NOTE: these ones are the trainings */
  selectedOTrainaing: null,
  selectedOfflineTraining: null,
  selectedOTrainingParcitipants: {},
  selectedUserOTraining: {}, /* NOTE: these are those values which are given by a user in one specific training. */
  isOTrainingModalOpen: false,
  isOfflineTrainingModalOpen: false,
  courses: [], /* NOTE: this one are the possible types of the trainings. (usefull for filltering) */
  questions: [],
  studentAnswers: [],
  finalPoints: -100,
  selectedSectionId: 0,
  selectedStepId: null,
  trainingSteps: {},
  answers: {},
  selectedRange: null,
};

const slice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    createOTraining(state, action) {
      state.oTrainings = action.payload;
      state.oTrainings.data = [...state.oTrainings.data, action.payload];
    },
    createOfflineTraining(state, action) {
      state.offlineTrainings = action.payload;
      state.offlineTrainings.data = [...state.offlineTrainings.data, action.payload];
    },
    getOnlineTrainings(state, action) {
      state.onlineTrainings = action.payload;
    },
    resetOnlineTrainings(state) {
      state.onlineTrainings = {};
    },
    getOfflineTrainings(state, action) {
      state.offlineTrainings = action.payload;
    },
    getOTrainings(state, action) {
      state.oTrainings = action.payload;
    },
    getSelectedOTraining(state, action) {
      state.selectedOTrainaing = action.payload;
    },
    getSelectedOfflineTraining(state, action) {
      state.selectedOfflineTraining = action.payload;
    },
    rangeSelectOfflineTraining(state, action) {
      const { start, end } = action.payload;
      state.selectedRange = {
        start,
        end
      };

      state.isOfflineTrainingModalOpen = true;
    },
    getSelectedOTrainingParcitipants(state, action) {
      state.selectedOTrainingParcitipants = action.payload;
    },
    getSelectedUserOTraining(state, action) {
      state.selectedUserOTraining = action.payload;
    },
    getCourses(state, action) {
      state.courses = action.payload;
    },
    setIsOTrainingModalOpen(state, action) {
      state.isOTrainingModalOpen = action.payload;
    },
    setIsOfflineTrainingModalOpen(state, action) {
      state.isOfflineTrainingModalOpen = action.payload;
    },
    getQuestions(state, action) {
      state.questions = action.payload;
    },
    postStudentAnswers(state, action) {
      state.studentAnswers = action.payload;
    },
    setFinalPoints(state, action) {
      state.finalPoints = action.payload;
    },
    setSelectedSectionId(state, action) {
      state.selectedSectionId = action.payload;
    },
    setSelectedStepId(state, action) {
      state.selectedStepId = action.payload;
    },
    getTrainingSteps(state, action) {
      state.trainingSteps = action.payload;
    },
    setAnswers(state, action) {
      state.answers = action.payload;
    }
  }
});

export const { reducer } = slice;

// NOTE: (Online) Training related actions START ----------------------------------------------------------------------------------------------

export const searchOTrainings = (filters, controllerSignal) => async (dispatch) => {
  const { pageNumber = 0, pageSize = 10, courseId, dueDateFrom, dueDateTo } = filters || {};
  dispatch(toggleLoading({ type: 'GET_O_TRAININGS' }));
  await axiosConfig.post(
    // 'otrainings/filter',
    `otrainings/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { courseId, dueDateFrom, dueDateTo },
    { signal: controllerSignal }
  ).then((response) => {
    dispatch(slice.actions.getOTrainings(response.data));
  }).catch((error) => {
    if (error.status) {
      toast.error('Hiba az online képzések lekérése közben!');
    }
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_O_TRAININGS' }));
  });
};

export const resetOnlineTrainings = () => async (dispatch) => {
  dispatch(slice.actions.resetOnlineTrainings());
};

export const setIsOTrainingModalOpen = (isOpen) => async (dispatch) => {
  dispatch(slice.actions.setIsOTrainingModalOpen(isOpen));
  // dispatch(searchOTrainings({}));
};

export const createOTraining = (oTrainingData, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'CREATE_O_TRAINING' }));
  await axiosConfig.post(
    'otrainings',
    oTrainingData
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // dispatch(slice.actions.createOTraining(response.data));
    dispatch(setIsOTrainingModalOpen(false));
    if (filters) {
      dispatch(searchOTrainings(filters));
    } else {
      dispatch(searchOTrainings());
    }
  }).catch((error) => {
    toast.error('Hiba a képzések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'CREATE_O_TRAINING' }));
  });
};

export const getSelectedOTraining = (oTrainingId, openModal) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_O_TRAINING${oTrainingId}` }));
  await axiosConfig.get(
    `otrainings/${oTrainingId}`
  ).then((response) => {
    dispatch(slice.actions.getSelectedOTraining(response.data));
    if (openModal) {
      dispatch(setIsOTrainingModalOpen(true));
    }
  }).catch((error) => {
    toast.error('Hiba az képzés lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: `GET_O_TRAINING${oTrainingId}` }));
  });
};

export const getSelectedOTrainingParticipants = (oTrainingId, filters) => async (dispatch) => {
  const { pageNumber = 0, pageSize = 24, name = '', status = '' } = filters || {};
  dispatch(toggleLoading({ type: 'GET_O_TRAINING_PARTICIPANTS' }));
  await axiosConfig.get(
    `otrainings/${oTrainingId}/participants?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}&status=${status}`
  ).then((response) => {
    dispatch(slice.actions.getSelectedOTrainingParcitipants(response.data));
  }).catch((error) => {
    toast.error('Hiba az képzés lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_O_TRAINING_PARTICIPANTS' }));
  });
};

export const getOnlineTrainings = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_USER_O_TRAININGS' }));
  await axiosConfig.get(
    'otrainings/user-trainings',
  ).then((response) => {
    dispatch(slice.actions.getOnlineTrainings(response.data));
  }).catch((error) => {
    toast.error('Hiba a képzések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_USER_O_TRAININGS' }));
  });
};

export const getSelectedUserOTraining = (userTrainingId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_USER_O_TRAINING' }));
  await axiosConfig.get(
    `otrainings/user-trainings/${userTrainingId}`
  ).then((response) => {
    dispatch(slice.actions.getSelectedUserOTraining(response.data));
  }).catch((error) => {
    toast.error('Hiba az képzés lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_USER_O_TRAINING' }));
  });
};

export const evaluateSelectedUserOTraining = (userTrainingId, evaluationData) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'EVALUATE_USER_O_TRAINING' }));
  await axiosConfig.put(
    `otrainings/user-trainings/${userTrainingId}/evaluation`,
    evaluationData
  ).then((response) => {
    dispatch(slice.actions.getSelectedUserOTraining(response.data));
    toast.success('Képzés értékelve!');
  }).catch((error) => {
    toast.error('Hiba az képzés értékelése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'EVALUATE_USER_O_TRAINING' }));
  });
};

export const updateSelectedOTraining = (trainingId, trainingData, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_O_TRAINING' }));
  await axiosConfig.put(
    `otrainings/${trainingId}`,
    trainingData
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    // dispatch(slice.actions.getSelectedOTraining(response.data));
    if (filters) {
      dispatch(searchOTrainings(filters));
    } else {
      dispatch(searchOTrainings());
    }
    dispatch(setIsOTrainingModalOpen(false));
    toast.success('Képzés módosítva!');
  }).catch((error) => {
    toast.error('Hiba az képzés módosítása közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_O_TRAINING' }));
  });
};

// NOTE: (Online) Training related actions END ----------------------------------------------------------------------------------------------

export const getCourses = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_COURSES' }));
  await axiosConfig.get(
    'otrainings/courses'
  ).then((response) => {
    dispatch(slice.actions.getCourses(response.data));
  }).catch((error) => {
    toast.error('Hiba a képzések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_COURSES' }));
  });
};

export const getQuestions = () => async (dispatch) => {
  // dispatch(getQuestions)
  await trainingApi.getQuestions(3).then((response) => {
    dispatch(slice.actions.getQuestions(response));
  }).catch((error) => {
    console.error(error);
  });
  // await
};

// NOTE: (Offline) Training related actions START ----------------------------------------------------------------------------------------------

export const setIsOfflineTrainingModalOpen = (isOpen) => async (dispatch) => {
  dispatch(slice.actions.setIsOfflineTrainingModalOpen(isOpen));
};

export const getOfflineTrainings = () => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_OFFLINE_TRAININGS' }));
  await axiosConfig.post(
    'trainings/search',
    {}
  ).then((response) => {
    dispatch(slice.actions.getOfflineTrainings(response.data));
  }).catch((error) => {
    toast.error('Hiba a képzések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_OFFLINE_TRAININGS' }));
  });
};

export const searchOfflineTrainings = (filters, contollerSignal) => async (dispatch) => {
  const { pageNumber = 0, pageSize = 10, title, from, to } = filters || {};
  dispatch(toggleLoading({ type: 'GET_OFFLINE_TRAININGS' }));
  await axiosConfig.post(
    `trainings/search?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { title, from, to },
    { signal: contollerSignal }
  ).then((response) => {
    dispatch(slice.actions.getOfflineTrainings(response.data));
  }).catch((error) => {
    if (error.status) {
      toast.error('Hiba a képzések lekérése közben!');
    }
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_OFFLINE_TRAININGS' }));
  });
};

export const createOfflineTraining = (trainingData, filters, calendarSearchFilters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'CREATE_OFFLINE_TRAINING' }));
  await axiosConfig.post(
    'trainings',
    trainingData
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    dispatch(setIsOfflineTrainingModalOpen(false));
    if (filters) {
      dispatch(searchOfflineTrainings(filters));
    } else {
      dispatch(searchOfflineTrainings());
    }
    if (calendarSearchFilters) { // NOTE: this modal is also in the training calendar. Therefore when we create a new training we have to refresh the calendar
      dispatch(searchEvents(calendarSearchFilters.dates, calendarSearchFilters.filters));
    }
    toast.success('Képzés létrehozva!');
  }).catch((error) => {
    toast.error('Hiba az képzés létrehozása közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'CREATE_OFFLINE_TRAINING' }));
  });
};

export const updateOfflineTraining = (trainingId, trainingData, filters, calendarSearchFilters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_OFFLINE_TRAINING' }));
  await axiosConfig.put(
    `trainings/${trainingId}`,
    trainingData
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    if (filters) {
      dispatch(searchOTrainings(filters));
    } else {
      dispatch(searchOTrainings());
    }
    if (calendarSearchFilters) { // NOTE: this modal is also in the training calendar. Therefore when we update a training we have to refresh the calendar
      dispatch(searchEvents(calendarSearchFilters.dates, calendarSearchFilters.filters));
    }
    dispatch(setIsOfflineTrainingModalOpen(false));
    toast.success('Képzés módosítva!');
  }).catch((error) => {
    toast.error('Hiba az képzés módosítása közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_OFFLINE_TRAINING' }));
  });
};

export const deleteOfflineTraining = (trainingId, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'DELETE_OFFLINE_TRAINING' }));
  await axiosConfig.delete(
    `trainings/${trainingId}`
    // eslint-disable-next-line no-unused-vars
  ).then((response) => {
    dispatch(setIsOfflineTrainingModalOpen(false));
    if (filters) {
      dispatch(searchOfflineTrainings(filters));
    } else {
      dispatch(searchOfflineTrainings());
    }
    toast.success('Képzés törölve!');
  }).catch((error) => {
    toast.error('Hiba az képzés törlése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'DELETE_OFFLINE_TRAINING' }));
  });
};

export const getSelectedOfflineTraining = (trainingId, isOpenModal) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_SELECTED_OFFLINE_TRAINING' }));
  await axiosConfig.get(
    `trainings/${trainingId}`
  ).then((response) => {
    dispatch(slice.actions.getSelectedOfflineTraining(response.data));
    if (isOpenModal) {
      dispatch(setIsOfflineTrainingModalOpen(true));
    }
  }).catch((error) => {
    toast.error('Hiba a képzés lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_SELECTED_OFFLINE_TRAINING' }));
  });
};

export const rangeSelectOfflineTraining = (start, end) => async (dispatch) => {
  dispatch(slice.actions.rangeSelectOfflineTraining({ start, end }));
};

export const clearSelectedOfflineTraining = () => (dispatch) => {
  dispatch(slice.actions.getSelectedOfflineTraining(null));
};

// NOTE: (Offline) Training related actions END ----------------------------------------------------------------------------------------------

export const postStudentAnswers = (answers) => async (dispatch) => {
  dispatch(slice.actions.postStudentAnswers(answers));
};
export const setFinalPoints = (points) => async (dispatch) => {
  dispatch(slice.actions.setFinalPoints(points));
};

export const clearOTrainings = () => (dispatch) => {
  dispatch(slice.actions.getOTrainings({}));
};
export const clearSelectedOTraining = () => (dispatch) => {
  dispatch(slice.actions.getSelectedOTraining(null));
};
export const clearSelectedOTrainingParticipants = () => (dispatch) => {
  dispatch(slice.actions.getSelectedOTrainingParcitipants({}));
};
export const clearSelectedUserOTraining = () => (dispatch) => {
  dispatch(slice.actions.getSelectedUserOTraining({}));
};

export const clearOfflineTrainings = () => (dispatch) => {
  dispatch(slice.actions.getOfflineTrainings({}));
};

export const setSelectedSectionId = (sectionId) => (dispatch) => {
  dispatch(slice.actions.setSelectedSectionId(sectionId));
};

export const setSelectedStepId = (stepId) => (dispatch) => {
  dispatch(slice.actions.setSelectedStepId(stepId));
};

export const setTrainingSteps = (trainingId, body) => async (dispatch) => {
  axiosConfig.put(`otrainings/user-trainings/${trainingId}/steps`, body)
    .then((response) => dispatch(slice.actions.getTrainingSteps(response.data)))
    .catch((error) => {
      console.log(error);
      toast.error('Hiba a lecke könyvelésében!');
    });
};

export const getTrainingSteps = (trainingId) => async (dispatch) => {
  if (!trainingId) dispatch(slice.actions.getTrainingSteps({}));

  await axiosConfig.get(`/otrainings/user-trainings/${trainingId}/steps`)
    .then((response) => {
      dispatch(slice.actions.getTrainingSteps(response.data));
    }).catch((error) => {
      toast.error('Hiba az képzés lekérése közben!');
      console.error(error);
    });
};

export const useFireworks = (id) => async () => {
  await axiosConfig.put(
    `/otrainings/user-trainings/${id}/firework`
  ).then(() => {
    // dispatch(slice.actions.useFireworks(response.data));
  }).catch((error) => {
    toast.error('Hiba a tűzijáték használatában!');
    console.error(error);
  });
};

export const setAnswers = (answers) => (dispatch) => {
  dispatch(slice.actions.setAnswers(answers));
};

// export const realMonitorTest = () => async (dispatch) => {
//   // I want to send a post to this url: https://www.realmonitor.hu/api/ia/ajax.php?action=individuals

export default slice;
