import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import { toggleLoading } from './loading';
import { toast } from 'react-hot-toast';

const initialState = {
  ratings: {},
  selectedRating: null,
  isRatingModalOpen: false,
};

const slice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    getRatings(state, action) {
      state.ratings = action.payload;
    },
    setSelectedRating(state, action) {
      state.selectedRating = action.payload;
    },
    updateRating(state, action) {
      const rating = action.payload;
      state.ratings.data = state.ratings.data.map((_rating) => {
        if (_rating.id === rating.id) {
          return rating;
        }
        return _rating;
      });
    },
    setIsRatingModalOpen(state, action) {
      state.isRatingModalOpen = action.payload;
    },
  }
});

export const { reducer } = slice;

export const getRatings = (pageNumber, pageSize) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_RATINGS' }));
  await axiosConfig.get(
    `users/ratings?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  ).then((response) => {
    // console.log('getTasks reponse', response);
    dispatch(slice.actions.getRatings(response.data));
  }).catch((error) => {
    toast.error('Hiba az értékelések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_RATINGS' }));
  });
};

export const searchRatings = (pageNumber, pageSize, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_RATINGS' }));
  await axiosConfig.post(
    `users/ratings/search?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    filters
  ).then((response) => {
    // console.log('getTasks reponse', response);
    dispatch(slice.actions.getRatings(response.data));
  }).catch((error) => {
    toast.error('Hiba az értékelések lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_RATINGS' }));
  });
};

export const selectRating = (ratingId) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'GET_SELECTED_RATING' }));
  await axiosConfig.get(
    `users/ratings/${ratingId}`,
  ).then((response) => {
    dispatch(slice.actions.setSelectedRating(response.data));
  }).catch((error) => {
    toast.error('Hiba az értékelés lekérése közben!');
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'GET_SELECTED_RATING' }));
  });
};

export const setIsRatingModalOpen = (isOpen) => async (dispatch) => {
  dispatch(slice.actions.setIsRatingModalOpen(isOpen));
};

export const updateRating = (ratingId, rating, getRatingsProp, filters) => async (dispatch) => {
  dispatch(toggleLoading({ type: 'UPDATE_RATING' }));
  await axiosConfig.put(
    `users/ratings/${ratingId}`,
    rating,
  ).then((response) => {
    dispatch(setIsRatingModalOpen(false));
    if (filters.state && getRatingsProp) { /* NOTE: (it is not the best) if filters are set then after update it is getting the the ratings again but if filters are not set then do not get them again, just change the ratings.data value */
      getRatingsProp();
    } else {
      dispatch(slice.actions.updateRating(response.data));
    }
  }).catch((error) => {
    toast.error('Hiba az értékelés frissítése közben!');
    // toast.error(error);
    console.error(error);
  }).then(() => {
    // always executed
    dispatch(toggleLoading({ type: 'UPDATE_RATING' }));
  });
};

export const clearSelecetedRating = () => async (dispatch) => {
  dispatch(slice.actions.setSelectedRating(null));
};

export const clearRatings = () => async (dispatch) => {
  dispatch(slice.actions.getRatings(null));
};
