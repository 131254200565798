import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import { useState } from 'react';

const viewOptions = [
  {
    icon: ViewConfigIcon,
    label: 'Hónap',
    value: 'dayGridMonth'
  },
  {
    icon: ViewWeekIcon,
    label: 'Hét',
    value: 'timeGridWeek'
  },
  {
    icon: ViewDayIcon,
    label: 'Nap',
    value: 'timeGridDay'
  },
  {
    icon: ViewAgendaIcon,
    label: 'Lista',
    value: 'listWeek'
  }
];

const CalendarToolbar = (props) => {
  const { date, onDateNext, onDatePrev, onDateToday, onViewChange, view, ...other } = props;
  const [isHover, setIsHover] = useState(false);

  const handleViewChange = (newView) => {
    if (onViewChange) {
      onViewChange(newView.value);
    }
  };

  const handleVisibility = (activeView) => {
    if (activeView === view) {
      return '1';
    }
    if (isHover) {
      return '1';
    }
    return '0.3';
  };

  const handleHover = (e) => {
    if (e.type === 'mouseenter') {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  };

  return (
    <Grid
      item
      {...other}
    >
      <Box sx={{ color: 'text.primary' }}>
        {viewOptions.map((viewOption) => {
          const Icon = viewOption.icon;

          return (
            <Tooltip
              key={viewOption.value}
              title={viewOption.label}
              placement="top"
            >
              <IconButton
                color={viewOption.value === view
                  ? 'primary'
                  : 'inherit'}
                onMouseEnter={(e) => handleHover(e)}
                onMouseLeave={(e) => handleHover(e)}
                onClick={() => handleViewChange(viewOption)}
                sx={{
                  opacity: () => handleVisibility(viewOption.value),
                  transition: '0.3s',
                }}
              >
                <Icon fontSize="small" />
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>
    </Grid>
  );
};

CalendarToolbar.propTypes = {
  children: PropTypes.node,
  date: PropTypes.instanceOf(Date).isRequired,
  onAddClick: PropTypes.func,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.oneOf([
    'dayGridMonth',
    'timeGridWeek',
    'timeGridDay',
    'listWeek'
  ])
};

export default CalendarToolbar;
