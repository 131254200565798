/* eslint-disable */
import { Box, Card, Container, Grid, Skeleton, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from '../../store';
import { getOfflineTrainings, getOnlineTrainings, resetOnlineTrainings } from '../../slices/training';
import { format } from 'date-fns';
import { getStatusColorTraining, getStatusLabelTraining } from '../../utils/helperFunctions';
import { Link } from 'react-router-dom';
import Firework from '../../utils/Fireworks';
import { selectLoading } from '../../slices/loading';
import CircularProgressBar from '../../components/CircularProgressBar';
import EastIcon from '@mui/icons-material/East';

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import OnlineTrainingCard from './OnlineTrainingCard';
import OfflineTrainingCard from './OfflineTrainingCard';

const OwnTrainings = () => {
  const { onlineTrainings, offlineTrainings } = useSelector((state) => state.training);
  const isLoadingArray = useSelector(selectLoading);

  const dispatch = useDispatch();
  const [page, setPage] = useState('onlineTraining'); // ['onlineTraining', 'offlineTraining'
  console.log(onlineTrainings, offlineTrainings, 'trainings');

  useEffect(() => {
    dispatch(getOnlineTrainings());
    dispatch(getOfflineTrainings());
    return () => {
      dispatch(resetOnlineTrainings());
    };
  }, []);

  return (
    <Grid>
      <Container>
        <Grid
          container
        >
          {!isLoadingArray.includes('GET_USER_O_TRAININGS') && (
            <Firework
              // canShoot={true}
              trainings={onlineTrainings}
            />
          )}
          <Grid
            item
            xs={12}
            my={5}
          >
            <Tabs
              // indicatorColor="primary"
              // scrollButtons="auto"
              // // textColor="primary"
              // variant="scrollable"
              value={page}
            >
              <Tab
                sx={{ backgroundColor: 'background.default' }}
                label="Online képzés"
                value="onlineTraining"
                onClick={() => {
                  setPage('onlineTraining');
                }}
              />
              <Tab
                sx={{ backgroundColor: 'background.default' }}
                label="offline képzés"
                value="offlineTraining"
                onClick={() => {
                  setPage('offlineTraining');
                }}
              />
            </Tabs>
          </Grid>
          <Grid
            display={page === 'onlineTraining' ? 'flex' : 'none'}
            item
            container
            xs={12}
            spacing={2}
          >
            {isLoadingArray.includes('GET_USER_O_TRAININGS') ? (
              <>
                {[...Array(24)].map((p, i) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <Card>
                      <Box
                        p={2}
                        height={350}
                      >
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {onlineTrainings?.length > 0 && onlineTrainings?.map((training) => (
                  <OnlineTrainingCard training={training} />
                ))}
              </>
            )}
          </Grid>
          <Grid
            display={page === 'offlineTraining' ? 'flex' : 'none'}
            item
            container
            xs={12}
            spacing={2}
          >
            {isLoadingArray.includes('GET_USER_O_TRAININGS') ? (
              <>
                {[...Array(24)].map((p, i) => (
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <Card>
                      <Box
                        p={2}
                        height={350}
                      >
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    </Card>
                  </Grid>
                ))}              </>
            ) : (
              <>
                {offlineTrainings?.data?.length > 0 && offlineTrainings?.data?.map((training) => (
                  <OfflineTrainingCard training={training} />
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default OwnTrainings;
