import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { addMinutes } from 'date-fns';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import TrashIcon from '../../../icons/Trash';
import {
  createDuty,
  // createEvent,
  deleteEvent,
  updateEvent
} from '../../../slices/calendar';
import { useDispatch, useSelector } from '../../../store';
import { DesktopDateTimePicker } from '@material-ui/lab';
import AOCAutocomplete from '../../AOCAutocomplete';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { getSimplifiedOffices } from '../../../slices/office';
import { getEmployees } from '../../../slices/employee';
import { selectLoading } from '../../../slices/loading';

const getInitialValues = (event, range, user) => {
  if (event) {
    return {
      allDay: event.allDay || false,
      description: event.description || '',
      end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      start: event.start ? new Date(event.start) : new Date(),
      office: event?.calendar?.office || null,
      employee: event.user || null,
      title: event.title || '',
      type: 'duty',
      submit: null
    };
  }

  if (range) {
    return {
      allDay: false,
      color: '',
      description: '',
      end: new Date(range.end),
      start: new Date(range.start),
      office: user?.office || null,
      employee: null,
      title: '',
      type: 'duty',
      submit: null
    };
  }

  return {
    allDay: false,
    color: '',
    description: '',
    end: addMinutes(new Date(), 30),
    start: new Date(),
    office: user?.office || null,
    employee: null,
    title: '',
    type: 'duty',
    submit: null
  };
};

const CalendarDutyEventForm = (props) => {
  const { event, onAddComplete, onCancel, onDeleteComplete, onEditComplete, range } = props;
  const { simplifiedOffices } = useSelector((state) => state.office);
  const { employees } = useSelector((state) => state.employee);
  const [employeeSearchName, setEmployeeSearchName] = useState();
  const [office, setOffice] = useState();
  const isLoadingArray = useSelector(selectLoading);
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSimplifiedOffices());
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getEmployees(0, { officeIds: [office] }, employeeSearchName));
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [employeeSearchName, office]);

  const handleDelete = async () => {
    try {
      await dispatch(deleteEvent(event.id));

      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues(event, range, user)}
      validationSchema={Yup
        .object()
        .shape({
          allDay: Yup.bool(),
          description: Yup.string().max(5000),
          end: Yup
            .date()
            .typeError('Nincs helyesen megadva a záró dátum (éééé/hh/nn óó/pp)')
            // .when('start',
            //   (start, schema) => (start && schema.min(start,
            //     'Az esemény vége, nem lehet korábbi a kezdeténél.'))),
            .min(
              Yup.ref('start'),
              'Az esemény vége, nem lehet korábbi a kezdeténél.'
            )
            .required('Záró dátum megadása kötelező'),
          start: Yup
            .date()
            .typeError('Nincs helyesen megadva a kezdő dátum (éééé/hh/nn óó/pp)'),
          // .required('Kezdő dátum megadása kötelező'),
          employee: Yup
            .object()
            .nullable(),
          // .required('Munkatárs hozzáadása kötelező'),
          // office: Yup
          //   .string()
          //   .max(255),
          // .required('Iroda megadása kötelező')
        })}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            allDay: values.allDay,
            description: values.description,
            end: values.end.getTime(),
            start: values.start.getTime(),
            officeId: values?.office?.id,
            userId: values?.employee?.id,
            type: 'duty',
            title: `Ügyeletes: ${values?.employee?.name || ''}`
          };
          if (event) {
            await dispatch(updateEvent(event.id, data));
          } else {
            await dispatch(createDuty(data));
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Naptár frissítve!');

          if (!event && onAddComplete) {
            onAddComplete();
          }

          if (event && onEditComplete) {
            onEditComplete();
          }
        } catch (err) {
          console.error(err);
          toast.error('Naptár frissítése sikertelen!');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {event
                ? 'Ügyelet szerkesztése'
                : 'Ügyelet hozzáadása'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <AOCAutocomplete
              options={simplifiedOffices && simplifiedOffices}
              onChange={(_, newValue) => {
                setFieldValue('office', newValue);
                setOffice(newValue?.id);
              }}
              // textFieldOnChange={handlePlaceChange}
              label="Iroda"
              noOptionsText="Nincs ilyen iroda"
              value={values.office}
            />
            <Box sx={{ mt: 2 }}>
              <AOCAutocomplete
                options={employees?.data && employees?.data}
                onChange={(_, newValue) => setFieldValue('employee', newValue)}
                textFieldOnChange={(e) => setEmployeeSearchName(e.target.value)}
                label="Munkatárs"
                placeholder="Kezdje el beírni a munkatárs nevét."
                loadingArray={isLoadingArray.includes('GET_EMPLOYEES')}
                value={values.employee}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={values.allDay}
                    color="primary"
                    name="allDay"
                    onChange={handleChange}
                  />
                )}
                label="Egész nap"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Kezdete"
                ampm={false}
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                onChange={(date) => setFieldValue('start', date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.start}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DesktopDateTimePicker
                label="Vége"
                inputFormat="yyyy/MM/dd HH:mm"
                mask="____/__/__ __:__"
                ampm={false}
                onChange={(date) => setFieldValue('end', date)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    helperText="éééé/hh/nn óó/pp"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: null,
                    }}
                  />
                )}
                value={values.end}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Megjegyzés"
                name="description"
                multiline
                rows={3}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>
            {Boolean(touched.start && errors.start) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>
                  {errors.start}
                </FormHelperText>
              </Box>
            )}
            {Boolean(touched.end && errors.end) && (
              <Box>
                <FormHelperText error>
                  {errors.end}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            {event && (
              <IconButton onClick={() => handleDelete()}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Mégsem
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Mentés
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CalendarDutyEventForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object
};

export default CalendarDutyEventForm;
