import { createSlice } from '@reduxjs/toolkit';
// import { hintApi } from '../__fakeApi__/hintApi';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import { toggleLoading } from './loading';

const initialState = {
  hints: [],
  selectedHint: {},
  searchHintId: null,
};

const slice = createSlice({
  name: 'hints',
  initialState,
  reducers: {
    getHints(state, action) {
      state.hints = action.payload;
    },
    createHint(state, action) {
      state.hints.data.unshift(action.payload);
    },
    setSelectedHint(state, action) {
      state.selectedHint = action.payload;
    },
    setSearchHintId(state, action) {
      state.searchHintId = action.payload;
    },
    deleteHint(state, action) {
      state.hints = state.hints.data.filter((event) => event.id !== action.payload);
    },
    // selectHint(state, action) {
    //   const { hintId } = action.payload;
    //   state.selectedHintId = hintId;
    // },

    updateHint(state, action) {
      const hint = action.payload;

      state.hints.data = state.hints.data.map((_hint) => {
        if (_hint.id === hint.id) {
          return hint;
        }

        return _hint;
      });
    },
  }
});

export const { reducer } = slice;

export const setSelectedHint = (hint) => (dispatch) => {
  dispatch(slice.actions.setSelectedHint(hint));
};

/* eslint-disable */

export const setSearchHintId = (hintId) => (dispatch) => {
  dispatch(slice.actions.setSearchHintId(hintId));
};

export const getPropertyHints = (type) => async (dispatch) => {
  dispatch(toggleLoading('HINTS'));
  await axiosConfig.get(
    `suggestions/assignable?type=${type}`
  ).then((response) => {
    // const data = response.data;
    // dispatch(slice.actions.createApplicant(response.data));
    dispatch(slice.actions.getHints(response.data));
  }).catch(() => {
    toast.error('Tippek lekérése sikertelen');
  }).finally(() => {
    dispatch(toggleLoading('HINTS'));
  });
};

export const getHints = (pageNumber, filters, pageSize) => async (dispatch) => {
  // const data = await hintApi.getHints();
  dispatch(toggleLoading('HINTS'));
  await axiosConfig.post(
    `suggestions/filter?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 10}`,
    // 'suggestions/filter',
    filters
  ).then((response) => {
    // const data = response.data;
    // dispatch(slice.actions.createApplicant(response.data));
    dispatch(slice.actions.getHints(response.data));
  }).catch(() => {
    toast.error('Tippek lekérése sikertelen');
  }).finally(() => {
    dispatch(toggleLoading('HINTS'));
  });
};
/* eslint-disable */

export const createHint = (createData) => async (dispatch) => {
  // const data = await hintApi.createHint(createData);
  await axiosConfig.post(
    'suggestions',
    createData
  ).then((response) => {
    toast.success('Tipp felvétele sikeres');
    dispatch(slice.actions.createHint(response.data));
  }).catch((error) => {
    console.error(error);
    toast.error('Tipp felvétele sikertelen');
  }).finally(() => {

  });
};

export const updateHintState = (hintId, message) => async (dispatch) => {
  await axiosConfig.put(
    `suggestions/${hintId}/state`,
    message
    // createData
  ).then((response) => {
    toast.success('Tipp státusz módosítás sikeres');
    dispatch(slice.actions.updateHint(response.data));
  }).catch((error) => {
    console.error(error);
    toast.error('Tipp státusz módosítás sikertelen');
  }).finally(() => {

  });
}

// export const selectHint = (hintId) => async (dispatch) => {
//   dispatch(slice.actions.selectHint({ hintId }));
// };
export const updateHint = (hintId, update) => async (dispatch) => {
  await axiosConfig.put(
    `suggestions/${hintId}/state`,
    update
    // createData
  ).then((response) => {
    toast.success('Tipp státusz módosítás sikeres');
    dispatch(slice.actions.updateHint(response.data));
  }).catch((error) => {
    console.error(error);
    toast.error('Tipp státusz módosítás sikertelen');
  }).finally(() => {

  });
  // const data = await hintApi.updateHint({
  //   hintId,
  //   update
  // });
  // dispatch(slice.actions.updateHint(response.data));
};

export const deleteHint = (hintId) => async (dispatch) => {
  // await hintApi.deleteHint(hintId);

  // await axiosConfig.delete(
  //   'sugg'
  // )

  // dispatch(slice.actions.deleteHint(hintId));
};

export default slice;
