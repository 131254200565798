import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  // Collapse,
  Divider,
  Grid,
  // TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import React, { useState } from 'react';
import FullScreenImageVideoSwiper from '../FullScreenImageVideoSwiper';
import NumberFormatCustom from '../NumberFormatCustom';
import WebPresentationDetailsList from './WebPresentationDetailsList';
import parsePhoneNumber from 'libphonenumber-js';
import PropTypes from 'prop-types';

const { REACT_APP_FILE_URL } = process.env;

const attributeNameMap = {
  electricity: 'Áram:', // Villany
  water: 'Víz:',
  gas: 'Gáz:',
  heating: 'Fűtés:',
  cooling: 'Klíma:',
  sewer: 'Csatorna',
  comfortLevel: 'Komfortfokozat:',
  furnishing: 'Bútorozottság:',
  accessibility: 'Akadálymentesítés:',
  constructionType: 'Építési mód:',
  internalCondition: 'Belső állapot:',
  externalCondition: 'Külső állapot:',
  parking: 'Parkolás:',
  view: 'Kilátás:',
};

const WebPresentationBrowseResults = ({ property }) => {
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const [extendDescription, setExtendDescription] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenModal = () => setOpenModal(true);

  const handleOnClose = () => setOpenModal(false);
  /* eslint-disable */
  const {
    images = [],
    videos = [],
    attributeOptions = [],
    address = {}, // tetejere megy
    buildYear = null, // tetejere megy
    renovationYear = null, // tetejere megy
    hungarianPrimary = '',
    floorArea = null, // tetejere megy
    landArea = null, // tetejere megy
    numberOfRooms = null, // tetejere megy
    salePrice = null, // tetejere megy ??
    title = '', // legesleg tetejere megy
    user = {}, // jobboldali kartya
    utilityCost = null, // rezsi ?
    commonCost = null, // kozos ktg ?
    id = null,
    formattedAddress
  } = property || {}; // TODO: ask what about kozos ktg and rezsi ktg?

  const {
    accessibility = [], // felszereltseg
    comfortLevel = [], // felszereltseg
    constructionType = [], // ingatlan tulajdonsag
    contractType = [], // TODO: vszeg nem kell
    cooling = [], // kozmu
    electricity = [], // kozmu
    energyCertificate = [], // felszereltseg
    externalCondition = [], // felszereltseg
    furnishing = [], // felszereltseg
    gas = [], // kozmu
    heating = [], // kozmu
    internalCondition = [], // felszereltseg
    internet = [], // kozmu
    orientation = [], // felszereltseg
    parking = [], // felszereltseg
    petFriendly = [], // felszereltseg
    phone = [], // kozmu
    publicTags = [], // tetejere megy
    propertyType = [], // TODO: vszeg nem kell
    residential = [], // tetejere megy // TODO: it can be 3 more other like industrial just in my first example it is residental
    secondaryHeating = [], // kozmu
    sewer = [], // kozmu NOTE: i am not sure it is needed
    transactionType = [], // Elado vagy kiado nem tudom hova kell h akell
    view = [], // felszereltseg
    water = [], // kozmu
  } = attributeOptions
  /* eslint-enable */
  const utilities = [...electricity, ...water, ...gas, ...sewer];
  const equipment = [...heating, ...cooling, ...comfortLevel, ...furnishing, ...accessibility];
  const propertyProperties = [...constructionType, ...externalCondition, ...internalCondition, ...parking, ...view];
  const mainBoxes = [
    {
      title: 'Hely',
      // name: address?.formattedAddress || address?.formattedAddress ? address?.formattedAddress : `${address?.postalCode || ''} ${address?.city || ''}${address?.street ? ',' : ''} ${address?.street || ''} ${address?.streetNumber || ''}${address?.streetNumber ? '.' : ''} ${address?.floor || ''}${address?.floor && address?.door ? '/' : ''}${address?.door || ''}`
      // eslint-disable-next-line no-mixed-operators
      name: formattedAddress || ''
    },
    {
      title: 'Típus',
      name: residential[0]?.name || '',
    },
    {
      title: 'Telek',
      name: landArea
    },
    {
      title: 'Alapterület',
      name: floorArea
    },
    {
      title: 'Szobák',
      name: numberOfRooms
    },
    {
      title: 'Építés éve',
      name: buildYear
    },
    {
      title: 'Felújítva',
      name: renovationYear
    },
    // {
    //   title: 'Ingatlan azonosító',
    //   name: id
    // }
  ]; // TODO: not always residential

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          marginBottom={5}
        >
          <Grid
            container
            spacing={2}
            flexDirection={mobileDevice ? 'column' : 'row'}
            display="flex"
            justifyContent="space-between"
          >
            <Grid
              item
              lg={9}
              md={9}
              sm={7}
              xs={12}
            >
              <Typography
                variant="h4"
                sx={{
                  position: 'relative',
                  '&:after': {
                    backgroundColor: 'primary.main',
                    bottom: '-8px',
                    content: '" "',
                    height: '3px',
                    left: 0,
                    position: 'absolute',
                    width: '48px'
                  }
                }}
              >
                {title}
                {` (ID: ${id}) `}
              </Typography>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={5}
              xs={12}
            >
              <Typography
                variant="h3"
                textAlign="right"
                // sx={{
                //   position: 'relative',
                //   '&:after': {
                //     backgroundColor: 'primary.main',
                //     bottom: '-8px',
                //     content: '" "',
                //     height: '3px',
                //     right: 0,
                //     position: 'absolute',
                //     width: '48px'
                //   }
                // }}
                mt={mobileDevice && 3}
              >
                <NumberFormatCustom
                  value={salePrice}
                  displayType="text"
                  suffix=" Ft"
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            display="flex"
            justifyContent="flex-start"
            spacing={1}
          >
            {mainBoxes.map((box) => (
              <Grid
                item
                key={`${box.name}`}
              >
                <Box
                  border="1px solid #DCDCDC"
                  borderRadius={1}
                  padding={1}
                  paddingX={2}
                  // backgroundColor={box.title === 'Ingatlan azonosító' && '#692023'}
                  backgroundColor={box.title === 'Ingatlan azonosító' && '#962F32'}
                >
                  <Typography
                    variant="h6"
                    color={box.title === 'Ingatlan azonosító' && 'white'}
                  >
                    {box?.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={box.title === 'Ingatlan azonosító' && 'white'}
                  >
                    {box?.title === 'Telek' || box?.title === 'Alapterület' ? (
                      <NumberFormatCustom
                        value={box.name}
                        displayType="text"
                        suffix=" m&sup2;"
                      />
                    ) : (
                      <>
                        {box?.name}
                      </>
                    )}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mt={1}
        >
          <Grid
            container
            display="flex"
            justifyContent="flex-start"
            spacing={1}
          >
            {publicTags.map((tag) => (
              <Grid
                item
                key={`${tag.name}`}
              >
                <Box
                  border="1px solid #DCDCDC"
                  borderRadius={1}
                  padding={1}
                  paddingX={2}
                >
                  {/* <Typography
                    variant="h6"
                  >
                    {box.title}
                  </Typography> */}
                  <Typography
                    // variant="body1"
                    variant="h6"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {tag.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={5}
        mt={1}
        height="100%"
      >
        <Grid
          item
          md={8}
          sm={12}
          xs={12}
        >
          <Grid
            container
            spacing={1}
          >
            {images.length > 0 && (
              <>
                <Grid
                  item
                  md={8}
                  xs={12}
                >
                  <CardMedia
                    onClick={() => {
                      setCurrentIndex(0);
                      handleOpenModal();
                    }}
                    image={`${REACT_APP_FILE_URL}${images[0]?.source?.large}`}
                    sx={{
                      backgroundColor: 'background.default',
                      height: 400,
                      maxWidth: 820,
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: { md: 0, xs: 18 },
                      borderBottomLeftRadius: { xs: 0, md: 18 },
                      margin: 'auto',
                      cursor: 'pointer',
                      '&:hover': {
                        transition: '.5s',
                        filter: 'brightness(80%)',
                        // '-o-transition': '.5s',
                        // '-ms-transition': '.5s',
                        // '-moz-transition': '.5s',
                        // '-webkit-transition': '.5s',
                      }
                    }}
                  />
                </Grid>
                {images.length > 1 && (
                  <>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          md={12}
                          xs={6}
                        >
                          <CardMedia
                            onClick={() => {
                              setCurrentIndex(1);
                              handleOpenModal();
                            }}
                            image={`${REACT_APP_FILE_URL}${images[1]?.source?.large}`}
                            sx={{
                              backgroundColor: 'background.default',
                              height: 196,
                              maxWidth: 820,
                              borderTopRightRadius: { xs: 0, md: 18 },
                              borderBottomLeftRadius: { xs: 18, md: 0 },
                              margin: 'auto',
                              cursor: 'pointer',
                              '&:hover': {
                                transition: '.5s',
                                filter: 'brightness(80%)',
                              }
                            }}
                          />
                        </Grid>
                        {images.length > 2 && (
                          <Grid
                            item
                            md={12}
                            xs={6}
                          >
                            <CardMedia
                              onClick={() => {
                                setCurrentIndex(2);
                                handleOpenModal();
                              }}
                              image={`${REACT_APP_FILE_URL}${images[2]?.source?.large}`}
                              sx={{
                                backgroundColor: 'background.default',
                                height: 196,
                                maxWidth: 820,
                                borderBottomRightRadius: 18,
                                margin: 'auto',
                                cursor: 'pointer',
                                '&:hover': {
                                  transition: '.5s',
                                  filter: 'brightness(80%)',
                                }
                              }}
                            />
                            <Box
                              sx={{
                                position: 'relative',
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  setCurrentIndex(2);
                                  handleOpenModal();
                                }}
                                variant="h6"
                                color="white"
                                sx={{
                                  position: 'absolute',
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: '#962F32',
                                  padding: 1,
                                  borderBottomRightRadius: 18,
                                  borderTopLeftRadius: 18,
                                  cursor: 'pointer'
                                }}
                              >
                                {`+${images.length - 3 + videos.length} fotó `}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            mt={4}
          >
            <Grid item>
              <Box>
                <Typography
                  variant="h5"
                  padding={1}
                  marginBottom={1}
                >
                  Közművek
                </Typography>
                <WebPresentationDetailsList
                  list={utilities}
                  nameMap={attributeNameMap}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  variant="h5"
                  padding={1}
                  marginBottom={1}
                >
                  Felszereltség
                </Typography>
                <WebPresentationDetailsList
                  list={equipment}
                  nameMap={attributeNameMap}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  variant="h5"
                  padding={1}
                  marginBottom={1}
                >
                  Ingatlan tulajdonságai
                </Typography>
                <WebPresentationDetailsList
                  list={propertyProperties}
                  nameMap={attributeNameMap}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            container
            mt={5}
          >
            <Typography
              variant="h5"
              marginBottom={2}
            >
              Ingatlan leírása
            </Typography>
            <Grid item>
              {/* <Collapse
                in={extendDescription}
                collapsedSize={280}
              > */}
              <Typography
                dangerouslySetInnerHTML={{ __html: hungarianPrimary }}
              />
              {/* </Collapse> */}
              {/* <Button
                onClick={() => setExtendDescription(!extendDescription)}
                variant="text"
                size="small"
                color="inherit"
                disableFocusRipple
                disableElevation
                disableRipple
              >
                Részletek megjelenítése
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
        // sx={{ height: 'auto' }}
        >
          <Card
            variant="outlined"
            // sx={{ height: '100%' }}
            sx={{
              // position: '-webkit-sticky',
              position: 'sticky',
              top: 50,
              // top: '20%',
              padding: 2
            }}
          >
            <CardHeader title={(
              <>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Grid>
                    <Avatar
                      // src={user?.images?.length > 0 ? `${REACT_APP_FILE_URL}${user?.images[0].source?.original}` : null}
                      // src="https://bo.lidohome.hu/public-images/iroda-1630/felhasznalok/195-v2/jpg.jpg"
                      src="\static\mock-images\avatars\avatar-iulia_albu.png"
                      sx={{
                        height: 120,
                        width: 120,
                        mr: 2
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      // variant="body1"
                      variant="h6"
                    >
                      {user.name}
                    </Typography>
                    <Typography
                      // variant="body1"
                      variant="subtitle1"
                    >
                      {user?.title || 'LidoHome'}
                    </Typography>
                  </Grid>
                </Box>
              </>
            )}
            />
            <Divider />
            <CardContent>
              <Box sx={{ mt: 1 }}>
                <Box
                  display="flex"
                >
                  <Typography
                    variant="h6"
                    sx={{ mr: 2 }}
                  >
                    Telefon:
                  </Typography>

                  <Typography
                    variant="body1"
                    mt={0.4} /* eslint-disable */
                  >
                    {user.phone ? (
                      user.phone.includes('*') ? user.phone : parsePhoneNumber(user?.phone).formatInternational()
                    ) : (
                      'Nincs megadva' /* eslint-enable */
                    )}
                    {/* {user.phone || '+36 20 443 5564'} */}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                >
                  <Typography
                    variant="h6"
                    sx={{ mr: 3 }}
                  >
                    E-mail:
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={0.4}
                  >
                    {/* {user.email || 'testemail@lidohome.hu'} */}
                    testemail@lidohome.hu
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            <Box mt={1}>
              <Button
                variant="contained"
                fullWidth
              >
                Helyszíni bemutatót kérek!
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      {
        images.length > 0 && (
          <FullScreenImageVideoSwiper
            open={openModal}
            handleOnClose={handleOnClose}
            images={images}
            videos={videos}
            currentIndex={currentIndex}
          // videos={propertyVideos}
          />
        )
      }
    </>
  );
};

WebPresentationBrowseResults.propTypes = {
  property: PropTypes.object,
};

export default WebPresentationBrowseResults;
