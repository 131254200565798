/* eslint-disable */
import Quill from 'react-quill';
import { experimentalStyled } from '@material-ui/core/styles';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import 'react-quill/dist/quill.bubble.css';

const wordCounter = (words, setCount, wlimit) => {
  // const lines = (words?.match(/<p>.*?<\/p\1> | <li>.*?<\/li\1> /g) || []);
  const lines = (words?.match(/<p>.*?<\/p>|<li>.*?<\/li>/g) || []);
  const limit = wlimit || 100;
  let lLength = lines.length;
  lines.forEach((line) => {
    let lineString = line?.replace(/<[^>]*>?/gm, '')?.length;
    while (lineString > limit) {
      lLength += 1;
      lineString -= limit;
    }
  });
  // const lineLength = (words?.match(/<p>|<li>/g) || []).length; // sor számláló
  // const charLength = words?.replace(/<[^>]*>?/gm, '')?.length; // karakter számláló
  // console.log(words, 'words', lines);
  setCount(lLength);
};

const QuillEditorRoot = experimentalStyled((props) => {
  const [count, setCount] = useState(0);
  return (
    <>
      <Quill
        /* eslint-disable-next-line */
        ref={(el) => {
          // console.log(el, el?.props?.value, props.value, 'props'),
          wordCounter(el?.props?.value, setCount, props.lineLength);
        }}
        // readOnly={count >= 10 && true}
        /* eslint-disable */
        theme={props.quillTheme || "snow"}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline',],        // toggled buttons
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            // [{ 'size': ['small', false, 'large'] }], // custom dropdown
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            // [{ 'align': [] }],
            ['clean']],
          clipboard: {
            matchVisual: false,
          }
        }}
        /* eslint-disable */
        {...props}
      />
      <Grid
        textAlign="right"
        p={1}
        mt={5}
      >
        {/* eslint-disable-next-line */}
        {count} sor
        <b>
          {count > (props.lineLimit || 10) && ' túllépted a karakter limitet'}
        </b>
      </Grid>
    </>
  );
})(({ theme }) => ({
  border: 0,
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  '& .ql-snow.ql-toolbar': {
    borderColor: theme.palette.divider,
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    '& .ql-picker-label:hover': {
      color: theme.palette.primary.main
    },
    '& .ql-picker-label.ql-active': {
      color: theme.palette.primary.main
    },
    '& .ql-picker-item:hover': {
      color: theme.palette.primary.main
    },
    '& .ql-picker-item.ql-selected': {
      color: theme.palette.primary.main
    },
    '& button:hover': {
      color: theme.palette.primary.main,
      '& .ql-stroke': {
        stroke: theme.palette.primary.main
      }
    },
    '& button:focus': {
      color: theme.palette.primary.main,
      '& .ql-stroke': {
        stroke: theme.palette.primary.main
      }
    },
    '& button.ql-active': {
      '& .ql-stroke': {
        stroke: theme.palette.primary.main
      }
    },
    '& .ql-stroke': {
      stroke: theme.palette.text.primary
    },
    '& .ql-picker': {
      color: theme.palette.text.primary
    },
    '& .ql-picker-options': {
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[10],
      padding: theme.spacing(2)
    }
  },
  '& .ql-snow.ql-container': {
    borderBottom: 'none',
    borderColor: theme.palette.divider,
    borderLeft: 'none',
    borderRight: 'none',
    flexGrow: 1,
    '& .ql-editor': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      padding: theme.spacing(2),
      '&.ql-blank::before': {
        color: theme.palette.text.secondary,
        fontStyle: 'normal',
        left: theme.spacing(2)
      }
    }
  },
  // '& .ql-snow.ql-picker.ql-header.ql-picker-item[data-value="1"]:before': {
  //   content: "Fejléc"
  // }
}));

const QuillEditor = (props) => (
  <QuillEditorRoot {...props} />
);

export default QuillEditor;
