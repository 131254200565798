/* eslint-disable arrow-body-style */
import { useRef, useState, useEffect, Fragment } from 'react';
// import { subDays, subHours } from 'date-fns';
import {
  // Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  CircularProgress
} from '@material-ui/core';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import { checkNewActiveNotifications, getActiveNotifications, getActiveNotificationsNextPage, notificationSeen, clearNotifications } from '../../slices/task';
import { useDispatch, useSelector } from '../../store';
// import { makeClickableLink } from '../../utils/helperFunctions';
import Scrollbar from '../Scrollbar';
import parse from 'html-react-parser';
import { selectLoading } from '../../slices/loading';

// const now = new Date();

/* NOTE: It is mounting after the user login.
  If the user clicks on the title of a notification, then decrement the (unseen) badge number,
  which is actually the totalElements of the notifications.
  in every 5 minutes get the notifications again, and check if the totalElements is changed.
  If change drop a toast and update the whole active notifications object.
   */

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text
  }
}));

// const notifications = [
//   {
//     id: '5e8883f1b51cc1956a5a1ec0',
//     createdAt: subHours(now, 2).getTime(),
//     description: 'Teszt értesítés',
//     title: 'Új értesítés',
//     type: 'order_placed'
//   },
//   {
//     id: '5e8883f7ed1486d665d8be1e',
//     createdAt: subDays(now, 1).getTime(),
//     description: 'Teszt értesítés',
//     title: 'Új értesítés',
//     type: 'new_message'
//   },
//   {
//     id: '5e8883fca0e8612044248ecf',
//     createdAt: subDays(now, 3).getTime(),
//     description: 'Teszt értesítés',
//     title: 'Új értesítés',
//     type: 'item_shipped'
//   }
// ];

// eslint-disable-next-line no-unused-vars
const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.task);
  const [pageNumber, setPageNumber] = useState(0);
  const isLoadingArray = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getActiveNotifications());
  }, []);

  const MINUTE_MS = 60000 * 5; /* NOTE: 5 minutes */
  // const MINUTE_MS = 10000; /* NOTE: 10 sec for test */

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkNewActiveNotifications());
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const handleOpen = () => {
    if (notifications?.data?.length === 0 || notifications?.data?.length === undefined) {
      dispatch(getActiveNotifications());
    }
    setOpen(true);
  };

  const handleClose = () => {
    setPageNumber(0);
    dispatch(clearNotifications());
    setOpen(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    dispatch(getActiveNotificationsNextPage(pageNumber + 1));
  };

  useEffect(() => {
    if (open) {
      document.getElementById('root').style.filter = 'blur(2px)';
      document.getElementById('root').style.transition = '0.3s';
    } else {
      document.getElementById('root').style.filter = 'blur(0px)';
      document.getElementById('root').style.transition = '0.3s';
    }
  }, [open]);

  const seenAllNotificaiton = () => {
    notifications?.data?.forEach((notification) => {
      if (notification.isNew) {
        dispatch(notificationSeen(notification.id));
      }
    });
    dispatch(getActiveNotifications()); /* NOTE: getActiveNotifications again bc it updates the badge, and this is now only make the first 50 notifications seen. */
    handleClose();
  };

  return (
    <>
      <Tooltip title="Értesítések">
        <IconButton
          className={classes.icon}
          ref={anchorRef}
          onClick={handleOpen}
        // disabled
        >
          <Badge
            color="error"
            badgeContent={notifications?.totalElements}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 450 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Értesítések
          </Typography>
        </Box>
        <Divider />
        {isLoadingArray.includes('GET_NOTIFICATIONS') && notifications?.data?.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            my={2}
          >
            <CircularProgress />
          </Box>
        ) : null}
        {notifications?.data?.length === 0 && !isLoadingArray.includes('GET_NOTIFICATIONS')
          ? (
            <>
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Nincs új értesítés
                </Typography>
              </Box>
            </>
          )
          : (
            <>
              <Scrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ maxHeight: '75vh', width: 450, overflowY: 'auto', overflowX: 'hidden' }}>
                  <List disablePadding>
                    {notifications?.data?.map((notification) => {
                      // const Icon = iconsMap[notification?.type];
                      return (
                        <Fragment key={notification.id}>
                          <ListItem
                            divider
                            key={notification.id}
                            sx={{
                              background: (theme) => (notification.isNew ? theme.palette.background.default : theme.palette.background.paper),
                              // cursor: 'pointer'
                            }}
                          >
                            {/* <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText'
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar> */}
                            <ListItemText
                              primary={(
                                <Tooltip title="Értesítés megjelölése olvasottként">
                                  <Link
                                    color="textPrimary"
                                    onClick={() => dispatch(notificationSeen(notification.id))}
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {notification.title}
                                  </Link>
                                </Tooltip>
                              )}
                              secondary={(
                                notification?.message && parse(notification.message)
                              )}
                            // secondary={notification.message}
                            // secondary={notification?.message && makeClickableLink(notification.message)}
                            />
                          </ListItem>
                        </Fragment>
                      );
                    })}
                    {notifications.totalPages > pageNumber + 1 && !isLoadingArray.includes('GET_NEXTPAGE_NOTIFICATIONS') && !isLoadingArray.includes('GET_NOTIFICATIONS') && (
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          onClick={handleNextPage}
                          fullWidth
                        >
                          További találatok betöltése...
                        </Button>
                        <Divider />
                      </Box>

                    )}
                    {notifications.totalPages > pageNumber + 1 && isLoadingArray.includes('GET_NEXTPAGE_NOTIFICATIONS') && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="center"
                          my={2}
                        >
                          <CircularProgress />
                        </Box>
                        <Divider />
                      </>
                    )}
                  </List>
                </Box>
              </Scrollbar>
            </>
          )}
        {notifications.totalElements > 0 && (
          <>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={seenAllNotificaiton}
              >
                Összes megjelölése olvasottként
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
