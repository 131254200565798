import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import Logo from '../Logo';
import NotificationsPopover from './NotificationsPopover';
import FolderOpen from '../../icons/FolderOpen';
import DashboardNavItem from '../DashboardNavItem';
// import ExternalLinkIcon from '../../icons/ExternalLink';
// import LanguagePopover from './LanguagePopover';
import EmployeeModal from '../modals/EmployeeModal';
import Hotkeys from '../Hotkeys';
import TaskListPopover from './TaskListPopover';
import { isCompanyManager, isOfficeManagerOrAbove } from '../../utils/helperFunctions';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text
  }
}));

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.navigation.background,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();
  /* eslint-disable-next-line */
  const classes = useStyles(); /** NOTE: This could be usefull in the future */

  const navigationData = [
    {
      title: 'Lido',
      items: [
        {
          title: 'Kezdőlap',
          path: '/',
        },
        {
          title: 'Ingatlanok',
          path: '/properties',
        },
        {
          title: 'Ügyfelek',
          children: [
            {
              title: 'Megbízó ügyfelek',
              path: '/clients/sell'
            },
            {
              title: 'Kereső ügyfelek',
              path: '/clients/buy'
            },
            {
              title: 'Összes ügyfél',
              path: '/clients/browse'
            }
          ]
        },
        {
          title: 'Dokumentumtár',
          path: '/documents',
          icon: <FolderOpen fontSize="small" />,
        },
        {
          title: 'Irodai prezentáció',
          icon: <FolderOpen fontSize="small" />,
          path: '/ip',
        },
        {
          title: 'További',
          children: [
            {
              title: 'Feladatok',
              path: '/tasks',
              // disabled: true
            },
            {
              title: 'Generálás',
              path: '/generation/new',
            },
            {
              title: 'HR',
              path: '/hr',
              disabled: !isCompanyManager(user?.roles)
            },
            {
              title: 'Híváslista',
              path: '/calls'
            },
            {
              title: 'Közvetítési nyilatkozatok',
              path: '/statements'
            },
            {
              title: 'Ingatlanok jóváhagyása',
              path: '/properties-unapproved'
            },
            {
              title: 'Saját képzéseim',
              path: '/owntrainings',
              // disabled: true
            },
            {
              title: 'Képzés',
              path: '/trainings/otrainings',
              disabled: user?.isPartner || !isOfficeManagerOrAbove(user?.roles)
            },
            {
              title: 'Magánhirdetések',
              path: '/invoices',
              // disabled: true
            },
            {
              title: 'Projektek',
              path: '/projects/browse',
              // disabled: !isOfficeManagerOrAbove(user.roles)
              disabled: false,
            },
            {
              title: 'Tipp adás',
              path: '/add-hint',
              // disabled: true
            },
            {
              title: 'Értékelés',
              path: '/ratings',
              disabled: !isOfficeManagerOrAbove(user?.roles)
            },
            {
              title: 'Blog',
              path: '/blog',
              disabled: !(!user?.isPartner && user?.roles?.includes('ROLE_CONTENT_MANAGER'))
            },
            {
              title: 'Statisztikák',
              path: '/statistics',
              disabled: !isOfficeManagerOrAbove(user?.roles)
            },
            // {
            //   title: 'Tipp modul',
            //   path: '/hint'
            // }
          ]
        },
        // {
        //   title: 'Statisztika',
        //   path: '/kanban',
        // },
        // {
        //   title: 'Vállalkozás',
        //   path: '/calendar',
        // }
      ]
    }
  ];

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              mr: '2.8vw',
              display: {
                lg: 'inline',
                xs: 'none'
              },
              height: 45,
              width: 150
            }}
          />
        </RouterLink>
        {navigationData.map((sections) => (
          sections.items.map((item) => (
            <Box
              key={item.title}
              sx={{
                px: 1,
                display: {
                  lg: 'flex',
                  xs: 'none'
                }
              }}
            >
              <DashboardNavItem navigationData={item} />
            </Box>
          ))
        ))}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {/* <Button
          href="https://my.aocsystem.com:444/LidoHome/home.html"
          target="_blank"
          rel="noreferrer"
          variant="text"
          color="primary"
        >
          Kattintható prototípus
        </Button> */}
        <Box sx={{ ml: 0 }}>
          <Hotkeys
            buttonVariant="contained"
            buttonText="Gyorsgombok"
          />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        {/* <Box sx={{ ml: 1 }}>
          <Tooltip title="Külső weblap">
            <IconButton
              className={classes.icon}
              href="https://lidohome.hu/"
              target="_blank"
              rel="noreferrer"
            >
              <ExternalLinkIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
        <Box sx={{ ml: 1 }}>
          <TaskListPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        {/* <Box sx={{ ml: 1 }}>
          <LanguagePopover component={IconButton} />
        </Box> */}
        <Box sx={{ ml: 3 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
      <EmployeeModal />
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
