import React, { Fragment } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Box, ListItem, Typography } from '@mui/material';

const defaultGetOptionLabel = (option) => option.label || option.name || option;

const defaultIsOptionToValue = (option, itemValue) => option?.id === itemValue?.id;

const defaultRenderOption = (renderProps, option) => (
  <Fragment key={option.id || option.value || option}>
    <Box
      component={ListItem}
      {...renderProps}
    >
      <Typography>{option.name || option.label || option}</Typography>
    </Box>
  </Fragment>
);

const AOCAutocomplete = (
  {
    autoFocus,
    getOptionLabel,
    isOptionEqualToValue,
    multiple,
    limitTags,
    options,
    disableCloseOnSelect,
    onChange,
    disableClearable,
    value,
    label,
    loadingArray,
    readOnly,
    placeholder,
    variant,
    freeSolo,
    size,
    fullWidth,
    textFieldOnChange,
    noOptionsText,
    error,
    helperText,
    renderOption,
    required,
    disabled,
    getOptionDisabled,
    sx,
    filterOptions
  }
) => (
  <>
    <Autocomplete
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionToValue}
      multiple={multiple}
      fullWidth={fullWidth}
      size={size}
      getOptionDisabled={getOptionDisabled}
      limitTags={limitTags || 3}
      loadingText="Töltés..."
      loading={loadingArray}
      options={options || []}
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={noOptionsText || 'Nincs találat'}
      onChange={onChange}
      disableClearable={disableClearable}
      disabled={disabled}
      value={value}
      readOnly={readOnly}
      variant={variant}
      freeSolo={freeSolo}
      openText=""
      closeText=""
      clearText="Összes kiválasztás visszavonása"
      sx={sx}
      // renderOption={renderOption}
      renderOption={renderOption || defaultRenderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          label={label}
          required={required}
          placeholder={placeholder}
          onChange={textFieldOnChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingArray ? (
                  <CircularProgress
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  </>
);

AOCAutocomplete.propTypes = {
  filterOptions: PropTypes.func,
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  textFieldOnChange: PropTypes.func,
  value: PropTypes.any,
  isOptionEqualToValue: PropTypes.func,
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  label: PropTypes.string,
  getOptionLabel: PropTypes.any,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  noOptionsText: PropTypes.string,
  loadingArray: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  readOnly: PropTypes.bool,
  freeSolo: PropTypes.bool,
  fullWidth: PropTypes.bool,
  renderOption: PropTypes.func,
  sx: PropTypes.object,
  required: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
};

export default AOCAutocomplete;
