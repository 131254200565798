import { Box, CircularProgress, Stack } from '@material-ui/core';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TEXT_COLOR = '#888';
const SIZE = 70;
const THICKNESS = 3;

const CircularProgressBar = ({ value, title, color, withText }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    direction="row"
    spacing={2}
  >
    {title && (
      <Typography
        variant="body2"
        color={TEXT_COLOR}
      >
        {title}
      </Typography>
    )}

    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'relative'
      }}
    >
      <CircularProgress
        thickness={THICKNESS}
        size={SIZE}
        sx={{
          color: '#f7f6f6',
          position: 'absolute'
        }}
        variant="determinate"
        value={100}
      />
      <CircularProgress
        thickness={THICKNESS}
        size={SIZE}
        sx={{
          color: color ?? 'green',
        }}
        variant="determinate"
        value={value || 0}
      />

      {withText && (
        <Typography
          color={TEXT_COLOR}
          variant="h6"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {value || 0}
          %
        </Typography>
      )}
    </Box>
  </Stack>
);

export default CircularProgressBar;

CircularProgressBar.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
  withText: PropTypes.bool
};
