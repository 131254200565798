import { useRef, useState, useEffect, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Link,
  Typography,
  Tab,
  Tabs,
  Divider,
  Link as MaterialLink,
  IconButton,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Menu,
  ListItemText,
  useMediaQuery,
  Badge,
  Button,
  Collapse,
} from '@material-ui/core';
import ChevronDownIcon from '../../../icons/ChevronDown';
import {
  ContactPageOutlined,
  ArchiveOutlined,
  EditOutlined,
} from '@material-ui/icons';
import getInitials from '../../../utils/getInitials';
import ClientBasicDetails from './ClientBasicDetails';
// import ClientContactDetails from './ClientContactDetails'; TODO: get ContactHistory from backend
// eslint-disable-next-line no-unused-vars
import ClientRecommendations from './ClientRecommendations';
import parsePhoneNumber from 'libphonenumber-js';
import { useDispatch } from '../../../store';
import {
  activateClient,
  archiveClient,
  selectClientId,
  // deleteClient,
  // updateClient
} from '../../../slices/client';
import SearchIcon from '../../../icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import DotsHorizontalIcon from '../../../icons/DotsHorizontal';
import ClockIcon from '../../../icons/Clock';
import CallIcon from '@material-ui/icons/Call';
import huLocale from 'date-fns/locale/hu';
// import NumberFormatCustom from '../../NumberFormatCustom';
// import deepCopy from '../../../utils/deepCopy';
import ClientSearches from './ClientSearches';
import ClientOrders from './ClientOrders';
import PermissionsGate from '../../permissions/PermissionsGate';
import { SCOPES } from '../../permissions/permissionsMap';
import ClientPresentation from './ClientPresentation';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ClientCallList from './ClientCallList';
import useAuth from '../../../hooks/useAuth';
import { isOfficeManagerOrAbove } from '../../../utils/helperFunctions';
import { CardContent } from '@mui/material';
import { ClientMessage } from '../../../pages/dashboard/ClientMessage';

const ClientCard = (props) => {
  const { client, mode, filter, closeSearchBar, searchComponent, setMode, ...other } = props;
  const { user } = useAuth();
  // const [lastProperty, setLastProperty] = useState({});

  // useEffect(() => {
  //   if (filter === 'sell') {
  //     /* eslint-disable-next-line */
  //     client?.properties && client?.properties[0] && setLastProperty(client.properties[0]);
  //   } else if (filter === 'buy') {
  //     /* eslint-disable-next-line */
  //     client?.searches && client?.searches[0] && setLastProperty(client.searches[0]);
  //   }
  // }, []);

  // const currency = 'HUF'; /** Backend doesn't send currency information */
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [currentTab, setCurrentTab] = useState('details');
  const dispatch = useDispatch();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  useEffect(() => {
    if (mobileDevice && filter !== 'all') {
      if (setMode) {
        setMode('grid');
      }
    }
  }, [mobileDevice]);

  const handleIsCollapse = () => {
    setIsCollapse((prev) => !prev);
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  // const handleMarkArchiveClient = () => { TODO: is it possible to mark an employee for archive?
  //   const clonedClient = deepCopy(client);
  //   clonedClient.state = 4;
  //   dispatch(updateClient(client.id, clonedClient));
  // };

  // const handleArchiveClient = () => {
  //   const clonedClient = deepCopy(client);
  //   clonedClient.state = 4;
  //   dispatch(updateClient(client.id, clonedClient));
  // };

  const tabs = [
    { label: 'Alapadatok', value: 'details', visible: true },
    { label: 'Megbízások', value: 'orders', visible: filter === 'all' || filter === 'sell' },
    { label: 'Keresések', value: 'searches', visible: filter === 'all' || filter === 'buy' },
    { label: 'Kiajánlások', value: 'recommendations', visible: true },
    { label: 'Híváslista', value: 'contact', visible: true },
    { label: 'Megjegyzés', value: 'message', visible: true }
  ];

  const handleCall = (id) => {
    dispatch(selectClientId(id, searchComponent));
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const getAvatarStatus = (clientName, clientStatus) => {
    const statusMap = [
      {
        color: '#4caf50', // green
        text: 'saját ügyfél' // protected
      },
      {
        color: '#962F32', // dark red
        text: 'irodai ügyfél' // public
      },
      {
        color: '#c62828', // light red
        text: 'inaktív' // inactive
      },
      {
        color: '#172b4d', // blue
        text: 'archivált' // archived
      },
      {
        color: '#b0bec5', // grey
        text: 'archiválásra jelölt' // mark archive TODO: is it existing?
      },
    ];

    const {
      // text,
      color
    } = statusMap[clientStatus - 1] || statusMap[3];
    return (
      <>
        {/* <Tooltip // NOTE: it can add a tooltip to the picture
          title={text || 'Nincs megadva'}
        > */}
        <Avatar
          alt="Author"
          sx={{ backgroundColor: color }}
        // src={client.author.avatar}
        >
          {getInitials(clientName || 'Nincs megadva')}
        </Avatar>
        {/* </Tooltip> */}
      </>
    );
  };

  // const handleStatusId = client?.clientEmployees?.find((clientEmployee) => clientEmployee?.employee?.id === user.id)?.state || null;
  const handleStatusId = client?.clientEmployees?.find((clientEmployee) => clientEmployee?.employee?.id === user.id)?.state;


  const canIEdit = () => {
    if (isOfficeManagerOrAbove(user.roles)) {
      return true;
    }
    if (client?.clientEmployees?.find((clientEmployee) => clientEmployee?.employee?.id === user.id)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Card
        {...other}
        sx={{ height: '100%' }}
      >
        <Grid
          container
          spacing={1}
          p={2}
        >
          <Grid
            item
            lg="auto"
            xs={12}
          >
            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              p: 1,
              borderRadius: 1,
              backgroundColor: 'background.default',
            }}
            >
              {client && client.name && client.state && getAvatarStatus(client?.name, client?.state)}
              <Grid
                container
                alignItems="center"
                spacing={1}
                sx={{ ml: 0 }}
              >
                <Grid item>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    onClick={closeSearchBar}
                    to={`/clients/${client.id}`}
                    variant="h6"
                  >
                    {client?.name || 'Nincs megadva'}
                  </Link>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      onClick={closeSearchBar}
                      to={`/clients/${client.id}`}
                      variant="subtitle2"
                    >
                      {`(ID: ${client?.id})`}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <MaterialLink
                    href={client?.defaultPhone && `tel:${client?.defaultPhone}`}
                    onClick={() => client?.defaultPhone && handleCall(client?.id)}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {/* eslint-disable-next-line */
                        // isValidPhoneNumber(client?.phone) ? parsePhoneNumber(client?.phone).formatInternational() : `${client?.phone} Nem valódi telefonszám!` // TODO: fixme do i need to notify the user about it?
                        /* eslint-disable-next-line */
                        client?.defaultPhone ? (
                          (client?.defaultPhone?.includes('*') || !isValidPhoneNumber(client?.defaultPhone)) ? client?.defaultPhone : parsePhoneNumber(client?.defaultPhone)?.formatInternational()
                        ) : (
                          'Nincs megadva'
                        )
                      }
                    </Typography>
                  </MaterialLink>
                  <MaterialLink
                    href={`mailto:${client?.defaultEmail}` || 'Nincs megadva'}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {client?.defaultEmail || 'Nincs megadva'}
                    </Typography>
                  </MaterialLink>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            flexGrow={8}
          >
            <Box
              sx={{ p: 1 }}
            >
              <Grid
                item
                display="flex"
              >
                {client?.clientEmployees?.length > 0 ? (
                  <>
                    {client?.clientEmployees?.map((clientEmployee, index) => (
                      <Fragment key={clientEmployee.employee.id || index}>
                        <Typography
                          variant="subtitle2"
                          mr={index === client?.clientEmployees?.length - 1 ? 0 : 1}
                        >
                          {`${clientEmployee?.employee?.name || 'Nincs megadva'}${index === client?.clientEmployees?.length - 1 ? '' : ', '}`}
                        </Typography>
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <Typography
                    variant="subtitle2"
                  >
                    Nincs kapcsolattartó
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                display="flex"
                alignItems="center"
              >
                {mode === 'grid' || mobileDevice ? (
                  <Tooltip
                    title="Utolsó kapcsolattartás"
                  >
                    <ClockIcon fontSize="small" />
                  </Tooltip>
                )
                  : (
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      Utolsó kapcsolattartás:
                    </Typography>
                  )}
                {client?.clientEmployees?.length > 0 ? (
                  <>
                    {client?.clientEmployees?.map((clientEmployee, index) => (
                      <Fragment key={clientEmployee.id || index}>
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          sx={{ ml: 1 }}
                        >
                          {clientEmployee?.lastInteractionDate ? (
                            <>
                              {`${formatDistanceToNowStrict(clientEmployee?.lastInteractionDate, { locale: huLocale }) || 'Nincs megadva'}${index === client?.clientEmployees?.length - 1 ? '' : ', '}`}
                            </>
                          ) : (
                            'Nincs megadva'
                          )}
                        </Typography>
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ ml: 1 }}
                  >
                    Nem ismert
                  </Typography>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 1, p: 1 }}
          >
            <Grid
              container
              spacing={2}
              display="flex"
              justifyContent="flex-end"
            >
              {filter !== 'sell' && (
                <Grid item>
                  <Tooltip
                    title={`Keresések száma ${client?.numberOfSearches}`}
                  >
                    <Badge
                      color="primary"
                      badgeContent={client?.numberOfSearches}
                    >
                      <SearchIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsCollapse(true);
                          setCurrentTab('searches');
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              )}
              {filter !== 'buy' && (
                <Grid item>
                  <Tooltip
                    title={`Ingatlanok száma ${client?.numberOfProperties || 0}`}
                  >
                    <Badge
                      color="primary"
                      badgeContent={client?.numberOfProperties || 0}
                    >
                      <HomeIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsCollapse(true);
                          setCurrentTab('orders');
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Grid>
              )}
              {!searchComponent && (
                <Grid item>
                  <Tooltip
                    title="Gyors gombok"
                  >
                    <IconButton
                      edge="end"
                      onClick={handleMenuOpen}
                      ref={moreRef}
                      size="small"
                    >
                      <DotsHorizontalIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {/* // NOTE: it is needed when we show the last search or assignment of the client */}
              {/* {filter === 'all' && ( */}
              <Grid item>
                <Tooltip
                  title="Részletek"
                >
                  <IconButton
                    onClick={handleIsCollapse}
                    size="small"
                  >
                    <ChevronDownIcon
                      sx={{
                        transform: isCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: '0.2s',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              {/* )} */}
            </Grid>
          </Grid>
          {searchComponent && (
            <Grid
              item
              // flexGrow={3}
              display="flex"
              alignItems="center"
              mb={mobileDevice && 2}
            >
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={1}
              >
                <Grid
                  item
                  xs={6}
                >
                  <MaterialLink
                    underline="none"
                    href={`tel:${client?.defaultPhone}` || 'Nincs megadva'}
                  >
                    <Button
                      size="small"
                      color="primary"
                      sx={{ width: '100%' }}
                      onClick={() => handleCall(client?.id)}
                      variant="outlined"
                      startIcon={<CallIcon />}
                    >
                      Hívás
                    </Button>
                  </MaterialLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <Button
                    size="small"
                    color="primary"
                    component={RouterLink}
                    sx={{ width: '100%' }}
                    to={`/clients/${client?.id}` || undefined}
                    onClick={closeSearchBar}
                    variant="outlined"
                    startIcon={<ContactPageOutlined />}
                  >
                    Adatlap
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* {filter !== 'all' && ( //NOTE: shows the last search or assignment of the clint but Domonik says they doesn't need it
          <>
            <Divider sx={{ mt: -1 }} />
            <Grid
              container
              mb={0}
              mt={0}
            >
              <Grid
                item
                md={mode === 'grid' ? 12 : 4}
                sm={mode === 'grid' ? 12 : 4}
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{
                  px: 1,
                  py: 0,
                  display: 'flex',
                  width: '100%',
                  // flexGrow: 6
                }}
              >
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Tranzakció típusa
                      </Typography>
                    </Grid>
                    <Grid item>
                      {lastProperty?.attributeOptions?.transactionType ? (
                        <>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {lastProperty?.attributeOptions?.transactionType[0].name}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            Nincs megadva
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Ingatlan típusa
                      </Typography>
                    </Grid>
                    <Grid item>
                      {lastProperty?.attributeOptions?.propertyType ? (
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          textAlign="end"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {lastProperty?.attributeOptions?.propertyType[0].name || 'Nincs megadva'}
                        </Typography>
                      ) : (
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          textAlign="end"
                        >
                          Nincs megadva
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={mode === 'grid' ? 12 : 4}
                sm={mode === 'grid' ? 12 : 4}
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{
                  px: 1,
                  py: 0,
                  display: 'flex',
                  width: '100%',
                  // flexGrow: 6
                }}
              >
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Ár
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {filter === 'buy' ? (
                          <>
                            {lastProperty?.salePriceFrom && (
                              <>
                                <NumberFormatCustom
                                  value={`${currency === 'HUF' ? lastProperty?.salePriceFrom / 1000000 : lastProperty?.salePriceFrom}`}
                                  suffix={`${currency === 'HUF' ? ' M (Ft)' : '€'}`}
                                  displayType="text"
                                />
                                {' - '}
                                <NumberFormatCustom
                                  value={`${currency === 'HUF' ? lastProperty?.salePriceTo / 1000000 || 0 : lastProperty?.salePriceTo || 0}`}
                                  suffix={`${currency === 'HUF' ? ' M (Ft)' : '€'}`}
                                  displayType="text"
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <NumberFormatCustom
                              value={`${currency === 'HUF' ? lastProperty?.salePrice / 1000000 || 0 : lastProperty?.salePrice || 0}`}
                              suffix={`${currency === 'HUF' ? ' M (Ft)' : '€'}`}
                              displayType="text"
                            />
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Alapterület
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {filter === 'buy' ? (
                          <>
                            <NumberFormatCustom
                              value={`${lastProperty?.floorAreaFrom || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                            {' - '}
                            <NumberFormatCustom
                              value={`${lastProperty?.floorAreaTo || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                          </>
                        ) : (
                          <>
                            <NumberFormatCustom
                              value={`${lastProperty?.floorArea || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={mode === 'grid' ? 12 : 4}
                sm={mode === 'grid' ? 12 : 4}
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{
                  px: 1,
                  py: 0,
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Vízpart
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {filter === 'buy' ? (
                          <>
                            <NumberFormatCustom
                              value={`${lastProperty?.waterDistanceFrom || 0}`}
                              displayType="text"
                              suffix=" m"
                            />
                            {' - '}
                            <NumberFormatCustom
                              value={`${lastProperty?.waterDistanceTo || 0}`}
                              displayType="text"
                              suffix=" m"
                            />
                          </>
                        ) : (
                          <NumberFormatCustom
                            value={`${lastProperty?.waterDistance || 0}`}
                            displayType="text"
                            suffix=" m"
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    alignItems: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    display="flex"
                    justifyContent={mode === 'grid' && 'space-between'}
                    p={1}
                  >
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        sx={{ mr: 2 }}
                      >
                        Telekterület
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {filter === 'buy' ? (
                          <>
                            <NumberFormatCustom
                              value={`${lastProperty?.landAreaFrom || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                            {' - '}
                            <NumberFormatCustom
                              value={`${lastProperty?.landAreaTo || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                          </>
                        ) : (
                          <>
                            <NumberFormatCustom
                              value={`${lastProperty?.landArea || 0}`}
                              displayType="text"
                              suffix=" m&sup2;"
                            />
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={mode === 'grid' ? 12 : 12}
                sm={mode === 'grid' ? 12 : 12}
                alignItems="flex-start"
                flexDirection="column"
                justifyContent="flex-start"
                sx={{
                  px: 1,
                  py: 0,
                  display: 'flex',
                  width: '100%',
                  // flexGrow: 6
                }}
              >
                <Grid
                  item
                  sx={{
                    px: 1,
                    py: 0,
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{ p: 1 }}
                  >
                    <Grid
                      container
                      display="flex"
                      justifyContent={mode === 'grid' && 'space-between'}
                    >

                      <Grid item>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          sx={{ mr: 2 }}
                        >
                          Település:
                        </Typography>
                      </Grid>
                      <Grid item>
                        {filter === 'buy' ? (
                          <>
                            <Typography // TODO: locations instead of cities
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {lastProperty?.cities || 'Nincs megadva'} /
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {lastProperty?.address?.formattedAddress || 'Nincs megadva'}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )} */}
        {/* {filter !== 'all' && (
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            mt={0}
          >
            <IconButton
              sx={{ mr: 1 }}
              onClick={handleIsCollapse}
            >
              <ChevronDownIcon
                fontSize="small"
                sx={{
                  transform: isCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: '0.2s',
                }}
              />
            </IconButton>
          </Grid>
        )} */}
        <Collapse in={isCollapse}>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map((tab) => tab.visible && (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Divider />
          <Box sx={{ p: 2 }}>
            {currentTab === 'details' && (
              <ClientBasicDetails client={client} />
            )}
            {currentTab === 'orders' && (
              <ClientOrders clientId={client.id} />
            )}
            {currentTab === 'searches' && (
              <ClientSearches
                // clientSearches={client.searches}
                clientId={client.id}
              />
            )}
            {currentTab === 'recommendations' && (
              <ClientPresentation id={client.id} isClientCard />
            )}
            {currentTab === 'contact' && (
              // <ClientContactDetails contactHistory={client.interactions && client.interactions} /> // TODO: get contactHistory from backend
              // <ClientRecommendations />
              <ClientCallList clientId={client?.id} />
            )}
            {currentTab === 'message' && (
              <ClientMessage clientId={client?.id} />
            )}
          </Box>
        </Collapse>
      </Card>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        elevation={1}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 250
          }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`/clients/${client.id}`}
        >
          <ListItemIcon>
            <ContactPageOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Adatlap" />
        </MenuItem>
        {/* <PermissionsGate
          scopes={[SCOPES.canClientUpdate, SCOPES.canClientUpdateOwn]}
          employeeList={client.clientEmployees}
          officeId={client.clientEmployees.length > 0 ? client.clientEmployees[0].employee?.office?.id : 0}
          ownerId={client.clientEmployees.length > 0 ? client.clientEmployees[0].employee.id : 0}
          statusId={handleStatusId}
        > */}

        {canIEdit() && (
          <MenuItem
            component={RouterLink}
            to={`/clients/${client.id}/edit`}
          >
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Ügyfél szerkesztése" />
          </MenuItem>
        )}
        {/* </PermissionsGate> */}
        {/* {client.state !== 4 && client.state !== 5 && (
          <PermissionsGate
            scopes={[SCOPES.canClientUpdate]}
          >
            <MenuItem
              onClick={handleMarkArchiveClient}
            >
              <ListItemIcon>
                <ArchiveOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Archiválásra jelölés" />
            </MenuItem>
          </PermissionsGate>
        )} */}
        {/* {client.state !== 4 && ( */}
        <PermissionsGate
          scopes={[SCOPES.canClientArchive]}
        >
          {client.state !== 4 && (
            <MenuItem onClick={() => dispatch(archiveClient(client.id))}>
              <ListItemIcon>
                <ArchiveOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText primary='Archiválás' />
            </MenuItem>
          )}
        </PermissionsGate>
        {client.state === 4 && isOfficeManagerOrAbove(user?.roles) && (
          <MenuItem onClick={() => dispatch(activateClient(client.id))}>
            <ListItemIcon>
              <ArchiveOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primary='Archiválás visszavonása' />
          </MenuItem>
        )}
        {/* )} */}
      </Menu>
    </>
  );
};

ClientCard.propTypes = {
  client: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  closeSearchBar: PropTypes.func,
  searchComponent: PropTypes.bool
};

export default ClientCard;
