import { createSlice } from '@reduxjs/toolkit';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';

const initialState = {
  applicants: {},
  searchParams: {},
  isModalOpen: false,
  selectedApplicantId: null,
};

const slice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    getApplicants(state, action) {
      state.applicants = action.payload;
    },
    createApplicant(state, action) {
      state.applicants?.data.unshift(action.payload);
      state.applicants.totalElements += 1;
      if (state.applicants.totalElements > (state.applicants.pageSize * (state.applicants.pageNumber + 1))) {
        state.applicants?.data.pop();
      }
    },
    setSearchParams(state, action) {
      state.searchParams = action.payload;
    },
    selectApplicant(state, action) {
      const { applicantId } = action.payload;
      state.selectedApplicantId = applicantId;
      state.isModalOpen = true;
    },
    updateApplicant(state, action) {
      const applicant = action.payload;

      state.applicants.data = state.applicants?.data.map((_applicant) => {
        if (_applicant.id === applicant.id) {
          return applicant;
        }

        return _applicant;
      });
    },
    deleteApplicant(state, action) {
      state.applicants.data = state.applicants.data.filter((applicant) => applicant.id !== action.payload);
      state.applicants.totalElements -= 1;
    },
    createFile(state, action) {
      state.applicants.data.forEach((applicant) => {
        if (applicant.id === action.payload.applicantId) {
          applicant.documents.push(action.payload.data);
        }
      });
    },
    deleteApplicantFile(state, action) {
      const applicant = action.payload;

      state.applicants.data = state.applicants?.data.map((_applicant) => {
        if (_applicant.id === applicant.id) {
          const docs = _applicant.documents.filter((doc) => doc.id !== applicant.fileId);
          _applicant.documents = docs;
          return _applicant;
        }

        return _applicant;
      });
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedApplicantId = null;
    }
  }
});

export const { reducer } = slice;

export const setSearchParams = (searchParams) => async (dispatch) => {
  dispatch(slice.actions.setSearchParams(searchParams));
};

export const getApplicants = (pageNumber, filters, pageSize) => async (dispatch) => {
  // const data = await applicantApi.getApplicants(filters);
  await axiosConfig.post(
    `candidates/filter?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 10}`,
    filters
  ).then((response) => {
    // dispatch(slice.actions.createApplicant(response.data));
    dispatch(slice.actions.getApplicants(response.data));
  }).catch(() => {
    toast.error('jelentkezők lekérése sikertelen');
  }).finally(() => {

  });
};

export const createApplicant = (createData, files) => async (dispatch) => {
  // const data = await applicantApi.createApplicant(createData);
  await axiosConfig.post(
    'candidates',
    createData
  ).then((response) => {
    toast.success('jelentkező felvétele sikeres');
    dispatch(slice.actions.createApplicant(response.data));
    for (let i = 0; i < files.length; i++) {
      const file = files[i].attributes;
      const formData = new FormData();
      // formData.append('file', createData.files)
      // console.log(file, 'flies');
      formData.append('file', file);
      formData.append('title', file.name);
      axiosConfig.post(
        `candidates/${response.data.id}/files`,
        // createData.files
        formData
      ).then((response2) => {
        console.log(response2);
        toast.success('fájl sikeresen feltöltve');
        dispatch(slice.actions.createFile({ applicantId: response.data.id, data: response2.data }));
      }).catch((error) => {
        console.error(error);
        toast.error('hiba a fájl feltöltésekor');
      }).finally(() => {
        // always executed
      });
    }
  }).catch((error) => {
    console.error(error);
    toast.error('jelentkező felvétele sikertelen');
  }).finally(() => {

  });
};

export const deleteApplicantFile = (applicantId, fileId) => async (dispatch) => {
  await axiosConfig.delete(
    `candidates/${applicantId}/files/${fileId}`
  ).then(() => {
    toast.success('Fájl sikeresen törölve');
    const data = { id: applicantId, fileId };
    dispatch(slice.actions.deleteApplicantFile(data));
  }).catch((error) => {
    console.error(error);
    toast.error('Fájl törlése sikertelen');
  }).finally(() => {

  });
};

export const selectApplicant = (applicantId, type) => async (dispatch) => {
  dispatch(slice.actions.selectApplicant({ applicantId, type }));
};

export const updateApplicant = (applicantId, update, files) => async (dispatch) => {
  await axiosConfig.put(
    `candidates/${applicantId}`,
    update
  ).then((response) => {
    dispatch(slice.actions.updateApplicant(response.data));
    for (let i = 0; i < files.length; i++) {
      const file = files[i].attributes;
      const formData = new FormData();
      // formData.append('file', createData.files)
      console.log(file, 'flies');
      formData.append('file', file);
      formData.append('title', file.name);
      axiosConfig.post(
        `candidates/${response.data.id}/files`,
        // createData.files
        formData
      ).then((response2) => {
        console.log(response2);
        toast.success('fájl sikeresen feltöltve');
        dispatch(slice.actions.createFile({ applicantId, data: response2.data }));
      }).catch((error) => {
        toast.error('hiba a fájl feltöltésekor');
        console.error(error);
      }).finally(() => {
        // always executed
      });
    }
  }).catch((error) => {
    console.error(error);
    toast.error('jelentkező frissítése sikertelen');
  }).finally(() => {

  });
  // const data = await applicantApi.updateApplicant({
  //   applicantId,
  //   update
  // });

  // dispatch(slice.actions.updateApplicant(data));
};

export const deleteApplicant = (applicantId, page, search, limit) => async (dispatch) => {
  await axiosConfig.delete(
    `candidates/${applicantId}`
  ).then(() => {
    toast.success('Jelentkező sikeresen törölve');
    dispatch(slice.actions.deleteApplicant(applicantId));
    console.log(page, search, limit, 'page, search, limit');
    dispatch(getApplicants(page, search, limit));
  }).catch((error) => {
    console.error(error);
    toast.error('Jelentkező törlése sikertelen');
  }).finally(() => {
  });
};

export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
