import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Grid,
  // FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
  IconButton,
  Menu,
  // MenuItem,
  Typography,
  makeStyles,
  Button,
  Divider
} from '@material-ui/core';
import { CalendarToolbar } from '.';
import { FilterListOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { getSimplifiedOffices } from '../../../slices/office';
import { useDispatch, useSelector } from '../../../store';
import { getEmployees } from '../../../slices/employee';
import { selectLoading } from '../../../slices/loading';
import AOCAutocomplete from '../../AOCAutocomplete';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.navigation.text
  }
}));

const CalendarFilter = (props) => {
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.employee);
  const { simplifiedOffices } = useSelector((state) => state.office);
  const isLoadingArray = useSelector(selectLoading);
  const { defaultUser, checkboxOptions, filters, viewTitle, date, onDateNext, onDatePrev, onDateToday, onViewChange, view, setFilters } = props;
  const [employeeSearchName, setEmployeeSearchName] = useState();
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [calendarFilters, setCalendarFilters] = useState({
    office: null,
    user: defaultUser,
  });

  const handleHover = (e) => {
    if (e.type === 'mouseenter') {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getEmployees(0, { office: calendarFilters.office?.id }, employeeSearchName, 100));
    }, 400);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [employeeSearchName, calendarFilters.office]);

  useEffect(() => {
    dispatch(getSimplifiedOffices());
  }, []);

  const handleVisibility = () => {
    // if (calendar) { /** NOTE: Hide Today Button when the currentDay is visible */
    //   const calendarApi = calendar.getApi();

    //   const today = new Date().getDate();
    //   const currentStartDay = calendarApi.view.currentStart.getDate();
    //   const currentEndDay = calendarApi.view.currentEnd.getDate();

    //   if (currentStartDay <= today && today < currentEndDay) {
    //     return '0';
    //   }
    // }
    if (isHover) {
      return '1';
    }
    return '0.7';
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
    // {...other}
    >
      <Grid
        item
        display="flex"
      >
        <Button
          onClick={onDateToday}
          color="inherit"
          size="small"
          onMouseEnter={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e)}
          sx={{
            opacity: handleVisibility,
            transition: '0.5s',
          }}
        >
          Ma
        </Button>
        <CalendarToolbar
          date={date}
          onDateNext={onDateNext}
          onDatePrev={onDatePrev}
          onDateToday={onDateToday}
          onViewChange={onViewChange}
          view={view}
        />
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
      >
        <IconButton
          className={classes.icon}
          onClick={onDatePrev}
          disableRipple
          disableFocusRipple
        >
          <ChevronLeftRoundedIcon />
        </IconButton>
        <Tooltip
          title={date ? `Vissza a mai dátumhoz (${format(new Date(), 'yyyy. MMMM dd.', { locale: huLocale })})` : 'Vissza a mai naphoz'}
          placement="top"
        >
          <Box
            onClick={onDateToday}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {viewTitle || format(date, 'yyyy MMMM', { locale: huLocale })}
            </Typography>
          </Box>
        </Tooltip>
        <IconButton
          className={classes.icon}
          onClick={onDateNext}
          disableRipple
          disableFocusRipple
        >
          <ChevronRightRoundedIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Box sx={{ color: 'text.primary' }}>
          <Grid
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row'
              }
            }}
          >
            <div>
              <Tooltip
                title="Szűrés"
                placement="top"
              >
                <IconButton
                  onClick={handleMenuOpen}
                  ref={moreRef}
                  variant="contained"
                >
                  <FilterListOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={moreRef.current}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
                elevation={1}
                onClose={handleMenuClose}
                open={openMenu}
                PaperProps={{
                  sx: {
                    mt: (theme) => theme.spacing(5),
                    // maxWidth: '100%',
                    // width: 200
                  }
                }}
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
              >
                {checkboxOptions.map((filterOption, i) => (
                  <Box key={filterOption.id}>
                    {i !== 0 && (
                      <Divider />
                    )}
                    <Box>
                      <FormControlLabel
                        label={filterOption.label}
                        sx={{ py: 1, px: 2, width: '100%' }}
                        control={(
                          <Checkbox
                            value={filterOption.value}
                            checked={filters.calendarTypes.includes(filterOption.value)}
                            onChange={(event) => {
                              if (!filters.calendarTypes.includes(event.target.value)) {
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  calendarTypes: [...prevFilters.calendarTypes, event.target.value]
                                }));
                              } else {
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  calendarTypes: prevFilters.calendarTypes.filter((item) => item !== event.target.value)
                                }));
                              }
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                ))}
              </Menu>
            </div>
            <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                width: 180
              }}
            >
              <Autocomplete
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={simplifiedOffices}
                size="small"
                // freeSolo // NOTE: If u do not want to bound it to provided options, use this (in this case u wont need isOptionEqualToValue anymore)
                noOptionsText="Nincs találat"
                onChange={(_, value) => {
                  setCalendarFilters((prevFilters) => ({
                    ...prevFilters,
                    office: value
                  }));
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    officeId: value?.id
                  }));
                }}
                value={calendarFilters.office}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    label="Iroda"
                    name="office"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                maxWidth: '100%',
                width: 180
              }}
            >
              <AOCAutocomplete
                size="small"
                options={employees?.data && employees?.data}
                fullWidth
                onChange={(_, value) => {
                  setCalendarFilters((prevFilters) => ({
                    ...prevFilters,
                    user: value
                  }));
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    userId: value?.id
                  }));
                }}
                textFieldOnChange={(event) => setEmployeeSearchName(event.target.value)}
                placeholder="Kezdje el beírni a munkatárs nevét."
                label="Munkatárs"
                loadingArray={isLoadingArray.includes('GET_EMPLOYEES')}
                value={calendarFilters.user}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

CalendarFilter.propTypes = {
  // children: PropTypes.node,
  // onFilterChange: PropTypes.func,
  checkboxOptions: PropTypes.array,
  filters: PropTypes.object,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onViewChange: PropTypes.func,
  date: PropTypes.instanceOf(Date).isRequired,
  viewTitle: PropTypes.string,
  view: PropTypes.string,
  calendar: PropTypes.object,
  setFilters: PropTypes.func,
  defaultUser: PropTypes.object
};

export default CalendarFilter;
