import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Box,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CalendarMeetingEventModal from '../../../../modals/CalendarMeetingEventModal';
import CalendarAppointmentEventModal from '../../../../modals/CalendarAppointmentEventModal';
import MainCalendar from '../../../../../pages/dashboard/MainCalendar';
import { useDispatch } from '../../../../../store';
import { selectCallEventRange, openModal } from '../../../../../slices/calendar';
import PlusIcon from '../../../../../icons/Plus';

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

const CallTask = (props) => {
  const dispatch = useDispatch();
  const {
    newCallProcess,
    onBack,
    onComplete,
    handleOpenisConfrimModal,
    client,
  } = props;
  const { name } = client ?? {};
  const [open, setOpen] = useState(true);

  const handleSelect = (arg) => dispatch(selectCallEventRange(arg.start.getTime(), arg.end.getTime(), newCallProcess?.callResult?.meeting, newCallProcess?.callResult?.appointment));

  const handleOpenModal = () => {
    if (newCallProcess?.callResult?.meeting) {
      dispatch(openModal('meeting'));
    } else {
      dispatch(openModal('appointment'));
    }
  };

  const handleClose = () => {
    setOpen(false);
    onBack();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {newCallProcess.callResult.resultName === 1 ? 'Találkozó megszervezése' : 'Ügyfél visszahívást kér'}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClose}
            >
              Vissza
            </Button>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                handleOpenisConfrimModal();
              }}
            >
              Megszakítás
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 5,
            px: 3
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid
              item
              flexGrow={1}
            />
            <Grid item>
              <Box>
                <Button
                  color="primary"
                  onClick={handleOpenModal}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  {newCallProcess?.callResult?.meeting ? (

                    <Typography variant="inherit">Találkozó hozzáadása</Typography>
                  )
                    : (
                      <Typography variant="inherit">Visszahívás időpontjának hozzáadása</Typography>
                    )}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <MainCalendar handleSelect={handleSelect} />
        </Box>
        <CalendarMeetingEventModal
          processModalClose={handleClose}
          onProcessModalComplete={onComplete}
          clientName={name}
        />
        <CalendarAppointmentEventModal
          processModalClose={handleClose}
          onProcessModalComplete={onComplete}
          clientName={name}
        />
      </Dialog>
    </>
  );
};

CallTask.propTypes = {
  newCallProcess: PropTypes.object,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
  handleOpenisConfrimModal: PropTypes.func,
  client: PropTypes.object,
};

export default CallTask;
